import React from 'react';
import { LoadingPage } from 'src/components/LoadingPage/LoadingPage';
import ProjectListWrapper from '../components/ProjectListWrapper/ProjectListWrapper';
import Page from 'src/components/Page/Page';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'src/features/authorization';
import { Link } from 'react-router-dom';
import useDocumentTitle from 'src/hooks/use-document-title';
import DocumentTitle from 'src/components/DocumentTitle/DocumentTitle';
import { PortalProjectListItemDto } from 'src/api/v2';
import usePortalProjects from '../hooks/use-portal-projects';

const OtherProjects: React.FC = () => {
  const { t: tProjects } = useTranslation('projects');
  const { t: tPageTitles } = useTranslation('pageTitles');

  const { checkAccess } = useAuth();

  const { portalProjectsQuery } = usePortalProjects();

  const { documentTitle } = useDocumentTitle(tPageTitles('ownProjects'), tProjects('projects'));

  if (portalProjectsQuery.isFetching) {
    return (
      <Page>
        <DocumentTitle title={documentTitle} />
        <LoadingPage />
      </Page>
    );
  }

  return (
    <Page>
      <DocumentTitle title={documentTitle} />
      <h1>{tPageTitles('ownProjects')}</h1>
      <p>
        {tProjects('portalProjectsDescription')}{' '}
        {checkAccess('DevelopmentProject').read && (
          <span>
            {tProjects('seeINProjects')}{' '}
            <Link to="/projects/in-projects">{tProjects('inFinancedProjects')}</Link>
          </span>
        )}
      </p>

      <ProjectListWrapper<PortalProjectListItemDto>
        isFetching={portalProjectsQuery.isFetching}
        projects={portalProjectsQuery.data || []}
        projectType={'other'}
      />
    </Page>
  );
};

export default OtherProjects;
