import React from 'react';
import { HelpAndSupportMenuPageSelection } from '../constants/HelpAndSupportMenu';
import ContactUs from './ContactUs';
import CommonQuestions from './common-questions/CommonQuestions';

import { GuidedTourWrapper } from './guided-tour/GuidedTourWrapper';

type Props = {
  subpage: HelpAndSupportMenuPageSelection;
};

const HelpAndSupportMenuContent: React.FC<Props> = ({ subpage }) => {
  switch (subpage) {
    case 'stepConfirmation':
      return <GuidedTourWrapper />;
    case 'commonQuestions':
      return <CommonQuestions />;
    case 'contactUs':
      return <ContactUs />;
    case 'none':
      break;
  }

  return <></>;
};

export default HelpAndSupportMenuContent;
