import React, { useMemo } from 'react';
import css from './SupportButtonPopup.module.scss';
import { FaIcon, FaIcons, IconButton } from '@in/component-library';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'src/features/authorization';
import { useCluster } from 'src/features/cluster';

type SupportButtonPopupProps = {
  show: boolean;
  deactivate: () => void;
};

const SupportButtonPopup: React.FC<SupportButtonPopupProps> = ({ show, deactivate }) => {
  const { t: tCommon } = useTranslation('common');
  const { t: tReports } = useTranslation('reports');

  const { cluster } = useCluster();
  const { checkAccess } = useAuth();

  const userUrl = window.location.href;
  const userBrowser = navigator.userAgent;

  const classNames = show ? css['support-button-popup'] : css['support-button-popup--hide'];

  const isCluster = useMemo(() => {
    if (!cluster) {
      return true;
    }
    return !checkAccess('OneWayIn').read && !checkAccess('SivaCore').read;
  }, [checkAccess, cluster]);

  const isOneWayIn = useMemo(() => {
    if (!cluster) {
      return true;
    }
    return checkAccess('OneWayIn').read;
  }, [checkAccess, cluster]);

  const isSiva = useMemo(() => {
    if (!cluster) {
      return true;
    }
    return checkAccess('SivaCore').read;
  }, [checkAccess, cluster]);

  return (
    <div className={classNames}>
      <div className={css['support-button-popup__top-container']}>
        <p className="font-weight--bold">{tCommon('contactSupport')}</p>
        <IconButton
          className={css['support-button-popup__top-container--close']}
          iconName={'crossmark'}
          ariaLabel={tCommon('close')}
          size="small"
          onClick={() => deactivate()}
        />
      </div>
      <p className={css['support-button-popup__help-text']}>{tCommon('howCanWeHelp')}</p>
      <div className={css['support-button-popup__links']}>
        {isCluster && (
          <p>
            <a
              aria-label="Kontakt Klyngeportalen på e-post"
              href={`mailto:flerbedriftsportal@innovasjonnorge.no?subject=Klynge&body=%0D%0A%0D%0AURL%3A%20${userUrl}%0D%0ANettleser%3A%20${userBrowser}`}
            >
              <FaIcon className={'margin-right--1'} name={FaIcons.EnvelopeLight} />
              {tReports('memberType.CLUSTER')}
            </a>
          </p>
        )}
        {isOneWayIn && (
          <p>
            <a
              aria-label="Kontakt Én Vei Inn på e-post"
              href={`mailto:support@enveiinn.no?subject=En%20vei%20inn&body=%0D%0A%0D%0AURL%3A%20${userUrl}%0D%0ANettleser%3A%20${userBrowser}`}
            >
              <FaIcon className={'margin-right--1'} name={FaIcons.EnvelopeLight} />
              {tCommon('oneWayIn')}
            </a>
          </p>
        )}
        {isSiva && (
          <p>
            <a
              aria-label="Kontakt Siva på e-post"
              href={`mailto:flerbedriftsportal@innovasjonnorge.no?subject=Rapportering%20til%20SIVA&body=%0D%0A%0D%0AURL%3A%20${userUrl}%0D%0ANettleser%3A%20${userBrowser}`}
            >
              <FaIcon className={'margin-right--1'} name={FaIcons.EnvelopeLight} />
              {tCommon('sivaReporting')}
            </a>
          </p>
        )}
      </div>
      <p className={css['support-button-popup__email']}>
        {tCommon('allInquiriesSentTo')}{' '}
        <a href="mailto:flerbedriftsportal@innovasjonnorge.no">flerbedriftsportal@innovasjonnorge.no</a>
      </p>
    </div>
  );
};

export default SupportButtonPopup;
