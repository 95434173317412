import { Workbook } from 'exceljs';
import saveAs from 'file-saver';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import {
  DevelopmentProjectDto,
  DevelopmentProjectListItemDto,
  OperationProjectDto,
  PortalProjectDto,
  PortalProjectListItemDto,
  ProjectStatisticsDto,
} from 'src/api/v2';
import { PROJECT_STATISTICS_KEY } from 'src/constants/hook-keys';

function formatDate(input: Date) {
  if (!input) {
    return '';
  }

  const dateInput = new Date(input);

  const date = dateInput.getDate();
  const month = dateInput.getMonth() + 1;
  const year = dateInput.getFullYear();

  const d = String(date).padStart(2, '0');
  const m = String(month).padStart(2, '0');

  return `${d}.${m}.${year}`;
}

export default function useProjectsExport() {
  const { t: tCommon } = useTranslation();
  const { t: tProjects } = useTranslation('projects');

  const queryClient = useQueryClient();

  const exportToExcel = (
    items: (
      | DevelopmentProjectListItemDto
      | PortalProjectListItemDto
      | PortalProjectDto
      | DevelopmentProjectDto
      | OperationProjectDto
    )[],
  ) => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Data');

    worksheet.columns = [
      {
        header: tCommon('name'),
        key: 'name',
        width: 32,
      },
      {
        header: tCommon('startDate'),
        key: 'startDate',
        width: 14,
      },
      {
        header: tCommon('endDate'),
        key: 'endDate',
        width: 14,
      },
      {
        header: tProjects('projectLeader'),
        key: 'projectLeader',
        width: 28,
      },
      {
        header: tCommon('category'),
        key: 'projectCategory',
        width: 14,
      },
      {
        header: tProjects('projectType'),
        key: 'projectType',
        width: 14,
      },
      {
        header: tCommon('phase'),
        key: 'phase',
        width: 14,
      },
      {
        header: tCommon('participants'),
        key: 'participants',
        width: 14,
      },
      {
        header: tProjects('registeredHours'),
        key: 'hoursRegistered',
        width: 14,
      },
      {
        header: tCommon('inkind'),
        key: 'inkind',
        width: 14,
      },
      {
        header: tProjects('numberOfEvents'),
        key: 'endDate',
        width: 14,
      },
      {
        header: tProjects('mainGoal'),
        key: 'mainGoal',
        width: 32,
      },
      {
        header: tProjects('focusAreas'),
        key: 'focusAreas',
        width: 32,
      },
    ];

    worksheet.getRow(1).font = {
      bold: true,
    };

    const rows = items.map((project) => {
      const data = queryClient.getQueryData<ProjectStatisticsDto>([PROJECT_STATISTICS_KEY, project.id]);
      const isPortalProject = (<PortalProjectDto>project).projectCategory !== null;

      return [
        project.name,
        formatDate(project.startDate),
        formatDate(project.endDate),
        '', // project leader, no longer part of projects
        (isPortalProject && (<PortalProjectDto>project).projectCategory?.name) || '',
        (!isPortalProject && (<DevelopmentProjectDto>project).projectType?.name) || '',
        project.projectPhase?.name || '',
        data?.uniqueParticipants,
        data?.timeEntryHoursTotal || 0, // hoursRegistreded
        data?.timeEntryCostTotal || 0, // inkind
        data?.numberOfEvents || 0, // events
        '', // main goals, no longer part of dto
        '', // focus areas, no longer part of dto
      ];
    });
    worksheet.addRows(rows);

    return workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
      });

      saveAs(blob, `${tCommon('project_other')}-${new Date().toJSON()}.xlsx`);
    });
  };

  return {
    exportToExcel,
  };
}
