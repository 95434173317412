import React, { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  InputField,
  Toggle,
  Select,
  TextArea,
  Button,
  ButtonVariant,
  Theme,
  DateInput,
  SelectOptionProps,
} from '@in/component-library';
import {
  DevelopmentProjectDto,
  DevelopmentProjectListItemDto,
  EventCategory,
  EventCreateDtoV2,
  EventDtoV2,
  EventUpdateDtoV2,
  OperationProjectDto,
  PortalProjectDto,
  PortalProjectListItemDto,
  ProjectListItemDto,
  ProjectsDto,
} from 'src/api/v2';
import { requiredI18n } from 'src/utils/validation';

import './EventForm.scss';
import { useTranslation } from 'react-i18next';
import { convertHoursAndMinutesToHours, convertHourstoHoursAndMinutes } from 'src/utils/time';
import AddEntityModalButton from 'src/components/AddEntityModalButton/AddEntityModalButton';
import DeleteEventForm from './DeleteEventForm';
import { formatDateInputDate } from 'src/utils/FormatValue';
import { getSafeDateOnly } from 'src/utils/date';

interface EventFormProps {
  onSubmit: (data: ExtendedCreateEventDto | ExtendedUpdateEventDto) => void;
  onClose?: () => void;
  setSelectedProjectId: (id: string) => void;
  project?:
    | DevelopmentProjectDto
    | PortalProjectDto
    | OperationProjectDto
    | ProjectsDto
    | DevelopmentProjectListItemDto
    | PortalProjectListItemDto
    | ProjectListItemDto;
  defaultValues: Partial<EventDtoV2 & { projectId?: string }>;
  showProjectSelector: boolean;
  showExpenseAssociation: boolean;
  showDeleteFormButton: boolean;
  shouldUseDefaults: boolean;
  isLoading: boolean;
  projectOptions: SelectOptionProps[];
  projectExpenseOptions: SelectOptionProps[];
  projectStartDate?: Date;
  projectEndDate?: Date;
  activityStartDate?: Date;
  activityEndDate?: Date;
}

export interface ExtraFormDto {
  formHours: number;
  formMinutes: number;
  projectId: string;
  expenseTypeIds: string[];
  category: EventCategory;
}
type ExtendedCreateEventDto = EventCreateDtoV2 & ExtraFormDto;
type ExtendedUpdateEventDto = EventUpdateDtoV2 & ExtraFormDto;
const EventForm: FC<EventFormProps> = ({
  onSubmit,
  onClose,
  setSelectedProjectId,
  project,
  defaultValues,
  showProjectSelector,
  showExpenseAssociation,
  showDeleteFormButton,
  shouldUseDefaults,
  projectOptions,
  projectExpenseOptions,
  isLoading,
}) => {
  const { t } = useTranslation();
  const { t: tEvents } = useTranslation('events');
  const { t: tProjects } = useTranslation('projects');
  const { t: tError } = useTranslation('error');
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    watch,
    getValues,
  } = useForm<ExtendedCreateEventDto | ExtendedUpdateEventDto>(
    shouldUseDefaults ? { defaultValues } : undefined,
  );
  const values = getValues();

  const minuteWatch = watch('formMinutes', 0);
  const hourWatch = watch('formHours', 0);

  function ensureInt(n: number) {
    const parsed = parseInt(n + '');
    return parsed || 0;
  }

  const { hours, minutes } = convertHourstoHoursAndMinutes(defaultValues?.duration || values.duration);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {showProjectSelector && (
        <Controller
          control={control}
          name="projectId"
          rules={{
            validate: {
              assertNotEmpty: (value) =>
                value != null && value !== ' ' ? true : requiredI18n(tError).message,
            },
          }}
          render={({ field, fieldState }) => (
            <Select
              label={tProjects('project')}
              placeholder={`${t('select')} ${tProjects('project').toLowerCase()}`}
              options={projectOptions}
              value={field.value}
              name={field.name}
              onChange={(value) => {
                field.onChange(value);
                setSelectedProjectId(value);
                setValue('expenseTypeIds', []);
              }}
              errorMsg={fieldState.error?.message}
            />
          )}
        />
      )}

      <Controller
        control={control}
        name="name"
        rules={{ required: requiredI18n(tError) }}
        render={({ field, fieldState }) => (
          <InputField label={tEvents('eventName')} {...field} errorMsg={fieldState.error?.message} />
        )}
      />
      <div className="display--flex justify-content--center gap--2 date-input-container">
        <DateInput
          name={
            register('startDate', {
              required: requiredI18n(tError),
            }).name
          }
          label={t('startDate')}
          min={project ? formatDateInputDate(getSafeDateOnly(new Date(project.startDate))) : undefined}
          max={project ? formatDateInputDate(getSafeDateOnly(new Date(project.endDate))) : undefined}
          onChange={(event) => {
            const { value } = event.target;
            const StartDate = value
              ? getSafeDateOnly(new Date(value))
              : project
                ? getSafeDateOnly(new Date(project.startDate))
                : getSafeDateOnly(new Date());
            setValue('startDate', StartDate, { shouldValidate: true, shouldDirty: true });
          }}
          value={
            values.startDate ? formatDateInputDate(getSafeDateOnly(new Date(values.startDate))) : undefined
          }
          errorMsg={errors.startDate?.message}
        />
        <DateInput
          name={register('endDate').name}
          label={`${t('endDate')} (${t('optional')})`}
          min={project ? formatDateInputDate(getSafeDateOnly(new Date(project.startDate))) : undefined}
          max={project ? formatDateInputDate(getSafeDateOnly(new Date(project.endDate))) : undefined}
          onChange={(event) => {
            const { value } = event.target;
            const EndDate = value
              ? getSafeDateOnly(new Date(value))
              : project
                ? getSafeDateOnly(new Date(project.endDate))
                : getSafeDateOnly(new Date());
            setValue('endDate', EndDate, { shouldValidate: true, shouldDirty: true });
          }}
          value={values.endDate ? formatDateInputDate(getSafeDateOnly(new Date(values.endDate))) : undefined}
          errorMsg={errors.endDate?.message}
        />
      </div>
      <Toggle
        label={tEvents('closedEvent')}
        labelPosition="right"
        value={values.isPublic ? false : true}
        onValueChange={(value) => {
          setValue('isPublic', value);
        }}
      />
      <TextArea
        label={t('description')}
        {...register('description', {
          required: requiredI18n(tError),
        })}
        errorMsg={errors?.description?.message}
      />
      <div className="display--flex gap--2">
        <Controller
          control={control}
          name="location"
          rules={{ required: requiredI18n(tError) }}
          render={({ field, fieldState }) => (
            <InputField
              className="width--70"
              value={field.value}
              label={tEvents('location')}
              name={field.name}
              onChange={(value) => {
                field.onChange(value);
              }}
              errorMsg={fieldState.error?.message}
            />
          )}
        />
      </div>
      <div className="display--flex gap--2 margin-bottom--1">
        <div className="input__label--text">{tEvents('expectedDuration')}</div>
      </div>

      <div className="display--flex gap--2">
        <InputField
          className="width--30"
          label={`${tEvents('expectedDuration')} ${tEvents('hours')}`}
          hideLabel={true}
          type="number"
          suffix={tEvents('hours')}
          {...register(`formHours`, {
            required: values.formMinutes === 0 ? requiredI18n(tError) : false,
            validate:
              minutes === 0
                ? {
                    assertTotalNotZero: (value) =>
                      convertHoursAndMinutesToHours(ensureInt(value), ensureInt(minuteWatch)) > 0
                        ? true
                        : requiredI18n(tError).message,
                  }
                : undefined,
          })}
          value={hours}
          errorMsg={errors.formHours?.message}
        />

        <InputField
          className="width--30"
          label={`${tEvents('expectedDuration')} ${tEvents('minutes')}`}
          hideLabel={true}
          aria-describedby={'hoursAndMinutesLabel'}
          type="number"
          suffix={tEvents('minutes')}
          {...register(`formMinutes`, {
            required: values.formHours === 0 ? requiredI18n(tError) : false,
            validate:
              hours === 0
                ? {
                    assertTotalNotZero: (value) =>
                      convertHoursAndMinutesToHours(ensureInt(hourWatch), ensureInt(value)) > 0
                        ? true
                        : requiredI18n(tError).message,
                  }
                : undefined,
          })}
          value={minutes}
          errorMsg={errors.formMinutes?.message}
        />
      </div>
      {showExpenseAssociation && (
        <Controller
          control={control}
          name="expenseTypeIds"
          rules={{ required: requiredI18n(tError) }}
          render={({ field, fieldState }) => {
            return (
              <Select
                label={tEvents('associateToType')}
                name={field.name}
                placeholder={`${t('select')} ${t('costType')}`}
                value={field.value?.[0]}
                options={projectExpenseOptions}
                onChange={(value) => {
                  field.onChange([value]);
                }}
                errorMsg={fieldState.error?.message}
              />
            );
          }}
        />
      )}
      <div className="display--flex gap--2 justify-content--space-between padding-top--3">
        <div>
          <Button
            type="submit"
            theme={Theme.Neutral}
            variant={ButtonVariant.Solid}
            isLoading={isLoading}
            className="margin-right--4"
          >
            {t('save')}
          </Button>
          <Button
            theme={Theme.Neutral}
            variant={ButtonVariant.Outlined}
            type="button"
            onClick={onClose}
            disabled={isLoading}
          >
            {t('cancel')}
          </Button>
        </div>

        {showDeleteFormButton && (
          <div>
            <AddEntityModalButton
              buttonText={t('delete')}
              buttonTheme={Theme.Danger}
              modalTitle={`${t('delete')} ${t('event').toLowerCase()}`}
            >
              <DeleteEventForm event={defaultValues as EventDtoV2} projectId={defaultValues?.projectId} />
            </AddEntityModalButton>
          </div>
        )}
      </div>
    </form>
  );
};

export default EventForm;
