import { useState } from 'react';
import {
  ClusterMemberEconomyCreateDto,
  ClusterMemberEconomyService,
  ClusterMemberEconomyUpdateDto,
} from 'src/api/v2';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { MEMBER_ECONOMY_KEY } from 'src/constants/hook-keys';
//TODO: rydde opp i queries
type UpdateMutationArgs = {
  clusterMemberEconomyId: string;
  clusterMemberEconomy: ClusterMemberEconomyUpdateDto;
};

export default function useMemberEconomy(memberId: string | undefined) {
  const [service] = useState(new ClusterMemberEconomyService());

  const queryClient = useQueryClient();

  const { data: economy } = useQuery(
    [MEMBER_ECONOMY_KEY, memberId],
    () => service.getClusterMemberEconomies(memberId),
    {
      enabled: !!memberId,
    },
  );

  const updateMutation = useMutation(
    ({ clusterMemberEconomyId, clusterMemberEconomy }: UpdateMutationArgs) => {
      return service.updateClusterMemberEconomy(clusterMemberEconomyId, clusterMemberEconomy);
    },
    {
      onSuccess() {
        queryClient.invalidateQueries([MEMBER_ECONOMY_KEY, memberId]);
      },
      retry: false,
    },
  );

  const createMutation = useMutation(
    (clusterMemberEconomy: ClusterMemberEconomyCreateDto) =>
      service.createClusterMemberEconomy(clusterMemberEconomy),

    {
      onSuccess() {
        queryClient.invalidateQueries([MEMBER_ECONOMY_KEY, memberId]);
      },
      retry: false,
    },
  );

  const deleteMutation = useMutation(
    (clusterMemberEconomyId: string) => {
      return service.deleteClusterMemberEconomy(clusterMemberEconomyId);
    },

    {
      onSuccess() {
        queryClient.invalidateQueries([MEMBER_ECONOMY_KEY, memberId]);
      },
      retry: false,
    },
  );

  return {
    economy,
    createMutation,
    updateMutation,
    deleteMutation,
  };
}
