import React from 'react';
import { Button, ModalActionButtonContainer } from '@in/component-library';
import TextCollapse from 'src/components/TextCollapse/TextCollapse';
import { EventDtoV2 } from 'src/api/v2';
import { toastPromise } from 'src/utils/toast';
import { useTranslation } from 'react-i18next';
import useProjectDetails from 'src/hooks/use-project-details';
import useEvent from 'src/hooks/use-event';
import { useNavigate } from 'react-router-dom';
import useQueryParams from 'src/hooks/use-query-params';
interface Props {
  event: EventDtoV2;
  projectId: string | undefined;
  onClose?: () => void;
}

const DeleteEventForm: React.FC<Props> = ({ event, projectId, onClose }) => {
  const { t: tCommon } = useTranslation();
  const { t: tEvents } = useTranslation('events');

  const { timeEntries } = useProjectDetails(projectId);

  const { deleteMutation } = useEvent(event.id);

  const navigate = useNavigate();

  const queryParams = useQueryParams();
  const currentProjectId = queryParams.get('projectId');

  const usedTimeEntries = timeEntries.filter(
    (timeEntry) => timeEntry.eventId === event.id && timeEntry.status === 'Counting',
  );

  const deleteHandler = () => {
    if (event.id) {
      const promise = deleteMutation.mutateAsync(event.id);

      toastPromise(promise, {
        pending: `${tCommon('deleting')} ${event.name}...`,
        success: `${event.name} ${tCommon('deleted')}`,
        error: tEvents('couldNotDeleteEvent'),
      }).then(() => {
        const eventProjectId = currentProjectId || projectId;
        onClose?.();
        if (eventProjectId) {
          navigate(`/projects/details/${eventProjectId}/events`);
        } else {
          navigate('/projects/events');
        }
      });
    }
  };

  return (
    <div>
      <p>{tEvents('deleteEventWarning')}</p>

      <div>
        {usedTimeEntries.length > 0 ? (
          <>
            <p className="margin-bottom--1">{`${usedTimeEntries.length} ${tEvents('contactsRegistered')}`}</p>

            <TextCollapse collapseText={tCommon('showAll')} ariaLabel={tEvents('showAllContacts')}>
              <ul>
                {usedTimeEntries.map((usedTimeEntry) => (
                  <li key={usedTimeEntry.id}>{usedTimeEntry.clusterContactName}</li>
                ))}
              </ul>
            </TextCollapse>
          </>
        ) : (
          ''
        )}
      </div>

      <p>{tCommon('cannotBeUndone')}</p>

      <ModalActionButtonContainer>
        <div>
          <Button theme="neutral" disabled={deleteMutation.isLoading} type="button" onClick={onClose}>
            {tCommon('cancel')}
          </Button>
        </div>

        <div>
          <Button theme="danger" disabled={deleteMutation.isLoading} type="button" onClick={deleteHandler}>
            {tCommon('delete')}
          </Button>
        </div>
      </ModalActionButtonContainer>
    </div>
  );
};

export default DeleteEventForm;
