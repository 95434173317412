import React from 'react';
import { ColumnDef, Row, Table } from '@tanstack/react-table';
import { IndeterminateCheckbox } from '../IndeterminateCheckbox/IndeterminateCheckbox';
import { useTranslation } from 'react-i18next';

type CreateSelectionColumnOptions = {
  disabledRowTitle?: string;
};

export function createSelectionColumn<TData extends object>({
  disabledRowTitle,
}: CreateSelectionColumnOptions): ColumnDef<TData, any> {
  return {
    id: '__selection',
    header: ({ table }) => {
      return <MarkAllRowsCheckbox table={table} />;
    },
    cell: ({ row }) => {
      return <MarkRowCheckbox row={row} disabledRowTitle={disabledRowTitle} />;
    },
    size: 50,
    maxSize: 50,
    enableSorting: false,
  };
}

function MarkAllRowsCheckbox<TData extends object>({ table }: { table: Table<TData> }) {
  const { t: tTableTitles } = useTranslation('tableTitles');

  return (
    <>
      <IndeterminateCheckbox
        className="margin-bottom--0 padding-x--0"
        title={tTableTitles('markAllRows')}
        {...{
          checked: table.getIsAllRowsSelected(),
          indeterminate: table.getIsSomeRowsSelected(),
          onChange: table.getToggleAllRowsSelectedHandler(),
        }}
      />
      <span className="sr-only">{tTableTitles('markAllRows')}</span>
    </>
  );
}

function MarkRowCheckbox<TData extends object>({
  row,
  disabledRowTitle,
}: {
  row: Row<TData>;
  disabledRowTitle?: string;
}) {
  const { t: tTableTitles } = useTranslation('tableTitles');
  const canSelect = row.getCanSelect();

  return (
    <IndeterminateCheckbox
      className="margin-bottom--0 padding-x--0"
      title={
        canSelect === false
          ? disabledRowTitle ?? tTableTitles('rowCannotBeMarked')
          : tTableTitles('markRow', {
              index: row.index + 1,
            })
      }
      {...{
        checked: row.getIsSelected(),
        disabled: !canSelect,
        indeterminate: row.getIsSomeSelected(),
        onChange: row.getToggleSelectedHandler(),
      }}
    />
  );
}
