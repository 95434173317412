import React from 'react';
import { useExternalCompany } from 'src/hooks/use-external-company';
import CompanyBaseInfoBox from './CompanyBaseInfoBox';
import CompanyInformationTabs from './company-tabs/CompanyInformationTabs';
import { useExternalAccountingData } from 'src/hooks/use-external-accounting-data';
import { Notification, PageLoader } from '@in/component-library';
import { Link } from 'react-router-dom';
import { ROUTES } from 'src/navigation';
import { useTranslation } from 'react-i18next';
import { SivaPhase } from 'src/api/v2';
import { FormStepButtons } from '../FormStepButtons';

type Props = {
  organizationNumber: string;
  availablePhases: ReadonlyArray<SivaPhase>;
  isBusinessGarden: boolean;
  stepForward: (() => void) | undefined;
  stepBackward: (() => void) | undefined;
  skipPhaseStep: () => void;
};

const NewCompanyInfo: React.FC<Props> = ({
  organizationNumber,
  availablePhases,
  isBusinessGarden,
  stepBackward,
  stepForward,
  skipPhaseStep,
}) => {
  const { t: tSiva } = useTranslation('siva');

  const { data: company, isLoading, isError } = useExternalCompany(organizationNumber);
  useExternalAccountingData(organizationNumber); // pre-loading for other tab

  if (isLoading) {
    return (
      <div>
        <h2>{tSiva('newCompany.info.title')}</h2>

        <p>{tSiva('newCompany.info.description')}</p>

        <p>{tSiva('newCompany.info.loadingText')}</p>

        <PageLoader aria-label={tSiva('newCompany.info.loadingText')} />
      </div>
    );
  }

  if (isError || !company) {
    return (
      <>
        <p>{tSiva('newCompany.info.error.text1')}</p>
        <p>{tSiva('newCompany.info.error.text2')}</p>

        <Link to={ROUTES.SIVA.IDEAS_AND_COMPANIES.BASE}>{tSiva('newCompany.info.error.link.text')}</Link>
      </>
    );
  }

  return (
    <div>
      <h2>{tSiva('newCompany.info.title')}</h2>

      <p>{tSiva('newCompany.info.description')}</p>

      <CompanyBaseInfoBox company={company} />

      <CompanyInformationTabs company={company} />

      <AvailablePhaseNotification
        availablePhases={availablePhases}
        companyName={company.name}
        isBusinessGarden={isBusinessGarden}
      />

      <FormStepButtons
        stepForward={availablePhases.length === 1 ? skipPhaseStep : stepForward}
        stepBackward={stepBackward}
        stepForwardDisabled={availablePhases.length === 0}
      />
    </div>
  );
};

function AvailablePhaseNotification({
  availablePhases,
  companyName,
  isBusinessGarden,
}: {
  availablePhases: ReadonlyArray<SivaPhase>;
  companyName: string | undefined;
  isBusinessGarden: boolean;
}) {
  const { t: tSiva } = useTranslation('siva');

  if (availablePhases.length === 0) {
    if (isBusinessGarden)
      return (
        <Notification type="error">
          {companyName} har allerede en avtale. Kun én avtale kan registreres per bedrift. Se
          avtaleinformasjonen til {companyName}
        </Notification>
      );
    return (
      <Notification type="error">
        {companyName} har allerede en avtale i en annen fase enn {tSiva('phase.Fi')}. Kun én fase kan
        registreres per bedrift unntatt {tSiva('phase.Fi')}. Se avtaleinformasjonen til {companyName}
      </Notification>
    );
  }

  if (availablePhases.length === 1 && availablePhases[0] === SivaPhase.Fi)
    return (
      <Notification type="info">
        {companyName} har allerede en avtale i fase {tSiva('phase.Fi')}. Ny avtale kan opprettes kun med fase{' '}
        {tSiva('phase.Fi')}.
      </Notification>
    );

  return <></>;
}

export default NewCompanyInfo;
