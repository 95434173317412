import React, { useState, useMemo } from 'react';

import { useCluster } from 'src/features/cluster';
import { Counties, CountiesBefore2024 } from '../constants/counties';
import '../styles/OneWayInStyle.scss';
import OneWayInReviewOpportunities from '../components/review/OneWayInReviewOpportunities';
import useDocumentTitle from 'src/hooks/use-document-title';
import { useTranslation } from 'react-i18next';
import DocumentTitle from 'src/components/DocumentTitle/DocumentTitle';
import Page from 'src/components/Page/Page';
import useOneWayInCounty from '../hooks/use-one-way-in-county';

const OneWayInCountyOpportunities: React.FC = () => {
  const { cluster, isLoading } = useCluster();
  const { t: tOneWayIn } = useTranslation('oneWayIn');
  const { documentTitle } = useDocumentTitle(tOneWayIn('opportunities'));

  const ownCounties = useMemo(() => {
    if (!cluster) return [];

    // New counties and support legacy counties.
    const counties = [
      ...Counties.filter((county) => cluster?.oneWayInCounties.includes(county.countyNumber)),
      ...CountiesBefore2024.filter((county) => cluster?.oneWayInCounties.includes(county.countyNumber)),
    ];

    return counties;
  }, [cluster]);

  // Due to possible duplicate municipality numbers because we also support legacy, ensure that we only have unique valules
  const allMunicipalities = useMemo(() => {
    if (!ownCounties) return [];
    return [...new Set(ownCounties.flatMap((county) => county.municipalities))];
  }, [ownCounties]);

  const [orderFromOldToNew, setOrderFromOldToNew] = React.useState<boolean>(false);
  const [fromDate, setFromDate] = useState<Date | undefined>(undefined);
  const [toDate, setToDate] = useState<Date | undefined>(undefined);

  const { oneWayInFormsQuery, oneWayInFeedbackListFromActorQuery } = useOneWayInCounty(
    allMunicipalities,
    [...new Set(ownCounties.map((county) => county.countyNumber))],
    orderFromOldToNew,
    fromDate,
    toDate,
  );

  if (ownCounties.length === 0 && !isLoading) {
    return (
      <DocumentTitle title={documentTitle}>
        <Page>
          <div>{tOneWayIn('errorNoCounties')}</div>
        </Page>
      </DocumentTitle>
    );
  }

  return (
    <OneWayInReviewOpportunities
      listQuery={oneWayInFormsQuery}
      feedbackListQuery={oneWayInFeedbackListFromActorQuery}
      fromList={[...new Set(ownCounties.map((county) => county.countyName))]}
      setFromDate={setFromDate}
      setToDate={setToDate}
      countyView
      orderFromOldToNew={{ init: orderFromOldToNew, set: setOrderFromOldToNew }}
    />
  );
};

export default OneWayInCountyOpportunities;
