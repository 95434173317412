import React from 'react';

import ColumnLinks from './components/ColumnLinks';
import ColumnWrapper from './components/ColumnWrapper';
import ColumnFigCaptions from './components/ColumnFigCaptions';
import ColumnSivaContactSupport from './components/ColumnSivaContactSupport';

import styles from './styles/SivaFooter.module.scss';

const SivaFooter = () => {
  return (
    <footer className={styles['footer']}>
      <ColumnWrapper>
        <ColumnFigCaptions />

        <ColumnSivaContactSupport />

        <ColumnLinks />
      </ColumnWrapper>
    </footer>
  );
};

export default SivaFooter;
