import React from 'react';
import Label from './Label';
import { FormGroupErrorContext } from './FormGroup';
import FormElementError from './FormElementError';
import classnames from 'classnames';
import './style.scss';
import generateGuid from 'src/utils/Guid';

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  error?: React.ReactNode | boolean;
  id?: string;
  inputClassName?: string;
  label?: React.ReactNode;
  description?: React.ReactNode;
  name?: string;
  maxCount?: number;
  helpText?: React.ReactNode;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    { className, error, id, inputClassName, label, description, name, helpText, ...props }: InputProps,
    ref,
  ) => {
    const context = React.useContext(FormGroupErrorContext);

    const inputId = id || generateGuid();
    const resolvedError = context.error || error;
    const errorId = context.errorId || generateGuid();
    const descriptionId = description ? generateGuid() : undefined;

    const classes = classnames('form-element', className);
    const inputClasses = classnames(
      'form-element__input',
      {
        'has-error': resolvedError,
      },
      inputClassName,
    );

    return (
      <div className={classes}>
        {label && (
          <Label htmlFor={inputId} helpText={helpText}>
            {label}
            {props.required && <span className="form-element__label--required">*</span>}
          </Label>
        )}
        {description && (
          <div className="form-element__description" id={descriptionId}>
            {description}
          </div>
        )}
        <input
          type="text"
          className={inputClasses}
          id={inputId}
          name={name}
          ref={ref}
          aria-invalid={!!error}
          aria-describedby={descriptionId}
          aria-errormessage={error ? errorId : undefined}
          {...props}
        />
        {!context.error && !!error && (
          <FormElementError id={errorId}>{typeof error !== 'boolean' && error}</FormElementError>
        )}
      </div>
    );
  },
);

Input.displayName = 'Input';

export default Input;
