import React from 'react';
import { Button } from '@in/component-library';
import { ReactElement } from 'react';

export interface SideActionMenuForm {
  header: string;
  buttonName: string;
  form: ReactElement;
  icon?: string;
  closeProps?: string[];
}

type Props = {
  forms: SideActionMenuForm[];
};

const SideActionMenuFormChooser: React.FC<Props> = ({ forms }) => {
  const [displayForm, setDisplayForm] = React.useState<ReactElement | undefined>(undefined);
  const [selectedForm, setSelectedForm] = React.useState<SideActionMenuForm | undefined>(undefined);

  const onClose = () => {
    setSelectedForm(undefined);
    setDisplayForm(undefined);
  };

  React.useEffect(() => {
    if (selectedForm) {
      const closeProps = selectedForm.closeProps || ['onClose'];
      const displayProps = {};

      closeProps.forEach((p) => {
        displayProps[p] = onClose;
      });

      setDisplayForm(React.cloneElement(selectedForm.form as React.ReactElement<any>, displayProps));
    }
  }, [selectedForm]);

  if (displayForm) {
    return displayForm;
  }

  return (
    <div className="display--flex flex-direction--column">
      {forms.map((form) => {
        return (
          <div className="flex--1 margin-top--2" key={form.buttonName}>
            <div>{form.header}</div>
            <div className="margin-top--2">
              <Button iconPosition="left" iconName={form.icon || ''} onClick={() => setSelectedForm(form)}>
                {form.buttonName}
              </Button>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default SideActionMenuFormChooser;
