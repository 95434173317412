import React from 'react';
import { Link } from '@in/component-library';
import { CustomerMailRecommendedServices } from '../../models/OneWayInModels';

type Props = {
  customerName: string;
  note: string;
  recommendedServices: CustomerMailRecommendedServices[];
  userName: string;
  actorName: string;
  textView?: boolean;
};

const RecommendServiceMailCustomer: React.FC<Props> = ({
  customerName,
  note,
  recommendedServices,
  userName,
  actorName,
  textView = false,
}) => {
  //Not translated as this is the email being sent. No translation in email.
  return (
    <div className={textView ? '' : 'margin-top--2 margin-bottom--2 recommend-mail__box'}>
      <div className="recommend-mail__content">
        <div className="margin-bottom--2">
          <b>Vi anbefaler disse tjenestene</b>
        </div>
        <p>Hei {customerName},</p>
        {note.length > 0 && <p>{note}</p>}
        <ol>
          {recommendedServices.map((service) => {
            let serviceName = `${service.owner || ''}/${service.name || ''}`;
            if (service.links && service.links?.length > 0) {
              serviceName += ' - ';
            }

            return (
              <li key={service.id}>
                {serviceName}
                {service.links?.map((link) => (
                  <Link key={link.url} className="margin-left--1 margin-right--1" href={link.url}>
                    {link.text}
                  </Link>
                ))}
              </li>
            );
          })}
        </ol>
        <p>Med vennlig hilsen,</p>
        <div>{userName}</div>
        <div>{actorName}</div>
      </div>
    </div>
  );
};

export default RecommendServiceMailCustomer;
