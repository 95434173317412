import React, { useState, useMemo } from 'react';
import { InputField } from '@in/component-library';
import { Select } from 'src/components/Form/Select';
import { EventDtoV2 } from 'src/api/v2';

import css from './EventListWrapper.module.scss';
import { useTranslation } from 'react-i18next';
import EventPagination from './EventPagination';
import { SelectOption } from 'src/components/Form/Select';
import { getSafeDateOnly } from 'src/utils/date';

type EventListWrapperTypes = {
  events: EventDtoV2[];
  isFetching: boolean;
  projectId?: string;
};

const EventListWrapper: React.FC<EventListWrapperTypes> = ({ events, isFetching, projectId }) => {
  const [searchResults, setSearchResults] = useState<Array<EventDtoV2>>(events);
  const [selectedFilter, setSelectedFilter] = useState<string>('');

  const { t: tCommon } = useTranslation('common');
  const { t: tEvents } = useTranslation('events');

  const searchInputHandler = (searchInput: string) => {
    setSearchResults(events.filter((event) => event.name.toLowerCase().includes(searchInput)));
  };

  const filterOptions: SelectOption[] = [
    { text: tCommon('none'), value: '' },
    { text: tEvents('upcoming'), value: 'upcoming' },
    { text: tCommon('active_plural'), value: 'active' },
    { text: tEvents('completed'), value: 'completed' },
  ];

  const utcCurrentDate = getSafeDateOnly(new Date());

  const filterResults = useMemo(() => {
    switch (selectedFilter) {
      case 'active':
        return searchResults.filter((event) =>
          event.endDate
            ? getSafeDateOnly(new Date(event.startDate)) <= utcCurrentDate &&
              getSafeDateOnly(new Date(event.endDate)) >= utcCurrentDate
            : getSafeDateOnly(new Date(event.startDate)).toString() == getSafeDateOnly(new Date()).toString(),
        );
      case 'upcoming':
        return searchResults.filter((event) => new Date(event.startDate) > new Date());
      case 'completed':
        return searchResults.filter((event) =>
          event.endDate
            ? getSafeDateOnly(new Date(event.endDate)) < getSafeDateOnly(new Date())
            : getSafeDateOnly(new Date(event.startDate)) < getSafeDateOnly(new Date()),
        );
      default:
        return searchResults;
    }
  }, [searchResults, selectedFilter, utcCurrentDate]);

  return (
    <div className={css['event-list-wrapper']}>
      <div className={css['event-list-wrapper__top-items']}>
        <Select
          className={css['event-list-wrapper__top-items--select']}
          name="filter"
          label={tCommon('filterBy')}
          options={filterOptions}
          onChange={(e) => setSelectedFilter(e.target.value)}
        />
        <InputField
          className={css['event-list-wrapper__top-items--search-bar']}
          type={'search'}
          hideLabel
          label={tCommon('search')}
          placeholder={tCommon('search')}
          icon={'fal fa-search'}
          iconPlacement="right"
          onChange={(e) => searchInputHandler(e.target.value.toLowerCase())}
        />
      </div>
      <div className={css['event-list-wrapper__top-items']}>
        <h2>
          {filterResults.length} {tCommon('hits')}
        </h2>
      </div>
      <EventPagination events={filterResults} isFetching={isFetching} projectId={projectId} />
    </div>
  );
};

export default EventListWrapper;
