import { OneWayInStatus } from 'src/api/v2';
import { StatusFilterType } from '../opportunity-table/hooks/use-status-tags';

type StatusTags = Record<StatusFilterType, (OneWayInStatus | null)[]>;

export const statusTags: StatusTags = {
  new: [null, OneWayInStatus.New, OneWayInStatus.Received],
  wip: [OneWayInStatus.Active, OneWayInStatus.Opened, OneWayInStatus.ReOpened, OneWayInStatus.Shared],
  archived: [OneWayInStatus.Closed, OneWayInStatus.Forwarded],
  recieved: [],
  onhold: [OneWayInStatus.FollowUpLater],
};
