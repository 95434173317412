import React from 'react';
import { useNavigate } from 'react-router-dom';
import { LoadingPage } from 'src/components/LoadingPage/LoadingPage';
import DocumentTitle from 'src/components/DocumentTitle/DocumentTitle';

const ForwardOpportunityUserReroute: React.FC = () => {
  const navigate = useNavigate();

  React.useEffect(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <DocumentTitle title="Deler mulighet..">
      <LoadingPage />;
    </DocumentTitle>
  );
};

export default ForwardOpportunityUserReroute;
