import classNames from 'classnames';
import React, { useId, useMemo, useState } from 'react';
import ReactAutosuggest, { SuggestionsFetchRequestedParams } from 'react-autosuggest';

import generateGuid from 'src/utils/Guid';

import './Autosuggest.scss';

type Props<T> = Omit<React.InputHTMLAttributes<HTMLElement>, 'onChange' | 'onBlur'> & {
  filter: (value: string) => T[];
  render: (value: T) => React.ReactNode;
  /**
   * @param {T} value of selection
   * @return {void|true} When true is returned, the input field will clear after selection
   */
  onValueSelected: (value: T) => void | true;
  getSuggestionValue: (value: T) => string;

  // Form stuff
  label: React.ReactNode;
  error?: string;
};

function AutosuggestInner<T extends object>(
  {
    filter,
    render,
    onValueSelected,
    getSuggestionValue,
    label,
    error,
    id,
    defaultValue,
    name,
    className,
    ...rest
  }: Props<T>,
  ref?: React.ForwardedRef<HTMLInputElement>,
) {
  const resolvedId = useMemo(() => id || generateGuid(), [id]);
  const labelId = useId();

  const [value, setValue] = useState<string>((defaultValue as string) || '');
  const [suggestions, setSuggestions] = useState<T[]>([]);

  const handleOnSuggestionFetchRequested = (request: SuggestionsFetchRequestedParams): void => {
    setSuggestions(filter(request.value.toLowerCase()));
  };

  const handleOnSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const handleOnSuggestionSelected = (
    event: React.FormEvent<any>,
    { suggestion }: ReactAutosuggest.SuggestionSelectedEventData<T>,
  ): void => {
    const reset = onValueSelected(suggestion);
    if (reset) {
      setValue('');
    }
  };

  return (
    <div className={classNames('react-autosuggest__wrapper', className)}>
      <label className="react-autosuggest__label" htmlFor={resolvedId} id={labelId}>
        {label}
      </label>

      <ReactAutosuggest
        suggestions={suggestions}
        containerProps={{
          'aria-labelledby': labelId,
        }}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={render}
        onSuggestionsFetchRequested={handleOnSuggestionFetchRequested}
        onSuggestionsClearRequested={handleOnSuggestionsClearRequested}
        onSuggestionSelected={handleOnSuggestionSelected}
        inputProps={{
          ...rest,
          ref: ref,
          id: resolvedId,
          name: name,
          value: value,
          'aria-errormessage': error,
          onChange: (event, params) => {
            setValue(params.newValue);
          },
          onKeyDown: (event) => {
            if (event.key === 'Escape') {
              // Dette hindrer at en modal lukker seg hvis man trykker på ESC
              event.stopPropagation();
            }
          },
        }}
      />

      {error && (
        <div className="react-autosuggest__error">
          <p>{error}</p>
        </div>
      )}
    </div>
  );
}

const Autosuggest = React.forwardRef(AutosuggestInner) as <T>(
  props: Props<T> & { ref?: React.ForwardedRef<HTMLInputElement> },
) => ReturnType<typeof AutosuggestInner>;

export default Autosuggest;
