import React from 'react';
import { FaIcon, FaIcons, Spinner } from '@in/component-library';
import { icon } from '@fortawesome/fontawesome-svg-core';
import { IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import { EventDetailsDto, EventDtoV2 } from 'src/api/v2';
import css from './EventCard.module.scss';
import { formatAsCurrencyString } from 'src/utils/FormatValue';
import { useTranslation } from 'react-i18next';

type Props = {
  event: EventDtoV2;
  eventDetails?: EventDetailsDto;
  eventStatus?: 'completed' | 'upcoming' | 'active' | '';
  isFetching?: boolean;
};

const EventCardStats: React.FC<Props> = ({ event, eventDetails, eventStatus, isFetching }) => {
  const { t: tCommon } = useTranslation('common');
  const { t: tSelfEffort } = useTranslation('selfEffort');

  if (isFetching) {
    return (
      <div className="display--flex justify-content--center">
        <Spinner />
      </div>
    );
  }
  return (
    <div className={css['event-card__center']}>
      {eventStatus !== 'upcoming' ? (
        <>
          <p>
            <FaIcon name={FaIcons.UserLight} size="xs" className={css['event-card__center--icon']} />
            {eventDetails?.participants ? eventDetails.participants : 0}{' '}
            {tCommon('participants').toLowerCase()}
          </p>
          <p>
            <FaIcon
              icon={icon({ prefix: 'fal', iconName: 'calendar-edit' }) as IconDefinition}
              size="xs"
              className={css['event-card__center--icon']}
            />
            {eventDetails?.timeEntryHours ? eventDetails.timeEntryHours : 0} {tSelfEffort('hoursRegistered')}
          </p>
          <p>
            <FaIcon
              icon={icon({ prefix: 'fal', iconName: 'sack-dollar' }) as IconDefinition}
              size="xs"
              className={css['event-card__center--icon']}
            />
            {formatAsCurrencyString(
              eventDetails?.inKindTotal ? eventDetails.inKindTotal : 0,
              ' ',
              undefined,
              0,
              undefined,
              ',-',
            )}{' '}
            {tCommon('inkind').toLowerCase()}
          </p>
        </>
      ) : (
        <>
          <p>
            <FaIcon name={FaIcons.MapMarkerAltLight} size="xs" className={css['event-card__center--icon']} />
            {event.location}
          </p>
          <p>
            <FaIcon name={FaIcons.ClockLight} size="xs" className={css['event-card__center--icon']} />
            {event.duration} {tCommon('hours')}
          </p>
        </>
      )}
    </div>
  );
};

export default EventCardStats;
