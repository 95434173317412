import React from 'react';
import { Table } from '@tanstack/react-table';
import { DataTableV3PaginationListenersProps } from '..';
import { useTranslation } from 'react-i18next';
import TertiaryButton from '../../TertiaryButton/TertiaryButton';

type Props<TData extends object> = {
  table: Table<TData>;
  disabled?: boolean;
  pageSizeOptions: number[];
  paginationListeners?: DataTableV3PaginationListenersProps;
  overridePaginationTotalNumber?: number;
  setStoragePageSize: (pageSizeNumber: number) => void;
};

const DataTablePagination = <TData extends object>({
  table,
  disabled,
  pageSizeOptions,
  paginationListeners,
  overridePaginationTotalNumber,
  setStoragePageSize,
}: Props<TData>) => {
  const { t: tCommon } = useTranslation();

  if (disabled) {
    return null;
  }

  const pagination = {
    from: table.getState().pagination.pageIndex * table.getState().pagination.pageSize + 1,
    to: Math.min(
      (table.getState().pagination.pageIndex + 1) * table.getState().pagination.pageSize,
      table.getFilteredRowModel().flatRows.length,
    ),
    total: table.getFilteredRowModel().flatRows.length,
  };

  const paginationPageDescriptor = `${pagination.from} - ${pagination.to} ${tCommon('of')} ${
    overridePaginationTotalNumber ? overridePaginationTotalNumber : pagination.total
  }`;
  const tablePageSize = table.getState().pagination.pageSize;

  return (
    <div className="display--flex justify-content--flex-end align-items--center gap--4">
      <div className="display--flex align-items--center gap--1">
        <label htmlFor="dataTableRowsPerPage">{tCommon('rowsPerPage')}</label>

        <select
          id="dataTableRowsPerPage"
          onChange={(e) => {
            const value = +e.currentTarget.value;

            table.setPageSize(value);
            setStoragePageSize(value);
            paginationListeners?.onSetPageSize?.(value);
          }}
          defaultValue={tablePageSize}
          className="height--26px font-size--medium"
        >
          {pageSizeOptions.map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              {pageSize}
            </option>
          ))}
        </select>
      </div>

      <div className="display--flex align-items--center gap--1">
        <TertiaryButton
          ariaLabel={tCommon('previousPage')}
          icon={{ prefix: 'fal', iconName: 'chevron-left' }}
          minimalPadding={true}
          onClick={() => {
            const previousPageIndex = table.getState().pagination.pageIndex - 1;
            if (previousPageIndex < 0) {
              return;
            }

            table.setPageIndex(previousPageIndex);
            paginationListeners?.onPreviousPageClick?.(previousPageIndex);
          }}
        />
        <span>{paginationPageDescriptor}</span>
        <TertiaryButton
          ariaLabel={tCommon('nextPage')}
          icon={{ prefix: 'fal', iconName: 'chevron-right' }}
          minimalPadding={true}
          onClick={() => {
            const nextPageIndex = table.getState().pagination.pageIndex + 1;

            if (nextPageIndex > table.getPageCount() - 1) {
              return;
            }

            table.setPageIndex(nextPageIndex);
            paginationListeners?.onNextPageClick?.(nextPageIndex);
          }}
        />
      </div>
    </div>
  );
};

export default DataTablePagination;
