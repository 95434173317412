import { FaIcons, IconButton } from '@in/component-library';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DevelopmentProjectListItemDto,
  OperationProjectListItemDto,
  PortalProjectListItemDto,
} from 'src/api/v2';
import ProjectCard from '../ProjectCard/ProjectCard';
import css from './ProjectListWrapper.module.scss';

type ProjectPaginationTypes = {
  projects: (OperationProjectListItemDto | DevelopmentProjectListItemDto | PortalProjectListItemDto)[];
  isFetching: boolean;
  projectType?: 'development' | 'operation' | 'other' | undefined;
};

const ProjectPagination: React.FC<ProjectPaginationTypes> = ({ projects, isFetching, projectType }) => {
  const { t: tCommon } = useTranslation();
  const { t: tProjects } = useTranslation('projects');

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [projectsPerPage, setProjectsPerPage] = useState<number>(5);

  const pageSizes: number[] = [5, 10, 15, 20, 50];

  const totalCount = projects.length;
  const from = (currentPage - 1) * projectsPerPage;
  const to = Math.min((currentPage - 1) * projectsPerPage + projectsPerPage, totalCount);

  React.useEffect(() => {
    if (projects.length <= projectsPerPage) {
      setCurrentPage(1);
    }
  }, [projects.length, projectsPerPage]);

  return (
    <>
      {projects.slice(from, to).map((project) => {
        return (
          <ProjectCard key={project.id} project={project} isFetching={isFetching} projectType={projectType} />
        );
      })}
      <div className="display--flex items-align--center justify-content--flex-end gap--2">
        <div className="display--flex items-align--center gap-1">
          <span id="projectsPerPage">{tProjects('projectsPerPage')}:</span>
          <select
            className={css['projectPagination__select']}
            onChange={(event) => {
              const { value } = event.currentTarget;
              const numValue = Number(value);
              if (numValue < totalCount) {
                setProjectsPerPage(numValue);
              } else {
                setProjectsPerPage(totalCount);
              }
              setCurrentPage(1);
            }}
            aria-labelledby="rowsPerPage"
          >
            {pageSizes.map((num) => {
              return <option key={num}>{num}</option>;
            })}
          </select>
        </div>

        <div className="display--flex items-align--center gap--1">
          <span>
            {from + 1} - {to}
          </span>
          <span>{tCommon('of')}</span>
          <span>{totalCount}</span>
        </div>

        <div className="display--flex items-align--center gap--2">
          <IconButton
            iconName={FaIcons.ChevronLeftLight}
            onClick={() => {
              setCurrentPage(currentPage - 1);
            }}
            disabled={currentPage === 1 || projectsPerPage === totalCount}
            aria-disabled={currentPage === 1}
            aria-label={tCommon('previousPage')}
            title={tCommon('previousPage')}
          />
          <IconButton
            iconName={FaIcons.ChevronRightLight}
            onClick={() => {
              setCurrentPage(currentPage + 1);
            }}
            disabled={to === totalCount || projectsPerPage === totalCount}
            aria-disabled={to === totalCount}
            aria-label={tCommon('nextPage')}
            title={tCommon('nextPage')}
          />
        </div>
      </div>
    </>
  );
};

export default ProjectPagination;
