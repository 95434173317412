import React from 'react';
import { useTranslation } from 'react-i18next';
import { ClusterContactCreateDto, ClusterMemberCreateDto } from 'src/api/v2';
import DialogContainer from 'src/components/DialogContainer/DialogContainer';
import CreateMemberForm from './CreateMemberForm';

export interface CreateMemberContextProps {
  organizationNumber: string;
  submitting: boolean;

  member?: ClusterMemberCreateDto;
  contacts: ClusterContactCreateDto[];
}

const initialValue: CreateMemberContextProps = {
  organizationNumber: '',
  submitting: false,
  member: undefined,
  contacts: [],
};

export const CreateMemberContext = React.createContext<CreateMemberContextProps>(initialValue);

type Props = {
  show: boolean;
  onClose: () => void;
};

const CreateMemberModal: React.FC<Props> = ({ show, onClose }) => {
  const { t: tButtons } = useTranslation('buttons');

  return (
    <DialogContainer title={tButtons('addMember')} show={show} onClose={onClose}>
      <CreateMemberContext.Provider value={initialValue}>
        <CreateMemberForm onClose={() => onClose?.()} />
      </CreateMemberContext.Provider>
    </DialogContainer>
  );
};

export default CreateMemberModal;
