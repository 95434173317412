import React from 'react';
import { useTranslation } from 'react-i18next';
import { resources } from 'src/i18n';

export interface ToastForbiddenTemplateProps {
  message: string;
  title?: string;
  error?: boolean;
}

type TErrorArgs = keyof typeof resources['nb']['error'];

const ToastTemplate: React.FC<ToastForbiddenTemplateProps> = ({ message, title, error }) => {
  const { t: tError } = useTranslation('error');

  return (
    <>
      {title && <h2>{title}</h2>}

      {error ? <p>{tError(message as TErrorArgs)}</p> : <p>{message}</p>}
    </>
  );
};

export const toastContent = (message: string, title?: string, error?: boolean) => {
  return <ToastTemplate message={message} title={title} error={error} />;
};
