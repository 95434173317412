import i18n from 'i18next';

/**
 * Convert Date-object to a date only string format
 *
 * Format: yyyy-mm-dd
 *
 * @param date
 * @param separator String to place between each step of object
 * @returns string
 */
export const getDateOnlyString = (date: Date, separator: string = '-'): string => {
  const _date = new Date(date); // Backend may lie about Dates

  const d = _date.getDate();
  const m = _date.getMonth() + 1;
  const y = _date.getFullYear();

  let str = `${y}${separator}`;

  if (m < 10) {
    str += `0${m}${separator}`;
  } else {
    str += `${m}${separator}`;
  }

  if (d < 10) {
    str += `0${d}`;
  } else {
    str += `${d}`;
  }

  return str;
};

export const formatSaveDate = (date: Date | undefined) => {
  const { t: tReports } = i18n;

  if (!date) return tReports('reports:formatSaveNotSaved');

  const d = new Date(date);
  const now = new Date();
  const isToday =
    now.getUTCDate() === d.getUTCDate() &&
    now.getUTCMonth() === d.getUTCMonth() &&
    now.getUTCFullYear() === d.getUTCFullYear();

  if (isToday) {
    return `${tReports('reports:formatSaveDraftSavedToday')} ${d?.toLocaleTimeString('nb', {
      hour: '2-digit',
      minute: '2-digit',
    })}`;
  }

  return `${tReports('reports:formatSaveDraftSaved')} ${d?.toLocaleString('nb', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  })}`;
};

/**
 * Konvertere Date til en Date.UTC, hvor kun år, måned og dato blir tatt med videre.
 * Dette gjør at man får en "clean" dato med riktig dato/måned og klokkeslett 00:00
 *
 * @param date
 * @returns Date med riktig dato, og klokkeslett 00:00:00
 */
export function getSafeDateOnly(date: Date): Date {
  return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
}

type DateSplitType = [string, string, string];

function createDateFromDateSplit([date, month, year]: DateSplitType): Date {
  return new Date(`${year}-${month}-${date}`);
}

export function parseDateString(input: string, fallbackValue?: Date): Date | undefined {
  if (!input) {
    return fallbackValue;
  }

  // DD.MM.YYYY
  // mulig å gjøre om DD til [0-2][0-9] og MM til [0-1][0-9] og YYYY til [1-2][0-9]{3}
  const DOT_FORMAT = /([0-9]{2}\.[0-9]{2}\.[0-9]{4})/;
  if (input.match(DOT_FORMAT)) {
    const dateSplit = input.split('.') as DateSplitType;

    return createDateFromDateSplit(dateSplit);
  }

  // DD/MM/YYYY
  const SLASH_FORMAT = /([0-9]{2}\/[0-9]{2}\/[0-9]{4})/;
  if (input.match(SLASH_FORMAT)) {
    const dateSplit = input.split('/') as DateSplitType;

    return createDateFromDateSplit(dateSplit);
  }

  // DD-MM-YYYY
  const DASH_FORMAT = /([0-9]{2}-[0-9]{2}-[0-9]{4})/;
  if (input.match(DASH_FORMAT)) {
    const dateSplit = input.split('-') as DateSplitType;

    return createDateFromDateSplit(dateSplit);
  }

  return fallbackValue;
}

export function getMonthAndYearString(date: Date): string {
  const safeDate = getSafeDateOnly(date);
  const month = safeDate.toLocaleString('default', { month: 'long' });
  const year = safeDate.getFullYear();

  return `${month} ${year}`;
}

export function toIsoDateString(date: Date | string | number | undefined) {
  return date ? new Date(date).toISOString().split('T')[0] : undefined;
}
