import React, { Suspense } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useProjects from '../hooks/use-projects';
import { LoadingPage } from 'src/components/LoadingPage/LoadingPage';
import { isDevelopmentProject, isOperationProject } from '../utils/project-utils';
import { useTranslation } from 'react-i18next';
import useDocumentTitle from 'src/hooks/use-document-title';
import DocumentTitle from 'src/components/DocumentTitle/DocumentTitle';
import Page from 'src/components/Page/Page';
import NotFoundPage from 'src/components/NotFoundPage/NotFoundPage';

type PageParams = {
  projectId: string;
};

const ProjectDetailsSwitcher: React.FC = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { projectId = '' } = useParams<PageParams>();

  const { project, isLoading } = useProjects(projectId);

  const { t: tPageTitles } = useTranslation('pageTitles');
  const { t: tProjects } = useTranslation('projects');

  const { documentTitle } = useDocumentTitle(tPageTitles('projectDetail'), tProjects('projects'));

  React.useEffect(() => {
    if (!project) return;

    if (isOperationProject(project)) {
      navigate(pathname.replace('/details/', '/cluster-operation/projects/'), { replace: true });
    }

    if (isDevelopmentProject(project)) {
      navigate(pathname.replace('/details/', '/in-projects/'), { replace: true });
    }

    navigate(pathname.replace('/details/', '/own-projects/'), { replace: true });
  }, [project, pathname, navigate]);

  if (isLoading) {
    return <LoadingPage />;
  }

  return (
    <DocumentTitle title={documentTitle}>
      <Page>
        <Suspense fallback={<LoadingPage />}>{!isLoading && !project && <NotFoundPage />}</Suspense>
      </Page>
    </DocumentTitle>
  );
};

export default ProjectDetailsSwitcher;
