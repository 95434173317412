import { lazy } from 'react';

export * from './constants/report';
export * from './constants/memberTypes';

export const ReportArchive = lazy(() => import('./pages/ReportArchive'));
export const ReportStatistics = lazy(() => import('./pages/ReportStatistics'));

export const HalfYearReports = lazy(() => import('./interimReport/pages/HalfYearReports'));
export const HalfYearReportDraft = lazy(() => import('./interimReport/pages/HalfYearReportDraft'));

export const FinalReports = lazy(() => import('./finalReport/pages/FinalReports'));
export const FinalReportDraft = lazy(() => import('./finalReport/pages/FinalReportDraft'));

export const AnnualReports = lazy(() => import('./annual/pages/AnnualReports'));
export const AnnualReportDraft = lazy(() => import('./annual/pages/AnnualReportDraft'));
