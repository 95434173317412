import { IDEAS_AND_COMPANIES } from 'src/features/siva';

const sivaReporting = '/siva/reporting';

export const SIVA = {
  BASE: `/siva`,
  REPORTING: {
    BASE: `${sivaReporting}`,
    BUDGET: `${sivaReporting}/budget`,
    ESA: {
      BASE: `${sivaReporting}/esa`,
      YEAR: (year: number) => `${sivaReporting}/esa/${year}` as const,
    },
    FUNDS: {
      BASE: `${sivaReporting}/funds`,
      YEAR: (year: number) => ({
        BASE: `${sivaReporting}/funds/${year}` as const,
        FUNDS: `${sivaReporting}/funds/${year}/administration` as const,
        ESA: `${sivaReporting}/funds/${year}/esa` as const,
      }),
    },
    GOALS: {
      BASE: `${sivaReporting}/goals`,
      YEAR: (year: string | number) =>
        ({
          BASE: `${sivaReporting}/goals/${year}` as const,
          TIMELINE: `${sivaReporting}/goals/${year}/timeline` as const,
          REGISTRATION: `${sivaReporting}/goals/${year}/registration` as const,
          CONFIRMATION: `${sivaReporting}/goals/${year}/confirmation` as const,
          SUMMARY: `${sivaReporting}/goals/${year}/summary` as const,
          FEEDBACK: `${sivaReporting}/goals/${year}/feedback` as const,
          APPROVED: `${sivaReporting}/goals/${year}/approved` as const,
        }) as const,
    } as const,
    ACHIEVED: {
      BASE: `${sivaReporting}/achieved`,
      YEAR: (year: string | number) => ({
        BASE: `${sivaReporting}/achieved/${year}` as const,
        COMPANIES: `${sivaReporting}/achieved/${year}/companies` as const,
        OPERATOR: {
          BASE: `${sivaReporting}/achieved/${year}/operator` as const,
          EDIT: `${sivaReporting}/achieved/${year}/operator/edit` as const,
        },
        ACTIVITIES: `${sivaReporting}/achieved/${year}/activities` as const,
      }),
    },
    DECLARATION: {
      BASE: `${sivaReporting}/declaration`,
      YEAR: (year: string | number) => `${sivaReporting}/declaration/${year}` as const,
    },
  } as const,
  ADMIN: {
    BASE: `/siva-admin`,
    BUDGET: `/siva-admin/budget`,
    GOALS: {
      BASE: `/siva-admin/goals`,
      YEAR: (year: string | number) =>
        ({
          BASE: `/siva-admin/goals/${year}`,
          DETAILS: (clusterId: string) =>
            ({
              BASE: `/siva-admin/goals/${year}/details/${clusterId}`,
              CONFIRMATION: `/siva-admin/goals/${year}/details/${clusterId}/confirmation`,
            }) as const,
        }) as const,
    } as const,
    EXTRACT: {
      BASE: `/siva-admin/extract`,
      GOALS: `/siva-admin/extract/goals`,
      GOALACCOMPLISHMENT: `/siva-admin/extract/goalaccomplishment`,
      IDEAS_AND_COMPANIES: `/siva-admin/extract/ideas-and-companies`,
    } as const,
    FUNDS: {
      BASE: `/siva-admin/funds`,
      NEW: `/siva-admin/funds/new`,
    },
  } as const,
  IDEAS_AND_COMPANIES: IDEAS_AND_COMPANIES,
} as const;
