import React from 'react';
import { Accordion, InputField } from '@in/component-library';
import { useTranslation } from 'react-i18next';
import useOneWayInServices from '../../hooks/use-one-way-in-services';
import RecommendServiceListItem from './RecommendServiceListItem';
import { useDebounce } from 'usehooks-ts';
import { Select } from 'src/components/Form/Select';
import { Counties } from '../../constants/counties';

import '../../styles/OneWayInStyle.scss';
import css from './RecommendServiceListItem.module.scss';

type RecommendServiceListProps = {
  updating: boolean;
  selectedChange: (e: React.FormEvent<HTMLInputElement>) => void;
  selectedIds: string[];
};
const RecommendServiceList: React.FC<RecommendServiceListProps> = ({ selectedChange, selectedIds }) => {
  const { t: tCommon } = useTranslation();
  const { t: tOneWayIn } = useTranslation('oneWayIn');
  const [searchQuery, setSearchQuery] = React.useState('');
  const [countyFilter, setCountyFilter] = React.useState('');
  const debouncedSearchQuery = useDebounce(searchQuery, 250);
  const { allServices } = useOneWayInServices();

  const { options, chosenOptions } = React.useMemo(() => {
    let services = allServices.map((service) => ({
      ...service,
      counties: service.municipalities
        .map((municipality) =>
          Counties.find((county) =>
            county.municipalities.some((countyMunicipality) => countyMunicipality === municipality),
          ),
        )
        .filter((county) => county)
        .map((county) => county!.countyName)
        .filter((value, index, array) => array.indexOf(value) === index),
    }));

    const chosenOptions =
      services
        .filter((option) => selectedIds.some((id) => id === option.id))
        .sort((a, b) => {
          if (!a.name || !b.name) return 0;
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        }) || [];

    if (countyFilter.length > 0) {
      services = services.filter((service) => service.counties.some((county) => county === countyFilter));
    }

    services =
      services
        ?.filter(
          (service) =>
            service?.name?.toLowerCase().includes(debouncedSearchQuery.toLowerCase()) ||
            service?.businessName?.toLowerCase().includes(debouncedSearchQuery.toLowerCase()) ||
            service?.contactName?.toLowerCase().includes(debouncedSearchQuery.toLowerCase()) ||
            service?.municipalities.some((municipality) =>
              municipality.toLowerCase().includes(debouncedSearchQuery.toLowerCase()),
            ) ||
            service?.municipalities.some((county) =>
              county.toLowerCase().includes(debouncedSearchQuery.toLowerCase()),
            ),
        )
        .sort((a, b) => {
          if (!a.name || !b.name) return 0;
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        }) || [];

    return { options: services, chosenOptions: chosenOptions };
  }, [allServices, selectedIds, debouncedSearchQuery, countyFilter]);

  const countyOptions = React.useMemo(() => {
    const allMunicipalities = allServices
      .map((service) => service.municipalities)
      .reduce((a, b) => a.concat(b), []);

    const allCounties = allMunicipalities
      .map((municipality) =>
        Counties.find((county) =>
          county.municipalities.some((countyMunicipality) => countyMunicipality === municipality),
        ),
      )
      .filter((county) => county)
      .map((county) => county!.countyName)
      .filter((value, index, array) => array.indexOf(value) === index);

    return [{ text: tCommon('chooseCounty'), value: '' }].concat(
      allCounties.map((county) => ({ text: county, value: county })),
    );
  }, [allServices, tCommon]);

  return (
    <>
      <div>
        <div className="display--flex justify-content--space-between">
          <InputField
            label={tCommon('search')}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder={tCommon('search')}
            className="width--60"
          />
          <div className="flex--1 flex-grow--1 margin-left--2">
            <Select
              label={tCommon('filterBy')}
              options={countyOptions}
              onChange={(e) => setCountyFilter(e.target.value)}
            />
          </div>
        </div>
        {chosenOptions.length > 0 && (
          <div className="margin-bottom--4 accordion__padding--0">
            <Accordion
              id="chosen-services-accordion"
              title={`${chosenOptions.length} ${
                chosenOptions.length === 1
                  ? tOneWayIn('chosenServices.singular')
                  : tOneWayIn('chosenServices.plural')
              }`}
              className={css['accordion__padding--0']}
            >
              {chosenOptions.map((item) => (
                <RecommendServiceListItem
                  key={item.id}
                  id={item.id}
                  name={item.name || ''}
                  contactPerson={item?.contactName || ''}
                  contactPersonEmail={item?.contactEmail || ''}
                  counties={item.counties}
                  prosecutor={item.businessName || ''}
                  tags={['Prefase', 'Tidlig']}
                  url={item.internalUrl}
                  checked={selectedIds.includes(item.id)}
                  onChange={selectedChange}
                />
              ))}
            </Accordion>
          </div>
        )}
        <div className="display--flex margin-top--1 margin-bottom--1 align-items--center">
          <div>
            {`${tOneWayIn('services.Displaying')} ${options.length} ${tOneWayIn('services.OfTotal')} ${
              allServices.length
            }`}
          </div>
        </div>
        <hr />
        <div className="margin-top--2 margin-bottom--2 display-services__box">
          {options.length === 0 && <div>{tOneWayIn('noServicesAvailable')}</div>}
          {options.map((item) => (
            <RecommendServiceListItem
              key={item.id}
              id={item.id}
              name={item.name || ''}
              contactPerson={item?.contactName || ''}
              contactPersonEmail={item?.contactEmail || ''}
              counties={item.counties}
              prosecutor={item.businessName || ''}
              tags={['Prefase', 'Tidlig']}
              url={item.internalUrl}
              checked={selectedIds.includes(item.id)}
              onChange={selectedChange}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default RecommendServiceList;
