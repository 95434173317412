import useConfiguration from './use-configuration';

export default function useSiteimprove() {
  const { environmentInformation } = useConfiguration();
  function log(category: string, action: string, label?: string) {
    if (environmentInformation?.name != 'prod') {
      console.debug(
        `SiteimproveEvent ['event', '${category}', '${action}'${label != null ? `, '${label}']` : ']'} called`,
      );
      return;
    }
    if (window && typeof window._sz === 'object') {
      return window._sz.push(['event', category, action, label]);
    } else {
      console.error('Siteimprove was not defined, failed to push an event');
    }
  }

  return {
    log,
  };
}
