import React from 'react';
import { useTranslation } from 'react-i18next';
import DialogContainer from 'src/components/DialogContainer/DialogContainer';
import { AddProjectForm } from 'src/features/projects/components/AddProjectForm';

type Props = {
  show: boolean;
  onClose: () => void;
};

const AddProjectModal: React.FC<Props> = ({ show, onClose }) => {
  const { t: tButtons } = useTranslation('buttons');

  return (
    <DialogContainer show={show} title={tButtons('createProject')} onClose={onClose}>
      <AddProjectForm onClose={onClose} />
    </DialogContainer>
  );
};

export default AddProjectModal;
