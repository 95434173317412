import React from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import CompanyActiveTag from '../components/CompanyActiveTag';
import { Link } from 'react-router-dom';
import { formatDateToNOString } from 'src/utils/FormatValue';
import { SivaContractDetailDto } from 'src/api/v2';
import { isSivaContractActive } from '../utils/isContractActive';
import { getContractCurrentPhase } from '../utils/helpers';
import i18n from 'i18next';
import { ROUTES } from 'src/navigation';

const { t } = i18n;

const columnHelper = createColumnHelper<SivaContractDetailDto>();

export const contractsTableColumns = [
  columnHelper.accessor((row) => row.id, {
    id: 'status',
    cell: (info) => <CompanyActiveTag active={isSivaContractActive(info.row.original)} />,
  }),
  columnHelper.accessor((row) => row.name, {
    id: 'ideaName',
    size: 400,
    cell: (info) => (
      <span aria-label={info.getValue()} title={info.getValue()}>
        {info.getValue()}
      </span>
    ),
  }),
  columnHelper.accessor((row) => row.phases, {
    id: 'phase',
    cell: (info) => {
      const phases = info.getValue();
      const lastPhase = getContractCurrentPhase(phases);

      return (
        <Link
          aria-label={`${t(`siva:phase.${lastPhase?.sivaPhase}`)}`}
          title={`${t(`siva:phase.${lastPhase?.sivaPhase}`)}`}
          to={ROUTES.SIVA.IDEAS_AND_COMPANIES.COMPANY_DETAILS.INDEX(info.row.original.numericId.toString())}
        >
          {`${t(`siva:phase.${lastPhase?.sivaPhase || phases.at(-1)?.sivaPhase}`)}`}
        </Link>
      );
    },
  }),
  columnHelper.accessor('admissionDate', {
    id: 'admissionDate',
    sortingFn: 'datetime',
    maxSize: 150,
    cell: (info) => {
      const admissionDate = info.getValue();
      if (!admissionDate) return '';
      return `${formatDateToNOString(admissionDate, false, '{date}.{month}.{year}', true)}`;
    },
  }),
  columnHelper.accessor((row) => row.advisorName, {
    id: 'advisor',
    cell: (info) => <span>{info.getValue()}</span>,
  }),
];
