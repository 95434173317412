import { Tabs } from '@in/component-library';
import React, { useMemo, useState } from 'react';
import { SivaCompanyDetailDto, SivaContractDetailDto } from 'src/api/v2';
import { useExternalCompany } from 'src/hooks/use-external-company';
import useMember from 'src/hooks/use-member';
import CompanyInformationAboutTab from '../../components/company/company-tabs/CompanyInformationAboutTab';
import CompanyInformationEconomyTab from '../../components/company/company-tabs/CompanyInformationEconomyTab';
import CompanyInformationRegistreTab from '../../components/company/company-tabs/CompanyInformationRegistreTab';
import { useTranslation } from 'react-i18next';

type Props = {
  contract: SivaContractDetailDto;
  company: SivaCompanyDetailDto;
};

type TabType = 'about' | 'registre' | 'economy';

const CompanyInformationTabs: React.FC<Props> = ({ contract, company }) => {
  const { t: tSiva } = useTranslation('siva');

  const { data: externalCompany } = useExternalCompany(company.organizationNumber);
  const { member } = useMember(contract.clusterMemberId);
  const [activeTab, setActiveTab] = useState<TabType>('about');
  const tabs = useMemo(
    () => [
      {
        'aria-controls': 'panel-about',
        'aria-label': tSiva('companyTabs.tabs.about'),
        'aria-selected': activeTab === 'about',
      },
      {
        'aria-controls': 'panel-registre',
        'aria-label': tSiva('companyTabs.tabs.register'),
        'aria-selected': activeTab === 'registre',
      },
      {
        'aria-controls': 'panel-economy',
        'aria-label': tSiva('companyTabs.tabs.economy'),
        'aria-selected': activeTab === 'economy',
      },
    ],
    [tSiva, activeTab],
  );

  if (!externalCompany || !member)
    return <div>Kunne ikke laste bedriftsinfo. Kontakt support om feilen vedvarer.</div>;

  return (
    <>
      <Tabs
        tabs={tabs}
        setActiveTab={(tab) => {
          switch (tab) {
            case 0:
              setActiveTab('about');
              break;
            case 1:
              setActiveTab('registre');
              break;
            case 2:
              setActiveTab('economy');
              break;
            default:
              break;
          }
        }}
      />

      <CompanyInformationAboutTab
        company={externalCompany}
        member={member}
        id="panel-about"
        isActive={activeTab === 'about'}
      />
      <CompanyInformationRegistreTab
        company={externalCompany}
        id="panel-registre"
        isActive={activeTab === 'registre'}
      />
      <CompanyInformationEconomyTab
        company={externalCompany}
        id="panel-economy"
        isActive={activeTab === 'economy'}
      />
    </>
  );
};

export default CompanyInformationTabs;
