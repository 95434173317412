import { Tabs } from '@in/component-library';
import React, { useMemo, useState } from 'react';
import { CustomerInfoDto } from 'src/api/v2';
import CompanyInformationAboutTab from './CompanyInformationAboutTab';
import CompanyInformationEconomyTab from './CompanyInformationEconomyTab';
import CompanyInformationRegistreTab from './CompanyInformationRegistreTab';
import { useTranslation } from 'react-i18next';

type Props = {
  company: CustomerInfoDto;
};

type TabType = 'about' | 'registre' | 'economy';

const CompanyInformationTabs: React.FC<Props> = ({ company }) => {
  const { t: tSiva } = useTranslation('siva');

  const [activeTab, setActiveTab] = useState<TabType>('about');
  const tabs = useMemo(
    () => [
      {
        'aria-controls': 'panel-about',
        'aria-label': tSiva('companyTabs.tabs.about'),
        'aria-selected': activeTab === 'about',
      },
      {
        'aria-controls': 'panel-registre',
        'aria-label': tSiva('companyTabs.tabs.register'),
        'aria-selected': activeTab === 'registre',
      },
      {
        'aria-controls': 'panel-economy',
        'aria-label': tSiva('companyTabs.tabs.economy'),
        'aria-selected': activeTab === 'economy',
      },
    ],
    [tSiva, activeTab],
  );

  return (
    <>
      <Tabs
        tabs={tabs}
        setActiveTab={(tab) => {
          switch (tab) {
            case 0:
              setActiveTab('about');
              break;
            case 1:
              setActiveTab('registre');
              break;
            case 2:
              setActiveTab('economy');
              break;
            default:
              break;
          }
        }}
      />

      <CompanyInformationAboutTab company={company} id="panel-about" isActive={activeTab === 'about'} />
      <CompanyInformationRegistreTab
        company={company}
        id="panel-registre"
        isActive={activeTab === 'registre'}
      />
      <CompanyInformationEconomyTab company={company} id="panel-economy" isActive={activeTab === 'economy'} />
    </>
  );
};

export default CompanyInformationTabs;
