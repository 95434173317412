import { UseQueryOptions, useQuery } from 'react-query';
import { BrRegEnhet } from 'src/api/brreg';
import { BRREG_SEARCH_KEY } from 'src/constants/hook-keys';

const orgNoExactRegex = /[0-9]{9}/;
const orgNoSpacesRegex = /[0-9]{3}%20[0-9]{3}%20[0-9]{3}/;

type ResponseType = {
  page: {
    number: number;
    size: number;
    totalElements: number;
    totalPages: number;
  };
  _embedded?: {
    enheter: BrRegEnhet[];
  };
};

export function useBrregSearch(value: string, options?: UseQueryOptions<ResponseType, any, ResponseType>) {
  return useQuery({
    queryKey: [BRREG_SEARCH_KEY, value],
    queryFn: async () => {
      let params = `navn=${value}`;

      // Check if matching organization number (also with spaces. Example: '987 654 321')
      if (value.match(orgNoExactRegex) || value.match(orgNoSpacesRegex)) {
        params = `organisasjonsnummer=${value.replaceAll('%20', '')}`;
      }

      const url = `https://data.brreg.no/enhetsregisteret/api/enheter?${params}`;
      const resp = await fetch(url);
      if (!resp.ok) {
        console.log({
          url,
          statusCode: resp.status,
          statusText: resp.statusText,
        });
        throw new Error(resp.statusText);
      }

      const data = await resp.json();
      return data as ResponseType;
    },
    ...options,
    enabled: !!value && options?.enabled !== false,
  });
}
