import React from 'react';
import { useTranslation } from 'react-i18next';

import Navbar from './Navbar';

import styles from './AppHeader.module.scss';

export const AppHeader = () => {
  const { t: tCommon } = useTranslation();

  return (
    <>
      <div role="navigation" aria-labelledby="skip-to-main" className={styles['skip-to-content']}>
        <a href="#main-content" id="skip-to-main">
          {tCommon('navbar.skipToContent')}
        </a>
      </div>

      <Navbar />

      <div id="main-content"></div>
    </>
  );
};
