import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { SivaEsaReportingDto, SivaReportingService } from 'src/api/v2';
import {
  SIVA_COMPANY_DETAILS_ESA_BILLS_KEY,
  SIVA_COMPANY_ESA_COMMON_REPORT,
  SIVA_INNOVATION_FUNDS_KEY,
} from 'src/constants/hook-keys';
import { useAuth } from 'src/features/authorization';
import { useCluster } from 'src/features/cluster';

const useESABills = (year: number, sivaContractId?: number) => {
  const { t: tCommon } = useTranslation('common');
  const { t: tError } = useTranslation('error');
  const { cluster } = useCluster();
  const { checkAccess } = useAuth();
  const { read, write } = checkAccess('SivaCore');
  const queryClient = useQueryClient();
  const [sivaReportingService] = useState(() => new SivaReportingService());

  const sivaESABillsQuery = useQuery({
    queryKey: [SIVA_COMPANY_DETAILS_ESA_BILLS_KEY, sivaContractId],
    queryFn: () => sivaReportingService.getEsa(year, sivaContractId),
    enabled: !!cluster && read,
    retry: false,
    staleTime: 1000 * 60 * 15,
    refetchOnMount: true,
  });

  const addNewESABillsMutation = useMutation(
    (bills: SivaEsaReportingDto[]) => {
      if (!write) {
        throw new Error(tCommon('cannotWrite'));
      }

      return sivaReportingService.upsertEsa(bills);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([SIVA_COMPANY_DETAILS_ESA_BILLS_KEY, sivaContractId]);
        queryClient.invalidateQueries(SIVA_INNOVATION_FUNDS_KEY);
        queryClient.invalidateQueries(SIVA_COMPANY_ESA_COMMON_REPORT);
        queryClient.refetchQueries(SIVA_INNOVATION_FUNDS_KEY);
        queryClient.refetchQueries(SIVA_COMPANY_ESA_COMMON_REPORT);
      },
    },
  );

  const deleteESABillsMutation = useMutation(
    (bills: SivaEsaReportingDto[]) => {
      if (!write) {
        throw new Error(tCommon('cannotWrite'));
      }
      if (bills.length < 1) throw new Error(tError('errorCode.Required'));

      //type-predicate since id can be undefined for some reason
      return sivaReportingService.deleteEsa(bills.map((bill) => bill.id).filter(hasId));
    },
    {
      onSuccess: () => {
        queryClient.removeQueries([SIVA_COMPANY_DETAILS_ESA_BILLS_KEY, sivaContractId]);
        queryClient.invalidateQueries(SIVA_INNOVATION_FUNDS_KEY);
        queryClient.invalidateQueries(SIVA_COMPANY_ESA_COMMON_REPORT);
        queryClient.refetchQueries(SIVA_INNOVATION_FUNDS_KEY);
        queryClient.refetchQueries(SIVA_COMPANY_ESA_COMMON_REPORT);
      },
    },
  );

  const updateESABillsMutation = useMutation(
    (bills: SivaEsaReportingDto[]) => {
      if (!write) {
        throw new Error(tCommon('cannotWrite'));
      }

      return sivaReportingService.upsertEsa(bills);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([SIVA_COMPANY_DETAILS_ESA_BILLS_KEY, sivaContractId]);
      },
    },
  );

  return {
    sivaESABillsQuery,
    addNewESABillsMutation,
    updateESABillsMutation,
    deleteESABillsMutation,
  };
};

const hasId = (id: string | undefined): id is string => {
  return !!id;
};

export default useESABills;
