import React from 'react';
import { useTranslation } from 'react-i18next';
import { FieldArrayWithId, UseFormRegister, UseFormSetValue } from 'react-hook-form';

import { Button, FaIcon, FaIcons, InputField } from '@in/component-library';

import { getGenderOptions } from 'src/utils/options';

import { Select } from 'src/components/Form/Select';
import { CardAccordion } from 'src/components/CardAccordion/CardAccordion';

import * as validation from 'src/utils/validation';

import { ClusterContactCreateDto, Gender, ClusterMemberDto } from 'src/api/v2';
import { Autocomplete } from 'src/components/Autocomplete/Autocomplete';
import useMembers from 'src/hooks/use-members';

interface Props {
  title: React.ReactNode;
  open: boolean;
  index: number;

  register: UseFormRegister<any & { contacts: ClusterContactCreateDto[] }>;
  fields: FieldArrayWithId<{ contacts: ClusterContactCreateDto[] }, 'contacts', 'id'>[];
  field: ClusterContactCreateDto & { id: string };
  errors: any;
  remove: (index: number) => void;
  setValue?: UseFormSetValue<any & { contacts: ClusterContactCreateDto[] }>;
  selectMember?: boolean;
  isSubmitting: boolean;

  clusterId: string;
}

const ContactAccordion: React.FC<Props> = ({
  title,
  open,
  register,
  index,
  field,
  errors,
  clusterId,
  fields,
  remove,
  isSubmitting,
  selectMember = false,
  setValue,
}) => {
  const { t: tCommon } = useTranslation();
  const { t: tResourceBase } = useTranslation('resourceBase');
  const { t: tError } = useTranslation('error');
  const { combined } = useMembers();

  return (
    <CardAccordion title={title} open={open}>
      <div className="display--flex gap--2 align-items--center">
        <div className="flex--1">
          <InputField
            label={tCommon('firstName')}
            {...register(`contacts.${index}.firstName` as const, {
              required: validation.requiredI18n(tError),
            })}
            value={field.firstName}
            errorMsg={errors?.contacts?.[index]?.firstName?.message}
          />
        </div>

        <div className="flex--1">
          <InputField
            label={tCommon('surname')}
            {...register(`contacts.${index}.lastName` as const, {
              required: validation.requiredI18n(tError),
            })}
            value={field.lastName}
            errorMsg={errors?.contacts?.[index]?.lastName?.message}
          />
        </div>
      </div>

      <div className="display--flex gap--2 align-items--center">
        <div className="flex--1">
          <InputField
            label={tCommon('email')}
            {...register(`contacts.${index}.emailAddress` as const, {
              required: validation.requiredI18n(tError),
              validate: {
                uniqueInCluster: validation.uniqueEmailInClusterContacts(clusterId),
                uniqueInForm: (v: string | undefined): boolean | string => {
                  if (v) {
                    const duplicateIndex = fields.findIndex(
                      (x) => x.emailAddress?.toLowerCase() === v.toLowerCase() && x.id !== field.id,
                    );
                    if (duplicateIndex !== -1 && duplicateIndex !== index) {
                      return tError('email.alreadyInUseInList');
                    }

                    return true;
                  }

                  return false;
                },
              },
            })}
            value={field.emailAddress}
            errorMsg={errors?.contacts?.[index]?.emailAddress?.message}
          />
        </div>

        <div className="flex--1">
          <InputField
            label={`${tCommon('mobilePhone')} (${tCommon('optional')})`}
            {...register(`contacts.${index}.mobilePhone` as const)}
            value={field.mobilePhone}
            errorMsg={errors?.contacts?.[index]?.mobilePhone?.message}
          />
        </div>
      </div>

      <div className="display--flex gap--2 align-items--center">
        <div className="flex--1">
          <Select
            label={`${tCommon('gender')} (${tCommon('optional')})`}
            {...register(`contacts.${index}.gender` as const)}
            options={getGenderOptions()}
            defaultValue={Gender.Unspecified}
          />
        </div>
      </div>

      {selectMember && (
        <div className="display--flex gap--2 align-items--center">
          <div className="flex--1 flex-grow--2">
            <Autocomplete<ClusterMemberDto>
              label={`${tResourceBase('attachContactToMember')} (${tCommon('optional')})`}
              items={combined}
              itemLabel={(item) => `${item.name}`}
              renderItem={(item) => `${item.name}`}
              filter={(item, value: string): boolean => {
                const lowerCaseValue = value.toLowerCase();

                return item.name?.toLowerCase().includes(lowerCaseValue) || false;
              }}
              onItemSelected={(item) => {
                if (item.id) {
                  setValue?.(`contacts.${index}.clusterMemberId`, item.id);
                }
              }}
              defaultValue={
                combined.find((x) => x.id === field.clusterMemberId)?.name ?? field.clusterMemberId
              }
            />
          </div>
          <div className="flex--1">
            <Button
              variant="outlined"
              onClick={() => setValue?.(`contacts.${index}.clusterMemberId`, undefined)}
            >
              {tCommon('remove')}
            </Button>
          </div>
        </div>
      )}

      <div className="display--flex gap--2 justify-content--flex-end align-items--center">
        <Button
          variant="link"
          aria-label="Fjern kontakt"
          onClick={() => {
            if (!isSubmitting) {
              remove(index);
            }
          }}
        >
          <FaIcon name={FaIcons.Times} /> {tCommon('remove')}
        </Button>
      </div>
    </CardAccordion>
  );
};

export default ContactAccordion;
