import { useState } from 'react';
import { useQuery } from 'react-query';

import { OperationApplicationsService } from 'src/api/v2';

import { OPERATION_APPLICATIONS_KEY } from 'src/constants/hook-keys';
import { useAuth } from 'src/features/authorization';

import useCluster from '../features/cluster/hooks/use-cluster';

export default function useOperationApplication() {
  const { cluster } = useCluster();

  const { checkAccess } = useAuth();
  const { read } = checkAccess('Operation');

  const [service] = useState(new OperationApplicationsService());

  const { data, ...rest } = useQuery(
    [OPERATION_APPLICATIONS_KEY],
    () => service.getOperationApplicationList(cluster!.id),
    {
      enabled: !!cluster && read,
    },
  );

  return {
    operationApplications: data || [],
    ...rest,
  };
}
