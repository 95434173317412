import React, { useState, useMemo } from 'react';
import { Button, ButtonVariant, FaIcons, InputField, Notification } from '@in/component-library';
import { DevelopmentProjectListItemDto, PortalProjectListItemDto } from 'src/api/v2';
import css from './ProjectListWrapper.module.scss';
import { ProjectPhaseTypes } from 'src/constants/project';
import ProjectPagination from './ProjectPagination';
import { useTranslation } from 'react-i18next';
import { useBoolean } from 'usehooks-ts';
import { useAuth } from 'src/features/authorization';
import AddProjectModal from '../AddProjectModal';
import { Select, SelectOption } from 'src/components/Form/Select';
import useProjectsExport from '../../hooks/use-projects-export';

type ProjectTypes = DevelopmentProjectListItemDto | PortalProjectListItemDto;

type ProjectListWrapperTypes<T = ProjectTypes> = {
  projects: T[];
  isFetching: boolean;
  hideCreateButton?: boolean;
  projectType?: 'development' | 'operation' | 'other';
};

type ProjectListWrapperWithTypeDeclaration<T = ProjectTypes> = React.FC<ProjectListWrapperTypes<T>>;

const ProjectListWrapper: <T extends ProjectTypes>(
  props: ProjectListWrapperTypes<T>,
) => React.ReactElement<ProjectListWrapperWithTypeDeclaration<T>> = ({
  projects,
  isFetching,
  hideCreateButton,
  projectType,
}) => {
  const [searchResults, setSearchResults] = useState<Array<ProjectTypes>>([]);
  const [selectedFilter, setSelectedFilter] = useState<string>('');

  const { t: tCommon } = useTranslation();
  const { t: tButtons } = useTranslation('buttons');
  const { t: tProjects } = useTranslation('projects');

  const showCreateModal = useBoolean();

  const { checkAccess } = useAuth();

  const { exportToExcel } = useProjectsExport();

  const searchInputHandler = (searchInput: string) => {
    setSearchResults(projects.filter((project) => project.name.toLowerCase().includes(searchInput)));
  };

  React.useEffect(() => {
    setSearchResults(projects);
  }, [projects]);

  const notificationText = useMemo(() => {
    if (projectType === 'development') {
      return tProjects('noInFinancedProjects');
    }

    if (projectType === 'other') {
      return tProjects('noOwnProjects');
    }

    return '';
  }, [projectType, tProjects]);

  const filterOptions: SelectOption[] = [
    { text: tCommon('none'), value: '' },
    { text: tCommon('active_plural'), value: 'active' },
    { text: tProjects('ended'), value: 'ended' },
  ];

  const filterResults = useMemo(() => {
    switch (selectedFilter) {
      case 'active':
        return searchResults.filter((result) => result.projectPhase?.id !== ProjectPhaseTypes.terminated.id);

      case 'ended':
        return searchResults.filter((result) => result.projectPhase?.id === ProjectPhaseTypes.terminated.id);

      default:
        return searchResults;
    }
  }, [searchResults, selectedFilter]);

  const handleOnExport = () => {
    exportToExcel(filterResults);
  };

  //TODO: Ordne mer filtrering/sortering
  return (
    <div className={css['project-list-wrapper']}>
      <div className={css['project-list-wrapper__top-items']}>
        <Select
          className={css['project-list-wrapper__top-items--select']}
          name={'filter'}
          label={tCommon('filterBy')}
          options={filterOptions}
          onChange={(e) => setSelectedFilter(e.target.value)}
        />

        <InputField
          className={css['project-list-wrapper__top-items--search-bar']}
          type={'search'}
          hideLabel
          label={tCommon('search')}
          placeholder={tCommon('search')}
          icon={'fal fa-search'}
          iconPlacement="right"
          onChange={(e) => searchInputHandler(e.target.value.toLowerCase())}
        />
      </div>
      <div className="display--flex justify-content--flex-end align-items--center gap--2">
        <Button iconName={FaIcons.FileExcelLight} variant={ButtonVariant.Outlined} onClick={handleOnExport}>
          {tCommon('exportToExcel')}
        </Button>

        {checkAccess('PortalProject').write && hideCreateButton !== true ? (
          <Button
            iconName={FaIcons.PlusLight}
            variant={ButtonVariant.Outlined}
            onClick={showCreateModal.setTrue}
          >
            {tButtons('createProject')}
          </Button>
        ) : null}
      </div>

      <div className={css['project-list-wrapper__top-items']}>
        <h2>
          {filterResults.length} {tCommon('hits')}
        </h2>
      </div>
      {projects.length === 0 ? (
        <Notification type="info">{notificationText}</Notification>
      ) : (
        <ProjectPagination projects={filterResults} isFetching={isFetching} projectType={projectType} />
      )}

      <AddProjectModal show={showCreateModal.value} onClose={showCreateModal.setFalse} />
    </div>
  );
};

export default ProjectListWrapper;
