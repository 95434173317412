import { useMemo } from 'react';
import { OneWayInServiceProviderDto } from 'src/api/v2';

const usePortfolioIndustries = (actors: OneWayInServiceProviderDto[]) => {
  const actorIndustries = actors
    .map((actor) => actor.portfolioIndustries)
    .flat()
    .sort();

  const portfolioIndustries = useMemo(() => {
    const uniqueIndustries = actorIndustries.filter(
      (industry, index, self) =>
        index === self.findIndex((t) => t!.name === industry!.name && t!.id === industry!.id),
    );

    return [...new Set(uniqueIndustries)].map((industry) => ({
      text: industry!.name,
      value: industry!.name,
    }));
  }, [actorIndustries]);

  if (!actorIndustries) {
    return { portfolioIndustries: [] };
  }

  return { portfolioIndustries };
};

export default usePortfolioIndustries;
