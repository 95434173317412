import React from 'react';
import { PageParams } from '../types/PageParams';
import { useParams } from 'react-router-dom';

const GoalAchievement: React.FC = () => {
  const { contractId } = useParams<PageParams>();

  return <div>Måloppnåelse - {contractId}</div>;
};

export default GoalAchievement;
