import React from 'react';
import { LoadingPage } from 'src/components/LoadingPage/LoadingPage';
import ProjectListWrapper from '../components/ProjectListWrapper/ProjectListWrapper';
import Page from 'src/components/Page/Page';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useAuth } from 'src/features/authorization';
import DocumentTitle from 'src/components/DocumentTitle/DocumentTitle';
import useDocumentTitle from 'src/hooks/use-document-title';
import { DevelopmentProjectListItemDto } from 'src/api/v2';
import useDevelopmentProjects from '../hooks/use-development-projects';
import NoAccess from 'src/components/NoAccess/NoAccess';

const DevelopmentProjects: React.FC = () => {
  const { t: tProjects } = useTranslation('projects');
  const { t: tPageTitles } = useTranslation('pageTitles');

  const { checkAccess } = useAuth();

  const { developmentProjectsQuery } = useDevelopmentProjects();

  const { documentTitle } = useDocumentTitle(tPageTitles('inFinancedProjects'), tProjects('projects'));

  if (developmentProjectsQuery.isFetching) {
    return (
      <Page>
        <DocumentTitle title={documentTitle} />
        <LoadingPage />
      </Page>
    );
  }

  if (!checkAccess('DevelopmentProject').read) return <NoAccess />;

  return (
    <Page>
      <DocumentTitle title={documentTitle} />
      <h1>{tPageTitles('inFinancedProjects')}</h1>
      <p>
        {tProjects('inFinancedProjectsDescription')}{' '}
        {checkAccess('PortalProject').read && (
          <span>
            {tProjects('seeOtherProjects')}{' '}
            <Link to="/projects/own-projects">{tProjects('ownProjects')}</Link>
          </span>
        )}
      </p>

      <ProjectListWrapper<DevelopmentProjectListItemDto>
        isFetching={developmentProjectsQuery.isFetching}
        projects={developmentProjectsQuery.data || []}
        hideCreateButton
        projectType="development"
      />
    </Page>
  );
};

export default DevelopmentProjects;
