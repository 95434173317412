import React from 'react';
import { RouteObject } from 'react-router-dom';
import { ACTOR_PROFILE_BASE_ROUTE } from 'src/navigation';

const ActorProfile = React.lazy(() => import('./pages/ActorProfile'));
const GeneralInfo = React.lazy(() => import('./pages/GeneralInfo'));
const ActorProfileContactInfo = React.lazy(() => import('./pages/ActorProfileContactInfo'));
const ActorProfileSettings = React.lazy(() => import('./pages/ActorProfileSettings'));
const ActorProfileEmailSettings = React.lazy(() => import('./pages/ActorProfileEmailSettings'));
const ActorProfileAccessControlSettings = React.lazy(
  () => import('./pages/ActorProfileAccessControlSettings'),
);
const ActorProfileEdit = React.lazy(() => import('./pages/ActorProfileEdit'));

const ACTOR_PROFILE_SETTINGS = 'settings';
const ACTOR_PROFILE_EDIT = 'edit';
const ACTOR_PROFILE_GENERAL_INFO = 'general-info';
const ACTOR_PROFILE_CONTACT_INFO = 'contact-info';
const ACTOR_PROFILE_EMAIL_SETTINGS = 'email';
const ACTOR_PROFILE_ACCESS_CONTROL = 'access';

export const ACTOR_PROFILE = {
  BASE: ACTOR_PROFILE_BASE_ROUTE,
  SETTINGS: {
    BASE: `${ACTOR_PROFILE_BASE_ROUTE}/${ACTOR_PROFILE_SETTINGS}`,
    ACCESS_CONTROL: `${ACTOR_PROFILE_BASE_ROUTE}/${ACTOR_PROFILE_SETTINGS}/${ACTOR_PROFILE_ACCESS_CONTROL}`,
    EMAIL: `${ACTOR_PROFILE_BASE_ROUTE}/${ACTOR_PROFILE_SETTINGS}/${ACTOR_PROFILE_EMAIL_SETTINGS}`,
  },
  EDIT: {
    BASE: `${ACTOR_PROFILE_BASE_ROUTE}/${ACTOR_PROFILE_EDIT}`,
    GENERAL_INFO: `${ACTOR_PROFILE_BASE_ROUTE}/${ACTOR_PROFILE_EDIT}/${ACTOR_PROFILE_GENERAL_INFO}`,
    CONTACT_INFO: `${ACTOR_PROFILE_BASE_ROUTE}/${ACTOR_PROFILE_EDIT}/${ACTOR_PROFILE_CONTACT_INFO}`,
  },
} as const;

const routes: RouteObject[] = [
  {
    path: '',
    index: true,
    element: <ActorProfile />,
  },
  {
    path: ':actorId',
    element: <ActorProfile />,
  },
  {
    path: ACTOR_PROFILE_SETTINGS,
    element: <ActorProfileSettings />,
    children: [
      {
        path: ACTOR_PROFILE_ACCESS_CONTROL,
        element: <ActorProfileAccessControlSettings />,
      },
      {
        path: ACTOR_PROFILE_EMAIL_SETTINGS,
        element: <ActorProfileEmailSettings />,
      },
    ],
  },
  {
    path: ACTOR_PROFILE_EDIT,
    element: <ActorProfileEdit />,
    children: [
      {
        path: ACTOR_PROFILE_GENERAL_INFO,
        element: <GeneralInfo />,
      },
      {
        path: ACTOR_PROFILE_CONTACT_INFO,
        element: <ActorProfileContactInfo />,
      },
    ],
  },
];

export default routes;
