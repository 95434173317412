import React, { useMemo } from 'react';
import { Button, RadioButton, RadioButtonOptionProps, TextArea } from '@in/component-library';
import { OneWayInFeedbackDto } from 'src/api/v2';
import { useTranslation } from 'react-i18next';

type Props = {
  opportunityFeedback: OneWayInFeedbackDto | undefined;
  isLoading: boolean;
  onSave: (correctlyReferred: boolean, comment: string) => void;
};

const OpportunityFeedbackForm: React.FC<Props> = ({ opportunityFeedback, isLoading, onSave }) => {
  const [correctlyReferred, setCorrectlyReferred] = React.useState<boolean | undefined>(
    opportunityFeedback?.wasCorrectlyReferred,
  );
  const [comment, setComment] = React.useState<string>(
    opportunityFeedback?.comment ? opportunityFeedback.comment : '',
  );
  const { t: tCommon } = useTranslation('common');

  const feedbackOptions = useMemo<RadioButtonOptionProps[]>(
    () => [
      {
        selected: correctlyReferred ? true : false,
        text: tCommon('yes'),
        value: 'true',
      },
      {
        selected: correctlyReferred !== undefined && !correctlyReferred ? true : false,
        text: tCommon('no'),
        value: 'false',
      },
    ],
    [correctlyReferred, tCommon],
  );

  const handleRadioButtonChange = (e) => {
    if (e.target.value === 'true') {
      setCorrectlyReferred(true);
    }
    if (e.target.value === 'false') {
      setCorrectlyReferred(false);
    }
  };

  const handleOnSave = () => {
    onSave(correctlyReferred === undefined ? true : correctlyReferred, comment);
  };

  return (
    <>
      <p>Er denne henvendelsen sendt riktig?</p>
      <RadioButton
        name={`${opportunityFeedback?.id}-wasCorrectlyReferred`}
        onChange={(e) => handleRadioButtonChange(e)}
        options={feedbackOptions}
        layout={'horizontal'}
      />
      <div className="margin-top--2">
        <TextArea
          onChange={(e) => setComment(e.target.value)}
          rows={2}
          placeholder="Skriv inn hvorfor henvendelsen ikke er sendt riktig"
          value={opportunityFeedback?.comment || ''}
        />
      </div>
      <Button disabled={isLoading} type="button" onClick={handleOnSave}>
        {tCommon('save')}
      </Button>
    </>
  );
};

export default OpportunityFeedbackForm;
