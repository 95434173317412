import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DocumentTitle from 'src/components/DocumentTitle/DocumentTitle';
import { Select, SelectOption } from 'src/components/Form/Select';
import Page from 'src/components/Page/Page';
import useEvents from 'src/hooks/use-events';
import EventListWrapper from '../components/EventListWrapper/EventListWrapper';
import useDocumentTitle from 'src/hooks/use-document-title';
import { useProjects } from 'src/features/projects';

const EventOverview: React.FC = () => {
  const [selectedProject, setSelectedProject] = useState<string>();
  const { events, isFetching } = useEvents(selectedProject);
  const { projects } = useProjects();

  const { t: tCommon } = useTranslation('common');
  const { t: tProjects } = useTranslation('projects');
  const { t: tPageTitles } = useTranslation('pageTitles');

  const { documentTitle } = useDocumentTitle(tPageTitles('events'), tProjects('projects'));

  const projectOptions: SelectOption[] = useMemo(
    () => projects.map((project) => ({ value: project.id, text: project.name })),
    [projects],
  );

  return (
    <DocumentTitle title={documentTitle}>
      <Page>
        <h1>{tPageTitles('events')}</h1>
        <Select
          label={tProjects('selectProject')}
          options={[{ value: '', text: tCommon('none') }, ...projectOptions]}
          onChange={(e) => setSelectedProject(e.target.value)}
          className="width--15"
        />
        {!isFetching && <EventListWrapper events={isFetching ? [] : events} isFetching={isFetching} />}
      </Page>
    </DocumentTitle>
  );
};

export default EventOverview;
