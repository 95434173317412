import { saveAs } from 'file-saver';
import { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { SivaManagementService } from 'src/api/v2';
import { SIVA_BUDGET_DETAILS_LIST_KEY } from 'src/constants/hook-keys';
import { useAuth } from 'src/features/authorization';
import { useCluster } from 'src/features/cluster';

export function useSivaBudgetDetailsList(year: number | undefined) {
  const { cluster } = useCluster();
  const { checkAccess } = useAuth();
  const { read } = checkAccess('SivaAdmin');

  const queryClient = useQueryClient();

  const [sivaManagementService] = useState(() => new SivaManagementService());

  const { data, ...rest } = useQuery({
    queryKey: [SIVA_BUDGET_DETAILS_LIST_KEY, year],
    queryFn: () => sivaManagementService.getSivaBudgetDetailsList(year!),
    enabled: !!cluster && read && !!year,
  });

  const downloadMutation = useMutation({
    mutationFn: (budgetId: string) => sivaManagementService.budgetDownload(budgetId),
    onSuccess(file) {
      saveAs(file.data, file.fileName);
    },
    onError() {
      toast.error('Kunne ikke laste ned fil. Vennligst prøv igjen.');
    },
  });

  const approveMutation = useMutation({
    mutationFn: (sivaClusterDetailsId: string) => sivaManagementService.approveBudget(sivaClusterDetailsId),
    onSuccess() {
      queryClient.invalidateQueries([SIVA_BUDGET_DETAILS_LIST_KEY, year]);
    },
  });

  const rejectMutation = useMutation({
    mutationFn: (sivaClusterDetailsId: string) => sivaManagementService.rejectBudget(sivaClusterDetailsId),
    onSuccess() {
      queryClient.invalidateQueries([SIVA_BUDGET_DETAILS_LIST_KEY, year]);
    },
  });

  return {
    sivaBudgets: data || [],

    ...rest,

    mutations: {
      download: downloadMutation,
      approve: approveMutation,
      reject: rejectMutation,
    },
  };
}
