import React from 'react';
import { Navigate, RouteObject } from 'react-router-dom';

const WorkGroupOverview = React.lazy(() => import('./pages/WorkGroupOverview'));
const WorkGroupParticipents = React.lazy(() => import('./pages/WorkGroupParticipents'));

const routes: RouteObject[] = [
  {
    index: true,
    element: <Navigate to="overview" replace />,
  },
  {
    path: 'overview',
    element: <WorkGroupOverview />,
  },
  {
    path: 'participants',
    element: <WorkGroupParticipents />,
  },
];

export default routes;
