import { MessageType, Tag } from '@in/component-library';
import React, { useMemo } from 'react';
import { SivaGoalsStatus } from 'src/api/v2';

type Props = {
  status: SivaGoalsStatus;
};

const SivaGoalTag: React.FC<Props> = ({ status }) => {
  const text = useMemo(() => {
    switch (status) {
      case SivaGoalsStatus.Draft:
        return 'Utkast';
      case SivaGoalsStatus.Approved:
        return 'Godkjent';
      case SivaGoalsStatus.ChangeRequested:
        return 'Sendt tilbake';
      case SivaGoalsStatus.Submitted:
        return 'Innsendt';
      default:
        return status;
    }
  }, [status]);

  const type = useMemo<MessageType | undefined>(() => {
    switch (status) {
      case SivaGoalsStatus.Approved:
        return MessageType.Success;
      case SivaGoalsStatus.ChangeRequested:
        return MessageType.Error;
      case SivaGoalsStatus.Draft:
        return MessageType.None;
      case SivaGoalsStatus.Submitted:
        return MessageType.Info;
      default:
        return MessageType.Generic;
    }
  }, [status]);

  return <Tag type={type}>{text}</Tag>;
};

export default SivaGoalTag;
