import { useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { ClusterMemberCreateDto, ClusterMembersService, ClusterMemberUpdateDto } from 'src/api/v2';

import { ClusterMemberCategories } from 'src/constants/cluster-member-category';
import { MEMBERS_KEY, MEMBER_KEY } from 'src/constants/hook-keys';
import { useAuth } from 'src/features/authorization';

import useCluster from '../features/cluster/hooks/use-cluster';
import useStatusPrograms from './use-status-programs';

export default function useMembers() {
  const { cluster } = useCluster();
  const { checkAccess } = useAuth();
  const { isProgramSiva } = useStatusPrograms();
  const { read, write } = isProgramSiva ? checkAccess('SivaCore') : checkAccess('ResourceBase');
  const queryClient = useQueryClient();

  const [service] = useState(new ClusterMembersService());

  const { data, ...rest } = useQuery([MEMBERS_KEY], () => service.getAll(), {
    enabled: !!cluster && read,
  });

  const members = useMemo(
    () =>
      data?.filter(
        (x) =>
          x.clusterMemberCategory?.id === ClusterMemberCategories.Member.id ||
          x.clusterMemberCategory?.id === ClusterMemberCategories.CoreMember.id,
      ) || [],
    [data],
  );
  const associates = useMemo(
    () =>
      data?.filter((x) => x.clusterMemberCategory?.id === ClusterMemberCategories.AssociateMember.id) || [],
    [data],
  );
  const leads = useMemo(
    () => data?.filter((x) => x.clusterMemberCategory?.id === ClusterMemberCategories.Lead.id) || [],
    [data],
  );

  const createMutation = useMutation(
    async (record: ClusterMemberCreateDto) => {
      if (!write) {
        throw new Error('no write access');
      }

      if (!record.clusterId && cluster) {
        record.clusterId = cluster.id;
      }

      // Eslint klagde over denne og den gjør egentlig ingenting.
      // Kommenterer den ut istedenfor å slette den bare for å gjøre oppmerksom på det.
      // I denne PR-en skal jeg bare oppgradere eslint, ikke tukle med kode, så prøver ikke å "fikse" den
      // if (record.clusterMemberCategoryId === undefined || record.clusterMemberCategoryId === '') {
      //   record.clusterMemberCategoryId === ClusterMemberCategories.NotSet.id;
      // }

      return await service.create(record);
    },
    {
      onSuccess() {
        queryClient.resetQueries([MEMBERS_KEY]);
      },
    },
  );

  const updateMutation = useMutation(
    async ({ memberId, record }: { memberId: string; record: ClusterMemberUpdateDto }) => {
      if (!write) {
        throw new Error('no write access');
      }

      if (!record.clusterId && cluster) {
        record.clusterId = cluster.id;
      }

      // Eslint klagde over denne og den gjør egentlig ingenting.
      // Kommenterer den ut istedenfor å slette den bare for å gjøre oppmerksom på det.
      // I denne PR-en skal jeg bare oppgradere eslint, ikke tukle med kode, så prøver ikke å "fikse" den
      // if (record.clusterMemberCategoryId === undefined || record.clusterMemberCategoryId === '') {
      //   record.clusterMemberCategoryId === ClusterMemberCategories.NotSet.id;
      // }

      return await service.update(memberId, record);
    },
    {
      onSuccess(data) {
        queryClient.resetQueries([MEMBERS_KEY]);
        queryClient.resetQueries([MEMBER_KEY, data.id]);
      },
    },
  );

  const deleteMutation = useMutation(
    async (memberId: string) => {
      if (!write) {
        throw new Error('no write access');
      }

      return await service.delete(memberId);
    },
    {
      onSuccess(_, id) {
        queryClient.resetQueries([MEMBERS_KEY]);
        queryClient.removeQueries([MEMBER_KEY, id]);
      },
    },
  );

  return {
    combined: data || [],
    members,
    associates,
    leads,

    ...rest,

    createMutation,
    updateMutation,
    deleteMutation,
  };
}
