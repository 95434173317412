import React from 'react';
import {
  InputField,
  TextArea,
  Button,
  ButtonVariant,
  Theme,
  DateInput,
  RadioButton,
  IconButton,
} from '@in/component-library';
import {
  EventCreateDtoV2,
  ClusterContactDto,
  TimeEntrySource,
  TimeEntryUpsertDto,
  TimeEntryStatus,
  HttpStatusCode,
  EventCategory,
} from 'src/api/v2';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { requiredI18n } from 'src/utils/validation';
import { formatDateInputDate } from 'src/utils/FormatValue';
import { getSafeDateOnly } from 'src/utils/date';
import { Select } from 'src/components/Form/Select';

import { convertHoursAndMinutesToHours, convertHourstoHoursAndMinutes } from 'src/utils/time';
import { ExpenseTypeIds } from 'src/constants/expense-types';
import useTimeRates from 'src/hooks/use-time-rates';
import { useWorkGroups } from 'src/features/work-groups';
import Autosuggest from 'src/components/Autosuggest/Autosuggest';
import { useContacts } from 'src/features/contacts';
import useProjectSelectOptions from 'src/hooks/use-project-select-options';
import { Slider } from 'src/components/Form/Slider';
import useProjectDetails from 'src/hooks/use-project-details';
import useEvent from 'src/hooks/use-event';
import { toastPromise } from 'src/utils/toast';
import { toast } from 'react-toastify';
import useTimeEntries from 'src/hooks/use-time-entries';
import OperationResultList, {
  OperationResultListData,
} from 'src/components/OperationResultList/OperationResultList';
import useProjects from 'src/features/projects/hooks/use-projects';
import { SYSTEM_DEFAULT_HOUR_RATE } from 'src/features/inkind';

type Props = {
  onClose?: () => void;
};

export interface ExtraFormDto {
  formHours: number;
  formMinutes: number;
  projectId: string;
  expenseTypeIds: string[];
  workGroupId: string;
  hourRate: number;
  meetingType: EventCategory;
}

type ExtendedCreateEventDto = EventCreateDtoV2 & ExtraFormDto;

const MeetingForm: React.FC<Props> = ({ onClose }) => {
  const { t: tCommon } = useTranslation();
  const { t: tEvents } = useTranslation('events');
  const { t: tProjects } = useTranslation('projects');
  const { t: tError } = useTranslation('error');
  const { t: tSelfEffort } = useTranslation('selfEffort');
  const { timeRates, defaultTimeRate } = useTimeRates();
  const { workGroups } = useWorkGroups();
  const { contacts } = useContacts();
  const { projectSelectOptionsGrouped } = useProjectSelectOptions();
  const { createMutation } = useEvent('');
  const { createTimeEntryListMutation } = useTimeEntries();

  const [selectedContacts, setSelectedContacts] = React.useState<
    (ClusterContactDto & { workGroupName?: string; workGroupId?: string })[]
  >([]);
  const [selectedProjectId, setSelectedProjectId] = React.useState<string>('');
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [timeEntryErrors, setTimeEntryErrors] = React.useState<OperationResultListData[]>([]);

  const { projectExpenses } = useProjectDetails(selectedProjectId);

  const { project: selectedProject } = useProjects(selectedProjectId);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    watch,
    getValues,
  } = useForm<ExtendedCreateEventDto>({
    defaultValues: {
      startDate: new Date(),
      duration: convertHoursAndMinutesToHours(0, 30),
      formMinutes: 30,
      expenseTypeIds: [],
    },
  });

  const values = getValues();

  // Rerenders every time this changes. Ensures a selection of another expense types removes the radiobutton select.
  watch('expenseTypeIds');
  watch('duration');

  function ensureInt(n: number) {
    const parsed = parseInt(n + '');
    return parsed || 0;
  }

  const { hours, minutes } = convertHourstoHoursAndMinutes(values.duration);

  const timeRateOptions = React.useMemo(
    () => [
      {
        value: '',
        text: tSelfEffort('keepContactHourRate'),
      },
      {
        value: defaultTimeRate?.hourRate || 0,
        text: `${defaultTimeRate?.name || ''} (${(
          defaultTimeRate?.hourRate || SYSTEM_DEFAULT_HOUR_RATE
        ).toLocaleString()} kr)`,
      },
      ...timeRates.map((timeRate) => ({
        value: timeRate.hourRate,
        text: `${timeRate.name} (${timeRate.hourRate.toLocaleString()} kr)`,
      })),
    ],
    [timeRates, tSelfEffort, defaultTimeRate],
  );

  const workGroupOptions = [
    {
      value: '',
      text: '---',
    },
    ...workGroups.map((workGroup) => ({ value: workGroup.id, text: workGroup.name })),
  ];

  const projectExpenseOptions = React.useMemo(
    () =>
      projectExpenses
        .map((expenseType) => {
          if (expenseType.expenseType?.name) {
            return { text: expenseType.expenseType.name, value: expenseType.expenseType.id };
          } else if (expenseType.title) {
            return { text: expenseType.title, value: expenseType.id };
          } else {
            return { text: '', value: '' };
          }
        })
        .filter(
          (x) =>
            ![
              ExpenseTypeIds.ProjectManagementFacilitationOfCollaborationInformationSharing as string,
              ExpenseTypeIds.PersonnelCostsEmployees as string,
              ExpenseTypeIds.AdviceConsultancyAssistance as string,
            ].includes(x.value),
        ),
    [projectExpenses],
  );

  const meetingTypeOptions = React.useMemo(
    () => [
      {
        text: tEvents('meeting.Meeting'),
        value: EventCategory.Meeting,
        selected: values.meetingType === EventCategory.Meeting,
      },
      {
        text: tEvents('meeting.Phone'),
        value: EventCategory.Phonecall,
        selected: values.meetingType === EventCategory.Phonecall,
      },
      {
        text: tEvents('meeting.Email'),
        value: EventCategory.Email,
        selected: values.meetingType === EventCategory.Email,
      },
    ],
    [values.meetingType, tEvents],
  );

  const handleOnSubmit = handleSubmit(async (formData) => {
    if (selectedContacts.length === 0) {
      toast.error(tSelfEffort('contactNotSelected'));
      return;
    }

    setIsLoading(true);
    setTimeEntryErrors([]);

    const newEventRecord: EventCreateDtoV2 = {
      name: formData.name,
      description: formData.description,
      projectId: formData.projectId,
      startDate: getSafeDateOnly(new Date(formData.startDate)),
      endDate: undefined,
      duration: formData.duration,
      location: formData.meetingType,
      isPublic: false,
      expenseTypeIds: formData.expenseTypeIds,
      category: formData.meetingType,
    };

    const eventPromise = createMutation.mutateAsync(newEventRecord);

    const event = await toastPromise(eventPromise, {
      error: tEvents('couldNotCreateEvent'),
    });

    if (!event) {
      setIsLoading(false);
      return;
    }

    const hourRateSelection = +formData.hourRate;
    const timeEntries = selectedContacts.map((contact) => {
      const hourRate = hourRateSelection === 0 ? contact.timeRate.hourRate : hourRateSelection;

      return {
        id: undefined,
        source: TimeEntrySource.Manual,
        status: TimeEntryStatus.Counting,
        hours: formData.duration,
        clusterContactId: contact.id,
        hourRate,
        workGroupId: contact.workGroupId,
        eventId: event.id,
        reportedDate: new Date(),
        description: undefined,
      } as TimeEntryUpsertDto;
    });

    const timeEntriesPromise = createTimeEntryListMutation.mutateAsync(timeEntries);

    toastPromise(timeEntriesPromise, {
      pending: tSelfEffort('submittingInKind'),
      success: tCommon('done'),
      error: tSelfEffort('couldNotCreateInKind'),
    })
      .then((result) => {
        const failed = result.filter((res) => res.status !== HttpStatusCode.Created);
        if (failed.length) {
          setTimeEntryErrors(
            failed.map((x) => ({
              name: x.data?.clusterContactName ?? '',
              statusCode: x.status,
              message: x.message,
            })),
          );
        }

        if (failed.length === 0) onClose?.();
      })
      .finally(() => setIsLoading(false));
  });

  return (
    <form onSubmit={handleOnSubmit}>
      <RadioButton
        label={tEvents('meetingType')}
        {...register('meetingType', { required: true })}
        options={meetingTypeOptions}
        errorMsg={errors?.meetingType?.message || (errors?.meetingType && tError('validation.required'))}
      />
      <section className="margin-bottom--5">
        <Controller
          control={control}
          name="projectId"
          rules={{
            validate: {
              assertNotEmpty: (value) =>
                value != null && value !== '' ? true : requiredI18n(tError).message,
            },
          }}
          render={({ field }) => (
            <Select
              label={tProjects('project')}
              placeholder={`${tCommon('select')} ${tProjects('project').toLowerCase()}`}
              options={projectSelectOptionsGrouped}
              value={field.value}
              name={field.name}
              error={errors?.projectId?.message}
              onChange={(value) => {
                field.onChange(value);
                const projectId = value.target.value;
                setSelectedProjectId(projectId);
              }}
            />
          )}
        />
      </section>
      <section className="margin-bottom--5">
        <Controller
          control={control}
          name="name"
          rules={{ required: requiredI18n(tError) }}
          render={({ field, fieldState }) => (
            <InputField label={tEvents('meetingName')} {...field} errorMsg={fieldState.error?.message} />
          )}
        />
      </section>

      <section className="margin-bottom--5">
        <DateInput
          name={
            register('startDate', {
              required: requiredI18n(tError),
            }).name
          }
          label={tCommon('date')}
          min={
            selectedProject
              ? formatDateInputDate(getSafeDateOnly(new Date(selectedProject.startDate)))
              : undefined
          }
          max={
            selectedProject
              ? formatDateInputDate(getSafeDateOnly(new Date(selectedProject.endDate)))
              : undefined
          }
          onChange={(event) => {
            const { value } = event.target;
            const StartDate = value ? getSafeDateOnly(new Date(value)) : getSafeDateOnly(new Date());

            setValue('startDate', StartDate, { shouldValidate: true, shouldDirty: true });
          }}
          value={
            values.startDate ? formatDateInputDate(getSafeDateOnly(new Date(values.startDate))) : undefined
          }
          errorMsg={errors.startDate?.message}
        />
      </section>
      <section className="margin-bottom--5">
        <div className="display--flex gap--2">
          <Autosuggest<ClusterContactDto>
            render={(value) => `${value.firstName} ${value.lastName} (${value.emailAddress})`}
            getSuggestionValue={(value) => `${value.firstName} ${value.lastName} (${value.emailAddress})`}
            filter={(value) => {
              const val = value.toLowerCase();

              return contacts
                .filter((x) => !selectedContacts.map((sc) => sc.id).includes(x.id))
                .filter((x) => {
                  const firstName = x.firstName?.toLowerCase() || '';
                  const lastName = x.lastName?.toLowerCase() || '';
                  const fullName = `${firstName} ${lastName}`.trim();
                  const email = x.emailAddress;

                  return fullName.includes(val) || email.includes(val);
                });
            }}
            onValueSelected={(value) => {
              if (!selectedContacts.map((x) => x.id).includes(value.id)) {
                setSelectedContacts((prev) => [...prev, value]);
                return true;
              }
            }}
            label={tCommon('contact')}
            className="flex--3"
          />

          <Controller
            name={'workGroupId'}
            control={control}
            render={({ field, fieldState }) => (
              <Select
                {...field}
                label={tSelfEffort('getFromWorkGroup')}
                className="flex--2"
                options={workGroupOptions}
                error={fieldState.error?.message}
                onChange={(e) => {
                  e.preventDefault();
                  const { value } = e.currentTarget;

                  const workGroup = workGroups.find((x) => x.id === value);
                  if (workGroup) {
                    setSelectedContacts((prev) => {
                      const mayContainDuplicates = [
                        ...prev,
                        ...workGroup.clusterContacts.map((contact) => ({
                          ...contact,
                          workGroupName: workGroup.name,
                          workGroupId: workGroup.id,
                        })),
                      ];

                      return mayContainDuplicates.filter(
                        (value, index, self) => index === self.findIndex((t) => t.id === value.id),
                      );
                    });
                  }

                  field.onChange('');
                }}
              />
            )}
          />
        </div>
        <div className="margin-top--2 margin-bottom--2">
          <div className="display--flex gap--2 align-items--center">
            <IconButton
              className="display--inline-block"
              ariaLabel={tSelfEffort('removeSelectedContact')}
              iconName="crossmark"
              onClick={() => setSelectedContacts([])}
            />

            <strong>{tSelfEffort('removeAll')}</strong>
          </div>
          <hr />
          <div className="display--flex flex-direction--column gap--2 margin-bottom--2">
            {selectedContacts.length === 0 && (
              <div className="margin-top--2 margin-left--2">{tSelfEffort('noContactsSelected')}</div>
            )}
            {selectedContacts.map((contact) => (
              <div key={contact.id} className="display--flex gap--2 align-items--center">
                <IconButton
                  className="display--inline-block"
                  ariaLabel={tSelfEffort('removeSelectedContact')}
                  iconName="crossmark"
                  onClick={() =>
                    setSelectedContacts([...selectedContacts.filter((x) => x.id !== contact.id)])
                  }
                />
                <div>
                  {`${contact.firstName} ${contact.lastName}`}
                  {contact.workGroupName && ` (${contact.workGroupName})`}
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <section className="margin-bottom--5">
        <div className="margin-top--2 margin-bottom--2">
          <Slider
            label={tEvents('registerTime')}
            description={`15 ${tCommon('min')}`}
            step="15"
            min="15"
            max="135"
            value="30"
            onChange={(e) =>
              setValue('duration', convertHoursAndMinutesToHours(0, +e.target.value), {
                shouldValidate: true,
                shouldDirty: true,
              })
            }
          />
        </div>
        <div className="display--flex gap--2">
          <InputField
            className="width--20"
            label={`${tEvents('expectedDuration')} ${tEvents('minutes')}`}
            hideLabel={true}
            aria-describedby={'hoursAndMinutesLabel'}
            type="number"
            suffix={tEvents('minutes')}
            {...register(`formMinutes`, {
              required: true,
              validate: {
                assertTotalNotZero: (value) =>
                  convertHoursAndMinutesToHours(0, ensureInt(value)) > 0
                    ? true
                    : requiredI18n(tError).message,
              },
            })}
            value={hours * 60 + minutes}
            errorMsg={errors?.formMinutes?.message || (errors?.formMinutes && tError('validation.required'))}
          />
        </div>
      </section>
      <section className="margin-bottom--5">
        <Select {...register('hourRate')} label={tCommon('timeRate')} options={timeRateOptions} />
      </section>
      <section className="margin-bottom--5">
        <RadioButton
          label={tEvents('meeting.ChooseExpenseType')}
          name="costType"
          options={[
            {
              text: tEvents(
                'meeting.ExpenseType.ProjectManagementFacilitationOfCollaborationInformationSharing',
              ),
              value: ExpenseTypeIds.ProjectManagementFacilitationOfCollaborationInformationSharing,
              selected: values.expenseTypeIds.includes(
                ExpenseTypeIds.ProjectManagementFacilitationOfCollaborationInformationSharing,
              ),
            },
            {
              text: tEvents('meeting.ExpenseType.PersonnelCostsEmployees'),
              value: ExpenseTypeIds.PersonnelCostsEmployees,
              selected: values.expenseTypeIds.includes(ExpenseTypeIds.PersonnelCostsEmployees),
            },
            {
              text: tEvents('meeting.ExpenseType.AdviceConsultancyAssistance'),
              value: ExpenseTypeIds.AdviceConsultancyAssistance,
              selected: values.expenseTypeIds.includes(ExpenseTypeIds.PersonnelCostsEmployees),
            },
          ]}
          onChange={(e) => setValue('expenseTypeIds', [(e.target as HTMLInputElement).value])}
          errorMsg={errors?.expenseTypeIds?.message}
        />

        <Controller
          control={control}
          name="expenseTypeIds"
          rules={{ required: requiredI18n(tError) }}
          render={({ field }) => {
            return (
              <Select
                label={tEvents('meeting.ChooseOtherExpenseType')}
                name={field.name}
                placeholder={`${tCommon('select')} ${tCommon('costType')}`}
                value={field.value?.[0]}
                options={[{ text: '', value: '' }, ...projectExpenseOptions]}
                onChange={(value) => {
                  field.onChange([value.target.value]);
                }}
              />
            );
          }}
        />
      </section>

      <section>
        <OperationResultList data={timeEntryErrors} className="margin-bottom--2" />
      </section>

      <TextArea
        label={tCommon('description')}
        description={`(${tCommon('optional')})`}
        {...register('description')}
        errorMsg={errors?.description?.message}
      />

      <div className="display--flex gap--2 justify-content--space-between padding-top--3">
        <div>
          <Button
            type="submit"
            theme={Theme.Neutral}
            variant={ButtonVariant.Solid}
            isLoading={isLoading}
            className="margin-right--4"
          >
            {tCommon('save')}
          </Button>
          <Button
            theme={Theme.Neutral}
            variant={ButtonVariant.Outlined}
            type="button"
            onClick={() => onClose?.()}
            disabled={isLoading}
          >
            {tCommon('cancel')}
          </Button>
        </div>
      </div>
    </form>
  );
};

export default MeetingForm;
