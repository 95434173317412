import React from 'react';

export function mapAllowedChildren(children: React.ReactNode, allowedChildren: string[]): React.ReactNode[] {
  const arr = React.Children.toArray(children);

  return React.Children.map(arr, (child) => {
    const element = child as any;

    if (element?.type?.displayName && allowedChildren.some((dn) => dn === element.type.displayName)) {
      return element as React.ReactNode;
    }

    return null;
  });
}
