type Options = { isAscending: boolean };

export const sortOptionsByValue = <T extends { value: string }>(
  items: T[],
  options: Options = { isAscending: true },
): T[] => {
  if (options.isAscending) {
    return items.sort((a, b) => {
      if (a.value < b.value) return -1;
      if (a.value > b.value) return 1;
      return 0;
    });
  } else {
    return items.sort((a, b) => {
      if (a.value > b.value) return -1;
      if (a.value < b.value) return 1;
      return 0;
    });
  }
};
