import { ClusterMemberEconomyDto } from 'src/api/v2';

export function arrayTotalCalcByKeys(
  results: Array<ClusterMemberEconomyDto>,
  key: keyof ClusterMemberEconomyDto | Array<keyof ClusterMemberEconomyDto>,
) {
  let totalValue = 0;
  //arrayTotalCalcByKeys
  results.forEach((item) => {
    if (typeof key === 'string') {
      const output = parseInt(String(item[key]), 10);
      totalValue = totalValue + (Number.isNaN(output) ? 0 : output);
    } else {
      key.forEach((itemKey) => {
        const output = parseInt(String(item[itemKey]), 10);
        totalValue = totalValue + (Number.isNaN(output) ? 0 : output);
      });
    }
  });

  return totalValue;
}
