import React from 'react';

import useStatusPrograms from 'src/hooks/use-status-programs';

import BaseFooter from './BaseFooter';
import SivaFooter from './siva-footers/SivaFooter';

const DisplayFooter: React.FC = () => {
  const { isProgramSiva } = useStatusPrograms();

  return <>{isProgramSiva ? <SivaFooter /> : <BaseFooter />}</>;
};

export default DisplayFooter;
