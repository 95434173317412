import React from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';
import DocumentTitle from 'src/components/DocumentTitle/DocumentTitle';
import NoAccess from 'src/components/NoAccess/NoAccess';
import Page from 'src/components/Page/Page';
import { useAuth } from 'src/features/authorization';
import useDocumentTitle from 'src/hooks/use-document-title';

const ProjectsSwitcher: React.FC = () => {
  const { checkAccess } = useAuth();

  const { t: tProjects } = useTranslation('projects');
  const { documentTitle } = useDocumentTitle(tProjects('projects'));

  if (checkAccess('DevelopmentProject').read) {
    return <Navigate to="in-projects" replace />;
  }

  if (checkAccess('PortalProject').read) {
    return <Navigate to="own-projects" replace />;
  }

  return (
    <DocumentTitle title={documentTitle}>
      <Page>
        <NoAccess />
      </Page>
    </DocumentTitle>
  );
};

export default ProjectsSwitcher;
