import { RadioButton } from '@in/component-library';
import React, { useMemo } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SivaContractCreateDto } from 'src/api/v2';
import { SivaPhase } from 'src/api/v2';
import { FormStepButtons } from '../FormStepButtons';

type Props = {
  form: UseFormReturn<SivaContractCreateDto>;
  availablePhases: ReadonlyArray<SivaPhase>;
  stepForward: (() => void) | undefined;
  stepBackward: (() => void) | undefined;
};

const SelectPhase = ({ form, availablePhases, stepBackward, stepForward }: Props) => {
  const { t: tSiva } = useTranslation('siva');
  const phase = form.watch('sivaPhase');
  const phaseOptions = useMemo(
    () =>
      availablePhases.map((key) => ({
        text: tSiva(`phase.${key}`),
        value: key,
        selected: phase === key,
      })),
    [tSiva, phase, availablePhases],
  );

  return (
    <>
      <h2>Velg fase</h2>
      <RadioButton
        label="Velg fase*"
        required
        layout="vertical"
        options={phaseOptions}
        {...form.register('sivaPhase')}
      />
      <FormStepButtons stepForward={stepForward} stepBackward={stepBackward} stepForwardDisabled={!phase} />
    </>
  );
};

export default SelectPhase;
