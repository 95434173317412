import { icon } from '@fortawesome/fontawesome-svg-core';
import { IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import { Button, ButtonVariant, FaIcon, Theme } from '@in/component-library';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import DialogContainer from 'src/components/DialogContainer/DialogContainer';
import Tooltip from 'src/components/Tooltip/Tooltip';
import { useAuth } from 'src/features/authorization';
import useEnvironment from 'src/hooks/use-environment';
import useToggle from 'src/hooks/use-toggle';
import useWindowSize from 'src/hooks/use-window-size';
import FormDisplay from './FormDisplay';
import './SideActionMenu.scss';

type Props = {
  className?: string;
};

const SideActionMenu: React.FC<Props> = ({ className }) => {
  const { isActive, activate, deactivate } = useToggle(false);
  const { t } = useTranslation('sidenav');
  const { isDevOrTest } = useEnvironment();

  const { isDesktop } = useWindowSize();

  const { checkAccess } = useAuth();

  const canWriteAny = useMemo(() => {
    const canWriteInkind = checkAccess('Inkind').write;
    const canWriteResourceBase = checkAccess('ResourceBase').write;
    const canWritePortalProject = checkAccess('PortalProject').write;

    return canWriteInkind || canWriteResourceBase || canWritePortalProject;
  }, [checkAccess]);

  const [title, setTitle] = React.useState<string>('');
  const [action, setAction] = React.useState<
    'selfeffort' | 'contact' | 'member' | 'event' | 'deductible' | 'meeting' | undefined
  >(undefined);

  if (!canWriteAny) {
    return null;
  }

  return (
    <aside className={className}>
      <div className="display--flex flex-direction--column justify-content--space-between">
        {checkAccess('Inkind').write && (
          <div className="flex--1 margin-top--2">
            <Tooltip text={t('fastInKindRegistration')} direction={isDesktop ? 'left' : 'right'}>
              <Button
                className="button--small"
                theme={Theme.Informative}
                variant={ButtonVariant.Plain}
                aria-label={t('fastInKindRegistration')}
                onClick={() => {
                  setTitle(t('fastInKindRegistration'));
                  setAction('selfeffort');
                  activate();
                }}
              >
                <FaIcon icon={icon({ prefix: 'fal', iconName: 'sack-dollar' }) as IconDefinition} />
              </Button>
            </Tooltip>
          </div>
        )}

        {checkAccess('ResourceBase').write && (
          <>
            <div className="flex--1 margin-top--2">
              <Tooltip text={t('fastContactRegistration')} direction={isDesktop ? 'left' : 'right'}>
                <Button
                  className="button--small"
                  theme={Theme.Informative}
                  variant={ButtonVariant.Plain}
                  aria-label={t('fastContactRegistration')}
                  onClick={() => {
                    setTitle(t('fastContactRegistration'));
                    setAction('contact');
                    activate();
                  }}
                >
                  <FaIcon icon={icon({ prefix: 'fal', iconName: 'user-plus' }) as IconDefinition} />
                </Button>
              </Tooltip>
            </div>
            <div className="flex--1 margin-top--2">
              <Tooltip text={t('fastMemberRegistration')} direction={isDesktop ? 'left' : 'right'}>
                <Button
                  className="button--small"
                  theme={Theme.Informative}
                  variant={ButtonVariant.Plain}
                  aria-label={t('fastMemberRegistration')}
                  onClick={() => {
                    setTitle(t('fastMemberRegistration'));
                    setAction('member');
                    activate();
                  }}
                >
                  <FaIcon icon={icon({ prefix: 'fal', iconName: 'handshake' }) as IconDefinition} />
                </Button>
              </Tooltip>
            </div>
          </>
        )}

        {checkAccess('PortalProject').write && (
          <div className="flex--1 margin-top--2">
            <Tooltip text={t('addEvent')} direction={isDesktop ? 'left' : 'right'}>
              <Button
                className="button--small"
                theme={Theme.Informative}
                variant={ButtonVariant.Plain}
                aria-label={t('addEvent')}
                onClick={() => {
                  setTitle(t('addEvent'));
                  setAction('event');
                  activate();
                }}
              >
                <FaIcon icon={icon({ prefix: 'fal', iconName: 'clipboard-list-check' }) as IconDefinition} />
              </Button>
            </Tooltip>
          </div>
        )}

        {/* TODO: Fjern isDevOrTest når backend er klar for møter og integrasjon er gjort ordentlig */}
        {checkAccess('PortalProject').write && isDevOrTest && (
          <div className="flex--1 margin-top--2">
            <Tooltip text={t('addMeeting')} direction={isDesktop ? 'left' : 'right'}>
              <Button
                className="button--small"
                theme={Theme.Informative}
                variant={ButtonVariant.Plain}
                aria-label={t('addMeeting')}
                onClick={() => {
                  setTitle(t('addMeeting'));
                  setAction('meeting');
                  activate();
                }}
              >
                <FaIcon icon={icon({ prefix: 'fal', iconName: 'mug-hot' }) as IconDefinition} />
              </Button>
            </Tooltip>
          </div>
        )}

        {checkAccess('MemberEconomy').write && (
          <div className="flex--1 margin-top--2">
            <Tooltip text={t('fastDeductibleRegistration')} direction={isDesktop ? 'left' : 'right'}>
              <Button
                className="button--small"
                theme={Theme.Informative}
                variant={ButtonVariant.Plain}
                aria-label={t('fastDeductibleRegistration')}
                onClick={() => {
                  setTitle(t('registerDeductible'));
                  setAction('deductible');
                  activate();
                }}
              >
                <FaIcon icon={icon({ prefix: 'fal', iconName: 'badge-percent' }) as IconDefinition} />
              </Button>
            </Tooltip>
          </div>
        )}
      </div>

      <DialogContainer
        title={title}
        show={isActive}
        onClose={() => {
          deactivate();
        }}
        className="sidemenu-modal"
      >
        <FormDisplay
          cases={action}
          case1={'selfeffort'}
          case2={'contact'}
          case3={'member'}
          case4={'event'}
          case5={'deductible'}
          case6={'meeting'}
          toggle={deactivate}
        />
      </DialogContainer>
    </aside>
  );
};

export default SideActionMenu;
