import React from 'react';
import { Notification } from '@in/component-library';
import useMaintenance from 'src/features/maintenance/hooks/use-maintenance';

import './MaintenanceBanner.scss';

const MaintenanceBanner: React.FC = () => {
  const { isMaintenanceActive, notificationText } = useMaintenance();

  if (!notificationText && !isMaintenanceActive) return <></>;

  return (
    <div className={isMaintenanceActive ? 'maintenance-banner' : ''}>
      {notificationText && (
        <Notification type="info" fullWidth dismissable>
          {notificationText}
        </Notification>
      )}
    </div>
  );
};

export default MaintenanceBanner;
