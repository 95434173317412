import React, { ReactNode } from 'react';
import './TertiaryButton.scss';
import { FaIcon, FaIcons } from '@in/component-library';
import classNames from 'classnames';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { icon } from '@fortawesome/fontawesome-svg-core';

// Sizes available in utilities.scss
type InSize =
  | 'x-small'
  | 'small'
  | 'medium'
  | 'large'
  | 'x-large'
  | 'xx-large'
  | '2rem'
  | '20px'
  | '40px'
  | '3rem';

function TertiaryButton(props: {
  children?: ReactNode;
  icon?: { prefix: IconDefinition['prefix']; iconName: string };
  onClick?: () => void;
  standardizedSize?: InSize | false;
  minimalPadding?: boolean;
  className?: string;
  ariaLabel?: string;
  iconRight?: boolean;
  faIcon?: FaIcons;
  disabled?: boolean;
}) {
  const fontSize = props.standardizedSize ?? 'medium';

  //Backwards compatibility for datatables
  const IconToShow = () => {
    return props.faIcon ? (
      <FaIcon
        name={props.faIcon}
        // The size should match the fontSize of the button, not be overriden by FaIcon logic
        // This is a workaround as FaIcon forces a static font-size
        size={'none' as any}
      />
    ) : (
      <FaIcon icon={icon(props.icon as IconDefinition)} />
    );
  };

  return (
    <button
      className={classNames(
        'tertiary-button',
        fontSize && `font-size--${fontSize}`,
        props.minimalPadding && 'minimal-padding',
        props.className,
      )}
      disabled={props.disabled}
      aria-label={props.ariaLabel}
      type="button"
      onClick={props.onClick}
    >
      <span>
        {(props.icon || props.faIcon) && !props.iconRight && <IconToShow />}
        {props.children}
        {(props.icon || props.faIcon) && props.iconRight && <IconToShow />}
      </span>
    </button>
  );
}

export default TertiaryButton;
