import React from 'react';
import './style.scss';
import { FaIcon, FaIcons } from '@in/component-library';

export type FormElementErrorProps = React.HTMLAttributes<HTMLDivElement>;

const FormElementError: React.FC<FormElementErrorProps> = ({ children, ...props }) => {
  if (!props['aria-live']) {
    props['aria-live'] = 'polite';
  }

  return (
    <div {...props}>
      {children && (
        <div className={'error-message'}>
          <div className={'error-message__icon'}>
            <FaIcon name={FaIcons.ExclamationCircleLight} />
          </div>
          {children}
        </div>
      )}
    </div>
  );
};

export default FormElementError;
