import React from 'react';

import { StepProps } from 'src/components/Stepper';
import { BrRegInputRadio } from 'src/components/BrRegInputRadio/BrRegInputRadio';

import { ClusterMemberCategories } from 'src/constants/cluster-member-category';

import { fetchFromBrReg } from 'src/api/brreg';
import { ClusterMemberCreateDto } from 'src/api/v2';

import useCluster from 'src/features/cluster/hooks/use-cluster';

import { CreateMemberContext } from './CreateMemberModal';

export const Intro: React.FC<StepProps> = (props) => {
  const context = React.useContext(CreateMemberContext);

  const { cluster } = useCluster();

  return (
    <BrRegInputRadio
      onSelected={(value: string) => {
        context.organizationNumber = value;

        fetchFromBrReg(value).then((response) => {
          const record: ClusterMemberCreateDto = {
            ...response,

            clusterId: cluster!.id,

            memberSince: undefined,
            clusterMemberTypeId: '',
            clusterMemberCategoryId: ClusterMemberCategories.Member.id,
            clusterMemberFeeId: undefined,

            interestAreaIds: [],
          };

          context.member = record;

          props.goNextStep();
        });
      }}
      onSelectedEmpty={() => {
        context.organizationNumber = '';
        context.member = undefined;

        props.goNextStep();
      }}
    />
  );
};
