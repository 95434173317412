import { useMemo } from 'react';

import useDevelopmentProjects from './use-development-projects';
import useOperationProjects from './use-operation-projects';
import usePortalProjects from './use-portal-projects';
import {
  OperationProjectListItemDto,
  DevelopmentProjectListItemDto,
  PortalProjectListItemDto,
} from 'src/api/v2';

export type UseProjectsType =
  | OperationProjectListItemDto
  | DevelopmentProjectListItemDto
  | PortalProjectListItemDto;

const useProjects = (projectId?: string) => {
  const { operationProjectsQuery } = useOperationProjects();
  const { developmentProjectsQuery } = useDevelopmentProjects();
  const { portalProjectsQuery } = usePortalProjects();

  const projects = useMemo<UseProjectsType[]>(() => {
    return [
      ...(operationProjectsQuery.data || []),
      ...(developmentProjectsQuery.data || []),
      ...(portalProjectsQuery.data || []),
    ];
  }, [operationProjectsQuery.data, developmentProjectsQuery.data, portalProjectsQuery.data]);

  const findProjectById = (projectId: string) => projects.find((project) => project.id === projectId);

  const project = useMemo(
    () => (projectId ? projects.find((project) => project.id === projectId) : undefined),
    [projects, projectId],
  );

  return {
    projects,
    findProjectById,
    isLoading:
      operationProjectsQuery.isLoading || developmentProjectsQuery.isLoading || portalProjectsQuery.isLoading,
    isFetching:
      operationProjectsQuery.isFetching ||
      developmentProjectsQuery.isFetching ||
      portalProjectsQuery.isFetching,
    project: project,
  };
};

export default useProjects;
