import React from 'react';
import { SivaPhase } from 'src/api/v2';
import useCompanyPhase from '../company-details/hooks/use-company-phase';

const useCompanyPhaseCheck = (selectedPhase: SivaPhase, organizationNumber: string) => {
  const { companyAvailablePhases } = useCompanyPhase(organizationNumber);

  const isPhaseAllowed: boolean = React.useMemo(() => {
    if (!companyAvailablePhases.data?.includes(selectedPhase)) {
      return false;
    }

    return true;
  }, [companyAvailablePhases.data, selectedPhase]);

  if (!companyAvailablePhases.isLoading) return isPhaseAllowed;
};

export default useCompanyPhaseCheck;
