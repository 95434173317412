import { toast, ToastOptions, UpdateOptions } from 'react-toastify';
import { ApiException } from 'src/api/v2';
import { toastContent } from 'src/components/ToastTemplate/ToastTemplate';

export const toastApiExceptionMessage = (fallbackMessage: string): UpdateOptions => {
  const result: UpdateOptions = {
    autoClose: false,
    render({ data }) {
      if (ApiException.isApiException(data)) {
        return `${data.message}`;
      }

      return fallbackMessage;
    },
  };

  return result;
};

/**
 * Utility function for simple implementation of toast params
 *
 * @example
 * toast.promise(promise,
 *      defaultToastParams({
 *          error: 'Noe gikk galt, vennligst prøv igjen.'
 *      })
 * );
 *
 * @param messages
 * @returns ToastPromiseParams
 * @deprecated Bruk `toastPromise`
 */
export const defaultToastParams = ({
  pending = 'Lagrer...',
  success = 'Lagret!',
  error,
}: {
  pending?: string | UpdateOptions;
  success?: string | UpdateOptions;
  error: string | UpdateOptions;
}) => {
  if (typeof error === 'string') {
    return {
      pending,
      success,
      error: toastApiExceptionMessage(error),
    };
  }

  return {
    pending,
    success,
    error,
  };
};

type ErrorFn = (message: unknown) => string;

export type ToastPromiseOptions = {
  pending?: string;
  success?: string;
  error: string | ErrorFn;
};

export const toastPromise = <T>(
  promise: Promise<T>,
  { pending = 'Lagrer...', success = 'Lagret!', error }: ToastPromiseOptions,
): Promise<T> => {
  return new Promise((resolve, reject) => {
    const toastId = toast.loading(toastContent(pending), { autoClose: false, theme: 'light' });

    const defaultOptions: ToastOptions = {
      isLoading: false,
      closeButton: true,
      theme: 'light',
    };

    promise
      .then((data) => {
        toast.update(toastId, {
          render: toastContent(success),
          type: 'success',
          ...defaultOptions,
          autoClose: 5000,
        });

        resolve(data);
      })
      .catch((ex) => {
        if (ApiException.isApiException(ex)) {
          // Hvis status === [401, 403, 500] predef. melding
          // Hvis annen status, vis toast fra implementasjon
          const { status } = ex;

          if (status === 401) {
            toast.update(toastId, {
              render: toastContent('rest401', undefined, true),
              type: 'error',
              ...defaultOptions,
            });

            return reject(ex);
          }

          if (status === 403) {
            toast.update(toastId, {
              render: toastContent('rest403', undefined, true),
              type: 'error',
              ...defaultOptions,
            });

            return reject(ex);
          }

          if (status === 500) {
            toast.update(toastId, {
              render: toastContent('rest500', undefined, true),
              type: 'error',
              ...defaultOptions,
            });

            return reject(ex);
          }

          if (typeof error === 'function') {
            error(ex.message);
          }

          // Sender API Exception ut til `catch` for utvikler
          return reject(ex);
        }

        if (typeof error === 'function') {
          toast.update(toastId, {
            render: toastContent(error(ex)),
            type: 'error',
            ...defaultOptions,
          });
        } else {
          toast.update(toastId, {
            render: toastContent(error),
            type: 'error',
            ...defaultOptions,
          });
        }

        reject(ex);
      });
  });
};
