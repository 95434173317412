import { useMemo } from 'react';

import { OneWayInListDto } from '../../types/oneWayInList';

import { sortOptionsByValue } from '../helpers/sortOptions';
import { useCluster } from 'src/features/cluster';

const useMunicipality = (opportunities: OneWayInListDto[]) => {
  const { cluster } = useCluster();
  const municipalityOptions = useMemo(() => {
    const oneWayInMunicipalities = cluster?.oneWayInMunicipalities || [];
    const municipalities = opportunities
      .map((opportunity) => opportunity?.municipality)
      .filter((x) => x !== undefined);

    const combinedMunicipalities = [...oneWayInMunicipalities, ...municipalities];

    const uniqueMunicipalities = [...new Set(combinedMunicipalities)];

    const municipality = uniqueMunicipalities.map((municipality) => ({
      text: municipality,
      value: municipality,
    }));

    return sortOptionsByValue(municipality);
  }, [opportunities]);

  return { municipalityOptions };
};

export default useMunicipality;
