import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

const useUpdateQueryStringParam = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const updateQueryStringParam = useCallback(
    (key: string, value: string, filterMode: 'single' | 'multiple' = 'multiple') => {
      setSearchParams(
        (prevParams) => {
          let values = prevParams.get(key)?.split(',');

          if (values) {
            // existing values, add/remove specific values
            if (values.includes(value)) {
              // remove value from array
              values = values.filter((currentValue) => currentValue !== value);
            } else {
              // append value to array
              if (filterMode === 'single') {
                //
                values = [value];
              } else {
                values.push(value);
              }
            }

            if (values.length) {
              // set new key-value if values is still populated
              prevParams.set(key, values.toString());
            } else {
              // delete key if values array is empty
              prevParams.delete(key);
            }
          } else {
            // no values for key, create new array with value
            prevParams.set(key, value);
          }

          return prevParams;
        },
        { replace: true },
      );
    },
    [setSearchParams],
  );
  return { updateQueryStringParam, searchParams, setSearchParams };
};

export default useUpdateQueryStringParam;
