import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { PortalProjectCreateDto, PortalProjectsService } from 'src/api/v2';
import { PORTAL_PROJECTS_KEY } from 'src/constants/hook-keys';
import { useAuth } from 'src/features/authorization';
import { useCluster } from 'src/features/cluster';

type Props = {
  projectPhaseId?: string;
  mainGoalId?: string;
  focusAreaId?: string;
};

const usePortalProjects = (props: Props = {}) => {
  const { cluster } = useCluster();
  const [portalProjectService] = useState(new PortalProjectsService());
  const { t: tError } = useTranslation('error');

  const { checkAccess } = useAuth();
  const { read, write } = checkAccess('PortalProject');

  const queryClient = useQueryClient();

  const portalProjectsQuery = useQuery(
    [PORTAL_PROJECTS_KEY, props.projectPhaseId, props.mainGoalId, props.focusAreaId],
    () =>
      portalProjectService.getProjects(
        cluster!.id,
        props.projectPhaseId,
        props.mainGoalId,
        props.focusAreaId,
      ),
    {
      enabled: !!cluster && read,
    },
  );

  const createProjectMutation = useMutation(
    (record: PortalProjectCreateDto) => {
      if (!write) throw Error(tError('rest403'));

      if (!record.clusterId && cluster?.id) {
        record.clusterId = cluster.id;
      }

      return portalProjectService.postProject(record);
    },
    {
      onSuccess() {
        queryClient.invalidateQueries([PORTAL_PROJECTS_KEY]);
      },
    },
  );

  return {
    portalProjectsQuery,
    createProjectMutation,
  };
};

export default usePortalProjects;
