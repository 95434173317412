import React, { useCallback, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import './DrawerSideMenu.scss';
import Divider from 'src/components/Divider';
import { useLocalStorage } from 'src/hooks/use-local-storage';
import TertiaryButton from 'src/components/TertiaryButton/TertiaryButton';
import { SideMenuSubpageContext } from '../OnboardingSideMenu';

type Props = {
  minimized?: boolean;
};

const DrawerSideMenu: React.FC<React.PropsWithChildren<Props>> = ({ minimized, children }) => {
  const { setSubpage, setCommonQuestionsSubpage } = useContext(SideMenuSubpageContext);
  const { t: tCommon } = useTranslation();
  const [localStorageMinimized, setLocalStorageMinimized] = useLocalStorage(
    'drawerSideMenuMinimization',
    minimized || false,
  );

  const closeMenu = useCallback(() => {
    setLocalStorageMinimized(false);
  }, [setLocalStorageMinimized]);

  const openMenuAtReferralProcess = useCallback(() => {
    if (setSubpage) setSubpage('commonQuestions');
    if (setCommonQuestionsSubpage) setCommonQuestionsSubpage('referralprocess');
    setLocalStorageMinimized(false);
  }, [setLocalStorageMinimized, setSubpage, setCommonQuestionsSubpage]);

  useEffect(() => {
    window.addEventListener('openDrawerMenu', closeMenu);
    window.addEventListener('openMenuAtReferralProcess', openMenuAtReferralProcess);
    return () => {
      window.removeEventListener('openDrawerMenu', closeMenu);
      window.removeEventListener('openMenuAtReferralProcess', openMenuAtReferralProcess);
    };
  }, [closeMenu, setLocalStorageMinimized, openMenuAtReferralProcess]);

  return (
    <aside>
      <div
        className={
          localStorageMinimized
            ? 'drawer-side-menu__menu drawer-side-menu__menu--minimized'
            : 'drawer-side-menu__menu'
        }
      >
        {localStorageMinimized ? (
          <div>
            <TertiaryButton
              ariaLabel={tCommon('show')}
              icon={{ prefix: 'fal', iconName: 'angle-double-left' }}
              onClick={() => setLocalStorageMinimized(false)}
              minimalPadding={true}
            />
            <Divider />
          </div>
        ) : (
          <div>
            <TertiaryButton
              icon={{ prefix: 'fal', iconName: 'angle-double-right' }}
              onClick={() => setLocalStorageMinimized(true)}
              minimalPadding={true}
            >
              {tCommon('hide')}
            </TertiaryButton>
          </div>
        )}
        <>
          {React.Children.toArray(children)?.map((child) =>
            React.cloneElement(child as React.ReactElement<any>, { minimized: localStorageMinimized }),
          )}
        </>
      </div>
    </aside>
  );
};

export default DrawerSideMenu;
