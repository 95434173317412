import React from 'react';
import '../../styles/OneWayInStyle.scss';
import { OneWayInFeedbackUpsertDto, SkjemaInfo } from 'src/api/v2';
import { useTranslation } from 'react-i18next';
import { FaIcon, FaIcons, Tabs } from '@in/component-library';
import OpportunityFeedback from './OpportunityFeedback';
import { RaskAvklaringViser } from '@innovationnorway/kunde-raskavklaring-visning';
import { LoadingPage } from 'src/components/LoadingPage/LoadingPage';
import useOneWayInReview from '../../hooks/use-one-way-in-review';
import { formatDateToNOString } from 'src/utils/FormatValue';
import HistoryViewEmailContent from '../../opportunity-details/components/history/HistoryViewEmailContent';
import { sanitizeMinSideEmail } from '../../utils/htmlTextConverter';
import useOneWayInFeedback from '../../hooks/use-one-way-in-feedback';
import OpportunityFeedbackOtherActorsList from './OpportunityFeedbackOtherActorsList';
import '../../../../styles/style.scss';

type Props = {
  opportunity: SkjemaInfo & {
    municipality: string;
    county: string | undefined;
    serviceProviderName: string | undefined;
    resolved?: { opportunityId: string; resolved: boolean; comment?: string };
  };
};

type TabTypes = 'form' | 'mail' | 'other-feedbacks';

const OneWayInReviewListItem: React.FC<Props> = ({ opportunity }) => {
  const { t: tOneWayIn } = useTranslation('oneWayIn');
  const [showFeedback, setShowFeedback] = React.useState<boolean>(false);
  const { oneWayInReviewGroupedFormQuery, onewWayInOpportunityFormINResponseMailQuery } = useOneWayInReview(
    showFeedback ? opportunity.id : undefined,
  );
  const { opportunityFeedbackCollection, upsertMutation, isFetching } = useOneWayInFeedback(
    showFeedback ? opportunity.id : undefined,
  );

  const [tab, setTab] = React.useState<TabTypes>('form');

  const tabs = React.useMemo(
    () => [
      {
        'aria-selected': tab === 'form',
        title: tOneWayIn('tab.Form'),
        'aria-label': tOneWayIn('tab.Form'),
        onClick: () => {
          setTab('form');
        },
      },
      {
        'aria-selected': tab === 'mail',
        title: tOneWayIn('tab.INResponseMail'),
        'aria-label': tOneWayIn('tab.INResponseMail'),
        onClick: () => {
          setTab('mail');
        },
      },
      {
        'aria-selected': tab === 'other-feedbacks',
        title: tOneWayIn('feedbackFromOtherActors'),
        'aria-label': tOneWayIn('feedbackFromOtherActors'),
        onClick: () => {
          setTab('other-feedbacks');
        },
      },
    ],
    [tab, tOneWayIn],
  );

  const onShowFeedbackClick = () => {
    setShowFeedback((prevValue) => !prevValue);
  };

  const onFeedbackSave = (feedbackUpsertDto: OneWayInFeedbackUpsertDto) => {
    upsertMutation.mutate(feedbackUpsertDto);
  };

  const form = React.useMemo(() => {
    if (!oneWayInReviewGroupedFormQuery.data) {
      return undefined;
    }

    return oneWayInReviewGroupedFormQuery.data;
  }, [oneWayInReviewGroupedFormQuery.data]);

  const renderStatusIcon = () => {
    if (opportunity.resolved?.resolved === true)
      return <FaIcon size="lg" color="#2fcc72" name={FaIcons.CheckCircle} />;
    if (opportunity.resolved?.resolved === false)
      return <FaIcon size="lg" color="#ed0000" name={FaIcons.Times} />;

    return <FaIcon size="lg" name={FaIcons.InfoSquare} />;
  };

  return (
    <div className="opportunity-card margin-top--2 display--flex flex-direction--column">
      <button
        className="display--flex flex-direction--row cursor--pointer clickable-wrapper item-wrapper"
        onClick={onShowFeedbackClick}
      >
        <div className="display--flex align-items--center">
          <div className="margin-right--4">{renderStatusIcon()}</div>
        </div>
        <div className="display--flex flex-direction--column flex--2">
          <div className="display--flex align-items--center margin-bottom--1">
            <div className="opportunity-card__municipality-name">
              {opportunity.insendtDato &&
                formatDateToNOString(opportunity.insendtDato, false, '{date}.{month}.{year}', true)}
            </div>
          </div>
          <div className="display--flex align-items--center margin-bottom--1">
            <div className="opportunity-card__municipality-name">{opportunity.municipality}</div>
          </div>
          <div className="display--flex">
            <div className="flex--2 opportunity-card__title">
              <span>{opportunity.tittel}</span>
            </div>
            <div>
              <FaIcon name={showFeedback ? FaIcons.ChevronUp : FaIcons.ChevronDown} />
            </div>
          </div>
          <div className="display--flex align-items--center margin-bottom--1">
            {opportunity.serviceProviderName && (
              <div className="opportunity-card__municipality-name">{`${tOneWayIn('sentTo')}: ${
                opportunity.serviceProviderName ? opportunity.serviceProviderName : ''
              }`}</div>
            )}
          </div>
          {opportunity.resolved?.comment && (
            <div className="margin-top--2 margin-bottom--2">{opportunity.resolved?.comment}</div>
          )}
        </div>
      </button>
      <div>
        {showFeedback && (
          <div>
            <div className="margin-top--1 margin-bottom--4">
              <OpportunityFeedback
                opportunityId={opportunity.id!}
                opportunityTitle={opportunity.tittel || ''}
                opportunityMunicipality={opportunity.municipality}
                opportunityCounty={opportunity.county}
                actorFeedback={opportunityFeedbackCollection?.actorFeedback}
                isFetching={isFetching}
                onSave={onFeedbackSave}
              />
            </div>
            <div className="margin-bottom--4">
              <Tabs tabs={tabs} />
            </div>
            {tab === 'form' && (
              <>
                {oneWayInReviewGroupedFormQuery.isFetching && <LoadingPage />}
                {form && !oneWayInReviewGroupedFormQuery.isFetching && (
                  <RaskAvklaringViser
                    id={form?.id ?? ''}
                    tittel={form.tittel}
                    grupper={form?.grupper ?? []}
                    organisasjon={{
                      navn: form?.organisasjon.navn ?? '',
                      erPerson: form?.organisasjon.erPerson ?? false,
                      etableringsDato: form?.organisasjon.etableringsDato ?? '',
                      bedriftsAlder: form?.organisasjon.bedriftsAlder ?? '',
                      kommune: form?.organisasjon.kommune ?? '',
                      antallAnsatte: form?.organisasjon.antallAnsatte?.toString() ?? '',
                    }}
                    kontakt={{
                      navn: form?.kontakt?.navn ?? '',
                      epost: form?.kontakt?.epost ?? '',
                      telefon: form?.kontakt?.telefon ?? '',
                    }}
                    innsendtDato={form.innsendtDato || ''}
                  />
                )}
              </>
            )}
            {tab === 'mail' && (
              <>
                {onewWayInOpportunityFormINResponseMailQuery.isLoading && <LoadingPage />}
                {!onewWayInOpportunityFormINResponseMailQuery.isLoading &&
                  onewWayInOpportunityFormINResponseMailQuery.isError && (
                    <div>{tOneWayIn('errorINResponse')}</div>
                  )}
                {onewWayInOpportunityFormINResponseMailQuery.data && (
                  <section>
                    <HistoryViewEmailContent
                      from={onewWayInOpportunityFormINResponseMailQuery.data?.sender?.emailAddress || ''}
                      to={onewWayInOpportunityFormINResponseMailQuery.data?.recipient?.emailAddress || ''}
                      date={formatDateToNOString(
                        onewWayInOpportunityFormINResponseMailQuery.data.createdOn || new Date(),
                        false,
                        '{date}.{month}.{year}',
                        true,
                      )}
                      content={
                        <div
                          dangerouslySetInnerHTML={{
                            __html: sanitizeMinSideEmail(
                              onewWayInOpportunityFormINResponseMailQuery.data?.body || '',
                            ),
                          }}
                        />
                      }
                    />
                  </section>
                )}
              </>
            )}
            {tab === 'other-feedbacks' && (
              <OpportunityFeedbackOtherActorsList
                feedbackList={opportunityFeedbackCollection?.otherFeedbacks || []}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default OneWayInReviewListItem;
