import React from 'react';

type Props = {
  actorName: string;
};

const RecommendServiceMailActor: React.FC<Props> = ({ actorName }) => {
  //Not translated as this is the email being sent. No translation in email.
  return (
    <div className="margin-top--2 margin-bottom--2 recommend-mail__box">
      <div className="recommend-mail__content">
        <p>Du har mottat en henvendelse fra {actorName},</p>
        <p>
          {actorName} mener du kan tilby en eller flere tjenester som er relevante for kunden. Du kan logge
          deg på Flerbedriftsportalen ved å bruke BankID for å lese henvendelsen.
        </p>
        <p>Med vennlig hilsen,</p>
        <div>{actorName}</div>
        <p>Denne meldingen er sendt fra Flerbedriftsportalen - En vei inn.</p>
      </div>
    </div>
  );
};

export default RecommendServiceMailActor;
