export enum ExpenseTypeCodes {
  RentalOfStand = 'ET17',
  OperationOfStand = 'ET19',
  ActivityThatIsNotEligible = 'ET23',
  StrategyAndAnalysisProcessesForCluster = 'ET14',
  MaterialsAndEquipment = 'ET07',
  TravelAndSubsistenceCosts = 'ET06',
  Plot = 'ET25',
  CourseParticipantsOverhead = 'ET05',
  VesselsBoats = 'ET24',
  MachinesAndEquipment = 'ET27',
  SettingUpStand = 'ET18',
  ExternalCourseLeader = 'ET02',
  OrganizationOfTrainingWorkshopsAndConferences = 'ET16',
  TotalAmountAppliedFor = 'ET22',
  PurchaseOfReportsAndAnalyzes = 'ET10',
  AdviceConsultancyAssistance = 'ET03',
  PremisesAndEquipment = 'ET08',
  TravelExpenses = 'ET20',
  PersonnelCostsEmployees = 'ET09',
  InternalCourseLeader = 'ET04',
  IntellectualPropertyRights = 'ET30',
  Building = 'ET26',
  MotorVehiclesAndConstructionMachinery = 'ET28',
  TestingOfIdeaSolution = 'ET11',
  InnovationConsulting = 'ET01',
  OtherCostsForEquipmentMaterialsAndServices = 'ET21',
  ManagementOfClusterFacilities = 'ET13',
  ProjectManagementFacilitationOfCollaborationInformationSharing = 'ET12',
  ProfilingAndMarketingOfCluster = 'ET15',
  AssemblyShippingCustomsTraining = 'ET29',
}

export enum ExpenseTypeIds {
  AdviceConsultancyAssistance = '9f4daca0-5f5f-45d4-8c83-8cbf0cd9409d',
  MaterialsAndEquipment = 'efcc0a47-93f4-4e5b-8062-394248038072',
  TravelAndSubsistenceCosts = '115276c9-398f-4eed-b20b-3d4b810f81d4',
  PremisesAndEquipment = '55de6103-ec31-45d4-8244-a1e9e3bf9b78',
  ExternalCourseLeader = '793771c4-116a-4fcd-ad8a-652e46c9b2ec',
  InternalCourseLeader = '82c3720e-02c0-4128-8276-bdd0e8e31ec5',
  CourseParticipantsOverhead = '453cad06-7cb9-4121-9b77-4b49a576604c',
  ProjectManagementFacilitationOfCollaborationInformationSharing = 'f0c62615-ee7e-47c2-9a7c-eee5cd2d92c3',
  PersonnelCostsEmployees = '76656093-5938-4fb3-9ccc-a7cc7308c00b',
}
