import { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { OneWayInService } from 'src/api/v2';
import { ONE_WAY_IN_ALL_SAVED_FORMS_KEY } from 'src/constants/hook-keys';
import { useAuth } from 'src/features/authorization';
import { useCluster } from 'src/features/cluster';

const useOneWayInList = () => {
  const [oneWayInService] = useState(new OneWayInService());
  const { cluster } = useCluster();

  const { checkAccess } = useAuth();
  const { read } = checkAccess('OneWayIn');

  const oneWayInAllSavedFormsQuery = useQuery(
    [ONE_WAY_IN_ALL_SAVED_FORMS_KEY],
    () => oneWayInService.getOneWayInOpportunityList(),
    {
      enabled: !!cluster && read,
      retry: false,
      staleTime: 1000 * 60 * 15, // 15 min refetching on mount
      refetchOnMount: true,
    },
  );

  const totalFormNumber = useMemo(() => {
    let totalForms = 0;

    if (oneWayInAllSavedFormsQuery.data) {
      totalForms += oneWayInAllSavedFormsQuery.data.length;
    }

    return totalForms;
  }, [oneWayInAllSavedFormsQuery]);

  return {
    oneWayInAllSavedFormsQuery,
    totalFormNumber,
  };
};

export default useOneWayInList;
