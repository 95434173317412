import React from 'react';
import { EventDtoV2 } from 'src/api/v2';
import QRCodeCanvas from 'qrcode.react';

import css from './QrCodePrintTemplate.module.scss';
import { formatDateToNOString } from 'src/utils/FormatValue';
import { useTranslation } from 'react-i18next';

type QrCodePrintTemplateProps = {
  event: EventDtoV2;
  inviteLink: string;
  logoSrc?: string;
};

const QrCodePrintTemplate = React.forwardRef<HTMLDivElement, QrCodePrintTemplateProps>(
  ({ event, inviteLink, logoSrc }: QrCodePrintTemplateProps, ref) => {
    const { t: tEvents } = useTranslation('events');

    return (
      <div className={css['qr-template']} ref={ref}>
        {logoSrc && (
          <div className={css['qr-template__item']}>
            <img src={logoSrc} className={css['qr-template__item--image']} alt="logo" />
          </div>
        )}

        <h2 className={css['qr-template__item']}>
          {tEvents('qrCodeForRegistering')} {event.name}
        </h2>
        <div className={css['qr-template__item']}>
          <QRCodeCanvas value={inviteLink} size={400} />
        </div>
        <p className={css['qr-template__item']}>{tEvents('scanQrCode')}</p>
        <p className={css['qr-template__item--date']}>
          {formatDateToNOString(new Date(event.startDate), false, '{date}.{month}.{year}', true)}
          {event.endDate &&
            formatDateToNOString(new Date(event.endDate), false, ' - {date}.{month}.{year}', true)}
        </p>
      </div>
    );
  },
);

QrCodePrintTemplate.displayName = 'QrCodePrintTemplate';
export default QrCodePrintTemplate;
