export const Municipalities2023 = [
  {
    code: '0301',
    name: 'Oslo',
  },
  {
    code: '1101',
    name: 'Eigersund',
  },
  {
    code: '1103',
    name: 'Stavanger',
  },
  {
    code: '1106',
    name: 'Haugesund',
  },
  {
    code: '1108',
    name: 'Sandnes',
  },
  {
    code: '1111',
    name: 'Sokndal',
  },
  {
    code: '1112',
    name: 'Lund',
  },
  {
    code: '1114',
    name: 'Bjerkreim',
  },
  {
    code: '1119',
    name: 'Hå',
  },
  {
    code: '1120',
    name: 'Klepp',
  },
  {
    code: '1121',
    name: 'Time',
  },
  {
    code: '1122',
    name: 'Gjesdal',
  },
  {
    code: '1124',
    name: 'Sola',
  },
  {
    code: '1127',
    name: 'Randaberg',
  },
  {
    code: '1130',
    name: 'Strand',
  },
  {
    code: '1133',
    name: 'Hjelmeland',
  },
  {
    code: '1134',
    name: 'Suldal',
  },
  {
    code: '1135',
    name: 'Sauda',
  },
  {
    code: '1144',
    name: 'Kvitsøy',
  },
  {
    code: '1145',
    name: 'Bokn',
  },
  {
    code: '1146',
    name: 'Tysvær',
  },
  {
    code: '1149',
    name: 'Karmøy',
  },
  {
    code: '1151',
    name: 'Utsira',
  },
  {
    code: '1160',
    name: 'Vindafjord',
  },
  {
    code: '1505',
    name: 'Kristiansund',
  },
  {
    code: '1506',
    name: 'Molde',
  },
  {
    code: '1507',
    name: 'Ålesund',
  },
  {
    code: '1511',
    name: 'Vanylven',
  },
  {
    code: '1514',
    name: 'Sande',
  },
  {
    code: '1515',
    name: 'Herøy (Møre og Romsdal)',
  },
  {
    code: '1516',
    name: 'Ulstein',
  },
  {
    code: '1517',
    name: 'Hareid',
  },
  {
    code: '1520',
    name: 'Ørsta',
  },
  {
    code: '1525',
    name: 'Stranda',
  },
  {
    code: '1528',
    name: 'Sykkylven',
  },
  {
    code: '1531',
    name: 'Sula',
  },
  {
    code: '1532',
    name: 'Giske',
  },
  {
    code: '1535',
    name: 'Vestnes',
  },
  {
    code: '1539',
    name: 'Rauma',
  },
  {
    code: '1547',
    name: 'Aukra',
  },
  {
    code: '1554',
    name: 'Averøy',
  },
  {
    code: '1557',
    name: 'Gjemnes',
  },
  {
    code: '1560',
    name: 'Tingvoll',
  },
  {
    code: '1563',
    name: 'Sunndal',
  },
  {
    code: '1566',
    name: 'Surnadal',
  },
  {
    code: '1573',
    name: 'Smøla',
  },
  {
    code: '1576',
    name: 'Aure',
  },
  {
    code: '1577',
    name: 'Volda',
  },
  {
    code: '1578',
    name: 'Fjord',
  },
  {
    code: '1579',
    name: 'Hustadvika',
  },
  {
    code: '1804',
    name: 'Bodø',
  },
  {
    code: '1806',
    name: 'Narvik',
  },
  {
    code: '1811',
    name: 'Bindal',
  },
  {
    code: '1812',
    name: 'Sømna',
  },
  {
    code: '1813',
    name: 'Brønnøy',
  },
  {
    code: '1815',
    name: 'Vega',
  },
  {
    code: '1816',
    name: 'Vevelstad',
  },
  {
    code: '1818',
    name: 'Herøy (Nordland)',
  },
  {
    code: '1820',
    name: 'Alstahaug',
  },
  {
    code: '1822',
    name: 'Leirfjord',
  },
  {
    code: '1824',
    name: 'Vefsn',
  },
  {
    code: '1825',
    name: 'Grane',
  },
  {
    code: '1826',
    name: 'Hattfjelldal',
  },
  {
    code: '1827',
    name: 'Dønna',
  },
  {
    code: '1828',
    name: 'Nesna',
  },
  {
    code: '1832',
    name: 'Hemnes',
  },
  {
    code: '1833',
    name: 'Rana',
  },
  {
    code: '1834',
    name: 'Lurøy',
  },
  {
    code: '1835',
    name: 'Træna',
  },
  {
    code: '1836',
    name: 'Rødøy',
  },
  {
    code: '1837',
    name: 'Meløy',
  },
  {
    code: '1838',
    name: 'Gildeskål',
  },
  {
    code: '1839',
    name: 'Beiarn',
  },
  {
    code: '1840',
    name: 'Saltdal',
  },
  {
    code: '1841',
    name: 'Fauske',
  },
  {
    code: '1845',
    name: 'Sørfold',
  },
  {
    code: '1848',
    name: 'Steigen',
  },
  {
    code: '1851',
    name: 'Lødingen',
  },
  {
    code: '1853',
    name: 'Evenes',
  },
  {
    code: '1856',
    name: 'Røst',
  },
  {
    code: '1857',
    name: 'Værøy',
  },
  {
    code: '1859',
    name: 'Flakstad',
  },
  {
    code: '1860',
    name: 'Vestvågøy',
  },
  {
    code: '1865',
    name: 'Vågan',
  },
  {
    code: '1866',
    name: 'Hadsel',
  },
  {
    code: '1867',
    name: 'Bø',
  },
  {
    code: '1868',
    name: 'Øksnes',
  },
  {
    code: '1870',
    name: 'Sortland',
  },
  {
    code: '1871',
    name: 'Andøy',
  },
  {
    code: '1874',
    name: 'Moskenes',
  },
  {
    code: '1875',
    name: 'Hamarøy',
  },
  {
    code: '3001',
    name: 'Halden',
  },
  {
    code: '3002',
    name: 'Moss',
  },
  {
    code: '3003',
    name: 'Sarpsborg',
  },
  {
    code: '3004',
    name: 'Fredrikstad',
  },
  {
    code: '3005',
    name: 'Drammen',
  },
  {
    code: '3006',
    name: 'Kongsberg',
  },
  {
    code: '3007',
    name: 'Ringerike',
  },
  {
    code: '3011',
    name: 'Hvaler',
  },
  {
    code: '3012',
    name: 'Aremark',
  },
  {
    code: '3013',
    name: 'Marker',
  },
  {
    code: '3014',
    name: 'Indre Østfold',
  },
  {
    code: '3015',
    name: 'Skiptvet',
  },
  {
    code: '3016',
    name: 'Rakkestad',
  },
  {
    code: '3017',
    name: 'Råde',
  },
  {
    code: '3018',
    name: 'Våler (Viken)',
  },
  {
    code: '3019',
    name: 'Vestby',
  },
  {
    code: '3020',
    name: 'Nordre Follo',
  },
  {
    code: '3021',
    name: 'Ås',
  },
  {
    code: '3022',
    name: 'Frogn',
  },
  {
    code: '3023',
    name: 'Nesodden',
  },
  {
    code: '3024',
    name: 'Bærum',
  },
  {
    code: '3025',
    name: 'Asker',
  },
  {
    code: '3026',
    name: 'Aurskog-Høland',
  },
  {
    code: '3027',
    name: 'Rælingen',
  },
  {
    code: '3028',
    name: 'Enebakk',
  },
  {
    code: '3029',
    name: 'Lørenskog',
  },
  {
    code: '3030',
    name: 'Lillestrøm',
  },
  {
    code: '3031',
    name: 'Nittedal',
  },
  {
    code: '3032',
    name: 'Gjerdrum',
  },
  {
    code: '3033',
    name: 'Ullensaker',
  },
  {
    code: '3034',
    name: 'Nes',
  },
  {
    code: '3035',
    name: 'Eidsvoll',
  },
  {
    code: '3036',
    name: 'Nannestad',
  },
  {
    code: '3037',
    name: 'Hurdal',
  },
  {
    code: '3038',
    name: 'Hole',
  },
  {
    code: '3039',
    name: 'Flå',
  },
  {
    code: '3040',
    name: 'Nesbyen',
  },
  {
    code: '3041',
    name: 'Gol',
  },
  {
    code: '3042',
    name: 'Hemsedal',
  },
  {
    code: '3043',
    name: 'Ål',
  },
  {
    code: '3044',
    name: 'Hol',
  },
  {
    code: '3045',
    name: 'Sigdal',
  },
  {
    code: '3046',
    name: 'Krødsherad',
  },
  {
    code: '3047',
    name: 'Modum',
  },
  {
    code: '3048',
    name: 'Øvre Eiker',
  },
  {
    code: '3049',
    name: 'Lier',
  },
  {
    code: '3050',
    name: 'Flesberg',
  },
  {
    code: '3051',
    name: 'Rollag',
  },
  {
    code: '3052',
    name: 'Nore og Uvdal',
  },
  {
    code: '3053',
    name: 'Jevnaker',
  },
  {
    code: '3054',
    name: 'Lunner',
  },
  {
    code: '3401',
    name: 'Kongsvinger',
  },
  {
    code: '3403',
    name: 'Hamar',
  },
  {
    code: '3405',
    name: 'Lillehammer',
  },
  {
    code: '3407',
    name: 'Gjøvik',
  },
  {
    code: '3411',
    name: 'Ringsaker',
  },
  {
    code: '3412',
    name: 'Løten',
  },
  {
    code: '3413',
    name: 'Stange',
  },
  {
    code: '3414',
    name: 'Nord-Odal',
  },
  {
    code: '3415',
    name: 'Sør-Odal',
  },
  {
    code: '3416',
    name: 'Eidskog',
  },
  {
    code: '3417',
    name: 'Grue',
  },
  {
    code: '3418',
    name: 'Åsnes',
  },
  {
    code: '3419',
    name: 'Våler (Innlandet)',
  },
  {
    code: '3420',
    name: 'Elverum',
  },
  {
    code: '3421',
    name: 'Trysil',
  },
  {
    code: '3422',
    name: 'Åmot',
  },
  {
    code: '3423',
    name: 'Stor-Elvdal',
  },
  {
    code: '3424',
    name: 'Rendalen',
  },
  {
    code: '3425',
    name: 'Engerdal',
  },
  {
    code: '3426',
    name: 'Tolga',
  },
  {
    code: '3427',
    name: 'Tynset',
  },
  {
    code: '3428',
    name: 'Alvdal',
  },
  {
    code: '3429',
    name: 'Folldal',
  },
  {
    code: '3430',
    name: 'Os',
  },
  {
    code: '3431',
    name: 'Dovre',
  },
  {
    code: '3432',
    name: 'Lesja',
  },
  {
    code: '3433',
    name: 'Skjåk',
  },
  {
    code: '3434',
    name: 'Lom',
  },
  {
    code: '3435',
    name: 'Vågå',
  },
  {
    code: '3436',
    name: 'Nord-Fron',
  },
  {
    code: '3437',
    name: 'Sel',
  },
  {
    code: '3438',
    name: 'Sør-Fron',
  },
  {
    code: '3439',
    name: 'Ringebu',
  },
  {
    code: '3440',
    name: 'Øyer',
  },
  {
    code: '3441',
    name: 'Gausdal',
  },
  {
    code: '3442',
    name: 'Østre Toten',
  },
  {
    code: '3443',
    name: 'Vestre Toten',
  },
  {
    code: '3446',
    name: 'Gran',
  },
  {
    code: '3447',
    name: 'Søndre Land',
  },
  {
    code: '3448',
    name: 'Nordre Land',
  },
  {
    code: '3449',
    name: 'Sør-Aurdal',
  },
  {
    code: '3450',
    name: 'Etnedal',
  },
  {
    code: '3451',
    name: 'Nord-Aurdal',
  },
  {
    code: '3452',
    name: 'Vestre Slidre',
  },
  {
    code: '3453',
    name: 'Øystre Slidre',
  },
  {
    code: '3454',
    name: 'Vang',
  },
  {
    code: '3801',
    name: 'Horten',
  },
  {
    code: '3802',
    name: 'Holmestrand',
  },
  {
    code: '3803',
    name: 'Tønsberg',
  },
  {
    code: '3804',
    name: 'Sandefjord',
  },
  {
    code: '3805',
    name: 'Larvik',
  },
  {
    code: '3806',
    name: 'Porsgrunn',
  },
  {
    code: '3807',
    name: 'Skien',
  },
  {
    code: '3808',
    name: 'Notodden',
  },
  {
    code: '3811',
    name: 'Færder',
  },
  {
    code: '3812',
    name: 'Siljan',
  },
  {
    code: '3813',
    name: 'Bamble',
  },
  {
    code: '3814',
    name: 'Kragerø',
  },
  {
    code: '3815',
    name: 'Drangedal',
  },
  {
    code: '3816',
    name: 'Nome',
  },
  {
    code: '3817',
    name: 'Midt-Telemark',
  },
  {
    code: '3818',
    name: 'Tinn',
  },
  {
    code: '3819',
    name: 'Hjartdal',
  },
  {
    code: '3820',
    name: 'Seljord',
  },
  {
    code: '3821',
    name: 'Kviteseid',
  },
  {
    code: '3822',
    name: 'Nissedal',
  },
  {
    code: '3823',
    name: 'Fyresdal',
  },
  {
    code: '3824',
    name: 'Tokke',
  },
  {
    code: '3825',
    name: 'Vinje',
  },
  {
    code: '4201',
    name: 'Risør',
  },
  {
    code: '4202',
    name: 'Grimstad',
  },
  {
    code: '4203',
    name: 'Arendal',
  },
  {
    code: '4204',
    name: 'Kristiansand',
  },
  {
    code: '4205',
    name: 'Lindesnes',
  },
  {
    code: '4206',
    name: 'Farsund',
  },
  {
    code: '4207',
    name: 'Flekkefjord',
  },
  {
    code: '4211',
    name: 'Gjerstad',
  },
  {
    code: '4212',
    name: 'Vegårshei',
  },
  {
    code: '4213',
    name: 'Tvedestrand',
  },
  {
    code: '4214',
    name: 'Froland',
  },
  {
    code: '4215',
    name: 'Lillesand',
  },
  {
    code: '4216',
    name: 'Birkenes',
  },
  {
    code: '4217',
    name: 'Åmli',
  },
  {
    code: '4218',
    name: 'Iveland',
  },
  {
    code: '4219',
    name: 'Evje og Hornnes',
  },
  {
    code: '4220',
    name: 'Bygland',
  },
  {
    code: '4221',
    name: 'Valle',
  },
  {
    code: '4222',
    name: 'Bykle',
  },
  {
    code: '4223',
    name: 'Vennesla',
  },
  {
    code: '4224',
    name: 'Åseral',
  },
  {
    code: '4225',
    name: 'Lyngdal',
  },
  {
    code: '4226',
    name: 'Hægebostad',
  },
  {
    code: '4227',
    name: 'Kvinesdal',
  },
  {
    code: '4228',
    name: 'Sirdal',
  },
  {
    code: '4601',
    name: 'Bergen',
  },
  {
    code: '4602',
    name: 'Kinn',
  },
  {
    code: '4611',
    name: 'Etne',
  },
  {
    code: '4612',
    name: 'Sveio',
  },
  {
    code: '4613',
    name: 'Bømlo',
  },
  {
    code: '4614',
    name: 'Stord',
  },
  {
    code: '4615',
    name: 'Fitjar',
  },
  {
    code: '4616',
    name: 'Tysnes',
  },
  {
    code: '4617',
    name: 'Kvinnherad',
  },
  {
    code: '4618',
    name: 'Ullensvang',
  },
  {
    code: '4619',
    name: 'Eidfjord',
  },
  {
    code: '4620',
    name: 'Ulvik',
  },
  {
    code: '4621',
    name: 'Voss',
  },
  {
    code: '4622',
    name: 'Kvam',
  },
  {
    code: '4623',
    name: 'Samnanger',
  },
  {
    code: '4624',
    name: 'Bjørnafjorden',
  },
  {
    code: '4625',
    name: 'Austevoll',
  },
  {
    code: '4626',
    name: 'Øygarden',
  },
  {
    code: '4627',
    name: 'Askøy',
  },
  {
    code: '4628',
    name: 'Vaksdal',
  },
  {
    code: '4629',
    name: 'Modalen',
  },
  {
    code: '4630',
    name: 'Osterøy',
  },
  {
    code: '4631',
    name: 'Alver',
  },
  {
    code: '4632',
    name: 'Austrheim',
  },
  {
    code: '4633',
    name: 'Fedje',
  },
  {
    code: '4634',
    name: 'Masfjorden',
  },
  {
    code: '4635',
    name: 'Gulen',
  },
  {
    code: '4636',
    name: 'Solund',
  },
  {
    code: '4637',
    name: 'Hyllestad',
  },
  {
    code: '4638',
    name: 'Høyanger',
  },
  {
    code: '4639',
    name: 'Vik',
  },
  {
    code: '4640',
    name: 'Sogndal',
  },
  {
    code: '4641',
    name: 'Aurland',
  },
  {
    code: '4642',
    name: 'Lærdal',
  },
  {
    code: '4643',
    name: 'Årdal',
  },
  {
    code: '4644',
    name: 'Luster',
  },
  {
    code: '4645',
    name: 'Askvoll',
  },
  {
    code: '4646',
    name: 'Fjaler',
  },
  {
    code: '4647',
    name: 'Sunnfjord',
  },
  {
    code: '4648',
    name: 'Bremanger',
  },
  {
    code: '4649',
    name: 'Stad',
  },
  {
    code: '4650',
    name: 'Gloppen',
  },
  {
    code: '4651',
    name: 'Stryn',
  },
  {
    code: '5001',
    name: 'Trondheim',
  },
  {
    code: '5006',
    name: 'Steinkjer',
  },
  {
    code: '5007',
    name: 'Namsos',
  },
  {
    code: '5014',
    name: 'Frøya',
  },
  {
    code: '5020',
    name: 'Osen',
  },
  {
    code: '5021',
    name: 'Oppdal',
  },
  {
    code: '5022',
    name: 'Rennebu',
  },
  {
    code: '5025',
    name: 'Røros',
  },
  {
    code: '5026',
    name: 'Holtålen',
  },
  {
    code: '5027',
    name: 'Midtre Gauldal',
  },
  {
    code: '5028',
    name: 'Melhus',
  },
  {
    code: '5029',
    name: 'Skaun',
  },
  {
    code: '5031',
    name: 'Malvik',
  },
  {
    code: '5032',
    name: 'Selbu',
  },
  {
    code: '5033',
    name: 'Tydal',
  },
  {
    code: '5034',
    name: 'Meråker',
  },
  {
    code: '5035',
    name: 'Stjørdal',
  },
  {
    code: '5036',
    name: 'Frosta',
  },
  {
    code: '5037',
    name: 'Levanger',
  },
  {
    code: '5038',
    name: 'Verdal',
  },
  {
    code: '5041',
    name: 'Snåsa',
  },
  {
    code: '5042',
    name: 'Lierne',
  },
  {
    code: '5043',
    name: 'Røyrvik',
  },
  {
    code: '5044',
    name: 'Namsskogan',
  },
  {
    code: '5045',
    name: 'Grong',
  },
  {
    code: '5046',
    name: 'Høylandet',
  },
  {
    code: '5047',
    name: 'Overhalla',
  },
  {
    code: '5049',
    name: 'Flatanger',
  },
  {
    code: '5052',
    name: 'Leka',
  },
  {
    code: '5053',
    name: 'Inderøy',
  },
  {
    code: '5054',
    name: 'Indre Fosen',
  },
  {
    code: '5055',
    name: 'Heim',
  },
  {
    code: '5056',
    name: 'Hitra',
  },
  {
    code: '5057',
    name: 'Ørland',
  },
  {
    code: '5058',
    name: 'Åfjord',
  },
  {
    code: '5059',
    name: 'Orkland',
  },
  {
    code: '5060',
    name: 'Nærøysund',
  },
  {
    code: '5061',
    name: 'Rindal',
  },
  {
    code: '5401',
    name: 'Tromsø',
  },
  {
    code: '5402',
    name: 'Harstad',
  },
  {
    code: '5403',
    name: 'Alta',
  },
  {
    code: '5404',
    name: 'Vardø',
  },
  {
    code: '5405',
    name: 'Vadsø',
  },
  {
    code: '5406',
    name: 'Hammerfest',
  },
  {
    code: '5411',
    name: 'Kvæfjord',
  },
  {
    code: '5412',
    name: 'Tjeldsund',
  },
  {
    code: '5413',
    name: 'Ibestad',
  },
  {
    code: '5414',
    name: 'Gratangen',
  },
  {
    code: '5415',
    name: 'Lavangen',
  },
  {
    code: '5416',
    name: 'Bardu',
  },
  {
    code: '5417',
    name: 'Salangen',
  },
  {
    code: '5418',
    name: 'Målselv',
  },
  {
    code: '5419',
    name: 'Sørreisa',
  },
  {
    code: '5420',
    name: 'Dyrøy',
  },
  {
    code: '5421',
    name: 'Senja',
  },
  {
    code: '5422',
    name: 'Balsfjord',
  },
  {
    code: '5423',
    name: 'Karlsøy',
  },
  {
    code: '5424',
    name: 'Lyngen',
  },
  {
    code: '5425',
    name: 'Storfjord',
  },
  {
    code: '5426',
    name: 'Kåfjord',
  },
  {
    code: '5427',
    name: 'Skjervøy',
  },
  {
    code: '5428',
    name: 'Nordreisa',
  },
  {
    code: '5429',
    name: 'Kvænangen',
  },
  {
    code: '5430',
    name: 'Kautokeino',
  },
  {
    code: '5432',
    name: 'Loppa',
  },
  {
    code: '5433',
    name: 'Hasvik',
  },
  {
    code: '5434',
    name: 'Måsøy',
  },
  {
    code: '5435',
    name: 'Nordkapp',
  },
  {
    code: '5436',
    name: 'Porsanger',
  },
  {
    code: '5437',
    name: 'Karasjok',
  },
  {
    code: '5438',
    name: 'Lebesby',
  },
  {
    code: '5439',
    name: 'Gamvik',
  },
  {
    code: '5440',
    name: 'Berlevåg',
  },
  {
    code: '5441',
    name: 'Tana',
  },
  {
    code: '5442',
    name: 'Nesseby',
  },
  {
    code: '5443',
    name: 'Båtsfjord',
  },
  {
    code: '5444',
    name: 'Sør-Varanger',
  },
  {
    code: '9999',
    name: 'Uoppgitt',
  },
];

export const Municipalities2023Map = new Map(
  Municipalities2023.map((municipality) => [municipality.code, municipality.name]),
);
