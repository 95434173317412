import React, { useContext, useMemo } from 'react';
import { FlowContent } from '../../constants/Flows';
import StepConfirmation from './Steps/StepConfirmation';
import { SideMenuSubpageContext } from '../OnboardingSideMenu';
import { useOnboarding } from 'src/features/onboarding/hooks.tsx/use-onboarding';
import { ListLink, Notification, Spinner, Tag } from '@in/component-library';
import { useTranslation } from 'react-i18next';
import { OnboardingPacketDto } from 'src/api/v2';
import { useNavigate } from 'react-router-dom';
import useSiteimprove from 'src/hooks/use-siteimprove';

export const GuidedTourWrapper = () => {
  const { setSubpage, flow, setFlow } = useContext(SideMenuSubpageContext);
  const { t: tCommon } = useTranslation();
  const { t: tOnboarding } = useTranslation('onboarding');
  const navigate = useNavigate();
  const siteimprove = useSiteimprove();

  const { onboardingPackets, completeOnboardingPacket, onboardingQuery } = useOnboarding();

  const handleComplete = useMemo(() => {
    return () => {
      if (flow) {
        completeOnboardingPacket(flow.id).then(() => siteimprove.log('Onboarding', flow.code, 'Complete'));
      }
      if (setFlow) {
        setFlow(null);
      }
    };
  }, [completeOnboardingPacket, flow, setFlow]);

  if (!setSubpage) return <></>;

  if (onboardingQuery.isLoading) return <Spinner />;

  if (flow) {
    return (
      <StepConfirmation
        pages={FlowContent[flow.code]}
        name={flow.name || ''}
        code={flow.code || ''}
        flowCompleted={flow.completed}
        completeOnboardingPacket={handleComplete}
      ></StepConfirmation>
    );
  }

  const handleStartPacket = (packet: OnboardingPacketDto) => {
    if (setFlow) {
      setFlow(packet);
      navigate(FlowContent[packet.code][0].pageUrl);
    }
  };

  return (
    <>
      <h2 className="help-and-support__header">{tOnboarding('helpAndSupport.tab.Startup')}</h2>
      <Notification className="background-color--yellow-50 margin-bottom--2">
        {tOnboarding('guide.warning')}
      </Notification>
      <div>
        {onboardingPackets.map((packet) => (
          <ListLink
            key={packet.id + 'Selection'}
            header={packet.name || tOnboarding('guide.Overview.Heading')}
            className="help-and-support-sl-outline-fixer cursor--pointer"
            onClick={() => setFlow && handleStartPacket(packet)}
            onKeyDown={(e) => {
              if (!setFlow) return;
              if (e.code === 'Enter') handleStartPacket(packet);
            }}
            tabIndex={0}
          >
            <>
              {packet.completed && (
                <div>
                  <Tag type={'success'}>{tCommon('completed')}</Tag>
                </div>
              )}{' '}
              {packet.description}
            </>
          </ListLink>
        ))}
      </div>
    </>
  );
};
