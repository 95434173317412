import { ErrorMessage } from '@in/component-library';
import React from 'react';
import { toast } from 'react-toastify';

interface ValidationError {
  status: 400;
  title: string;
  errors: Record<string, string[]>;
  additionalProperties?: {
    traceId?: string;
  };
}

function isValidationError(error: object): error is ValidationError {
  if (!('errors' in error)) return false;
  if (!('status' in error && error.status === 400)) return false;
  return true;
}

function ValidationErrorToastContent({ error }: { error: ValidationError }) {
  return (
    <>
      <h3>Noe gikk galt:</h3>
      {Object.entries(error.errors).map(([key, values]) => (
        <div key={key}>
          <ErrorMessage errorMessage={`${key}: ${values.join(', ')}`} />
          <br />
        </div>
      ))}
      {error.additionalProperties?.traceId && (
        <p>
          Du kan kontakte oss og oppgi denne koden om problemet vedvarer: {error.additionalProperties.traceId}
        </p>
      )}
    </>
  );
}

export function toastIfValidationError(error: unknown) {
  if (!(error && typeof error === 'object')) return;
  const camelCaseError = Object.fromEntries(
    Object.entries(error).map(([key, value]) => [key[0].toLowerCase() + key.substring(1), value]),
  );
  if (isValidationError(camelCaseError)) {
    toast(<ValidationErrorToastContent error={camelCaseError} />, {
      toastId: 'ValidationError',
      updateId: camelCaseError.additionalProperties?.traceId,
      autoClose: false,
    });
  }
}
