import { useState } from 'react';
import { useInfiniteQuery, useQuery } from 'react-query';
import { OneWayInFeedbackService, OneWayInVMAService } from 'src/api/v2';
import { ONE_WAY_IN_FEEDBACK_LIST, ONE_WAY_IN_VMA_ALL, ONE_WAY_IN_VMA_FORMS } from 'src/constants/hook-keys';
import { useAuth } from 'src/features/authorization';
import { useCluster } from 'src/features/cluster';

const useOneWayInVMA = (
  vmaIdOrName: string | null = null,
  oldToNew?: boolean,
  fromDate?: Date,
  toDate?: Date,
  top?: number,
) => {
  const [vmaService] = useState(new OneWayInVMAService());
  const [oneWayInFeedbackService] = useState(new OneWayInFeedbackService());
  const { cluster } = useCluster();

  const { checkAccess } = useAuth();
  const read = checkAccess('OneWayIn').read;

  const oneWayInAllVMAsQuery = useQuery([ONE_WAY_IN_VMA_ALL], () => vmaService.getOneWayInVMAs(), {
    enabled: !!cluster && read,
    retry: false,
    staleTime: 1000 * 60 * 30, // 30 min refetching on mount
    refetchOnMount: true,
  });

  const oneWayInAllVMAFormsQuery = useInfiniteQuery(
    [ONE_WAY_IN_VMA_FORMS, vmaIdOrName, oldToNew, fromDate, toDate, top],
    ({ pageParam = 1 }) =>
      vmaService.getAllVMAForms(
        {
          oldToNew: oldToNew,
          fromDate: fromDate,
          toDate: toDate,
          counties: [],
          municipalities: [],
          ignoreReferralParty: false,
          referralPartyNameOrId: vmaIdOrName || undefined,
          top: top ?? 50,
        },
        pageParam,
      ),
    {
      enabled: !!cluster && !!vmaIdOrName && read,
      retry: false,
      staleTime: 1000 * 60 * 30, // 30 min refetching on mount
      refetchOnMount: true,
      getNextPageParam: (lastPage, pagesArray) => {
        if (lastPage.pages > pagesArray.length) return pagesArray.length + 1;

        return undefined;
      },
    },
  );

  const oneWayInFeedbackListFromActorQuery = useQuery(
    [ONE_WAY_IN_FEEDBACK_LIST],
    () => oneWayInFeedbackService.getFeedbackList(),
    {
      enabled: !!cluster && read,
      retry: false,
      staleTime: 1000 * 60 * 24,
      refetchOnMount: true,
    },
  );

  return {
    oneWayInAllVMAsQuery,
    oneWayInAllVMAFormsQuery,
    oneWayInFeedbackListFromActorQuery,
  };
};

export default useOneWayInVMA;
