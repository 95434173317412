import React from 'react';
import { useTranslation } from 'react-i18next';
import { SivaPhase } from 'src/api/v2';
import { Notification } from '@in/component-library';
import useCompanyPhaseCheck from '../hooks/use-company-phase-check';

type Props = {
  selectedPhase: SivaPhase;
  organizationNumber: string;
  setError: React.Dispatch<React.SetStateAction<boolean>>;
};

const PhaseChangeCompanyCheck: React.FC<Props> = ({ selectedPhase, organizationNumber, setError }) => {
  const { t: tSiva } = useTranslation('siva');
  const isPhaseAllowed = useCompanyPhaseCheck(selectedPhase, organizationNumber);

  React.useEffect(() => {
    if (!isPhaseAllowed) {
      setError(true);
    } else {
      setError(false);
    }
  }, [isPhaseAllowed, setError]);

  if (!isPhaseAllowed) {
    return (
      <div className="margin-bottom--3">
        <Notification type="error">
          <p>Denne bedriften har allerede en avtale med fase {tSiva(`phase.${selectedPhase}`)} registrert.</p>
        </Notification>
      </div>
    );
  }
  return <></>;
};

export default PhaseChangeCompanyCheck;
