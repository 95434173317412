import React from 'react';
import { useTranslation } from 'react-i18next';

const ArchivedMessage = () => {
  const { t: tOneWayIn } = useTranslation('oneWayIn');

  return (
    <>
      <h2>{tOneWayIn('details.Archived.Header')}</h2>
      <div>{tOneWayIn('details.Archived.Message')}</div>
    </>
  );
};

export default ArchivedMessage;
