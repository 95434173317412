import { ProjectPhaseDto, ProjectEndCategoryDto, ProjectEndSubCategoryDto } from 'src/api/v2';

interface IProjectCategoryTypes {
  custom: string;
  competenceDevelopment: string;
  innovationCounseling: string;
  internationalization: string;
  operationalApplication: string;
  innovationCollaboration: string;
}

interface IProjectSourceTypes {
  portal: string;
  application: string;
  crm: string;
}

interface IProjectPhaseTypes {
  planned: ProjectPhaseDto;
  idea: ProjectPhaseDto;
  ongoing: ProjectPhaseDto;
  terminated: ProjectPhaseDto;
  completed: ProjectPhaseDto;
}

interface IProjectEndCategoryTypes {
  cancelled: ProjectEndCategoryDto;
  completed: ProjectEndCategoryDto;
  transferred: ProjectEndCategoryDto;
}

interface IProjectEndSubCategoryTypes {
  other: ProjectEndSubCategoryDto;
  mainProjectPublicFinance: ProjectEndSubCategoryDto;
  mainProjectPrivateFinance: ProjectEndSubCategoryDto;
}

export const ProjectCategoryTypes: IProjectCategoryTypes = {
  custom: '1bd5af27-2dc2-4802-9c9a-26d3112e67e2',
  competenceDevelopment: '395fbbaf-1b19-4285-a860-2ad45a89b192',
  innovationCounseling: '3337bde4-18b3-4409-8ec9-93d721d5aa39',
  internationalization: '9e195c05-c38b-403d-acce-9c8ece6eb92a',
  operationalApplication: '02a02bd7-9899-42a3-9759-efbeca9651b5',
  innovationCollaboration: '71502565-fa69-41cd-acee-f86d450e97f0',
};

export const ProjectSourceCodeTypes: IProjectSourceTypes = {
  portal: 'Portal',
  application: 'Application',
  crm: 'CRM',
};

export const ProjectTypeOperationId = '3d47020e-20b9-46a2-b46c-ddabf23241b2';

export const ProjectPhaseTypes: IProjectPhaseTypes = {
  planned: {
    id: '0640d9f1-4ddf-4f26-8769-782c0b66b9e2',
    name: 'Planlagt',
    code: 'PLANNED',
    description: 'Planlagt',
  },
  idea: {
    id: 'ed9165a0-9af4-43bf-bf84-9a841c2aa5d9',
    name: 'Idé',
    code: 'IDEA',
    description: 'Prosjektidé',
  },
  ongoing: {
    id: '65b1256e-35f9-4965-a03b-b3e89e92b9b4',
    name: 'Pågående',
    code: 'ONGOING',
    description: 'Pågående',
  },
  terminated: {
    id: 'a9bf65e7-8e93-4911-a710-fe4870208abf',
    name: 'Avsluttet',
    code: 'TERMINATED',
    description: 'Avsluttet',
  },
  completed: {
    id: '1cb867b6-2c34-4b66-940b-258c922ed440',
    name: 'Ferdigstilt',
    code: 'COMPLETED',
    description: 'Ferdigstilt',
  },
};

export const ProjectEndCategoryTypes: IProjectEndCategoryTypes = {
  cancelled: {
    id: '4d98ceef-0d5e-4300-9d72-58fa89055101',
    name: 'Avbrutt',
    code: 'PEC01',
    description: 'Avbrutt',
  },
  completed: {
    id: '01676fc1-0ed3-4231-a51f-b7611e5194d9',
    name: 'Gjennomført',
    code: 'PEC02',
    description: 'Gjennomført',
  },
  transferred: {
    id: '25541cc3-7eed-47dc-968c-c98440ef6a8a',
    name: 'Videreført',
    code: 'PEC03',
    description: 'Videreført',
  },
};

export const ProjectEndSubCategoryTypes: IProjectEndSubCategoryTypes = {
  other: {
    id: '597aa33e-ad83-494a-9863-8847fb675fa8',
    name: 'Annet',
    code: 'PESC03',
    description: 'Annet',
  },
  mainProjectPublicFinance: {
    id: '90cabbfd-4d16-4d17-9f1a-d9dc32b49d24',
    name: 'Hovedprosjekt med offentlig finansiering',
    code: 'PESC01',
    description: 'Hovedprosjekt med offentlig finansiering',
  },
  mainProjectPrivateFinance: {
    id: 'ba770889-8b02-4fea-bf83-dbf5818bf955',
    name: 'Hovedprosjekt med privat finansiering',
    code: 'PESC02',
    description: 'Hovedprosjekt med privat finansiering',
  },
};
