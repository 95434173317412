import React, { useMemo } from 'react';

import { Button, ButtonVariant, Notification, FaIcon, FaIcons } from '@in/component-library';

import { HttpStatusCode } from 'src/api/v2';
import { useTranslation } from 'react-i18next';

export interface OperationResultListData {
  name: string;
  statusCode: HttpStatusCode;
  message: string;
}

type Props = {
  data: OperationResultListData[];
  className?: string;
};

const OperationResultList: React.FC<Props> = ({ data, className }) => {
  const { t: tCommon } = useTranslation();

  const [displayType, setDisplayType] = React.useState<'none' | 'success' | 'error' | 'all'>('none');

  const isSuccess = (status: HttpStatusCode): boolean => {
    switch (status) {
      case HttpStatusCode.OK:
      case HttpStatusCode.Created:
        return true;
      default:
        return false;
    }
  };

  const errors = useMemo(() => data.filter((x) => !isSuccess(x.statusCode)), [data]);
  const success = useMemo(() => data.filter((x) => isSuccess(x.statusCode)), [data]);
  const notificationType = useMemo<'info' | 'success' | 'error'>(
    () => (errors.length > 0 ? 'error' : 'success'),
    [errors.length],
  );

  const displayRows = useMemo(() => {
    switch (displayType) {
      case 'success':
        return [...success];

      case 'error':
        return [...errors];

      case 'all':
        return [...data];

      default:
        return [];
    }
  }, [data, displayType, errors, success]);

  const renderMessage = (message: string) => {
    // TODO: Få en translation-key fra SLv2 her som vi kan bruke
    switch (message) {
      case 'Created':
        return tCommon('created');

      default:
        return message;
    }
  };

  if (data.length === 0) {
    return <></>;
  }

  return (
    <>
      <Notification type={notificationType} className={className} fullWidth>
        <div>
          {tCommon('operationResultListDescription', { success: success.length, errors: errors.length })}
        </div>
        <div className="display--flex gap--2">
          <Button variant={ButtonVariant.Link} type="button" onClick={() => setDisplayType('error')}>
            {tCommon('showFailed')}
          </Button>

          <Button variant={ButtonVariant.Link} type="button" onClick={() => setDisplayType('success')}>
            {tCommon('showSucceeded')}
          </Button>

          <Button variant={ButtonVariant.Link} type="button" onClick={() => setDisplayType('all')}>
            {tCommon('showAll')}
          </Button>

          <Button variant={ButtonVariant.Link} type="button" onClick={() => setDisplayType('none')}>
            {tCommon('hide')}
          </Button>
        </div>
        {displayRows.length > 0 && (
          <>
            <hr />
            <div className="">
              {displayRows.map((x, index) => {
                return (
                  <div key={index} className="display--flex justify-content--space-between">
                    <div className="display--flex gap--2">
                      <span>
                        {isSuccess(x.statusCode) ? (
                          <FaIcon name={FaIcons.CheckLight} />
                        ) : (
                          <FaIcon name={FaIcons.TimesLight} />
                        )}
                      </span>
                      <span>{x.name}</span>
                    </div>

                    <span>{renderMessage(x.message)}</span>
                  </div>
                );
              })}
            </div>
          </>
        )}
      </Notification>
    </>
  );
};

export default OperationResultList;
