import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';

const dateTimeFormatNo = new Intl.DateTimeFormat('no-NB', {
  dateStyle: 'long',
});

const dateTimeFormatEn = new Intl.DateTimeFormat('en-GB', {
  dateStyle: 'long',
});

export function useUserReadableDate(date: Date | string | number) {
  const { i18n } = useTranslation();
  const dateTimeFormat = i18n.language === 'en' ? dateTimeFormatEn : dateTimeFormatNo;
  return useMemo(() => dateTimeFormat.format(new Date(date)), [date, dateTimeFormat]);
}
