import { ProgressBarFull } from '@in/component-library';
import React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  totalSteps: number;
  currentStepIndex: number;
};

const NewCompanyProgressBar: React.FC<Props> = ({ totalSteps, currentStepIndex }: Props) => {
  const { t: tCommon } = useTranslation();
  const currentStep = currentStepIndex + 1;

  return (
    <div className="width--50 margin-bottom--6">
      <div className="display--flex justify-content--space-between">
        <span>{tCommon('completed')}</span>
        <span className="text-align--right">
          {`${currentStep} ${tCommon('of').toLowerCase()} ${totalSteps} ${tCommon('step').toLowerCase()}`}
        </span>
      </div>

      <ProgressBarFull className="progress-bar--blue" percentage={100 * (currentStep / totalSteps)} />
    </div>
  );
};

export default NewCompanyProgressBar;
