import { Button } from '@in/component-library';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { OnboardingPacketDto } from 'src/api/v2';
import onboarding from 'src/assets/img/onboarding.svg';
import DialogContainer from 'src/components/DialogContainer/DialogContainer';
import { useOnboarding } from 'src/features/onboarding/hooks.tsx/use-onboarding';
import useToggle from 'src/hooks/use-toggle';
import { SideMenuSubpageContext } from './OnboardingSideMenu';
import { useNavigate } from 'react-router-dom';
import { FlowContent } from '../constants/Flows';
import useSiteimprove from 'src/hooks/use-siteimprove';
type Props = {
  onboardingPacket: OnboardingPacketDto;
};

const Introduction = ({ onboardingPacket }: Props) => {
  const { deactivate, isActive } = useToggle(true);
  const { t: tOnboarding } = useTranslation('onboarding');
  const { t: tOneWayIn } = useTranslation('oneWayIn');
  const navigate = useNavigate();
  const siteimprove = useSiteimprove();

  const { setSubpage, setFlow } = useContext(SideMenuSubpageContext);

  const { completeOnboardingPacket } = useOnboarding();

  const onStart = () => {
    if (setSubpage && setFlow) {
      setFlow(onboardingPacket);
      setSubpage('stepConfirmation');
      handleOpenWindow();
      navigate(FlowContent[onboardingPacket.code][0].pageUrl);
    }
    deactivate();
  };

  const handleOpenWindow = () => {
    window.dispatchEvent(new Event('openDrawerMenu'));
  };

  const onSkip = () => {
    deactivate();
    completeOnboardingPacket(onboardingPacket.id).then(() =>
      siteimprove.log('Onboarding', onboardingPacket.code, 'Skipped'),
    );
  };

  return (
    <DialogContainer show={isActive} title={onboardingPacket.name || ''} onClose={deactivate}>
      <div className="background-color--black-12 text-align--center">
        <img
          className="margin--auto padding-y--2"
          src={onboarding}
          alt={tOnboarding('popup.Image.AltText')}
        />
      </div>
      <div>
        <p className="margin-top--1 font-size--x-large">{onboardingPacket.description}</p>
        <div className="display--flex gap--1 margin-top--4">
          <Button onClick={onStart}>{tOnboarding('helpAndSupport.tab.Startup')}</Button>
          <Button onClick={onSkip} theme="neutral" variant="outlined">
            {tOneWayIn('onboarding.modal.skip')}
          </Button>
        </div>
      </div>
    </DialogContainer>
  );
};

export default Introduction;
