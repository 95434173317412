import React from 'react';
import Label from './Label';
import { FormGroupErrorContext } from './FormGroup';
import FormElementError from './FormElementError';
import classnames from 'classnames';
import './style.scss';
import generateGuid from 'src/utils/Guid';

export interface TextAreaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  className?: string;
  error?: React.ReactNode | boolean;
  id?: string;
  inputClassName?: string;
  label?: React.ReactNode;
  description?: React.ReactNode;
  name?: string;
  maxCount?: number;
  helpText?: React.ReactNode;
}

const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (
    {
      className,
      error,
      id,
      inputClassName,
      label,
      description,
      name,
      maxCount,
      helpText,
      ...props
    }: TextAreaProps,
    ref,
  ) => {
    const context = React.useContext(FormGroupErrorContext);

    const inputId = id || generateGuid();
    const resolvedError = context.error || error;
    const errorId = context.errorId || generateGuid();
    const descriptionId = description ? generateGuid() : undefined;

    let startValueLength = 0;
    if (props.value && typeof props.value === 'string') {
      startValueLength = props.value.length;
    } else if (props.defaultValue && typeof props.defaultValue === 'string') {
      startValueLength = props.defaultValue.length;
    }

    const [valueLength, setValueLength] = React.useState<number>(startValueLength);
    const isTooLong = maxCount && valueLength > maxCount;

    const classes = classnames('form-element', className);
    const inputClasses = classnames(
      'form-element__textarea',
      {
        'has-error': resolvedError,
      },
      inputClassName,
    );

    return (
      <div className={classes}>
        {label && (
          <Label htmlFor={inputId} helpText={helpText}>
            {label}
            {props.required && <span className="form-element__label--required">*</span>}
          </Label>
        )}
        {description && (
          <div className="form-element__description" id={descriptionId}>
            {description}
          </div>
        )}
        <textarea
          className={inputClasses}
          id={inputId}
          name={name}
          ref={ref}
          aria-invalid={!!error}
          aria-describedby={descriptionId}
          aria-errormessage={error ? errorId : undefined}
          {...props}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>): void => {
            setValueLength(e.currentTarget.value != null ? e.currentTarget.value.length : 0);
            props.onChange?.(e);
          }}
        />
        {maxCount && (
          <span
            className={`form-element__textarea__counter ${
              isTooLong ? 'form-element__textarea__counter--error' : ''
            } ${props.disabled ? 'form-element__textarea__counter--disabled' : ''}`}
          >
            <span className={'form-element__textarea__counter--current'}>{valueLength}</span> /{' '}
            <span className={'form-element__textarea__counter--max'}>{maxCount}</span>
          </span>
        )}
        {!context.error && !!error && (
          <FormElementError id={errorId}>{typeof error !== 'boolean' && error}</FormElementError>
        )}
      </div>
    );
  },
);

TextArea.displayName = 'TextArea';

export default TextArea;
