import { ClusterContactStatus, TimeEntrySource } from 'src/api/v2';
import { convertHourstoHoursAndMinutes } from './time';

/**
 *
 * @param value
 * @returns formatted organization number 'XXX XXX XXX'
 */
export function formatOrganizationNumber(value: string | null | undefined): string {
  if (value === null || value === undefined) {
    return '';
  }

  if (value.length !== 9) {
    return value;
  }

  const split = value.split('');

  const a = split.slice(0, 3).join('');
  const b = split.slice(3, 6).join('');
  const c = split.slice(6, 9).join('');

  return `${a} ${b} ${c}`;
}

/**
 *
 * @param value
 * @returns
 */
export function formatTimeEntryStatus(value: string | undefined): React.ReactNode {
  if (value) {
    switch (value) {
      case 'PendingApproval':
        return 'Trenger godkjenning';
      case 'NonCounting':
        return 'Ikke tellende';
      case 'Counting':
        return 'Godkjent';
      default:
        return 'Ikke tellende';
    }
  }

  return 'Ikke tellende';
}

/**
 *
 * @param value
 * @returns formatted NOK number 'x xxx xxx'
 */
export function formatNOKCurrency(value: number | string | null | undefined): string {
  if (!value) {
    return '0 kr';
  }

  return (+value).toLocaleString('nb') + ' kr';
}

/**
 *
 * @param value
 * @returns formatted phone number 'xx xx xxx'
 */
export function formatPhoneNumber(value: string | null | undefined): string {
  if (!value) {
    return '';
  }

  if (value.length !== 8) {
    return value;
  }

  const split = value.split('');

  const a = split.slice(0, 3).join('');
  const b = split.slice(3, 5).join('');
  const c = split.slice(5, 8).join('');

  return `${a} ${b} ${c}`;
}

export function formatHours(time: number) {
  const { hours, minutes } = convertHourstoHoursAndMinutes(time);

  if (minutes === 0) {
    return `${hours}t`;
  }

  return `${hours}t ${minutes}m`;
}

export function formatTimeEntrySource(value: TimeEntrySource) {
  switch (value) {
    case TimeEntrySource.Import:
      return 'imported';
    case TimeEntrySource.Link:
      return 'registrationLink';
    case TimeEntrySource.Manual:
      return 'manually';
    default:
      return 'unspecified';
  }
}

export function formatContactStatus(value: ClusterContactStatus) {
  switch (value) {
    case ClusterContactStatus.Active:
      return 'active';
    case ClusterContactStatus.InActive:
      return 'inactive';
    case ClusterContactStatus.PendingApproval:
      return 'pendingApproval';
  }
}
