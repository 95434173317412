export function truncate(str: string, n: number = 32, suffix: string = '...') {
  return str.length > n ? str.substring(0, n - 1) + suffix : str;
}

export function capitalizeFirstLetter(text: string) {
  if (!text || text.length === 0) return text;
  if (text.length === 1) return text.toUpperCase();

  return text.charAt(0).toUpperCase() + text.slice(1);
}
