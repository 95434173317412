import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomerInfoDto } from 'src/api/v2';
import Table from 'src/components/Table';

type Props = {
  company: CustomerInfoDto;
  id: string;
  isActive: boolean;
};

const CompanyInformationRegistreTab: React.FC<Props> = ({ company, id, isActive }) => {
  const { t: tCommon } = useTranslation();
  const { t: tSiva } = useTranslation('siva');

  const formatBoolean = useCallback(
    (value: boolean | undefined) => {
      return value ? tCommon('yes') : tCommon('no');
    },
    [tCommon],
  );

  const tableData = useMemo(() => {
    return [
      {
        key: 'mva',
        label: 'MVA',
        value: formatBoolean(company.status?.registeredInMvaregisteret),
      },
      {
        key: 'enhetsregisteret',
        label: 'Enhetsregisteret',
        value: formatBoolean(!!company),
      },
      {
        key: 'foretaksregisteret',
        label: 'Foretaksregisteret',
        value: formatBoolean(company.status?.registeredInForetaksregisteret),
      },
      {
        key: 'frivilighetsregisteret',
        label: 'Frivillighetsregisteret',
        value: formatBoolean(company.status?.registeredInFrivillighetsregisteret),
      },
      {
        key: 'stiftelsesregisteret',
        label: 'Stiftelsesregisteret',
        value: formatBoolean(company.status?.registeredInStiftelsesregisteret),
      },
      {
        key: 'registreringsdato',
        label: 'Registreringsdato',
        value: company.status?.registeredOn
          ? new Date(company.status?.registeredOn).toLocaleDateString()
          : '',
      },
    ] satisfies { key: string; label: React.ReactNode; value: React.ReactNode }[];
  }, [company, formatBoolean]);

  return (
    <div id={id} role="tabpanel" hidden={!isActive}>
      <h3>{tSiva('companyTabs.register.title')}</h3>

      <Table>
        <Table.Body>
          {tableData.map((item) => (
            <Table.Row key={item.key}>
              <Table.Cell className="width--256px max-width--256px">
                <strong>{item.label}</strong>
              </Table.Cell>
              <Table.Cell>{item.value}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </div>
  );
};

export default CompanyInformationRegistreTab;
