import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import DialogContainer from 'src/components/DialogContainer/DialogContainer';
import { useDevelopmentProjects, usePortalProjects } from 'src/features/projects';
import useOperationApplication from 'src/hooks/use-operation-application';
import RegisterInkindFormWrapper from './RegisterInkindFormWrapper';

export type RegisterInkindDefaultValues = {
  projectId?: string;
  eventId?: string;
};

type Props = {
  show: boolean;

  onClose: () => void;

  defaultValues?: RegisterInkindDefaultValues;

  isApplicationProject?: boolean;
  showAllProjects?: boolean;
};

const RegisterInkindModal: React.FC<Props> = ({
  show,
  onClose,
  defaultValues,
  isApplicationProject,
  showAllProjects,
}) => {
  const { t: tSelfEffort } = useTranslation('selfEffort');

  const { developmentProjectsQuery } = useDevelopmentProjects();
  const { portalProjectsQuery } = usePortalProjects();
  const { operationApplication } = useOperationApplication();

  const projects = useMemo(() => {
    if (isApplicationProject) {
      return operationApplication?.projects;
    }
    if (showAllProjects) {
      return undefined;
    }

    return [...(developmentProjectsQuery.data || []), ...(portalProjectsQuery.data || [])];
  }, [
    isApplicationProject,
    showAllProjects,
    operationApplication?.projects,
    developmentProjectsQuery.data,
    portalProjectsQuery.data,
  ]);

  return (
    <DialogContainer title={tSelfEffort('addSelfEffort')} show={show} onClose={onClose}>
      <RegisterInkindFormWrapper onClose={onClose} defaultValues={defaultValues} projects={projects} />
    </DialogContainer>
  );
};

export default RegisterInkindModal;
