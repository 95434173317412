import React from 'react';
import ActionPageDescription from './ActionPageDescription';
import { FaIcon, FaIcons } from '@in/component-library';
import { icon } from '@fortawesome/fontawesome-svg-core';
import { IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'src/features/authorization';

const Referralprocess: React.FC = () => {
  const { t: tOneWayIn } = useTranslation('oneWayIn');
  const { t: tHelpAndSupport } = useTranslation('onboarding');

  const { checkAccess } = useAuth();
  const newReferralProcessEnabled = checkAccess('FF_NewReferralProcess').read;

  return (
    <>
      <h3 className="margin-bottom--6">{tHelpAndSupport('commonQuestions.Referralprocess')}</h3>
      <div className="display--flex flex-direction--column gap--6">
        {newReferralProcessEnabled ? (
          <>
            <ActionPageDescription
              icon={<FaIcon icon={icon({ prefix: 'fal', iconName: 'list-check' }) as IconDefinition} />}
              iconName={tHelpAndSupport('commonQuestions.StepTitles.ChooseActor')}
              description={tHelpAndSupport('commonQuestions.StepDescriptions.ChooseActor')}
            />
            <ActionPageDescription
              icon={<FaIcon name={FaIcons.EnvelopeLight} />}
              iconName={tHelpAndSupport('commonQuestions.StepTitles.WriteReferral')}
              description={tHelpAndSupport('commonQuestions.StepDescriptions.WriteReferral')}
              showOptionalIndication={true}
            />
            <ActionPageDescription
              icon={<FaIcon icon={icon({ prefix: 'fal', iconName: 'paper-plane' }) as IconDefinition} />}
              iconName={tHelpAndSupport('commonQuestions.StepTitles.SendReferral')}
              description={tHelpAndSupport('commonQuestions.StepDescriptions.SendReferral')}
              showOptionalIndication={true}
            />
          </>
        ) : (
          <>
            <ActionPageDescription
              icon={<FaIcon icon={icon({ prefix: 'fal', iconName: 'paper-plane' }) as IconDefinition} />}
              iconName={tOneWayIn('details.Title.Referrer')}
              description={tHelpAndSupport('commonQuestions.Referralprocess.ReferrerDescription')}
            />
            <ActionPageDescription
              icon={<FaIcon icon={icon({ prefix: 'fal', iconName: 'list-timeline' }) as IconDefinition} />}
              iconName={tOneWayIn('details.Title.ActivityLog')}
              description={tHelpAndSupport('commonQuestions.Referralprocess.ActivityLogDescription')}
            />
            <ActionPageDescription
              icon={<FaIcon icon={icon({ prefix: 'fal', iconName: 'box-archive' }) as IconDefinition} />}
              iconName={tOneWayIn('details.Title.Archiving')}
              description={tHelpAndSupport('commonQuestions.Referralprocess.ArchivingDescription')}
            />
          </>
        )}
      </div>
    </>
  );
};

export default Referralprocess;
