import { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { WorkGroupCreateDto, WorkGroupsService, WorkGroupUpdateDto } from 'src/api/v2';

import { WORK_GROUPS_KEY, WORK_GROUP_KEY } from 'src/constants/hook-keys';
import useCluster from 'src/features/cluster/hooks/use-cluster';

type UseWorkGroupsProps = {
  enabled?: boolean;
};

export default function useWorkGroups({ enabled }: UseWorkGroupsProps = { enabled: true }) {
  const { cluster } = useCluster();

  const [service] = useState(new WorkGroupsService());

  const queryClient = useQueryClient();

  const { data, ...rest } = useQuery([WORK_GROUPS_KEY], () => service.getAllFromCluster(cluster!.id), {
    enabled: !!cluster && enabled,
  });

  const createMutation = useMutation(
    (record: WorkGroupCreateDto) => {
      return service.create(record);
    },
    {
      onSuccess() {
        queryClient.resetQueries([WORK_GROUPS_KEY]);
      },
    },
  );

  const updateMutation = useMutation(
    ({ workGroupId, record }: { workGroupId: string; record: WorkGroupUpdateDto }) => {
      if (!record.clusterId && cluster) {
        record.clusterId = cluster.id;
      }

      return service.update(workGroupId, record);
    },
    {
      onSuccess(data) {
        queryClient.resetQueries([WORK_GROUPS_KEY]);
        queryClient.resetQueries([WORK_GROUP_KEY, data.id], { exact: true });
      },
    },
  );

  const deleteMutation = useMutation(
    (workGroupId: string) => {
      return service.delete(workGroupId);
    },
    {
      onSuccess() {
        queryClient.resetQueries([WORK_GROUPS_KEY]);
      },
    },
  );

  return {
    workGroups: data || [],

    ...rest,

    createMutation,
    updateMutation,
    deleteMutation,
  };
}
