import { Control, useWatch } from 'react-hook-form';
import { EconomyData } from '../MemberEconomyForm';
import { arrayTotalCalcByKeys } from '../arrayTotalCalcByKeys';
import { ClusterMemberEconomyDto } from 'src/api/v2';
export const MAX_SUPPORT_INTENSITY = 0.75;
export default function useMemberEconomySumCalculation(control: Control<EconomyData>) {
  const results = useWatch({ control });

  const totalMarkedValue = arrayTotalCalcByKeys(
    results?.finances ? (results.finances as Array<ClusterMemberEconomyDto>) : [],
    'marketValue',
  );
  const totalInvoicedDeductible = arrayTotalCalcByKeys(
    results?.finances ? (results.finances as Array<ClusterMemberEconomyDto>) : [],
    'invoicedDeductible',
  );

  const totalSupportIntensityPercent =
    ((totalMarkedValue - totalInvoicedDeductible) / totalMarkedValue) * 100;
  const totalSupportIntensity = isNaN(totalSupportIntensityPercent) ? 0 : totalSupportIntensityPercent;

  const totalInnovationSupport = totalMarkedValue - totalInvoicedDeductible;

  const totalSupportLimitReducer = results?.finances?.reduce((total, current) => {
    const markedValue: number = current?.marketValue ?? 0;
    const invoicedDeductible = current?.invoicedDeductible ?? 0;
    const supportLimit = markedValue * MAX_SUPPORT_INTENSITY;
    const tooMuchSupport: number = markedValue - invoicedDeductible - supportLimit;

    return total + (tooMuchSupport > 0 ? tooMuchSupport : 0);
  }, 0);

  const totalSupportLimit = totalSupportLimitReducer != null ? ~~totalSupportLimitReducer : 0;

  const calculateSupportIntensity = (index) => {
    let output = 0;
    if (results?.finances?.[index]) {
      const markedValue = results?.finances?.[index]?.marketValue ?? 0;
      const invoicedDeductible = results?.finances?.[index]?.invoicedDeductible ?? 0;
      output = ((markedValue - invoicedDeductible) / markedValue) * 100;
    }
    return ~~output;
  };
  const calculateInnovationSupport = (index) => {
    let output = 0;
    if (results?.finances?.[index]) {
      const markedValue = results?.finances?.[index]?.marketValue ?? 0;
      const invoicedDeductible = results?.finances?.[index]?.invoicedDeductible ?? 0;
      output = markedValue - invoicedDeductible;
    }
    return ~~output;
  };

  const calculateLimitValue = (index) => {
    let output = 0;
    if (results?.finances?.[index]) {
      const markedValue = results?.finances?.[index]?.marketValue ?? 0;
      const invoicedDeductible = results?.finances?.[index]?.invoicedDeductible ?? 0;
      const supportLimit = markedValue * MAX_SUPPORT_INTENSITY;
      output = markedValue - invoicedDeductible - supportLimit;
    }
    const outputFinal = output > 0 ? output : 0;
    return ~~outputFinal;
  };
  return {
    totalSupportIntensity,
    totalInnovationSupport,
    totalSupportLimit,
    totalMarkedValue,
    totalInvoicedDeductible,
    calculateSupportIntensity,
    calculateInnovationSupport,
    calculateLimitValue,
  };
}
