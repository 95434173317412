import React from 'react';
import { SivaCompanyDetailDto, SivaContractDetailDto, SivaContractUpdateDto, SivaPhase } from 'src/api/v2';
import { Select } from 'src/components/Form/Select';
import ValueField from 'src/components/ValueField/ValueField';
import useSivaAdvisorOptions from '../../hooks/use-siva-advisor-options';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { FaIcon, TextArea, FaIcons, Button, Theme, ButtonVariant } from '@in/component-library';
import { useForm } from 'react-hook-form';
import useCompanyContract from '../../company-details/hooks/use-company-contract';
import DialogContainer from 'src/components/DialogContainer/DialogContainer';
import useToggle from 'src/hooks/use-toggle';
import { requiredI18n } from 'src/utils/validation';

type Props = {
  contract: SivaContractDetailDto;
  company: SivaCompanyDetailDto;
  selectedPhase: SivaPhase;
  onCancel: () => void;
};

const CompanyPhaseChangeForm: React.FC<Props> = ({ contract, company, selectedPhase, onCancel }) => {
  const { t: tCommon } = useTranslation('common');
  const { t: tSiva } = useTranslation('siva');
  const { t: tError } = useTranslation('error');

  const { advisorOptions } = useSivaAdvisorOptions();

  const { currentPhase, updateSivaContractMutation } = useCompanyContract(contract.numericId);

  const [updateError, setUpdateError] = React.useState(false);
  const { activate, deactivate, isActive } = useToggle();

  const { register, handleSubmit } = useForm<SivaContractUpdateDto>({
    defaultValues: {
      ...contract,
      newPhase: {
        newPhase: undefined,
        fromDate: new Date(),
      },
    },
  });

  const onSave = handleSubmit(async (formData) => {
    updateSivaContractMutation
      .mutateAsync({
        ...formData,
        newPhase: {
          newPhase: selectedPhase,
          fromDate: new Date(),
        },
        advisorId: formData.advisorId,
        notes: formData.notes,
      })
      .then(() => {
        setUpdateError(false);
      })
      .catch(() => {
        setUpdateError(true);
      })
      .finally(() => {
        activate();
      });
  });

  return (
    <form onSubmit={onSave}>
      <h2>Avtaleinformasjon</h2>

      <ValueField label="Bedrift" value={company.name} />
      <h2>Oppfølging</h2>
      <Select
        {...register('advisorId', {
          required: requiredI18n(tError),
        })}
        label="Rådgiver"
        options={advisorOptions}
        name={'advisor'}
        placeholder={contract.advisorName}
      />
      <p className="margin-top--0 margin-bottom--4">
        {tSiva('newCompany.contract.form.advisorId.link.text')}{' '}
        <Link to={`/actor-profile/settings/access`} target="_blank">
          <span className="margin-right--1">{tCommon('accessControl')}</span>
          <FaIcon name={FaIcons.ExternalLinkLight} />
        </Link>
      </p>
      <TextArea label="Notat" value={contract.notes} name="notes" />

      <div className="display--flex gap--1 justify-content--flex-start margin-top--7">
        <Button theme={Theme.Neutral} type="submit">
          {tCommon('save')}
        </Button>
        <Button theme={Theme.Neutral} variant={ButtonVariant.Outlined} type="button" onClick={onCancel}>
          {tCommon('cancel')}
        </Button>
      </div>

      <DialogContainer
        show={isActive}
        title={updateError ? tSiva('changePhase.error.title') : tCommon('confirmation')}
        onClose={updateError ? deactivate : onCancel}
      >
        {updateError ? (
          <>
            <p>{tSiva('changePhase.error.text')}.</p>
            <Button onClick={deactivate}>{tCommon('close')}</Button>
          </>
        ) : (
          <>
            <p>{`${tSiva('changePhase.success.text')} ${tSiva(`phase.${currentPhase?.sivaPhase}` as any)},
              ${tCommon('to')} ${tSiva(`phase.${selectedPhase}` as any)}`}</p>
            <Button onClick={onCancel}>{tCommon('close')}</Button>
          </>
        )}
      </DialogContainer>
    </form>
  );
};

export default CompanyPhaseChangeForm;
