import React from 'react';
import OneWayInHistoryV2 from '../components/history/OneWayInHistoryV2';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useOneWayIn } from '../..';
import { PageParams } from '../types/PageParams';

const ActivityLog: React.FC = () => {
  const { formId } = useParams<PageParams>();
  const { t: tOneWayIn } = useTranslation('oneWayIn');

  const { oneWayInOpportunityFormFullHistoryQuery } = useOneWayIn(formId);

  return (
    <>
      <h2>{tOneWayIn('details.Title.ActivityLog')}</h2>
      <OneWayInHistoryV2
        isLoading={oneWayInOpportunityFormFullHistoryQuery.isLoading}
        data={oneWayInOpportunityFormFullHistoryQuery.data || []}
        formId={formId || ''}
      />
    </>
  );
};

export default ActivityLog;
