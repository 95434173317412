import { useMemo } from 'react';
import { OneWayInServiceProviderDto } from 'src/api/v2';

const useServices = (actors: OneWayInServiceProviderDto[]) => {
  const services = useMemo(() => {
    return [
      ...new Set(
        actors
          .map((actor) => actor.deliveryTypes)
          .flat()
          .sort(),
      ),
    ].map((deliveryType) => ({
      text: deliveryType,
      value: deliveryType,
    }));
  }, [actors]);

  return { services };
};

export default useServices;
