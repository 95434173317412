import React, { useMemo } from 'react';
import { SivaCompanyDetailDto, SivaContractDetailDto, SivaContractUpdateDto, SivaPhase } from 'src/api/v2';
import { bransjeOptions } from '../../constants';
import {
  Button,
  ButtonVariant,
  DateInput,
  FaIcon,
  FaIcons,
  Notification,
  TextArea,
  Theme,
} from '@in/component-library';
import ValueField from 'src/components/ValueField/ValueField';
import useSivaAdvisorOptions from '../../hooks/use-siva-advisor-options';
import { useCalculateSivaKTUDates } from 'src/hooks/use-siva-ktu-date-calculation';
import { getMonthAndYearString, toIsoDateString } from 'src/utils/date';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import useCompanyContract from '../../company-details/hooks/use-company-contract';
import { requiredI18n } from 'src/utils/validation';
import { Select } from 'src/components/Form/Select';
import DialogContainer from 'src/components/DialogContainer/DialogContainer';
import useToggle from 'src/hooks/use-toggle';
import ConnectCompany from '../../company-details/components/ConnectCompany';
import { useUserReadableDate } from 'src/hooks/use-user-readable-date';

type Props = {
  contract: SivaContractDetailDto;
  selectedPhase: SivaPhase;
  company?: SivaCompanyDetailDto;
  onCancel: () => void;
};

const IdeaChangePhaseForm: React.FC<Props> = ({ contract, selectedPhase, company, onCancel }) => {
  const { t: tCommon } = useTranslation('common');
  const { t: tSiva } = useTranslation('siva');
  const { t: tError } = useTranslation('error');

  const [selectedAdmissionDate, setSelectedAdmissionDate] = React.useState<Date | null>(null);
  const [companyError, setCompanyError] = React.useState(false);
  const [orgNumber, setOrgNumber] = React.useState<string>('');
  const [companyName, setCompanyName] = React.useState<string>('');

  const { advisorOptions } = useSivaAdvisorOptions();
  const { currentPhase, updateSivaContractMutation } = useCompanyContract(contract.numericId);

  const [updateError, setUpdateError] = React.useState(false);
  const { activate, deactivate, isActive } = useToggle();

  const { register, handleSubmit } = useForm<SivaContractUpdateDto>({
    defaultValues: {
      ...contract,
      newPhase: {
        newPhase: undefined,
      },
      advisorId: contract?.advisorId,
    },
  });

  const onSave = handleSubmit(async (formData) => {
    updateSivaContractMutation
      .mutateAsync({
        ...formData,
        newPhase: {
          newPhase: selectedPhase,
          fromDate: selectedAdmissionDate!,
        },
        organizationNumber: company ? company.organizationNumber : orgNumber || '',
        externalIndustry: formData.externalIndustry,
        advisorId: formData.advisorId,
        notes: formData.notes,
      })
      .then(() => {
        setUpdateError(false);
      })
      .catch(() => {
        setUpdateError(true);
      })
      .finally(() => {
        activate();
      });
  });

  const [startupDate, midDate] = useCalculateSivaKTUDates(selectedAdmissionDate);

  const renderFormSendingDate = (date: Date | undefined) => {
    if (!date) {
      return 'Opptaksdato ikke satt';
    }

    return getMonthAndYearString(date);
  };

  const minDateString = useMemo(() => {
    // Wrap in new Date as fromDate is actually a string (should be fixed in nswag someday)
    // In this case it also instantiates a new Date that we can mutate
    const minDate = new Date(contract.phases[0].fromDate);
    // Add one day to the date
    minDate.setDate(minDate.getDate() + 1);
    // Return the date as an ISO date string without time (required to be used as mindate in datepicker)
    return toIsoDateString(minDate);
  }, [contract]);

  const readableRegistrationDate = useUserReadableDate(contract.phases[0].fromDate);

  return (
    <form onSubmit={onSave}>
      <h2>Avtaleinformasjon</h2>
      <p>Ny avtale vil opprettes og påkrevde felter må fylles ut</p>

      {company ? (
        <ValueField label="Bedrift" value={company.name} />
      ) : (
        <ConnectCompany
          phase={selectedPhase}
          orgNumber={orgNumber}
          setOrgNumber={setOrgNumber}
          selectedCompanyName={companyName}
          setSelectedCompanyName={setCompanyName}
          setCompanyError={setCompanyError}
          showNoCompanyCheckbox={selectedPhase === SivaPhase.PreIncubation}
        />
      )}

      <Select
        {...register('externalIndustry', {
          required: requiredI18n(tError),
        })}
        label="Bransje"
        options={bransjeOptions}
        placeholder="Velg bransje"
      />

      <DateInput
        label="Opptaksdato"
        name="startDate"
        required
        min={minDateString}
        onChange={(event) => {
          const { value } = event.target;
          setSelectedAdmissionDate(value ? new Date(value) : null);
        }}
      />
      <Notification type="info" fullWidth={true}>{
      tSiva('error.acceptedDateAfterRegisteredDate',{readableRegistrationDate: readableRegistrationDate})}
      </Notification>
      <br />

      <ValueField label="Utsendelse av oppstartsskjema" value={renderFormSendingDate(startupDate)} />
      <ValueField label="Utsendelse av underveisskjema" value={renderFormSendingDate(midDate)} />

      <h2>Oppfølging</h2>

      <Select
        {...register('advisorId', {
          required: requiredI18n(tError),
        })}
        label="Rådgiver"
        options={advisorOptions}
      />

      <p className="margin-top--0 margin-bottom--4">
        {tSiva('newCompany.contract.form.advisorId.link.text')}{' '}
        <Link to={`/actor-profile/settings/access`} target="_blank">
          <span className="margin-right--1">{tCommon('accessControl')}</span>
          <FaIcon name={FaIcons.ExternalLinkLight} />
        </Link>
      </p>

      <TextArea label="Notat" value={contract.notes} name="notes" />

      <div className="display--flex gap--1 justify-content--flex-start margin-top--7">
        <Button theme={Theme.Neutral} type="submit" disabled={companyError}>
          {tCommon('save')}
        </Button>
        <Button
          theme={Theme.Neutral}
          variant={ButtonVariant.Outlined}
          type="button"
          onClick={() => onCancel()}
        >
          {tCommon('cancel')}
        </Button>

        <DialogContainer
          show={isActive}
          title={updateError ? tSiva('changePhase.error.title') : tCommon('confirmation')}
          onClose={updateError ? deactivate : onCancel}
        >
          {updateError ? (
            <>
              <p>{tSiva('changePhase.error.text')}.</p>
              <Button onClick={deactivate}>{tCommon('close')}</Button>
            </>
          ) : (
            <>
              <p>{`${tSiva('changePhase.success.text')} ${tSiva(`phase.${currentPhase?.sivaPhase}` as any)},
              ${tCommon('to')} ${tSiva(`phase.${selectedPhase}` as any)}`}</p>
              <Button onClick={onCancel}>{tCommon('close')}</Button>
            </>
          )}
        </DialogContainer>
      </div>
    </form>
  );
};

export default IdeaChangePhaseForm;
