import { useMemo, useState } from 'react';
import { CallbackResponse } from '@in/component-library';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from 'react-query';
import { OnboardingPacketsService } from 'src/api/v2';
import { ONBOARDING_PACKETS_KEY } from 'src/constants/hook-keys';
import { useUser } from 'src/features/authorization';

export const useOnboarding = () => {
  const { user } = useUser();
  const [onboarding] = useState(new OnboardingPacketsService());
  const { t: tError } = useTranslation('error');
  const queryClient = useQueryClient();

  const onboardingQuery = useQuery({
    queryKey: [ONBOARDING_PACKETS_KEY],
    queryFn: () => onboarding.getOnboardingPackets(user?.clusterUserId ? user.clusterUserId : ''),
    enabled: !!user?.clusterUserId,
    retry: false,
    refetchOnMount: false,
  });

  const { onboardingPackets, priorityPacket } = useMemo(() => {
    const dataToSort = onboardingQuery.data || [];
    const sortedPackets =
      dataToSort.sort((a, b) => {
        if (a.completed !== b.completed) return a.completed ? 1 : -1;
        return a.order - b.order;
      }) || [];
    return {
      onboardingPackets: sortedPackets,
      priorityPacket:
        sortedPackets.filter((packet) => !packet.completed).length > 0 ? sortedPackets[0] : undefined,
    };
  }, [onboardingQuery]);

  async function completeOnboarding(onboardingPacketId: string): Promise<CallbackResponse | void> {
    try {
      if (!user?.clusterUserId) {
        return { status: false, message: tError('noClusterUserId') };
      }
      if (!onboardingPacketId) {
        return { status: false, message: tError('noOnboardingPacketId') };
      }

      await onboarding.completeOnboarding(user.clusterUserId, onboardingPacketId);
      queryClient.invalidateQueries([ONBOARDING_PACKETS_KEY]);

      return { status: true };
    } catch (error) {
      let message: string;
      if (error instanceof Error) message = error.message;
      else message = String(error);
      return { message: message, status: false };
    }
  }

  return {
    onboardingQuery,
    onboardingPackets,
    priorityPacket: priorityPacket,
    completeOnboardingPacket: completeOnboarding,
  };
};
