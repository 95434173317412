import React, { lazy } from 'react';
import { Navigate, RouteObject } from 'react-router-dom';

export const ContactInformation = lazy(() => import('./pages/ContactInformation'));
export const ContactInterestAreas = lazy(() => import('./pages/ContactInterestAreas'));
export const ContactSelfEffort = lazy(() => import('./pages/ContactSelfEffort'));

const routes: RouteObject[] = [
  {
    path: '',
    element: <Navigate to="contact-information" replace />,
  },
  {
    path: 'contact-information',
    element: <ContactInformation />,
  },
  {
    path: 'interest-areas',
    element: <ContactInterestAreas />,
  },
  {
    path: 'self-effort',
    element: <ContactSelfEffort />,
  },
];

export default routes;
