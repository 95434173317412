import { Button, ButtonVariant, FaIcons, Theme } from '@in/component-library';
import React from 'react';
import DialogContainer from 'src/components/DialogContainer/DialogContainer';
import useToggle from 'src/hooks/use-toggle';

export interface AddEntityModalButtonProps {
  buttonText: React.ReactNode;
  buttonTheme?: Theme;
  buttonVariant?: ButtonVariant;
  buttonIcon?: FaIcons;

  onClick?: () => void;
  onSuccess?: () => void;

  disabled?: boolean;

  modalTitle: string;
}

const AddEntityModalButton: React.FC<React.PropsWithChildren<AddEntityModalButtonProps>> = ({
  buttonText,
  buttonTheme = Theme.Neutral,
  buttonVariant = ButtonVariant.Solid,
  buttonIcon,
  onClick,
  onSuccess,
  disabled,
  modalTitle,
  children,
}) => {
  const { isActive, activate, deactivate } = useToggle(false);

  const childrenArray = React.Children.toArray(children);
  const onClose = () => {
    deactivate();
  };

  return (
    <>
      <Button
        type="button"
        theme={buttonTheme}
        variant={buttonVariant}
        onClick={() => {
          activate();
          onClick?.();
        }}
        disabled={disabled}
        iconName={buttonIcon}
      >
        {buttonText}
      </Button>

      <DialogContainer title={modalTitle} show={isActive} onClose={deactivate}>
        {childrenArray.map((child) =>
          React.cloneElement(child as React.ReactElement<any>, { onClose, onSuccess }),
        )}
      </DialogContainer>
    </>
  );
};

export default AddEntityModalButton;
