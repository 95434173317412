import React, { useMemo, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { useUser } from 'src/features/authorization';
import useOneWayIn from '../../hooks/use-one-way-in';
import DialogContainer from 'src/components/DialogContainer/DialogContainer';
import useToggle from 'src/hooks/use-toggle';

import { useTranslation } from 'react-i18next';
import { Button, Notification, RadioButton, TextArea } from '@in/component-library';
import { OneWayInClosedReason, OneWayInStatus } from 'src/api/v2';
import { ONE_WAY_IN } from '../../routes';
import { LoadingPage } from 'src/components/LoadingPage/LoadingPage';
import createOwiUpdateDto from '../utils/createOwiUpdateDto';
import { PageParams } from '../types/PageParams';
import useSiteimprove from 'src/hooks/use-siteimprove';

const Archiving = () => {
  const { t: tCommon } = useTranslation();
  const { t: tOneWayIn } = useTranslation('oneWayIn');
  const { formId } = useParams<PageParams>();
  const { oneWayInOpportunityFormQuery, updateOneWayInOpportunityStatus } = useOneWayIn(formId);
  const { isActive, activate, deactivate } = useToggle(false);
  const { isAadUser, user, displayName } = useUser();
  const siteimprove = useSiteimprove();

  const [comment, setComment] = useState('');
  const [referredCorrectly, setReferredCorrectly] = useState(true);
  const [error, setError] = useState(false);

  const opportunity = useMemo(() => {
    if (!oneWayInOpportunityFormQuery.data) return undefined;

    return oneWayInOpportunityFormQuery.data.opportunity;
  }, [oneWayInOpportunityFormQuery.data]);

  const onError = () => {
    setError(true);
  };

  const onArchive = () => {
    const promise = updateOneWayInOpportunityStatus.mutateAsync(
      createOwiUpdateDto(
        opportunity?.id,
        OneWayInStatus.Closed,
        comment,
        isAadUser ? undefined : user?.clusterUserId,
        displayName || undefined,
        referredCorrectly ? OneWayInClosedReason.Other : OneWayInClosedReason.SentInError,
      ),
    );
    promise.then(deactivate).catch(onError);
    siteimprove.log('OPPORTUNITY_ARCHIVING', 'old');
  };

  if (oneWayInOpportunityFormQuery.isFetching) {
    return (
      <div className="width--50">
        <LoadingPage />
      </div>
    );
  }

  if (opportunity?.isClosed) {
    return <Navigate to={ONE_WAY_IN.DETAILS.REPOPENING(formId as string)} />;
  }

  return (
    <div className="width--50">
      {error && (
        <Notification
          closeButtonLabel={tCommon('close')}
          dismissable
          onClose={() => setError(false)}
          type="error"
        >
          {tOneWayIn('details.Archiving.Error')}
        </Notification>
      )}
      <h2 className="margin-bottom--3">{tOneWayIn('details.Archiving.Header')}</h2>
      <p>{`${tOneWayIn('details.Archiving.Ingress')}. ${tOneWayIn('details.Archiving.Ingress2')}`}</p>

      <Button onClick={activate}>{tOneWayIn('details.Archiving.Archive')}</Button>

      {!error && (
        <DialogContainer
          className="one-way-in-details__modal"
          title={tOneWayIn('details.Archiving.Modal.Header')}
          show={isActive}
          onClose={deactivate}
          titleHeadingLevel={2}
        >
          <>
            <p>{tOneWayIn('details.Archiving.Ingress2')}</p>
            <TextArea
              label={`${tCommon('note')} *`}
              onChange={(e) => setComment(e.target.value)}
              aria-required="true"
              maxCount={1000}
              maxLength={1000}
            />
            <RadioButton
              name="referralCorrect"
              label={tOneWayIn('details.Archiving.ReferredCorrectly')}
              options={[
                {
                  selected: referredCorrectly,
                  text: tCommon('yes'),
                  value: 'yes',
                },
                {
                  selected: !referredCorrectly,
                  text: tCommon('no'),
                  value: 'no',
                },
              ]}
              onChange={(e) => {
                if (e.currentTarget.value === 'yes') {
                  setReferredCorrectly(true);
                } else {
                  setReferredCorrectly(false);
                }
              }}
            />
            <div className="display--flex gap--2">
              <Button disabled={comment.trim() === ''} onClick={onArchive}>
                {tOneWayIn('details.Archiving.Archive')}
              </Button>
              <Button variant="outlined" theme="neutral" onClick={deactivate}>
                {tOneWayIn('details.Archiving.DoNotArchive')}
              </Button>
            </div>
          </>
        </DialogContainer>
      )}
    </div>
  );
};

export default Archiving;
