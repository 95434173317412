import { useState } from 'react';
import { useQuery } from 'react-query';

import { OperationApplicationStatus, OperationApplicationsService, OperationProjectDto } from 'src/api/v2';

import {
  OPERATION_APPLICATION_BY_ID_KEY,
  OPERATION_APPLICATION_STATISTICS_BY_ID_KEY,
} from 'src/constants/hook-keys';
import { useAuth } from 'src/features/authorization';

import useCluster from '../features/cluster/hooks/use-cluster';

export default function useOperationApplication(operationApplicationId?: string | null) {
  const { cluster } = useCluster();

  const { checkAccess } = useAuth();
  const { read } = checkAccess('Operation');

  const [service] = useState(new OperationApplicationsService());

  const { data, ...rest } = useQuery(
    [OPERATION_APPLICATION_BY_ID_KEY, operationApplicationId],
    () => service.getOperationApplication(operationApplicationId!),
    {
      enabled: !!cluster && !!operationApplicationId && read,
    },
  );

  const statisticsById = useQuery(
    [OPERATION_APPLICATION_STATISTICS_BY_ID_KEY, operationApplicationId],
    () => service.getOperationApplicationStatisticsV2(operationApplicationId!),
    {
      enabled: !!cluster && !!operationApplicationId,
    },
  );

  return {
    operationApplication: {
      ...data,
      projects: data?.projects.map((project) => ({
        ...project,
        isTerminated: data.status !== OperationApplicationStatus.Active,
      })) as OperationProjectDto[],
    },
    statisticsById: statisticsById.data,

    ...rest,
  };
}
