import React from 'react';
import i18next from 'i18next';
import { createColumnHelper } from '@tanstack/react-table';
import { OneWayInServiceProviderDto } from 'src/api/v2';
import { ReadMore, Tag } from '@in/component-library';
import { Link } from 'react-router-dom';
import { ROUTES } from 'src/navigation';

const columnHelper = createColumnHelper<OneWayInServiceProviderDto>();

const { t } = i18next;

export const referralColumns = [
  columnHelper.accessor((row) => row.name, {
    id: 'actorName',
    cell: (info) => {
      return <Link to={`${ROUTES.ACTOR_PROFILE.BASE}/${info.row.original.id}`}>{info.getValue()}</Link>;
    },
  }),
  columnHelper.accessor((row) => row.clusterStatusName, {
    id: 'statusName',
    cell: (info) => {
      return <span>{info.getValue()}</span>;
    },
  }),
  columnHelper.accessor((row) => row.county, {
    id: 'county',
    cell: (info) => {
      return <span>{info.getValue()}</span>;
    },
  }),
  columnHelper.accessor((row) => row.municipalities, {
    id: 'municipalities',
    header: t<string>('oneWayIn:municipalityFirstLineFor'),
    enableSorting: false,
    cell: (info) => {
      return (
        <span className="table-cell__municipality">
          <ReadMore
            text={info.getValue()?.join(', ') || ''}
            ideal={100}
            min={80}
            max={2048}
            readMoreText={t<string>('showMore')}
            readMoreTextVisible={t<string>('showLess')}
          />
        </span>
      );
    },
  }),
  columnHelper.accessor((row) => row.deliveryTypes, {
    id: 'deliveryTypes',
    enableSorting: false,
    cell: (info) => {
      return (
        <div className="display--flex flex-wrap--wrap  gap--1">
          {info.getValue()?.map((type) => (
            <Tag key={type} type={'generic'}>
              {type}
            </Tag>
          ))}
        </div>
      );
    },
  }),
  columnHelper.accessor((row) => row.portfolioIndustries, {
    id: 'portfolioIndustries',
    enableSorting: false,
    cell: (info) => {
      return (
        <div className="display--flex flex-wrap--wrap gap--1">
          {info.getValue()?.map((industry) => (
            <Tag className="width--max-content" key={industry.id} type={'generic'}>
              {industry.name}
            </Tag>
          ))}
        </div>
      );
    },
  }),
];
