import { ClusterMemberTypeDto } from 'src/api/v2';

interface IClusterMemberTypes {
  Corporate: ClusterMemberTypeDto;
  RiskCapital: ClusterMemberTypeDto;
  Government: ClusterMemberTypeDto;
  Cluster: ClusterMemberTypeDto;
  Entrepreneur: ClusterMemberTypeDto;
  InnovationCompany: ClusterMemberTypeDto;
  UniversityResearch: ClusterMemberTypeDto;
  UnionFondation: ClusterMemberTypeDto;
}

export const ClusterMemberTypes: IClusterMemberTypes = {
  Corporate: {
    id: '5a6d87f3-3619-442b-9141-f1f9e426ff4d',
    name: 'Etablert bedrift',
    code: 'CORPORATE',
    description: 'Etablert bedrift',
  },
  RiskCapital: {
    id: 'b6bf0fdd-d9ba-47ae-a883-83846bf4ed14',
    name: 'Investor',
    code: 'RISK_CAPITAL',
    description: 'Investor',
  },
  Government: {
    id: 'e8d6ed05-526a-4b4d-ba59-05ee6b47f121',
    name: 'Offentlig Aktør',
    code: 'GOVERNMENT',
    description: 'Offentlig Aktør',
  },
  Cluster: {
    id: '1060dbcd-cf23-4492-b2fe-046fdb4ea7ea',
    name: 'Klynge',
    code: 'CLUSTER',
    description: 'Klynge',
  },
  Entrepreneur: {
    id: 'ae7c678f-3a1e-4a44-a7a3-639ad962b374',
    name: 'Gründer',
    code: 'ENTREPRENEUR',
    description: 'Gründer',
  },
  InnovationCompany: {
    id: '852e1256-a967-444d-8f33-195e28245322',
    name: 'Innovasjonsselskap',
    code: 'INNOVATION_COMPANY',
    description: 'Innovasjonsselskap',
  },
  UniversityResearch: {
    id: '21bb08cd-be3d-40e2-ae7b-0b04151992b2',
    name: 'FOU-institusjon',
    code: 'UNIVERSITY_RESEARCH',
    description: 'FOU-institusjon',
  },
  UnionFondation: {
    id: '159d3372-7d57-40f2-912a-ce760ffc83c3',
    name: 'Forening/Fagforening',
    code: 'UNION_FOUNDATION',
    description: 'Forening/Fagforening',
  },
};

export const ClusterMemberTypeArray: Array<ClusterMemberTypeDto> = Object.keys(ClusterMemberTypes).map(
  (key) => ClusterMemberTypes[key],
);
