import { Tag } from '@in/component-library';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SivaBudgetStatus } from 'src/api/v2';

type Props = {
  status: SivaBudgetStatus;
};

const BudgetStatusTag: React.FC<Props> = ({ status }) => {
  const { t: tCommon } = useTranslation();

  if (status === SivaBudgetStatus.ChangeRequested) {
    return <Tag type="error">{tCommon('sentBack')}</Tag>;
  }

  if (status === SivaBudgetStatus.Approved) {
    return <Tag type="success">{tCommon('approved')}</Tag>;
  }

  return <></>;
};

export default BudgetStatusTag;
