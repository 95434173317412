import { FaIcon, FaIcons } from '@in/component-library';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { OneWayInFeedbackDto } from 'src/api/v2';
import DataTableV3 from 'src/components/DataTableV3';
import { otherReviewTableColumns } from '../../constants/one-way-in-county-other-reviews-columns';
import { DataTableV3Ids } from 'src/constants/dataTableIds';

type Props = {
  feedbackList: OneWayInFeedbackDto[];
};

const OpportunityFeedbackOtherActorsList: React.FC<Props> = ({ feedbackList }) => {
  const { t: tCommon } = useTranslation();
  const { t: tTableTitles } = useTranslation('tableTitles');

  const sentCorrectly = feedbackList.filter((feedback) => feedback.wasCorrectlyReferred).length;
  const sentIncorrectly = feedbackList.filter((feedback) => feedback.wasCorrectlyReferred === false).length;

  return (
    <div>
      <div className="display--flex margin-bottom--1">
        <div>
          <FaIcon size="lg" color="#2fcc72" name={FaIcons.CheckCircle} className="margin-right--1" />{' '}
          {sentCorrectly}
        </div>
        <div className="margin-left--3">
          <FaIcon size="xl" color="#ed0000" name={FaIcons.Times} className="margin-right--1" />{' '}
          {sentIncorrectly}
        </div>
      </div>
      <DataTableV3
        id={DataTableV3Ids.OneWayIn.OpportunityFeedbackOtherActorsList}
        disableSelection={true}
        disableGlobalFilter={true}
        data={feedbackList}
        columns={otherReviewTableColumns}
        translationFn={(key: string) => tTableTitles(key as any)}
        texts={{
          globalFilterPlaceholder: tCommon('search'),
          rowsPerPage: tCommon('rowsPerPage'),
          of: tCommon('of'),
        }}
        disableColumnEditor
        disableExport
      />
    </div>
  );
};

export default OpportunityFeedbackOtherActorsList;
