import React from 'react';
import Label from './Label';
import { FormGroupErrorContext } from './FormGroup';
import FormElementError from './FormElementError';
import classnames from 'classnames';
import './style.scss';
import generateGuid from 'src/utils/Guid';

export type SelectOption = {
  value: number | string | readonly string[];
  text: React.ReactNode | string;
};

export type SelectOptionGroup = {
  label: string;
  options: Array<SelectOption>;
};

export interface SelectProps extends React.SelectHTMLAttributes<HTMLSelectElement> {
  className?: string;
  error?: React.ReactNode | boolean;
  id?: string;
  inputClassName?: string;
  label?: React.ReactNode;
  description?: React.ReactNode;
  name?: string;
  options: Array<SelectOption | SelectOptionGroup>;
  helpText?: React.ReactNode;
}

export const Select = React.forwardRef<HTMLSelectElement, SelectProps>(
  (
    {
      className,
      error,
      id,
      inputClassName,
      label,
      description,
      name,
      options,
      helpText,
      ...props
    }: SelectProps,
    ref,
  ) => {
    const context = React.useContext(FormGroupErrorContext);

    const inputId = id || generateGuid();
    const resolvedError = context.error || error;
    const errorId = context.errorId || generateGuid();
    const descriptionId = description ? generateGuid() : undefined;

    const classes = classnames('form-element', className);
    const inputClasses = classnames(
      'form-element__input',
      'form-element__select',
      {
        'has-error': resolvedError,
      },
      inputClassName,
    );

    return (
      <div className={classes}>
        {label && (
          <Label htmlFor={inputId} helpText={helpText}>
            {label}
            {props.required && <span className="form-element__label--required">*</span>}
          </Label>
        )}

        {description && (
          <div className="form-element__description" id={descriptionId}>
            {description}
          </div>
        )}

        <select
          ref={ref}
          className={inputClasses}
          id={inputId}
          name={name}
          aria-invalid={!!error}
          aria-describedby={descriptionId}
          aria-errormessage={error ? errorId : undefined}
          {...props}
        >
          {options.map((option, index) => (
            <Option key={index} option={option} />
          ))}
        </select>

        {!context.error && !!error && (
          <FormElementError id={errorId}>{typeof error !== 'boolean' && error}</FormElementError>
        )}
      </div>
    );
  },
);

Select.displayName = 'Select';

interface OptionProps {
  option: SelectOptionGroup | SelectOption;
}

const Option: React.FC<OptionProps> = ({ option }) => {
  const optionIsGroup = (option: SelectOption | SelectOptionGroup): option is SelectOptionGroup => {
    return (option as SelectOptionGroup).options !== undefined;
  };

  if (optionIsGroup(option)) {
    return (
      <optgroup label={option.label}>
        {option.options.map((option, index) => (
          <option value={option.value} key={index}>
            {option.text}
          </option>
        ))}
      </optgroup>
    );
  }

  return <option value={option.value}>{option.text}</option>;
};
