import { useState } from 'react';
import { useQuery } from 'react-query';
import { OneWayInServicesService } from 'src/api/v2';
import { ONE_WAY_IN_SERVICES_BY_ID } from 'src/constants/hook-keys';

const useOneWayInServicesByCluster = (clusterId: string) => {
  const [oneWayInServicesService] = useState(new OneWayInServicesService());

  const servicesByIdQuery = useQuery({
    queryKey: [ONE_WAY_IN_SERVICES_BY_ID, clusterId],
    queryFn: () => oneWayInServicesService.getOneWayInServices({ clusterId: clusterId! }),
    enabled: !!clusterId,
    staleTime: 1000 * 60 * 30, // 30 min refetching on mount
    refetchOnMount: true,
  });

  return {
    servicesByIdQuery,
    servicesById: servicesByIdQuery.data || [],
  };
};
export default useOneWayInServicesByCluster;
