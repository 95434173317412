import React from 'react';
import './IndeterminateCheckbox.scss';
import classNames from 'classnames';
import { FaIcon, FaIcons } from '@in/component-library';
import { icon } from '@fortawesome/fontawesome-svg-core';
import '../../styles/style.scss';

export interface IndeterminateCheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  indeterminate?: boolean;
}

const IndeterminateCheckbox = React.forwardRef<HTMLInputElement, IndeterminateCheckboxProps>(
  (props: IndeterminateCheckboxProps, ref) => {
    const { indeterminate, label, style, className, ...rest } = props;
    const defaultRef = React.createRef<HTMLInputElement>();
    const resolvedRef = ref || defaultRef;

    const handleOnClick = () => {
      if (!label && typeof resolvedRef !== 'function') {
        const checkbox = resolvedRef?.current;
        checkbox?.click();
      }
    };

    React.useEffect(() => {
      if (typeof resolvedRef !== 'function') {
        if (resolvedRef?.current && indeterminate !== undefined) {
          resolvedRef.current.indeterminate = indeterminate;
        }
      }
    }, [resolvedRef, indeterminate]);

    const content = () => {
      return (
        <>
          <input
            type="checkbox"
            className={classNames('indeterminate-checkbox__input', {
              'indeterminate-checkbox__input--indeterminate': indeterminate,
              'indeterminate-checkbox__input--disabled': rest.disabled,
            })}
            ref={resolvedRef}
            {...rest}
          />
          {label && <span>{label}</span>}
          <span
            className={classNames('indeterminate-checkbox__checkmark', {
              'indeterminate-checkbox__checkmark--disabled': rest.disabled,
            })}
          >
            <span className="indeterminate-checkbox__checkmark-icon">
              {indeterminate ? (
                <FaIcon icon={icon({ prefix: 'fal', iconName: 'minus' })} />
              ) : (
                <FaIcon name={FaIcons.Check} />
              )}
            </span>
          </span>
        </>
      );
    };

    return (
      <div
        className={classNames(
          'indeterminate-checkbox__label',
          {
            'indeterminate-checkbox__label--disabled': props.disabled,
          },
          className,
        )}
        style={style}
        title={rest.title}
        aria-label={rest['aria-label']}
      >
        {label && <label>{content()}</label>}
        {!label && (
          <div
            className="clickable-wrapper"
            onClick={handleOnClick}
            onKeyDown={(e) => (e.key === 'Enter' ? handleOnClick() : null)}
            role="button"
            tabIndex={0}
          >
            {content()}
          </div>
        )}
      </div>
    );
  },
);

IndeterminateCheckbox.displayName = 'IndeterminateCheckbox';

export { IndeterminateCheckbox };
