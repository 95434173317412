import { Card, FaIcon, FaIcons, Toast } from '@in/component-library';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { OneWayInServiceProviderDto } from 'src/api/v2';

type Props = {
  actorMessage: string;
  customerMessage: string;
  selectedRows: OneWayInServiceProviderDto[];
  customerEmailSelection: boolean;
  formTitle: string;
  actorName: string;
  customerName: string;
};

const SendReferral: React.FC<Props> = ({
  actorMessage,
  customerMessage,
  selectedRows,
  customerEmailSelection,
  formTitle,
  actorName,
  customerName,
}) => {
  const { t: tOneWayIn } = useTranslation('oneWayIn');
  const [searchParams] = useSearchParams();
  const listFormat = new Intl.ListFormat('no-NO');
  const selectedActors = selectedRows.map((row) => row.name ?? '').filter((name) => name !== '');
  const formattedActorList = listFormat.format(selectedActors);

  const EmailPreviewCardActor = () => {
    return (
      <Card className="message-display--card">
        <span className="margin-top--0 margin-bottom--2 font-weight--bold">
          Ny henvisning i Flerbedriftsportalen
        </span>
        <br />
        <span>
          {tOneWayIn('to')}
          {selectedActors.join(', ')}
        </span>
        <p>
          {formattedActorList}, dere har fått en ny henvisning fra {actorName}. Dette gjelder henvendelsen:{' '}
          <em>{formTitle}</em>. Logg inn på Flerbedriftsportalen for å lese henvendelsen.
        </p>
        <p className="margin-top--0">Melding fra {actorName}:</p>
        <p>{actorMessage}</p>
        <p>MERK: Svar til denne e-postadressen blir ikke behandlet.</p>
      </Card>
    );
  };

  const EmailPreviewCardCustomer = () => {
    return (
      <Card className="message-display--card">
        <span className="margin-top--0 margin-bottom--2 font-weight--bold">Informasjon om henvisning</span>
        <br />
        <span>
          {tOneWayIn('to')}
          {customerName}
        </span>
        <p>
          {customerName}, din henvendelse: <em>{formTitle}</em>, er henvist til {formattedActorList}.
        </p>
        <p className="margin-top--0">Melding fra {actorName}:</p>
        <p>{customerMessage}</p>
        <p>MERK: Svar til denne e-postadressen blir ikke behandlet.</p>
      </Card>
    );
  };

  return (
    <div className="display--flex flex-direction--column gap--6">
      <p className="margin-bottom--0 margin-top--0">
        {customerEmailSelection
          ? tOneWayIn('referral.sendReferral.sendReferralInfoWithCustomer')
          : tOneWayIn('referral.sendReferral.sendReferralInfo')}
      </p>
      <div>
        <h3 className="header--bold">{tOneWayIn('referral.writeReferral.toActors')}</h3>
        <EmailPreviewCardActor />
      </div>
      {customerEmailSelection && (
        <div>
          <h3 className="padding-top--3 header--bold">{tOneWayIn('referral.writeReferral.toCustomer')}</h3>
          <EmailPreviewCardCustomer />
        </div>
      )}
      <Toast
        className="toast--error"
        visible={searchParams.toString() === 'referralStatus=failure'}
        icon={<FaIcon className="color--red" name={FaIcons.InfoCircle} />}
        header={tOneWayIn('referral.sendReferral.referralError.header')}
        position="top-right"
        text={tOneWayIn('referral.error.text')}
      />
    </div>
  );
};

export default SendReferral;
