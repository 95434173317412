import React, { useMemo, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { useUser } from 'src/features/authorization';
import useOneWayIn from '../../hooks/use-one-way-in';
import DialogContainer from 'src/components/DialogContainer/DialogContainer';
import useToggle from 'src/hooks/use-toggle';

import { useTranslation } from 'react-i18next';
import { Button, Notification, TextArea } from '@in/component-library';
import { OneWayInStatus } from 'src/api/v2';
import { ONE_WAY_IN } from '../../routes';
import { LoadingPage } from 'src/components/LoadingPage/LoadingPage';
import createOwiUpdateDto from '../utils/createOwiUpdateDto';
import { PageParams } from '../types/PageParams';

const Reopening = () => {
  const { t: tCommon } = useTranslation();
  const { t: tOneWayIn } = useTranslation('oneWayIn');
  const { formId } = useParams<PageParams>();
  const { oneWayInOpportunityFormQuery, updateOneWayInOpportunityStatus } = useOneWayIn(formId);
  const { isActive, activate, deactivate } = useToggle(false);
  const { isAadUser, user, displayName } = useUser();

  const [comment, setComment] = useState('');
  const [error, setError] = useState(false);

  const opportunity = useMemo(() => {
    if (!oneWayInOpportunityFormQuery.data) return undefined;

    return oneWayInOpportunityFormQuery.data.opportunity;
  }, [oneWayInOpportunityFormQuery.data]);

  const onError = () => {
    setError(true);
  };

  const onArchive = () => {
    const promise = updateOneWayInOpportunityStatus.mutateAsync(
      createOwiUpdateDto(
        opportunity?.id,
        OneWayInStatus.ReOpened,
        comment,
        isAadUser ? undefined : user?.clusterUserId,
        displayName || undefined,
      ),
    );
    promise.then(deactivate).catch(onError);
  };

  if (oneWayInOpportunityFormQuery.isFetching) {
    return (
      <div className="width--50">
        <LoadingPage />
      </div>
    );
  }

  if (!opportunity?.isClosed) {
    return <Navigate to={ONE_WAY_IN.DETAILS.ARCHIVING(formId as string)} />;
  }

  return (
    <div className="width--50">
      {error && (
        <Notification
          closeButtonLabel={tCommon('close')}
          dismissable
          onClose={() => setError(false)}
          type="error"
        >
          {tOneWayIn('details.Reopening.Error')}
        </Notification>
      )}
      <h2 className="margin-bottom--3">{tOneWayIn('details.Title.Reopening')}</h2>
      <p>{`${tOneWayIn('details.Reopening.Ingress')} ${tOneWayIn('details.Reopening.Ingress2')}`}</p>

      <Button onClick={activate}>{tOneWayIn('details.Reopening.Reopen')}</Button>
      <DialogContainer
        className="one-way-in-details__modal"
        title={tOneWayIn('details.Reopening.Modal.Header')}
        show={isActive}
        onClose={deactivate}
        titleHeadingLevel={2}
      >
        <>
          <p>{tOneWayIn('details.Reopening.Ingress2')}</p>
          <TextArea
            label={`${tCommon('note')} *`}
            aria-required="true"
            required
            onChange={(e) => setComment(e.target.value)}
            maxCount={1000}
            maxLength={1000}
          />

          <div className="display--flex gap--2">
            <Button disabled={comment.trim() === ''} onClick={onArchive}>
              {tOneWayIn('details.Reopening.Reopen')}
            </Button>
            <Button variant="outlined" theme="neutral" onClick={deactivate}>
              {tOneWayIn('details.Reopening.DoNotReopen')}
            </Button>
          </div>
        </>
      </DialogContainer>
    </div>
  );
};

export default Reopening;
