import { Button, ButtonVariant, RadioButton, Theme } from '@in/component-library';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { SivaPhase } from 'src/api/v2';
import { PageParams } from '../types/PageParams';
import useCompanyContract from '../hooks/use-company-contract';
import { convertIdToNumber } from '../utils/idConversion';
import useContractPhase from '../hooks/use-contract-phase';
import { LoadingPage } from 'src/components/LoadingPage/LoadingPage';
import EditPhaseFormDisplay from './EditPhaseFormDisplay';

type Props = {
  onCancel: () => void;
};

const EditPhase = ({ onCancel }: Props) => {
  const { t: tCommon } = useTranslation('common');
  const { t: tSiva } = useTranslation('siva');
  const { contractId } = useParams<PageParams>();

  const { contract, currentPhase, updateSivaContractMutation, company } = useCompanyContract(
    convertIdToNumber(contractId),
  );

  const [selectedPhase, setSelectedPhase] = React.useState<SivaPhase>();
  const { phaseQuery, contractAvailablePhases } = useContractPhase(convertIdToNumber(contractId));

  const phaseOptions = React.useMemo(() => {
    if (contractAvailablePhases.data) {
      return contractAvailablePhases.data.map((phase) => {
        return {
          value: phase,
          text: tSiva(`phase.${phase}`),
          selected: phase === selectedPhase,
        };
      });
    }
  }, [contractAvailablePhases.data, selectedPhase, tSiva]);

  if (updateSivaContractMutation.isLoading || phaseQuery.isLoading || contractAvailablePhases.isLoading)
    return <LoadingPage />;

  return (
    <div className="width--60">
      <div>
        {phaseOptions && phaseOptions?.length > 0 ? (
          <RadioButton
            label={tSiva('phase.select')}
            name="newPhase"
            options={phaseOptions}
            onChange={(e) => {
              const { value } = e.currentTarget;
              setSelectedPhase(value as SivaPhase);
            }}
          />
        ) : (
          <p>Ingen tilgjengelige faser, dersom du skal endre til Alumni, bruk Avslutt knappen.</p>
        )}
      </div>
      {selectedPhase ? (
        <div>
          <EditPhaseFormDisplay
            contract={contract!}
            currentPhase={currentPhase!}
            selectedPhase={selectedPhase}
            company={company ? company : undefined}
            onCancel={onCancel}
          />
        </div>
      ) : (
        <Button theme={Theme.Neutral} variant={ButtonVariant.Outlined} type="button" onClick={onCancel}>
          {tCommon('cancel')}
        </Button>
      )}
    </div>
  );
};

export default EditPhase;
