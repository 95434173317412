import {
  Button,
  ButtonVariant,
  CallbackResponse,
  FileItem,
  FileList,
  FileUpload,
  Notification,
} from '@in/component-library';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { fetchFromBrReg } from 'src/api/brreg';
import {
  ClusterContactCreateDto,
  ClusterContactDto,
  ClusterContactStatus,
  ClusterMemberCreateDto,
  ClusterMemberDto,
  Gender,
} from 'src/api/v2';
import { ClusterMemberCategories } from 'src/constants/cluster-member-category';
import {
  AllowedFileTypes,
  ExcelImportClusterContactSettings,
  ExcelImportClusterContactSettingsChangelog,
} from 'src/constants/excel-import';
import useCluster from 'src/features/cluster/hooks/use-cluster';
import { useContacts } from 'src/features/contacts';
import useExcel from 'src/hooks/use-excel';
import useMembers from 'src/hooks/use-members';
import { emailRegex, organizationNumberRegex } from 'src/utils/regex';
import { toastPromise } from 'src/utils/toast';

import DuplicateEmailsNotification from './DuplicateEmailsNotification';
import ExistingContactsNotification from './ExistingContactsNotification';
import InvalidEmailsNotification from './InvalidEmailsNotification';
import InvalidOrganizationNumbersNotification from './InvalidOrganizationNumbersNotification';
import ReadyForImportNotification from './ReadyForImportNotification';
import ExcelChangelog from 'src/components/ExcelChangelog/ExcelChangelog';

interface Props {
  onImport?: () => void;
  onCancel?: () => void;
}

const ImportContactsForm: React.FC<Props> = ({ onImport, onCancel }) => {
  const { t: tCommon } = useTranslation();
  const { t: tError } = useTranslation('error');
  const { t: tResourceBase } = useTranslation('resourceBase');

  const { cluster } = useCluster();
  const { combined, createMutation } = useMembers();
  const { contacts, createListMutation } = useContacts();

  const [files, setFiles] = useState<Array<FileItem>>([]);

  const [createRecords, setCreateRecords] = useState<Array<ClusterContactCreateDto>>([]);
  const [existingContacts, setExistingContacts] = useState<Array<ClusterContactDto>>([]);
  const [createLeadList, setCreateLeadList] = useState<Array<ClusterMemberCreateDto>>([]);

  const [error, setError] = useState<string>('');

  const [invalidEmails, setInvalidEmails] = useState<Array<{ rowNumber: number; email: string }>>([]);
  const [duplicateEmails, setDuplicateEmails] = useState<Array<{ rowNumber: number; email: string }>>([]);
  const [invalidOrgNumbers, setInvalidOrgNumbers] = useState<Array<{ rowNumber: number; orgNo: string }>>([]);

  const { size, type, downloadFile, importFile } = useExcel<ClusterContactCreateDto>(
    ExcelImportClusterContactSettings,
  );

  const fileListFiles = useMemo(
    () => [
      {
        id: 'contacts',
        fileName: tResourceBase('import.template.contacts'),
        fileType: type,
        fileSize: size,
        onClick: () => {
          downloadFile(`import-mal_kontakt`);
        },
      },
    ],
    [downloadFile, size, tResourceBase, type],
  );

  const createNewClusterMembers = React.useCallback(async () => {
    if (cluster) {
      for (const item of createLeadList) {
        try {
          const record: ClusterMemberCreateDto = {
            ...item,
            clusterId: cluster.id,
            clusterMemberCategoryId: ClusterMemberCategories.Lead.id,
          };

          await createMutation.mutateAsync(record);
        } catch {
          toast.error(
            `${tError('couldNotCreateGeneric', { value: tResourceBase('lead') })}: ${
              item.organizationNumber
            }`,
          );
        }
      }
    }
  }, [cluster, createLeadList, createMutation, tResourceBase, tError]);

  const handleOnImport = () => {
    // lager copy av state
    const records = new Array<ClusterContactCreateDto>();

    for (let i = 0; i < createRecords.length; i++) {
      // hent ut record
      const record = createRecords[i];

      // sjekk om clusterMemberId matcher et organisasjonsnummer
      if (record.clusterMemberId?.match(organizationNumberRegex)) {
        // finn bedrift med orgno
        const found = combined.find((x) => x.organizationNumber === record.clusterMemberId);
        record.clusterMemberId = found?.id;
      } else {
        // dobbeltsjekk at medlem finnes
        const memberExists = combined.some((x) => x.id === record.clusterMemberId);
        if (!memberExists) {
          // set blank hvis ikke
          record.clusterMemberId = undefined;
        }
      }

      // sett tilbake record
      records.push(record);
    }

    const promise = createListMutation.mutateAsync(records);

    toastPromise(promise, {
      error: tError('selfEffort.newContactsCreateError'),
    }).then(() => {
      onImport?.();
    });
  };

  const startImportHandler = () => {
    createNewClusterMembers().then(() => {
      toast.success(
        `${createLeadList.length} ${tResourceBase('lead')} ${`$${tCommon('created')}`.toLowerCase()}!`,
      );
      handleOnImport();
    });
  };

  const convertGender = (input: string): Gender => {
    if (!input) {
      return Gender.Unspecified;
    }

    switch (input.toLowerCase()) {
      case 'mann':
        return Gender.Male;
      case 'kvinne':
        return Gender.Female;
      default:
        return Gender.Unspecified;
    }
  };

  const handleImportClusterContacts = (items: Array<ClusterContactCreateDto>) => {
    if (!cluster) {
      return;
    }

    setError('');

    // sørg for at eposter er små bokstaver (noen brukere har rar formattering/lagrer eposter rart...)
    // Fjern white-space fra orgnr (clusterMemberId)
    const mappedItems: Array<ClusterContactCreateDto> = items.map((item) => ({
      ...item,
      emailAddress: item.emailAddress.toLowerCase(),
      clusterMemberId: item.clusterMemberId?.replace(/\s/g, ''),
    }));

    // holder på alle eposter vi prøver å legge til (duplikatsjekk)
    const addedEmails = new Array<string>();
    // holder på alle orgnr vi prøver å legge til (duplikatsjekk)
    const addedLeads = new Array<string>();

    mappedItems.forEach((item, index) => {
      // sjekk vi allerede har lagt til denne e-posten
      const duplicateEmailCheck = addedEmails.some(
        (x) => x.toLowerCase() === item.emailAddress.toLowerCase(),
      );

      if (duplicateEmailCheck) {
        // legg i duplikat-lista
        setDuplicateEmails((prevValue) => [...prevValue, { rowNumber: index + 2, email: item.emailAddress }]);
        return;
      }

      // sjekk om kontakt finnes fra før, legg i en list hvis finnes fra før
      const found = contacts.find((x) => x.emailAddress.toLowerCase() === item.emailAddress.toLowerCase());

      // kontakt finnes
      if (found) {
        // legg i liste for å vise bruker
        setExistingContacts((prevValue) => [...prevValue, found]);
      } else {
        // fjern all white-space fra orgNo-string
        let clusterMemberExists: ClusterMemberDto | undefined = undefined;

        // sjekk om vi allerede har lagt til lead, eller om allerede invalid orgno
        const leadAlreadyAdded =
          addedLeads.some((x) => x === item.clusterMemberId) ||
          invalidOrgNumbers.some((x) => x.orgNo === item.clusterMemberId);

        // sjekk om det er noe igjen av orgno
        if (item.clusterMemberId && !leadAlreadyAdded) {
          // prøv å finn bedrift
          clusterMemberExists = combined.find((x) => x.organizationNumber === item.clusterMemberId);

          // bedrift finnes ikke
          if (!clusterMemberExists && item.clusterMemberId) {
            // legg til i liste for å lage lead
            fetchFromBrReg(item.clusterMemberId)
              .then((res) => {
                // sjekker om vi allerede har lagt til
                if (!addedLeads.some((x) => x === res.organizationNumber)) {
                  addedLeads.push(res.organizationNumber!);
                }
                setCreateLeadList((prevValue) => {
                  // legger til ekstra check på dette
                  if (prevValue.some((x) => x.organizationNumber === res.organizationNumber)) {
                    return [...prevValue];
                  }

                  return [...prevValue, res];
                });
              })
              .catch(() => {
                setInvalidOrgNumbers((prevValue) => [
                  ...prevValue,
                  { rowNumber: index + 2, orgNo: item.clusterMemberId! },
                ]);
              });
          }
        }

        // sjekk at epost er gyldig
        if (item.emailAddress.match(emailRegex)) {
          addedEmails.push(item.emailAddress);

          const record: ClusterContactCreateDto = {
            ...item,
            clusterId: cluster.id,
            // bruk id fra bedrift hvis den finnes, hvis ikke legg til orgNo (evt undefined)
            clusterMemberId: clusterMemberExists?.id || item.clusterMemberId,
            gender: convertGender(item.gender),
            mobilePhone: item.mobilePhone || '',
            interestAreaIds: [],
            status: ClusterContactStatus.Active,
          };

          setCreateRecords((prevValue) => {
            // dobbeltsjekker at vi ikke lager noen duplikater
            if (prevValue.some((x) => x.emailAddress === record.emailAddress)) {
              return [...prevValue];
            }

            return [...prevValue, record];
          });
        } else {
          // legg epost og radnummer i liste for ugyldige e-poster
          setInvalidEmails((prevValue) => [...prevValue, { rowNumber: index + 2, email: item.emailAddress }]);
        }
      }
    });
  };

  const handleFileUpload = (fileItem: FileItem) => {
    const { file } = fileItem;

    if (file) {
      importFile(file)
        .then((data) => {
          setCreateRecords([]);
          handleImportClusterContacts(data);
        })
        .catch(() => {
          toast.error(tError('excel.import.error'));
        });
    } else {
      toast.error(tError('excel.import.error'));
    }
  };

  const reset = () => {
    setFiles([]);
    setCreateRecords([]);
    setExistingContacts([]);
    setInvalidOrgNumbers([]);
    setInvalidEmails([]);
    setDuplicateEmails([]);
    setCreateLeadList([]);
  };

  const handleUploadCallback = async (fileItem: FileItem): Promise<CallbackResponse> => {
    if (!AllowedFileTypes.some((allowedFileType: string) => allowedFileType === fileItem.fileType)) {
      toast.error(tError('excel.import.invalidFileType'));

      setFiles([]);

      return {
        status: true,
        message: tError('excel.import.invalidFileType'),
      };
    }

    setFiles([fileItem]);
    handleFileUpload(fileItem);

    return { status: true, message: 'what does this do?' };
  };

  const handleDeletionCallback = async (): Promise<CallbackResponse> => {
    reset();

    return { status: true, message: 'what does this do?' };
  };

  return (
    <div>
      <p>{tResourceBase('excel.downloadTemplateTips')}</p>

      <FileList files={fileListFiles} downloadText={tCommon('downloadTemplate')} />

      <ExcelChangelog changelogs={ExcelImportClusterContactSettingsChangelog} />

      <FileUpload
        name=""
        files={files}
        fileTypes="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        uploadCallback={handleUploadCallback}
        deletionCallback={handleDeletionCallback}
      />

      <ReadyForImportNotification contacts={createRecords} leads={createLeadList} />
      <ExistingContactsNotification contacts={existingContacts} />

      {error ? (
        <Notification type="error" fullWidth className="margin-bottom--2">
          {error}
        </Notification>
      ) : null}

      <DuplicateEmailsNotification items={duplicateEmails} />
      <InvalidEmailsNotification items={invalidEmails} />
      <InvalidOrganizationNumbersNotification items={invalidOrgNumbers} />

      <div className="display--flex gap--1">
        <Button
          onClick={() => {
            if (createRecords.length > 0) {
              startImportHandler();
            } else {
              setError(tResourceBase('import.contact.noneToImport'));
            }
          }}
        >
          {tResourceBase('import')}
        </Button>
        <Button variant={ButtonVariant.Outlined} onClick={onCancel}>
          {tCommon('cancel')}
        </Button>
      </div>
    </div>
  );
};

export default ImportContactsForm;
