import { useMemo } from 'react';
import useConfiguration from './use-configuration';

const useEnvironment = () => {
  const { environmentInformation } = useConfiguration();

  const environment = useMemo(() => {
    return environmentInformation?.name || '';
  }, [environmentInformation]);

  const isDev = useMemo(() => {
    return environment === 'dev';
  }, [environment]);

  const isTest = useMemo(() => {
    return environment === 'test';
  }, [environment]);

  const isDevOrTest = useMemo(() => {
    return environment === 'dev' || environment === 'test';
  }, [environment]);

  return {
    isDev,
    isTest,
    isDevOrTest,
  };
};

export default useEnvironment;
