import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { FaIcon, FaIcons, Footer } from '@in/component-library';
import { useSupportEmail } from 'src/hooks/use-support-email';
const BaseFooter: FC = () => {
  const { t } = useTranslation();
  const { email } = useSupportEmail();

  const fullYear = new Date().getFullYear();

  return (
    <Footer>
      <p>
        {t('footer.questionsAboutPortal')}
        <br></br>

        <a href={`mailto:${email}`}>{t('footer.contact')}</a>
        <br></br>
        <Link to="/terms-of-use">{t('termsOfUse')}</Link>
      </p>
      <p>
        {t('footer.copyright', { date: fullYear })}
        <br></br>
        <a href="https://uustatus.no/nb/erklaringer/publisert/4ca5cfd8-f091-42cf-b2f1-8c682f09b102">
          <span className="margin-right--1">{t('footer.declarityOfAvailability')}</span>
          <FaIcon name={FaIcons.ExternalLinkLight} />
        </a>
      </p>
    </Footer>
  );
};

export default BaseFooter;
