import React, { useMemo } from 'react';
import { PageParams } from '../types/PageParams';
import { useParams } from 'react-router-dom';
import CompanyInformationTabs from '../components/CompanyInformationTabs';
import { convertIdToNumber } from '../utils/idConversion';
import { useExternalCompany } from 'src/hooks/use-external-company';
import CompanyBaseInfoBox from '../../components/company/CompanyBaseInfoBox';
import { LoadingPage } from 'src/components/LoadingPage/LoadingPage';
import { Notification, MessageType } from '@in/component-library';
import { Trans, useTranslation } from 'react-i18next';
import useCompanyContract from '../hooks/use-company-contract';
import useHotjar from 'src/hooks/use-hotjar';

const CompanyInfo: React.FC = () => {
  const { t: tSiva } = useTranslation('siva');
  const { contractId } = useParams<PageParams>();
  const {
    contract,
    isLoading: contractIsLoading,
    company,
  } = useCompanyContract(convertIdToNumber(contractId));
  const { data: externalCompany, isLoading: companyIsLoading } = useExternalCompany(
    company!.organizationNumber,
  );
  const hotjar = useHotjar();

  const isLoading = useMemo(
    () => contractIsLoading || companyIsLoading,
    [contractIsLoading, companyIsLoading],
  );

  React.useEffect(() => {
    if (!isLoading) hotjar.log('SIVA_COMPANY_DETAILS_OPENED');
  }, [isLoading]);

  if (isLoading) {
    return <LoadingPage />;
  }

  if (!companyIsLoading && !externalCompany) {
    return (
      <Notification type={MessageType.Info} className="margin-bottom--4">
        <p>
          <Trans
            t={tSiva}
            i18nKey={'companyTabs.about.notification.text1'}
            values={{ name: contract?.name }}
            components={{
              '1': <strong>{contract?.name}</strong>,
            }}
          >
            Kunne ikke finne bedriftsinfo for <strong>{contract?.name}</strong>
          </Trans>
        </p>

        <p>{tSiva('companyTabs.about.notification.text2')}</p>
      </Notification>
    );
  }

  return (
    <div className="company-info">
      {contract && company && externalCompany && (
        <>
          <CompanyBaseInfoBox company={externalCompany} hideName={true} />
          <CompanyInformationTabs contract={contract} company={company} />
        </>
      )}
    </div>
  );
};

export default CompanyInfo;
