import React from 'react';
import { RouteObject } from 'react-router-dom';
import OneWayInOpportunities from './pages/OneWayInOpportunities';
import OneWayInCountyOpportunities from './pages/OneWayInCountyOpportunities';
import OneWayInActorOpportunities from './pages/OneWayInActorOpportunities';
import { ONE_WAY_IN_BASE_ROUTE } from 'src/navigation';
import { ONE_WAY_IN_DETAILS } from './opportunity-details/routes';

const OpportunityDetails = React.lazy(() => import('./opportunity-details/pages/OpportunityDetails'));

const ONE_WAY_IN_COUNTY_ROUTE = 'countyOpportunities';
const ONE_WAY_IN_ACTOR_ROUTE = 'actorOpportunities';

export const ONE_WAY_IN_DETAILS_ROUTE = 'details/:formId';

export const ONE_WAY_IN = {
  BASE: ONE_WAY_IN_BASE_ROUTE,
  DETAILS: ONE_WAY_IN_DETAILS,
  COUNTY: `${ONE_WAY_IN_BASE_ROUTE}/${ONE_WAY_IN_COUNTY_ROUTE}`,
  ACTOR: `${ONE_WAY_IN_BASE_ROUTE}/${ONE_WAY_IN_ACTOR_ROUTE}`,
} as const;

const routes: RouteObject[] = [
  {
    path: '',
    element: <OneWayInOpportunities />,
    index: true,
  },
  {
    path: `/${ONE_WAY_IN_DETAILS_ROUTE}/*`,
    element: <OpportunityDetails />,
  },
  {
    path: `/${ONE_WAY_IN_COUNTY_ROUTE}`,
    element: <OneWayInCountyOpportunities />,
    index: true,
  },
  {
    path: `/${ONE_WAY_IN_ACTOR_ROUTE}`,
    element: <OneWayInActorOpportunities />,
    index: true,
  },
];

export const actorRoutes: RouteObject[] = [
  {
    path: '',
    element: <OneWayInActorOpportunities />,
    index: true,
  },
];
export default routes;
