import { useMemo } from 'react';
import { OneWayInServiceProviderDto } from 'src/api/v2';

const useActorTypes = (actors: OneWayInServiceProviderDto[]) => {
  const actorTypes = useMemo(() => {
    return [...new Set(actors.map((actor) => actor.clusterStatusName))].map((statusName) => ({
      text: statusName,
      value: statusName,
    }));
  }, [actors]);

  return { actorTypes };
};

export default useActorTypes;
