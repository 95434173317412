import React from 'react';
import { formatCurrencySimple } from 'src/utils/FormatValue';
import Divider from 'src/components/Divider';
import { useTranslation } from 'react-i18next';
import BillSupportNumberHandler from './BillSupportNumberHandler';
import { calculateInnovationSupport, calculateSupportIntensity } from '../utils/calculations';
import { SivaEsaReportingDto } from 'src/api/v2';
import { FaIcon } from '@in/component-library';
import { icon } from '@fortawesome/fontawesome-svg-core';
import { resources } from 'src/i18n';

type Props = {
  header: keyof (typeof resources)['nb']['siva'];
  bills: SivaEsaReportingDto[];
  isBusinessGarden: boolean;
};

const ESAPhaseBillsSummary: React.FC<Props> = ({ header, bills, isBusinessGarden }) => {
  const { t: tSiva } = useTranslation('siva');

  const summary = React.useMemo(() => {
    const marketValue = bills.reduce((accumulator, bill) => accumulator + bill.marketValue, 0);
    const deductible = bills.reduce((accumulator, bill) => accumulator + bill.invoicedDeductible, 0);
    const supportIntensity = calculateSupportIntensity(marketValue, deductible);
    const innovationSupport = calculateInnovationSupport(marketValue, deductible);

    return {
      marketValue,
      deductible,
      supportIntensity,
      innovationSupport,
    };
  }, [bills]);
  const translatedHeader = tSiva(`phase.${header}` as any, { defaultValue: header || '' });

  return (
    <div className="margin-bottom--6">
      <h3 className="margin-bottom--3">{tSiva(header)}</h3>
      <div className="margin--2">
        <b>{tSiva('company.details.esa.summary')}</b>
      </div>
      <div className="margin--2 display--flex align-items--space-between">
        <div className="flex--2">{tSiva('company.details.esa.marketValueDeliveredServices')}</div>
        <div>{formatCurrencySimple(summary.marketValue, true)}</div>
      </div>
      <Divider />
      <div className="margin--2 display--flex align-items--space-between">
        <div className="flex--2">{tSiva('company.details.esa.billedDeductible')}</div>
        <div>{formatCurrencySimple(summary.deductible, true)}</div>
      </div>
      <Divider />
      <div className="margin--2 display--flex align-items--space-between">
        <div className="flex--2">{tSiva('company.details.esa.supportIntensity')}</div>
        <div>
          <BillSupportNumberHandler
            value={summary.supportIntensity}
            formattedValue={`${summary.supportIntensity} %`}
          />
        </div>
      </div>
      {summary.supportIntensity > 75 && header === 'phase.OtherPhases' ? (
        <div className="margin--2">
          <FaIcon
            className="margin-right--1 "
            icon={icon({ prefix: 'fal', iconName: 'triangle-exclamation' })}
          />
          <i className="font-size--medium">
            {isBusinessGarden
              ? tSiva('company.details.esa.supportIntensityWarning')
              : tSiva('company.details.esa.supportIntensityWarningWithPhase', {
                  phase: translatedHeader,
                })}
          </i>
        </div>
      ) : (
        <></>
      )}
      <Divider />
      <div className="margin--2 display--flex align-items--space-between">
        <div className="flex--2">
          <b>{tSiva('company.details.esa.sumReceivedInnovationSupport')}</b>
        </div>
        <div>
          <BillSupportNumberHandler
            value={summary.innovationSupport}
            formattedValue={`${formatCurrencySimple(summary.innovationSupport, true)}`}
          />
        </div>
      </div>
      <Divider />
    </div>
  );
};

export default ESAPhaseBillsSummary;
