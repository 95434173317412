import { useState } from 'react';
import { SivaPhaseService } from 'src/api/v2';
import { useQuery } from 'react-query';
import { SIVA_COMPANY_AVAILABLE_PHASES_KEY } from 'src/constants/hook-keys';

const useCompanyPhase = (orgNumber?: string | null) => {
  const [sivaPhaseService] = useState(() => new SivaPhaseService());

  const companyAvailablePhases = useQuery({
    queryFn: () => sivaPhaseService.getAvailablePhasesForCompany(orgNumber!),
    queryKey: [SIVA_COMPANY_AVAILABLE_PHASES_KEY, orgNumber],
    retry: false,
    enabled: !!orgNumber,
  });

  return {
    companyAvailablePhases,
  };
};

export default useCompanyPhase;
