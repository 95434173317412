import { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { ONE_WAY_IN_OPPORTUNITY_FORM_LINK } from 'src/constants/hook-keys';
import { useCluster } from 'src/features/cluster';
import { OneWayInService, SharedOpportunityFormLinkCreateDto } from 'src/api/v2';
import { useAuth } from 'src/features/authorization';
import { useTranslation } from 'react-i18next';

export default function useOpportunityFormLink() {
  const { t: tCommon } = useTranslation('common');
  const { cluster } = useCluster();

  const { checkAccess } = useAuth();
  const { read, write } = checkAccess('OneWayIn');

  const queryClient = useQueryClient();

  const [service] = useState(new OneWayInService());

  const { data, ...rest } = useQuery(
    [ONE_WAY_IN_OPPORTUNITY_FORM_LINK],
    () => service.getAllSharedOpportunityFormLinks(),
    {
      enabled: !!cluster && read,
      retry: false,
    },
  );

  const createMutation = useMutation(
    (record: Omit<SharedOpportunityFormLinkCreateDto, 'clusterId'>) => {
      if (!cluster) throw new Error('No cluster selected');
      if (!write) {
        throw new Error(tCommon('cannotWrite'));
      }

      return service.createSharedOpportunityFormLink({ ...record, clusterId: cluster!.id });
    },
    {
      onSuccess() {
        queryClient.resetQueries([ONE_WAY_IN_OPPORTUNITY_FORM_LINK]);
        queryClient.invalidateQueries([ONE_WAY_IN_OPPORTUNITY_FORM_LINK]);
      },
    },
  );

  return {
    links: data,
    createMutation,
    ...rest,
  };
}
