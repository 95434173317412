import React, { useState } from 'react';
import { Button, Link as ClLink } from '@in/component-library';
import '../styles/OneWayInStyle.scss';
import { Link } from 'react-router-dom';
import { icon } from '@fortawesome/fontawesome-svg-core';
import { Trans, useTranslation } from 'react-i18next';
import { ONE_WAY_IN } from '..';
import { useCluster } from '../../cluster';
import useDocumentTitle from 'src/hooks/use-document-title';
import useOpportunityList from '../opportunity-table/hooks/use-opportunity-table-list';
import { useSupportEmail } from '../../../hooks/use-support-email';
import { LoadingPage } from 'src/components/LoadingPage/LoadingPage';
import DocumentTitle from 'src/components/DocumentTitle/DocumentTitle';
import { FaIcon, Notification } from '@in/component-library';
import OneWayInOpportunityTable from '../opportunity-table/components/OneWayInOpportunityTable';
import '../styles/OneWayInStyle.scss';
import useOneWayInList from '../hooks/use-one-way-in-list';
import useOneWayInNewOpportunitiesNumber from '../hooks/use-one-way-in-new-opportunities-number';
import { useLocalStorage } from 'usehooks-ts';
import { DataTableV3LocalStoragePrefixes } from 'src/components/DataTableV3/constants/localStorage';
import { DataTableV3Ids } from 'src/constants/dataTableIds';
import { useAuth } from 'src/features/authorization';
import CreateLinkToOpportunityForm from '../components/create-link-form/CreateLinkToOpportunityForm';
import useSiteimprove from 'src/hooks/use-siteimprove';

const OneWayInOpportunities: React.FC = () => {
  const { cluster } = useCluster();
  const siteimprove = useSiteimprove();
  const { email, mailto } = useSupportEmail();
  const { t: tOneWayIn } = useTranslation('oneWayIn');
  const [localStorageTop] = useLocalStorage(
    `${DataTableV3LocalStoragePrefixes.pageSize}${DataTableV3Ids.OneWayIn.Opportunities}`,
    25,
  );
  const { checkAccess } = useAuth();
  const [top, setTop] = React.useState<number>(localStorageTop);

  const { oneWayInAllSavedFormsQuery, totalFormNumber } = useOneWayInList();
  const { alertOneWayInNewOpportunities } = useOneWayInNewOpportunitiesNumber();

  const { opportunityList } = useOpportunityList({ formList: oneWayInAllSavedFormsQuery.data! });

  const { documentTitle } = useDocumentTitle(tOneWayIn('opportunities'));

  const hasCounties = !!cluster?.oneWayInCounties?.length;

  const hasOpportunities = oneWayInAllSavedFormsQuery.data
    ? oneWayInAllSavedFormsQuery.data.length > 0
    : false;
  const isLoading = oneWayInAllSavedFormsQuery.isLoading;

  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <DocumentTitle title={documentTitle}>
        <main className="opportunity-layout">
          {checkAccess('FF_Experiment').read && (
            <Notification className="opportunity-banner" fullWidth type="info">
              <p>{tOneWayIn('addOpportunities.banner')}</p>
              <br />
              <Button
                onClick={() => {
                  siteimprove.log('OPPORTUNITY_ADD', 'OPEN');
                  setShowModal(true);
                }}
              >
                {tOneWayIn('addOpportunities.cta')}
              </Button>
            </Notification>
          )}
          {alertOneWayInNewOpportunities > 0 && (
            <Notification className="opportunity-banner" fullWidth type="info" dismissable>
              {`${
                alertOneWayInNewOpportunities > 1
                  ? tOneWayIn('opportunities.newOpportunities', {
                      numberOfOpportunities: alertOneWayInNewOpportunities,
                    })
                  : tOneWayIn('opportunities.newOpportunity')
              }.`}
            </Notification>
          )}
          <h1>{tOneWayIn('opportunities')}</h1>
          {!isLoading && !hasOpportunities && (
            <div className="margin-top--4">
              <FaIcon icon={icon({ prefix: 'fal', iconName: 'folder-open' })} size={'xl'} />
              <h2 className={'margin-top--3'}>{tOneWayIn('noOpportunitiesFoundTitle')}</h2>
              <p>{tOneWayIn('noOpportunitiesFoundDescription')}</p>

              <p>
                <Trans
                  t={tOneWayIn}
                  i18nKey="noOpportunitiesFoundContactInfo"
                  values={{ email: 'support@enveiinn.no' }}
                  components={{ '1': <ClLink href={mailto}>{email}</ClLink> }}
                >
                  Hvis du har spørsmål eller innspill så ta gjerne kontakt på support@enveiinn.no
                </Trans>
              </p>
            </div>
          )}

          {isLoading && <LoadingPage />}
          {!isLoading && oneWayInAllSavedFormsQuery.isError && (
            <Notification type="error" wrapperRole="alert">
              {tOneWayIn('errorCouldNotGetAllForms')}
            </Notification>
          )}

          {!isLoading && hasOpportunities && (
            <OneWayInOpportunityTable
              opportunities={opportunityList}
              paginationListeners={{
                onSetPageSize: (pageSize) => setTop(pageSize),
              }}
              pageSize={top}
              overridePaginationTotalNumber={totalFormNumber}
            />
          )}
          {hasCounties && (
            <div className="margin-top--8">
              <p>
                {tOneWayIn('countyWarning')}{' '}
                <Link className="link--underline" to={ONE_WAY_IN.COUNTY}>
                  {tOneWayIn('goToCountyView')}
                </Link>
                .
              </p>
            </div>
          )}
          <CreateLinkToOpportunityForm
            titleHeadingLevel={2}
            title={tOneWayIn('addOpportunities.header')}
            show={showModal}
            onClose={() => setShowModal(false)}
          />
        </main>
      </DocumentTitle>
    </>
  );
};

export default OneWayInOpportunities;
