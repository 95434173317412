/* eslint-disable react/prop-types */
import classNames from 'classnames';
import React from 'react';

type Props = React.HTMLAttributes<HTMLHRElement> & {};

const Divider = React.forwardRef<HTMLHRElement, Props>(({ className, ...rest }, ref) => {
  return <hr className={classNames('divider', className)} {...rest} ref={ref} />;
});

Divider.displayName = 'Divider';

export default Divider;
