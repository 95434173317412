import React from 'react';

import { Button, ButtonVariant } from '@in/component-library';

import useToggle from 'src/hooks/use-toggle';

interface Props {
  text: React.ReactNode;
  initialOpen?: boolean;
  children: React.ReactNode;
}

const LinkButtonDetails: React.FC<Props> = ({ text, initialOpen = false, children }) => {
  const { isActive, toggle } = useToggle(initialOpen);

  return (
    <div>
      <Button type="button" variant={ButtonVariant.Link} onClick={toggle}>
        {text}
      </Button>
      {isActive && children}
    </div>
  );
};

export default LinkButtonDetails;
