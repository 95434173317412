import React, { type ReactNode } from 'react';
import classNames from 'classnames';

import styles from '../styles/ColumnWrapper.module.scss';

type ColumnWrapperProps = {
  className?: string;
  children: ReactNode;
};

const ColumnWrapper = ({ className, children }: ColumnWrapperProps) => {
  const combinedClassName = classNames(styles.sitemap, className);

  return <div className={combinedClassName}>{children}</div>;
};

export default ColumnWrapper;
