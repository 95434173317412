import React from 'react';
import { StepProps } from './Step';
import './Stepper.scss';

interface Step {
  title: string;
  element: (props: StepProps) => JSX.Element;
}

export interface StepperProps {
  steps: Step[];
}

export const Stepper: React.FC<StepperProps> = ({ steps }) => {
  const [currentStep, setCurrentStep] = React.useState<number>(1);

  const goNextStep = () => {
    const nextStep = currentStep + 1;
    if (nextStep <= steps.length) {
      setCurrentStep(nextStep);
    }
  };

  const goPreviousStep = () => {
    const previousStep = currentStep - 1;
    if (previousStep >= 1) {
      setCurrentStep(previousStep);
    }
  };

  const renderStep = () => {
    if (currentStep > 0 && currentStep <= steps.length) {
      const step = steps[currentStep - 1];

      return (
        <div className="step-wrapper">
          <step.element
            step={currentStep}
            goNextStep={goNextStep}
            goPreviousStep={goPreviousStep}
            currentStep={currentStep}
            isFirst={currentStep === 1}
            isLast={currentStep === steps.length}
          />
        </div>
      );
    }

    return <></>;
  };

  return (
    <div className="stepper stepper-wrapper">
      <div className="stepper-selector">{renderStep()}</div>
    </div>
  );
};
