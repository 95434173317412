import { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { OneWayInServiceProviderDto, OneWayInServicesService } from 'src/api/v2';
import { ONE_WAY_IN_SERVICE_PROVIDERS } from 'src/constants/hook-keys';
import { useCluster } from 'src/features/cluster';

const useOneWayInServiceProviders = () => {
  const { cluster } = useCluster();
  const [oneWayInServicesService] = useState(new OneWayInServicesService());

  const { data, ...rest } = useQuery(
    [ONE_WAY_IN_SERVICE_PROVIDERS],
    () => oneWayInServicesService.getOneWayInServiceProviders(),
    {
      retry: false,
    },
  );

  const cooperationServiceProviders = useMemo(() => {
    if (!data) {
      return [] as OneWayInServiceProviderDto[];
    }

    return data.filter(
      (serviceProvider) => serviceProvider.id !== cluster?.id && serviceProvider.vmaNames.length === 0,
    );
  }, [data, cluster]);

  return {
    serviceProviders: data,
    cooperationServiceProviders: cooperationServiceProviders,
    ...rest,
  };
};

export default useOneWayInServiceProviders;
