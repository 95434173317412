import React, { useMemo } from 'react';
import { Button, ButtonVariant, Card, FaIcon, FaIcons } from '@in/component-library';
import { EventDtoV2 } from 'src/api/v2';
import { useTranslation } from 'react-i18next';
import { icon } from '@fortawesome/fontawesome-svg-core';
import { IconDefinition } from '@fortawesome/pro-regular-svg-icons';

import classNames from 'classnames';
import css from './EventCard.module.scss';
import { formatDateToNOString } from 'src/utils/FormatValue';
import { RegisterInkindModal } from 'src/features/inkind';
import { useBoolean } from 'usehooks-ts';
import AddEntityModalButton from 'src/components/AddEntityModalButton/AddEntityModalButton';
import { LoadingPage } from 'src/components/LoadingPage/LoadingPage';
import { Link } from 'react-router-dom';
import useEventDetails from 'src/hooks/use-event-details';
import { getSafeDateOnly } from 'src/utils/date';
import { EventFormWrapper } from 'src/features/projects';
import useProjects from 'src/features/projects/hooks/use-projects';
import EventCardStats from './EventCardStats';

type EventCardProps = {
  event: EventDtoV2;
  isFetching: boolean;
  projectId?: string;
};

const EventCard: React.FC<EventCardProps> = ({ event, isFetching, projectId }) => {
  const { t: tCommon } = useTranslation('common');
  const { t: tEvents } = useTranslation('events');
  const { t: tSelfEffort } = useTranslation('selfEffort');

  const { eventDetails, isFetching: isFetchingStats } = useEventDetails(event.id);

  const { findProjectById, isFetching: projectsFetching } = useProjects();

  const project = useMemo(
    () => (projectsFetching ? undefined : findProjectById(event.projectId)),
    [findProjectById, projectsFetching, event.projectId],
  );

  const registerInKindModal = useBoolean();

  const utcCurrentDate = getSafeDateOnly(new Date());
  const utcStartDate = getSafeDateOnly(new Date(event.startDate));
  const utcEndDate = event.endDate ? getSafeDateOnly(new Date(event.endDate)) : undefined;

  const hasEventStarted: boolean = utcStartDate < utcCurrentDate;

  const hasEventEnded: boolean = utcEndDate ? utcEndDate < utcCurrentDate : utcStartDate < utcCurrentDate;

  const isEventActive: boolean = utcEndDate
    ? hasEventStarted && !hasEventEnded
    : utcCurrentDate.toString() == utcStartDate.toString();

  const eventStatus = useMemo(() => {
    if (hasEventStarted && hasEventEnded) {
      return 'completed';
    }
    if (!hasEventStarted) {
      return 'upcoming';
    }
    if (isEventActive) {
      return 'active';
    }

    return '';
  }, [hasEventStarted, hasEventEnded, isEventActive]);

  return (
    <>
      {isFetching && projectsFetching ? (
        <LoadingPage />
      ) : (
        <Card boxShadow={'elevated'} className={css['event-card']}>
          <div className={css['event-card__left']}>
            <span className={classNames(css['event-card__left--status'], css[`${eventStatus}`])}>
              {tEvents(`${eventStatus}` as unknown as TemplateStringsArray)}
            </span>
            {project && <span className={css['event-card__left--type-and-phase']}>{project.name}</span>}

            {projectId ? (
              <Link to={`/projects/events/${event.id}?projectId=${projectId}`}>
                <h2 className={css['event-card__left--name']}>{event.name}</h2>
              </Link>
            ) : (
              <Link to={`/projects/events/${event.id}`}>
                <h2 className={css['event-card__left--name']}>{event.name}</h2>
              </Link>
            )}

            <span className={css['project-card__left--dates']}>
              {tCommon('startDate')}:{' '}
              {formatDateToNOString(event.startDate, false, '{date}.{month}.{year}', true)}
              {event.endDate && (
                <>
                  {' '}
                  | {tCommon('endDate')}:{' '}
                  {formatDateToNOString(event.endDate, false, '{date}.{month}.{year}', true)}
                </>
              )}
            </span>
          </div>

          <EventCardStats
            event={event}
            eventDetails={eventDetails}
            eventStatus={eventStatus}
            isFetching={isFetchingStats}
          />

          <div className={css['event-card__right']}>
            <AddEntityModalButton
              buttonVariant={ButtonVariant.Outlined}
              buttonText={
                <>
                  <FaIcon
                    name={FaIcons.EditLight}
                    size="sm"
                    className={css['event-card__right--button-icon']}
                  />
                  {tEvents('editEvent')}
                </>
              }
              modalTitle={tEvents('editEvent')}
            >
              <EventFormWrapper project={project} event={event} />
            </AddEntityModalButton>
            {eventStatus !== 'upcoming' && (
              <Button variant={ButtonVariant.Outlined} onClick={registerInKindModal.setTrue}>
                <FaIcon
                  icon={icon({ prefix: 'fal', iconName: 'sack-dollar' }) as IconDefinition}
                  size="sm"
                  className={css['event-card__right--button-icon']}
                />{' '}
                {tSelfEffort('registerSelfEffort')}
              </Button>
            )}
          </div>
        </Card>
      )}
      {project?.id && (
        <RegisterInkindModal
          show={registerInKindModal.value}
          onClose={registerInKindModal.setFalse}
          defaultValues={{ projectId: project.id, eventId: event.id }}
        />
      )}
    </>
  );
};

export default EventCard;
