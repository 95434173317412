import React, { useEffect, useRef } from 'react';
import { Dialog, Alignment } from '@in/component-library';
import classNames from 'classnames';
import './DialogContainer.scss';

export type DialogContainerProps = {
  children: React.ReactNode;
  show: boolean;
  /**
   * This gets fired when the user clicks on the close button ("X") or on the overlay
   */
  onClose: () => void;
  /**
   * This is the title of the modal, you need to set the title even if you're not going to show the header or the title, because we use it for the aria-label
   */
  title: string;
  /**
   * Set this to false if you dont want the "X" close button in the corner
   */
  showCloseButton?: boolean;
  /**
   * Override aria-label on close button
   */
  closeButtonLabel?: string;
  className?: string;
  id?: string;
  /**
   * Set vertical alignment of modal
   * @default Alignment.Center
   * */
  verticalAlignment?: Alignment;

  /**
   * Set header level for modal.
   * Semantically, this should almost always be 2.
   * 3 is default for compatibility purposes with older code.
   */
  titleHeadingLevel?: number;
};

const DialogContainer: React.FC<DialogContainerProps> = ({
  show,
  title,
  children,
  onClose,
  showCloseButton = true,
  closeButtonLabel = 'Lukk dialog',
  className,
  id,
  verticalAlignment = Alignment.Center,
  titleHeadingLevel = 3,
}) => {
  const ref = useRef<HTMLDialogElement>(null);

  useEffect(() => {
    if (show) {
      ref?.current?.showModal();
    } else {
      ref?.current?.close();
    }
  }, [show]);

  return (
    <Dialog
      ref={ref}
      title={title}
      className={classNames('dialog-container', className)}
      closeButton={
        showCloseButton
          ? {
              'aria-label': closeButtonLabel,
              onClick: () => {
                onClose();
              },
            }
          : undefined
      }
      onClose={onClose}
      titleHeadingLevel={titleHeadingLevel}
      verticalAlignment={verticalAlignment}
      id={id}
    >
      {show && children}
    </Dialog>
  );
};
export default DialogContainer;
