import React from 'react';

export default function useEffectOnce(callback: () => void, cleanup?: () => void) {
  const hasRun = React.useRef(false);

  React.useEffect(() => {
    if (!hasRun.current) {
      callback();

      return () => {
        cleanup?.();
      };
    } else {
      hasRun.current = true;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
