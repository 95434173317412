import React from 'react';
import DocumentTitle from 'src/components/DocumentTitle/DocumentTitle';
import Page from 'src/components/Page/Page';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useDocumentTitle from 'src/hooks/use-document-title';

const NoAccess: React.FC = () => {
  const { t: tCommon } = useTranslation();
  const { documentTitle } = useDocumentTitle(tCommon('noAccess'));

  return (
    <DocumentTitle title={documentTitle}>
      <Page>
        <h1>{tCommon('noAccess')}</h1>
        <p>{tCommon('noAccessExplanation')}</p>
        <Link to="/">
          <u>{tCommon('goToMainPage')}</u>
        </Link>
      </Page>
    </DocumentTitle>
  );
};

export default NoAccess;
