import { CONTACT_INFORMATION_ROUTE, ROUTES } from 'src/navigation';
import { OnboardingPage } from './HelpAndSupportMenu';

type Content = {
  [key: string]: OnboardingPage[];
};

export const FlowContent: Content = {
  regular: [
    {
      description: 'guide.AccountSettings',
      pageUrl: CONTACT_INFORMATION_ROUTE,
      title: 'guide.AccountSettings.title',
    },
    {
      description: 'guide.Email',
      pageUrl: ROUTES.ACTOR_PROFILE.SETTINGS.EMAIL,
      title: 'guide.Email.title',
    },
  ],
  admin: [
    {
      description: 'guide.AccountSettings',
      pageUrl: CONTACT_INFORMATION_ROUTE,
      title: 'guide.AccountSettings.title',
    },
    {
      description: 'guide.GeneralInfo',
      pageUrl: ROUTES.ACTOR_PROFILE.EDIT.GENERAL_INFO,
      title: 'guide.GeneralInfo.title',
    },
    {
      description: 'guide.AccessControl',
      pageUrl: ROUTES.ACTOR_PROFILE.SETTINGS.ACCESS_CONTROL,
      title: 'guide.AccessControl.title',
    },
  ],
};
