import { icon } from '@fortawesome/fontawesome-svg-core';
import { IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import { FaIcon, ReadMore } from '@in/component-library';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { OneWayInOpportunityStatusDto, OneWayInStatus } from 'src/api/v2';
import Table from 'src/components/Table';
import TertiaryButton from 'src/components/TertiaryButton/TertiaryButton';
import { formatDateToNOString } from 'src/utils/FormatValue';

import './HistoryTableSection.scss';
import { EmailViewContent } from './OneWayInHistoryV2';

export type HistoryTableSectionProps = {
  date: Date;
  data: OneWayInOpportunityStatusDto[];
  openEmailView?: (content: EmailViewContent) => void;
};

const HistoryTableSection: React.FC<HistoryTableSectionProps> = ({ date, data, openEmailView }) => {
  const { t: tCommon } = useTranslation();
  const { t: tOneWayIn } = useTranslation('oneWayIn');

  function statusToIcon(item: OneWayInOpportunityStatusDto): React.ReactNode {
    switch (item.status) {
      case OneWayInStatus.ReOpened:
        return <FaIcon icon={icon({ prefix: 'fal', iconName: 'folder-open' }) as IconDefinition} />;
      case OneWayInStatus.Opened:
        return <FaIcon icon={icon({ prefix: 'fal', iconName: 'envelope-open' }) as IconDefinition} />;
      case OneWayInStatus.Closed:
        return <FaIcon icon={icon({ prefix: 'fal', iconName: 'box-archive' }) as IconDefinition} />;
      case OneWayInStatus.Forwarded:
        return <FaIcon icon={icon({ prefix: 'fal', iconName: 'paper-plane' }) as IconDefinition} />;
      case OneWayInStatus.Shared:
        return <FaIcon icon={icon({ prefix: 'fal', iconName: 'paper-plane' }) as IconDefinition} />;
      case OneWayInStatus.Active: {
        // Anbefalt tjeneste
        if (item.recommendedServices.length > 0) {
          return <FaIcon icon={icon({ prefix: 'fal', iconName: 'hand-holding-box' }) as IconDefinition} />;
        }

        // Registrert tjeneste
        if (item.registeredServices.length > 0) {
          return <FaIcon icon={icon({ prefix: 'fal', iconName: 'grid-2-plus' }) as IconDefinition} />;
        }

        // Notat
        if (item.description !== '') {
          return <FaIcon icon={icon({ prefix: 'fal', iconName: 'sticky-note' }) as IconDefinition} />;
        }

        return null;
      }
      case OneWayInStatus.Received:
        return <FaIcon icon={icon({ prefix: 'fal', iconName: 'down-to-bracket' }) as IconDefinition} />;
      case OneWayInStatus.FollowUpLater:
        return <FaIcon icon={icon({ prefix: 'fal', iconName: 'clock' }) as IconDefinition} />;
      default:
        return null;
    }
  }

  function statusToText(item: OneWayInOpportunityStatusDto): string {
    switch (item.status) {
      case OneWayInStatus.Active: {
        if (item.recommendedServices.length > 0) {
          return tOneWayIn('services.recommended');
        }

        if (item.registeredServices.length > 0) {
          return tOneWayIn('services.delivered');
        }

        if (item.description !== '') {
          return tCommon('note');
        }

        return tOneWayIn(`status.${item.status}` as const);
      }
      case OneWayInStatus.Forwarded:
      case OneWayInStatus.Shared:
        return tOneWayIn('status.Referred');
      case OneWayInStatus.Closed:
        return tOneWayIn('status.Archived');
      case OneWayInStatus.FollowUpLater:
        return tOneWayIn('status.FollowUpLater');
      default:
        return tOneWayIn(`status.${item.status}` as const);
    }
  }

  function renderStatusExtraInfo(item: OneWayInOpportunityStatusDto): React.ReactNode {
    switch (item.status) {
      case OneWayInStatus.Shared:
        return (
          <small>
            {tCommon('to')} {item.referenceClusterName}
          </small>
        );
      case OneWayInStatus.Received:
        return (
          <small>
            {tCommon('from')} {item.referenceClusterName}
          </small>
        );
      case OneWayInStatus.Active: {
        if (item.recommendedServices.length > 0) {
          return (
            <ul className="margin-y--0">
              {item.recommendedServices.map((s) => (
                <li key={s.id}>
                  <small>{s.name}</small>
                </li>
              ))}
            </ul>
          );
        }

        if (item.registeredServices.length > 0) {
          return (
            <ul className="margin-y--0">
              {item.registeredServices.map((s) => (
                <li key={s.id}>
                  <small>{s.name}</small>
                </li>
              ))}
            </ul>
          );
        }

        return null;
      }
      default:
        return null;
    }
  }

  function renderEmailNotification(item: OneWayInOpportunityStatusDto): React.ReactNode {
    return (
      <>
        {item.recommendationEmail && (
          <EmailRow
            emailContent={{
              title: tOneWayIn('services.recommend.mailpage.customer.title'),
              from: 'noreply@innovasjonnorge.no',
              to: item.recommendationEmail?.recipientName || '',
              date: formatDateToNOString(item.createdOn, false, '{date}.{month}.{year}', true),
              text: item.recommendationEmail?.emailContent || '',
              recommendedMail: true,
              userName: item.recommendationEmail?.senderName,
              actorName: item.recommendationEmail?.senderOrganisation,
              recommendedServices: item.recommendedServices.map((service) => ({
                id: service.businessId || '',
                name: service.name,
                owner: service.businessName,
                links: service.externalLinks,
              })),
            }}
            clusterName={item.clusterName || ''}
            handlersName={item.handlersName || ''}
          />
        )}
        {item.shareOpportunityCustomerEmail && (
          <EmailRow
            emailContent={{
              title: 'Ny henvisning i Flerbedriftsportalen',
              from: 'noreply@innovasjonnorge.no',
              to: item.shareOpportunityCustomerEmail.recipients?.join(', ') || '',
              date: formatDateToNOString(item.createdOn, false, '{date}.{month}.{year}', true),
              text: item.shareOpportunityCustomerEmail.emailContent,
              recommendedMail: false,
            }}
            clusterName={item.clusterName || ''}
            handlersName={item.handlersName || ''}
          />
        )}
      </>
    );
  }

  const EmailRow = ({
    emailContent,
    clusterName,
    handlersName,
  }: {
    emailContent: EmailViewContent;
    clusterName: string;
    handlersName: string;
  }) => {
    return (
      <Table.Row className="one-way-in-history__row-content">
        <Table.Cell data-level="1">
          <FaIcon icon={icon({ prefix: 'fal', iconName: 'envelope' }) as IconDefinition} />
        </Table.Cell>
        <Table.Cell data-level="1">
          <div className="display--flex flex-direction--column">
            <strong>{tCommon('email')}</strong>
            <small>{tOneWayIn('history.sentToCustomer')}</small>
          </div>
        </Table.Cell>
        <Table.Cell data-level="1">{clusterName}</Table.Cell>
        <Table.Cell data-level="1">{handlersName}</Table.Cell>
        <Table.Cell data-level="1">
          <TertiaryButton
            standardizedSize={'x-large'}
            icon={{ prefix: 'fal', iconName: 'browsers' }}
            onClick={() => !!openEmailView && openEmailView(emailContent)}
          >
            {tOneWayIn('history.readEmail')}
          </TertiaryButton>
        </Table.Cell>
      </Table.Row>
    );
  };

  const dateString = useMemo(() => {
    return formatDateToNOString(new Date(date));
  }, [date]);

  return (
    <>
      <Table.Row className="owi-history__table-header--no-bottom-border">
        <Table.Cell colSpan={5}>
          <strong>{dateString}</strong>
        </Table.Cell>
      </Table.Row>

      {data.map((item) => {
        return (
          <React.Fragment key={item.id}>
            {renderEmailNotification(item)}
            <Table.Row className="one-way-in-history__row-content">
              <Table.Cell data-level="1">{statusToIcon(item)}</Table.Cell>
              <Table.Cell data-level="1">
                <div className="display--flex flex-direction--column">
                  <strong>{statusToText(item)}</strong>
                  {renderStatusExtraInfo(item)}
                </div>
              </Table.Cell>
              <Table.Cell data-level="1">{item.clusterName}</Table.Cell>
              <Table.Cell data-level="1">
                {(item.status !== OneWayInStatus.Opened && item.handlersName) || ''}
              </Table.Cell>
              <Table.Cell data-level="1">
                {item.shareOpportunityActorEmail ? (
                  <TertiaryButton
                    standardizedSize={'x-large'}
                    icon={{ prefix: 'fal', iconName: 'browsers' }}
                    onClick={() =>
                      !!openEmailView &&
                      openEmailView({
                        title: 'Ny henvisning i Flerbedriftsportalen',
                        from: 'noreply@innovasjonnorge.no',
                        to: item.shareOpportunityActorEmail?.recipients?.join(', ') || '',
                        date: formatDateToNOString(item.createdOn, false, '{date}.{month}.{year}', true),
                        text: item.shareOpportunityActorEmail?.emailContent || '',
                        recommendedMail: false,
                      })
                    }
                  >
                    {tOneWayIn('history.readEmail')}
                  </TertiaryButton>
                ) : (
                  <ReadMore
                    text={item.description || ''}
                    ideal={100}
                    min={80}
                    max={2048}
                    readMoreText={tCommon('showMore')}
                    readMoreTextVisible={tCommon('showLess')}
                  />
                )}
              </Table.Cell>
            </Table.Row>
          </React.Fragment>
        );
      })}
    </>
  );
};

HistoryTableSection.displayName = 'Tr';

export default HistoryTableSection;
