import { FaIcon, FaIcons } from '@in/component-library';
import classNames from 'classnames';
import React from 'react';

type Props = {
  isSubmitted: boolean;
};

const SivaDeliveredIcon: React.FC<Props> = ({ isSubmitted }) => {
  const name = isSubmitted ? FaIcons.CheckCircleLight : FaIcons.TimesLight;

  const label = isSubmitted ? 'Levert' : 'Ikke levert';

  return (
    <FaIcon
      name={name}
      title={label}
      aria-label={label}
      className={classNames({
        'color--in-green-200': isSubmitted,
        'color--red': !isSubmitted,
      })}
    />
  );
};

export default SivaDeliveredIcon;
