import { OneWayInServiceProviderDto } from 'src/api/v2';

export const filterproviderTitle = (
  provider: Pick<OneWayInServiceProviderDto, 'name'>,
  searchQuery: string,
) => {
  return provider.name?.toLowerCase()?.includes(searchQuery.toLowerCase());
};

export const filterproviderMunicipality = (
  provider: Pick<OneWayInServiceProviderDto, 'municipalities'>,
  searchQuery: string,
) => {
  let includes = false;
  provider.municipalities.forEach((muni) => {
    if (muni.toLowerCase().includes(searchQuery.toLowerCase())) {
      includes = true;
    }
  });
  return includes;
};
export const filterServices = (
  provider: Pick<OneWayInServiceProviderDto, 'deliveryTypes'>,
  searchQuery: string,
) => {
  let includes = false;
  provider.deliveryTypes.forEach((service) => {
    if (service.toLowerCase().includes(searchQuery.toLowerCase())) {
      includes = true;
    }
  });
  return includes;
};

export const filterproviderCounty = (
  provider: Pick<OneWayInServiceProviderDto, 'county'>,
  searchQuery: string,
) => {
  return provider.county?.toLowerCase()?.includes(searchQuery.toLowerCase());
};

export const filterproviderPortfolioIndustries = (
  provider: Pick<OneWayInServiceProviderDto, 'portfolioIndustries'>,
  searchQuery: string,
) => {
  let includes = false;
  provider.portfolioIndustries?.forEach((industry) => {
    if (industry.name.toLowerCase().includes(searchQuery.toLowerCase())) {
      includes = true;
    }
  });
  return includes;
};
