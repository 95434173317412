import React from 'react';
import classNames from 'classnames';
import css from './PStepperContent.module.scss';
type Props = React.HTMLAttributes<HTMLDivElement> & { className?: string };
const PStepperContent = React.forwardRef<HTMLDivElement, Props>(
  ({ children, className, ...rest }: Props, ref) => {
    return (
      <div className={classNames(css['pstepper-content'], className)} {...rest} ref={ref}>
        {children}
      </div>
    );
  },
);
PStepperContent.displayName = 'PStepperContent';
export default PStepperContent;
