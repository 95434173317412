import React from 'react';
import { useTranslation } from 'react-i18next';

const ColumnSivaContactSupport = () => {
  const { t } = useTranslation();

  return (
    <div>
      <p>{t('footer.siva.contactSupportMessage')}</p>

      <p>
        <a href="mailto:support@siva.no" title="support@siva.no">
          {t('footer.siva.contactSupportMessage2')}
        </a>{' '}
        {t('footer.siva.contactSupportMessage3')}{' '}
        <a href="mailto:support@siva.no" title="support@siva.no">
          support@siva.no
        </a>
      </p>
    </div>
  );
};

export default ColumnSivaContactSupport;
