import React, { type FC, ChangeEvent } from 'react';
import { TextArea, Button } from '@in/component-library';
import { useTranslation } from 'react-i18next';

type ReferringModalFormProps = {
  isVisibleTextAreaMessage: boolean;
  onAddCommentField: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  onAddDisableModal: () => void;
  handleSubmit: () => void;
};

const ReferringModalForm: FC<ReferringModalFormProps> = (props) => {
  const { onAddCommentField, isVisibleTextAreaMessage, onAddDisableModal, handleSubmit } = props;
  const { t: tCommon } = useTranslation();
  const { t: tOneWayIn } = useTranslation('oneWayIn');

  return (
    <>
      <TextArea
        label={tOneWayIn('details.Referral.Actor.TextAreaField.Required')}
        onChange={onAddCommentField}
        maxCount={1000}
        maxLength={1000}
        errorMsg={
          isVisibleTextAreaMessage ? tOneWayIn('details.Referral.Actor.TextAreaField.errorMessage') : ''
        }
      />
      <div className="display--flex gap--2">
        <Button onClick={handleSubmit}>{tCommon('send')}</Button>

        <Button theme="neutral" variant="outlined" onClick={onAddDisableModal}>
          {tCommon('cancel')}
        </Button>
      </div>
    </>
  );
};

export default ReferringModalForm;
