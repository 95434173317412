import { useState } from 'react';
import { useQuery } from 'react-query';

import { ConfigurationService } from 'src/api/v2';

import { applicationInsights } from 'src/utils/application-insights';

import { ENVIRONMENT_INFORMATION_KEY } from 'src/constants/hook-keys';

import { useUser } from 'src/features/authorization';

export default function useConfiguration() {
  const { user } = useUser();

  const [service] = useState(new ConfigurationService());

  const { data: environmentInformation, ...rest } = useQuery(
    [ENVIRONMENT_INFORMATION_KEY],
    () => service.getEnvironmentInformation(),
    {
      enabled: !!user,
      onSuccess(data) {
        // Preventing appInsights core from being initialised twice
        if (data.appInsightsInstrumentationKey && applicationInsights?.appInsights?.core == null) {
          applicationInsights.config.instrumentationKey = data.appInsightsInstrumentationKey;
          applicationInsights.loadAppInsights();
        }
      },
    },
  );

  return {
    environmentInformation: environmentInformation,

    ...rest,
  };
}
