import React from 'react';
import { formatDateToNOString } from '../../../../utils/FormatValue';
import { createColumnHelper } from '@tanstack/react-table';
import { SivaContractOverviewDto } from '../../../../api/v2';
import { MessageType, Tag } from '@in/component-library';
import { padNumericId } from '../utils';
import { Link } from 'react-router-dom';
import SivaPhaseTag from '../components/SivaPhaseTag';
import { ROUTES } from 'src/navigation';
import i18next from 'i18next';
import { toIsoDateString } from '../../../../utils/date';

const columnHelper = createColumnHelper<SivaContractOverviewDto>();

export const advisorColumnAccessor = (row: SivaContractOverviewDto) =>
  `${row.advisorFirstName ?? ''} ${row.advisorLastName ?? ''}`.trim() ||
  `(${i18next.t('common:notRegistered')})`;
export const advisorColumn = columnHelper.accessor(advisorColumnAccessor, {
  id: 'advisor',
});
export const numericIdColumn = columnHelper.accessor((row) => padNumericId(row.numericId), {
  id: 'numericId',
  maxSize: 100,
});
export const registrationDateColumn = columnHelper.accessor((row) => row.fromDate, {
  id: 'registrationDate',
  sortingFn: 'datetime',
  cell: (info) => `${formatDateToNOString(info.getValue(), false, '{date}.{month}.{year}', true)}`,
});
//Ja, dette er samme data som over. De vil bare ha et annet navn på den, og litt egen behandling.
//Dette er enkleste måte
export const endDateColumn = columnHelper.accessor((row) => row.fromDate, {
  id: 'sivaEndDate',
  sortingFn: 'datetime',
  cell: (info) => `${formatDateToNOString(info.getValue(), false, '{date}.{month}.{year}', true)}`,
});
export const phaseColumn = columnHelper.accessor((row) => row.phase, {
  id: 'phase',
  cell: (info) => <SivaPhaseTag phase={info.getValue()} />,
});
export const admissionDateColumn = columnHelper.accessor((row) => toIsoDateString(row.admissionDate), {
  id: 'admissionDate',
  maxSize: 150,
  cell: (info) => {
    const admissionDate = info.getValue();
    if (!admissionDate) return '';
    const [year, month, day] = admissionDate.split('-');
    return `${day}.${month}.${year}`;
  },
});
// TODO funksjonaliteten er ikke ferdigstilt
export const sentInColumn = columnHelper.accessor(() => 'Ikke ferdigstilt', {
  header: 'Innsending for 2024',
  cell: (info) => <Tag type={MessageType.Error}>{info.getValue()}</Tag>,
});

export const ideaNameColumn = columnHelper.accessor((row) => row.name || row.companyName, {
  id: 'name',
  cell: (info) => <ContractLink numericId={info.row.original.numericId} text={info.getValue()} />,
});
export const companyNameOrIdeaNameColumn = columnHelper.accessor((row) => row.companyName ?? row.name, {
  id: 'name',
  cell: (info) => <ContractLink numericId={info.row.original.numericId} text={info.getValue()} />,
});
const ContractLink: React.FC<{
  numericId: number | string;
  text: React.ReactNode;
}> = ({ numericId, text }) => {
  const url = ROUTES.SIVA.IDEAS_AND_COMPANIES.COMPANY_DETAILS.INDEX(`${numericId}`);

  return <Link to={url}>{text}</Link>;
};
