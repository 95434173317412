import { MultiSelectListBox, CheckboxOptionProps, Link, FaIcon, FaIcons } from '@in/component-library';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useCluster } from 'src/features/cluster';
import useOneWayInServicesByCluster from '../../hooks/use-one-way-in-services-by-cluster';

type Props = {
  onChange: (selectedIds: string[]) => any;
  hideLabel?: boolean;
  optional?: boolean;
};

const RegisterServiceSelect: React.FC<Props> = ({ onChange, hideLabel = false, optional = false }) => {
  const { t: tCommon } = useTranslation();
  const { t: tOneWayIn } = useTranslation('oneWayIn');
  const { cluster } = useCluster();

  const { servicesById, servicesByIdQuery } = useOneWayInServicesByCluster(cluster!.id);
  const [selected, setSelected] = React.useState<CheckboxOptionProps[]>([] as CheckboxOptionProps[]);

  const options = React.useMemo(() => {
    return servicesById.map(
      (service) =>
        ({
          value: service.id,
          text: service.name,
          selected: selected.some((selection) => selection.value === service.id),
        }) as CheckboxOptionProps,
    );
  }, [servicesById, selected]);

  return (
    <div>
      {!hideLabel && (
        <label htmlFor="register-service-multi-select">
          <b>{`${tOneWayIn('services.register.deliveredService')}${
            optional ? ` (${tCommon('optional')})` : ''
          }`}</b>
        </label>
      )}
      <div>{tOneWayIn('services.register.deliveredServiceInfobox')}</div>
      <div className="margin-top--2 margin-bottom--4">
        <Link href="https://tjenester.enveiinn.no/" target="_blank">
          <u>
            {tOneWayIn('services.register.goToCommunityCatalogue')}{' '}
            <FaIcon name={FaIcons.ExternalLinkLight} />
          </u>
        </Link>
      </div>
      {!servicesByIdQuery.isLoading && options.length === 0 && (
        <div className="margin-top--4 margin-bottom--4">
          {tOneWayIn('services.register.noRegisteredServices')}
        </div>
      )}
      {!servicesByIdQuery.isLoading && options.length > 0 && (
        <MultiSelectListBox
          label={`${tOneWayIn('services.register.deliveredService')}${
            optional ? ` (${tCommon('optional')})` : ''
          })`}
          hideLabel
          name="register-service-multi-select"
          onChange={(selected) => {
            setSelected(selected);
            onChange(selected.map((selection) => selection.value));
          }}
          options={options}
          value={selected}
          placeHolderFilterInput={tCommon('search')}
          placeHolder={tCommon('select')}
        />
      )}
    </div>
  );
};

export default RegisterServiceSelect;
