import React from 'react';
import DataTableV3 from 'src/components/DataTableV3';
import { DataTableV3Ids } from 'src/constants/dataTableIds';
import { useTranslation } from 'react-i18next';
import { LoadingPage } from 'src/components/LoadingPage/LoadingPage';
import { contractsTableColumns } from '../constants/contracts-table-columns';
import { useParams } from 'react-router-dom';
import { PageParams } from '../types/PageParams';
import useCompanyContract from '../hooks/use-company-contract';

const Contracts: React.FC = () => {
  const { contractId } = useParams<PageParams>();
  const { t: tCommon } = useTranslation();
  const { t: tTableTitles } = useTranslation('tableTitles');

  const id =
    contractId !== undefined && !Number.isNaN(parseInt(contractId)) ? parseInt(contractId) : undefined;

  const { companyContracts, isLoading } = useCompanyContract(id);

  if (isLoading) {
    return <LoadingPage />;
  }

  return (
    <div>
      <DataTableV3
        id={DataTableV3Ids.Siva.IdeasAndCompanies.CompanyDetails.Contract}
        disableSelection={true}
        disableGlobalFilter={true}
        data={companyContracts || []}
        columns={contractsTableColumns}
        translationFn={(key: string) => tTableTitles(key as any)}
        texts={{
          globalFilterPlaceholder: tCommon('search'),
          rowsPerPage: tCommon('rowsPerPage'),
          of: tCommon('of'),
        }}
        pageSizeOptions={[5, 10, 25, 50, 100]}
        disableColumnEditor
        disableExport
        disablePagination
      />
    </div>
  );
};

export default Contracts;
