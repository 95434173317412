import { lazy } from 'react';

export { default as routes } from './routes';

export {
  REPORTS_ROUTE_BASE_HALF_YEAR,
  REPORTS_ROUTE_BASE_FINAL,
  REPORTS_ROUTE_BASE_ANNUAL,
  REPORTS_ROUTE_BASE_ARCHIVE,
  REPORTS_ROUTE_RELATIVE_DRAFT,
  REPORTS_ROUTE_BASE,
  getReportDraftNavigationRoute,
  getAnnualReportStatisticsNavigationRoute,
} from './routes';

export const ReportsFeature = lazy(() => import('./pages/Reports'));
