import React from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { formatAsCurrencyString } from 'src/utils/FormatValue';
import BillSupportNumberHandler from '../components/BillSupportNumberHandler';
import { SivaEsaReportingDto } from 'src/api/v2';
import { calculateInnovationSupport, calculateSupportIntensity } from '../utils/calculations';

const columnHelper = createColumnHelper<SivaEsaReportingDto>();

export const esaTableColumns = [
  columnHelper.accessor((row) => row.invoiceNumber, {
    id: 'billNumber',
    cell: (info) => <span>{info.getValue()}</span>,
    enableSorting: false,
  }),
  columnHelper.accessor((row) => row.sivaPhase, {
    id: 'phase',
    cell: (info) => <span>{info.getValue()}</span>,
    enableSorting: false,
  }),
  columnHelper.accessor((row) => row.marketValue, {
    id: 'marketValue',
    cell: (info) => <span>{formatAsCurrencyString(info.getValue(), ' ', undefined, 0)}</span>,
    enableSorting: false,
  }),
  columnHelper.accessor((row) => row.invoicedDeductible, {
    id: 'deductible',
    cell: (info) => <span>{formatAsCurrencyString(info.getValue(), ' ', undefined, 0)}</span>,
    enableSorting: false,
  }),
  columnHelper.accessor((row) => calculateInnovationSupport(row.marketValue, row.invoicedDeductible), {
    id: 'innovationSupport',
    cell: (info) => {
      const value = info.getValue();

      return (
        <div className="text-align--right">
          <BillSupportNumberHandler
            value={value}
            formattedValue={formatAsCurrencyString(info.getValue(), ' ', undefined, 0)}
          />
        </div>
      );
    },
    enableSorting: false,
  }),
  columnHelper.accessor((row) => calculateSupportIntensity(row.marketValue, row.invoicedDeductible), {
    id: 'supportIntensity',
    cell: (info) => {
      const value = info.getValue();

      return (
        <div className="text-align--right">
          <BillSupportNumberHandler value={value} formattedValue={`${value} %`} />
        </div>
      );
    },
    enableSorting: false,
  }),
];
