import { useState } from 'react';
import { useQuery } from 'react-query';

import { OperationApplicationsService } from 'src/api/v2';

import {
  ACTIVE_OPERATION_APPLICATION_KEY,
  OPERATION_APPLICATION_STATISTICS_KEY,
} from 'src/constants/hook-keys';
import { useAuth } from 'src/features/authorization';

import useCluster from '../features/cluster/hooks/use-cluster';

export default function useActiveOperationApplication() {
  const { cluster } = useCluster();

  const { checkAccess } = useAuth();
  const { read } = checkAccess('Operation');

  const [service] = useState(new OperationApplicationsService());

  const { data, ...rest } = useQuery(
    [ACTIVE_OPERATION_APPLICATION_KEY],
    () => service.getActiveOperationApplicationForCluster(cluster!.id),
    {
      enabled: !!cluster && read,
      retry: false,
    },
  );
  const statistics = useQuery(
    [OPERATION_APPLICATION_STATISTICS_KEY],
    () => service.getOperationApplicationStatisticsV2(cluster!.id),
    {
      enabled: !!cluster && read,
      retry: false,
    },
  );

  return {
    operationApplication: data,
    statistics: statistics.data,

    ...rest,
  };
}
