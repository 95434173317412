import React, { useMemo } from 'react';

import { DateInput } from '@in/component-library';
import useUpdateQueryStringParam from 'src/features/one-way-in/opportunity-table/hooks/use-update-query-string-param';

type FilterProps = {
  queryStringParam: string;
  label: string;
};

const FilterDateInput = ({ queryStringParam, label }: FilterProps) => {
  const { searchParams, updateQueryStringParam } = useUpdateQueryStringParam();

  const { date } = useMemo(() => {
    return {
      date: searchParams.get(queryStringParam) ?? '',
    };
  }, [queryStringParam, searchParams]);

  function handleChangeDate(e: React.ChangeEvent<HTMLInputElement>) {
    updateQueryStringParam(queryStringParam, e.target.value, 'single');
  }

  return (
    <>
      <DateInput
        label={label}
        name={queryStringParam}
        hideError
        className={`margin-bottom--0`}
        value={date}
        onChange={handleChangeDate}
      />
    </>
  );
};

export default FilterDateInput;
