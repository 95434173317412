import { FaIcon, FaIcons } from '@in/component-library';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CustomerInfoDto } from 'src/api/v2';
import { formatOrganizationNumber } from 'src/utils/format';
import { printCustomerInfoDtoAddress } from '../../utils';

type Props = {
  company: CustomerInfoDto | undefined;
  hideName?: boolean;
};

const CompanyBaseInfoBox: React.FC<Props> = ({ company: company, hideName }) => {
  const { t: tSiva } = useTranslation('siva');

  if (!company) {
    return null;
  }

  const address = printCustomerInfoDtoAddress(company) ?? tSiva('newCompany.baseInfoBox.noAddress');

  return (
    <div className="margin-bottom--4">
      {!hideName && (
        <p className="margin-bottom--0">
          <strong>{company.name}</strong>
        </p>
      )}

      <dl className="margin-top--2">
        <dt className="sr-only"></dt>
        <dd className="margin-left--0 margin-bottom--1">
          <FaIcon name={FaIcons.BuildingLight} className="margin-right--2 color--in-black-50" />
          {tSiva('newCompany.baseInfoBox.orgNo')} {formatOrganizationNumber(company.publicId)}
        </dd>
        <dt className="sr-only"></dt>
        <dd className="margin-left--0 margin-bottom--1">
          <FaIcon name={FaIcons.MapMarkerAltLight} className="margin-right--2 color--in-black-50" />
          {tSiva('newCompany.baseInfoBox.address')} {address}
        </dd>
      </dl>
    </div>
  );
};

export default CompanyBaseInfoBox;
