import React, { FC, PropsWithChildren, useState } from 'react';
import css from './TextCollapse.module.scss';
export interface TextCollapseProps {
  collapseText: string;
  ariaLabel: string;
  children: React.ReactNode;
}

const TextCollapse: FC<TextCollapseProps> = ({
  children,
  collapseText,
  ariaLabel,
}: PropsWithChildren<TextCollapseProps>) => {
  const [show, setShow] = useState<boolean>(false);

  return (
    <div className={css['text-collapse']}>
      <button
        aria-expanded={show}
        aria-label={ariaLabel}
        type="button"
        onClick={() => {
          setShow(!show);
        }}
      >
        {collapseText}
      </button>

      {show && <div>{children}</div>}
    </div>
  );
};

export default TextCollapse;
