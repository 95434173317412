import { createColumnHelper } from '@tanstack/react-table';
import React from 'react';
import { SivaBudgetAttachmentType, SivaBudgetDetailsDto } from 'src/api/v2';
import SivaDeliveredIcon from '../../components/SivaDeliveredIcon';
import BudgetDownloadButton from '../components/BudgetDownloadButton';
import BudgetStatusTag from '../components/BudgetStatusTag';
import { getLatestSivaBudgetVersion } from '../utils/siva-budget';

const columnHelper = createColumnHelper<SivaBudgetDetailsDto>();

export const budgetYearColumns = [
  columnHelper.accessor((row) => row.clusterStatusName, {
    id: 'type',
    cell: (info) => (<span>{info.getValue()}</span>) as React.ReactNode,
    size: 74,
  }),
  columnHelper.accessor((row) => row.clusterName, {
    id: 'name',
    cell: (info) => <span>{info.getValue()}</span>,
    size: 390,
  }),
  columnHelper.accessor('sivaBudgetAttachments', {
    id: 'budget',
    cell: (info) => {
      const attachments = info.getValue();
      if (attachments.length === 0) {
        return <span></span>;
      }

      const budgetId = getLatestSivaBudgetVersion(info.row.original, SivaBudgetAttachmentType.Budget)?.id;
      if (!budgetId) {
        return <span></span>;
      }

      return <BudgetDownloadButton budgetId={budgetId} />;
    },
    size: 105,
    enableSorting: false,
  }),
  columnHelper.display({
    id: 'delivered',
    cell: ({ row: { original } }) => {
      const hasBudget = getLatestSivaBudgetVersion(original, SivaBudgetAttachmentType.Budget) !== undefined;

      return <SivaDeliveredIcon isSubmitted={hasBudget} />;
    },
    size: 88,
  }),
  columnHelper.accessor((row) => row.budgetStatus, {
    id: 'status',
    size: 150,
    cell: (info) => {
      const value = info.getValue();
      if (value) {
        return <BudgetStatusTag status={value} />;
      }

      return null;
    },
  }),
  columnHelper.accessor('sivaBudgetAttachments', {
    id: 'accountantConfirmation',
    header: 'Revisorbekreftelse',
    cell: (info) => {
      const attachments = info.getValue();
      if (attachments.length === 0) {
        return <span></span>;
      }

      const budgetId = getLatestSivaBudgetVersion(
        info.row.original,
        SivaBudgetAttachmentType.AuditorConfirmation,
      )?.id;

      if (!budgetId) {
        return <span></span>;
      }

      return <BudgetDownloadButton budgetId={budgetId} />;
    },
    size: 105,
    enableSorting: false,
  }),
  columnHelper.display({
    id: 'accountantDelivered',
    cell: ({ row: { original } }) => {
      const hasBudget =
        getLatestSivaBudgetVersion(original, SivaBudgetAttachmentType.AuditorConfirmation) !== undefined;

      return <SivaDeliveredIcon isSubmitted={hasBudget} />;
    },
    size: 88,
  }),
];
