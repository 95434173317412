import { useTranslation } from 'react-i18next';

const useDocumentTitle = (...pageTitles: string[]) => {
  const { t: tPageTitles } = useTranslation('pageTitles');

  const documentTitle = () => {
    const portalName = tPageTitles('portal');
    if (pageTitles.length > 0) {
      // Filter away zero values and join
      const pageTitlesJoined = pageTitles.filter((x) => x).join(' - ');

      return `${pageTitlesJoined} - ${portalName}`;
    }

    return portalName;
  };

  return {
    documentTitle: documentTitle(),
  };
};

export default useDocumentTitle;
