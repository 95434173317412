import { RadioButton, TextArea } from '@in/component-library';
import React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  setActorNote: (text: string) => void;
  setCustomerNote: (text: string) => void;
  sendCustomerEmail: (value: boolean) => void;
  showCustomerEmail: boolean;
  canSendToCustomer: boolean;
};

const RecommendServiceNoteCreation: React.FC<Props> = ({
  setActorNote,
  setCustomerNote,
  sendCustomerEmail,
  showCustomerEmail,
  canSendToCustomer,
}) => {
  const { t: tCommon } = useTranslation();
  const { t: tOneWayIn } = useTranslation('oneWayIn');
  return (
    <>
      <div className="margin-top--2">
        <TextArea
          label={tOneWayIn('services.recommend.note.noteToActor')}
          description={tOneWayIn('services.recommend.note.noteToActorDescription')}
          maxLength={500}
          maxCount={500}
          onChange={(e) => setActorNote(e.target.value)}
        />
      </div>

      <div className="margin-top--5 margin-bottom--5">
        {!canSendToCustomer && (
          <>
            <div>{tOneWayIn('services.recommend.note.informCustomer')}</div>
            <div className="maring-top--1">
              {tOneWayIn('services.recommend.note.cannotSendToCustomerWarning')}
            </div>
          </>
        )}
        {canSendToCustomer && (
          <>
            <label htmlFor="displayCustomerNote" className="form-element__label margin-bottom--2">
              {tOneWayIn('services.recommend.note.informCustomer')}
            </label>
            <div className="margin-left--2">
              <RadioButton
                name="displayCustomerNote"
                onChange={(e) => {
                  const value = (e.target as HTMLInputElement).value;
                  const displayNote = value === 'true';
                  sendCustomerEmail(displayNote);
                }}
                options={[
                  {
                    text: tCommon('doNotSendEmail'),
                    value: 'false',
                    selected: showCustomerEmail === false,
                  },
                  {
                    text: tCommon('sendEmail'),
                    value: 'true',
                    selected: showCustomerEmail,
                  },
                ]}
              />
              {showCustomerEmail && (
                <div className="margin-left--3">
                  <TextArea
                    label={tOneWayIn('services.recommend.note.noteToCustomer')}
                    description={tOneWayIn('services.recommend.note.noteToCustomerDescription')}
                    maxCount={500}
                    maxLength={500}
                    onChange={(e) => setCustomerNote(e.target.value)}
                  />
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default RecommendServiceNoteCreation;
