import { useState } from 'react';
import { SivaPhaseService } from 'src/api/v2';
import { useQuery } from 'react-query';
import { SIVA_CONTRACT_AVAILABLE_PHASES_KEY, SIVA_CONTRACT_PHASE_KEY } from 'src/constants/hook-keys';

const useContractPhase = (contractId?: number | null) => {
  const [sivaPhaseService] = useState(() => new SivaPhaseService());

  const phaseQuery = useQuery({
    queryFn: () => sivaPhaseService.getPhases(),
    queryKey: [SIVA_CONTRACT_PHASE_KEY],
    retry: false,
  });

  const contractAvailablePhases = useQuery({
    queryFn: () => sivaPhaseService.getAvailablePhasesForContract(contractId!),
    queryKey: [SIVA_CONTRACT_AVAILABLE_PHASES_KEY, contractId],
    retry: false,
    enabled: !!contractId,
  });

  return {
    phaseQuery,
    contractAvailablePhases,
  };
};

export default useContractPhase;
