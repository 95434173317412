import React from 'react';
import ActionPageDescription from './ActionPageDescription';
import { FaIcon } from '@in/component-library';
import { icon } from '@fortawesome/fontawesome-svg-core';
import { IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from 'react-i18next';

const CommunicationBetweenActors: React.FC = () => {
  const { t: tCommon } = useTranslation();
  const { t: tOneWayIn } = useTranslation('oneWayIn');
  const { t: tHelpAndSupport } = useTranslation('onboarding');

  return (
    <>
      <h3 className="margin-bottom--6">
        {tHelpAndSupport('commonQuestions.Interplay.CommunicationBetweenActors')}
      </h3>
      <div className="display--flex flex-direction--column gap--6">
        <ActionPageDescription
          icon={<FaIcon icon={icon({ prefix: 'fal', iconName: 'paper-plane' }) as IconDefinition} />}
          iconName={tOneWayIn('details.Title.Referrer')}
          description={tHelpAndSupport('commonQuestions.CommunicationBetweenActors.ReferrerDescription')}
        />
        <ActionPageDescription
          icon={<FaIcon icon={icon({ prefix: 'fal', iconName: 'note-sticky' }) as IconDefinition} />}
          iconName={tCommon('note')}
          description={tHelpAndSupport('commonQuestions.CommunicationBetweenActors.NoteDescription')}
        />
        <ActionPageDescription
          icon={<FaIcon icon={icon({ prefix: 'fal', iconName: 'list-timeline' }) as IconDefinition} />}
          iconName={tOneWayIn('details.Title.ActivityLog')}
          description={tHelpAndSupport('commonQuestions.CommunicationBetweenActors.ActivityLogDescription')}
        />
        <ActionPageDescription
          icon={<FaIcon icon={icon({ prefix: 'fal', iconName: 'box-archive' }) as IconDefinition} />}
          iconName={tOneWayIn('details.Title.Archiving')}
          description={tHelpAndSupport('commonQuestions.CommunicationBetweenActors.ArchivingDescription')}
        />
      </div>
    </>
  );
};

export default CommunicationBetweenActors;
