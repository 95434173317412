import { useState } from 'react';
import { useQuery } from 'react-query';
import { ExternalService } from 'src/api/v2';
import { EXTERNAL_COMPANY_KEY } from 'src/constants/hook-keys';

export function useExternalCompany(organizationNumber: string) {
  const [service] = useState(() => new ExternalService());

  return useQuery({
    queryKey: [EXTERNAL_COMPANY_KEY, organizationNumber],
    queryFn: async () => await service.getCompanyFromBrreg(organizationNumber),
    enabled: !!organizationNumber && organizationNumber.length === 9,
  });
}
