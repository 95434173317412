import { FaIcon, FaIcons, Toast } from '@in/component-library';
import React from 'react';
import { icon } from '@fortawesome/fontawesome-svg-core';
import { IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from 'react-i18next';

type Props = {
  archiveReferral: boolean;
  archivalError: boolean;
};

const ReferralSent: React.FC<Props> = ({ archiveReferral, archivalError }) => {
  const { t: tOneWayIn } = useTranslation('oneWayIn');
  interface ActionDescriptionProps {
    description: string;
    iconDefinition: { prefix: IconDefinition['prefix']; iconName: string };
  }

  const ActionDescription = ({ description, iconDefinition }: ActionDescriptionProps) => {
    return (
      <div>
        <div className="icon-bubble">
          <FaIcon icon={icon(iconDefinition as IconDefinition)} />
        </div>
        <p className="margin-top--2 margin-bottom--0">{description}</p>
      </div>
    );
  };

  return (
    <>
      <h2 className="margin-bottom--6">
        {archiveReferral
          ? tOneWayIn('referral.referralSent.referralSentAndArchived')
          : tOneWayIn('referral.referralSent.referralSent')}
      </h2>
      <div className="display--flex flex-direction--column gap--6">
        <ActionDescription
          description={tOneWayIn('referral.referralSent.sentDescription')}
          iconDefinition={{ prefix: 'fal', iconName: 'paper-plane' }}
        />
        {!archiveReferral && (
          <ActionDescription
            description={tOneWayIn('referral.referralSent.archiveDescription')}
            iconDefinition={{ prefix: 'fal', iconName: 'box-archive' }}
          />
        )}
        <ActionDescription
          description={tOneWayIn('referral.referralSent.activityLogDescription')}
          iconDefinition={{ prefix: 'fal', iconName: 'list-timeline' }}
        />
        <Toast
          className="toast--error"
          visible={archiveReferral && archivalError}
          icon={<FaIcon className="color--red" name={FaIcons.InfoCircle} />}
          header={tOneWayIn('referral.referralSent.archivalError.header')}
          position="top-right"
          text={tOneWayIn('referral.error.text')}
        />
      </div>
    </>
  );
};

export default ReferralSent;
