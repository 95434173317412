import React from 'react';
import { Navigate, RouteObject } from 'react-router-dom';
import NotFoundPage from 'src/components/NotFoundPage/NotFoundPage';
import { SivaReportingWrapperPage } from './reporting';
import { IDEAS_AND_COMPANIES_ROUTES } from './ideas-and-companies';
import SivaContractsFeature from './ideas-and-companies/pages/SivaContractsFeature';

const IDEAS_AND_COMPANIES_BASE_ROUTE = 'ideas-and-companies';

export const IDEAS_AND_COMPANIES = IDEAS_AND_COMPANIES_ROUTES(`/siva/${IDEAS_AND_COMPANIES_BASE_ROUTE}`);

const routes: RouteObject[] = [
  {
    path: '',
    index: true,
    element: (<Navigate to={'reporting'} replace />) as React.ReactNode,
  },
  {
    path: `reporting/*`,
    element: <SivaReportingWrapperPage />,
  },
  {
    path: `${IDEAS_AND_COMPANIES_BASE_ROUTE}/*`,
    element: <SivaContractsFeature />,
  },
  {
    path: '*',
    element: <NotFoundPage />,
  },
];

export default routes;
