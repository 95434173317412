import { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { EventUpdateDtoV2, EventsVService, EventCreateDtoV2 } from 'src/api/v2';
import useCluster from '../features/cluster/hooks/use-cluster';
import { EVENTS_KEY, EVENT_DETAILS_KEY, EVENT_KEY } from 'src/constants/hook-keys';
import { useTranslation } from 'react-i18next';

export default function useEvent(eventId?: string) {
  const { t: tError } = useTranslation('error');

  const { cluster } = useCluster();

  const queryClient = useQueryClient();

  const [service] = useState(new EventsVService());

  const { data, ...rest } = useQuery([EVENT_KEY, eventId], () => service.getEvent(eventId!), {
    enabled: !!cluster && !!eventId,
  });

  const createMutation = useMutation(
    (record: EventCreateDtoV2) => {
      return service.postEvent(record);
    },
    {
      onSuccess() {
        queryClient.resetQueries([EVENTS_KEY]);
      },
    },
  );

  const updateMutation = useMutation(
    ({ eventId, record }: { eventId: string; record: EventUpdateDtoV2 }) => {
      if (!eventId) {
        throw new Error(tError('undefinedProperty', { property: 'eventId' }));
      }
      return service.putEvent(eventId, record);
    },
    {
      onSuccess() {
        queryClient.invalidateQueries([EVENT_KEY, eventId]);
        queryClient.invalidateQueries([EVENTS_KEY]);
        queryClient.resetQueries([EVENT_DETAILS_KEY, eventId]);
      },
    },
  );

  const deleteMutation = useMutation(
    (eventId: string) => {
      return service.deleteEvent(eventId);
    },
    {
      onSuccess() {
        queryClient.resetQueries([EVENTS_KEY]);
      },
    },
  );

  return {
    event: data,

    ...rest,
    createMutation,
    updateMutation,
    deleteMutation,
  };
}
