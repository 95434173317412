import {
  Button,
  ButtonVariant,
  ModalActionButtonContainer,
  Notification,
  Stepper,
} from '@in/component-library';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useOneWayInServices from '../../hooks/use-one-way-in-services';
import '../../styles/OneWayInStyle.scss';

import {
  CreateSharedStatusDto,
  OneWayInOpportunityStatusCreateDto,
  OneWayInRecommendation,
  OneWayInStatus,
  ServiceRecommendationDtoV2,
} from 'src/api/v2';
import { useUser } from 'src/features/authorization';
import { useCluster } from 'src/features/cluster';
import { toastPromise } from 'src/utils/toast';
import useOneWayIn from '../../hooks/use-one-way-in';
import useOneWayInServiceProviders from '../../hooks/use-one-way-in-service-providers';
import PStepperContent from '../p-stepper/PStepperContent';
import PStepperNavigationContainer from '../p-stepper/PStepperNavigationContainer';
import RecommendServiceList from './RecommendServiceList';
import RecommendServiceMailActor from './RecommendServiceMailActor';
import RecommendServiceMailCustomer from './RecommendServiceMailCustomer';
import RecommendServiceMailWrapper from './RecommendServiceMailWrapper';
import RecommendServiceNoteCreation from './RecommendServiceNoteCreation';

type Props = {
  opportunityId: string | undefined;
  updating: boolean;
  deativateModal: () => void;
};

const OneWayInRecommendService: React.FC<Props> = ({ opportunityId, updating, deativateModal }) => {
  const { cluster } = useCluster();
  const { displayName, user, isAadUser } = useUser();
  const { allServices } = useOneWayInServices();
  const { serviceProviders } = useOneWayInServiceProviders();
  const { oneWayInOpportunityFormQuery, recommendService } = useOneWayIn(opportunityId);

  const { t: tCommon } = useTranslation();
  const { t: tOneWayIn } = useTranslation('oneWayIn');

  const [selectedServiceIds, setSelectedServicesIds] = useState<string[]>([]);
  const [stepIndex, setStepIndex] = useState<number>(0);
  const [sendCustomerEmail, setSendCustomerEmail] = useState<boolean>(false);
  const [customerNote, setCustomerNote] = useState<string>('');
  const [actorNote, setActorNote] = useState<string>('');
  const [displayResultPage, setDisplayResultPage] = useState<boolean>(false);
  const [displayNoServicesSelectedWarning, setDisplayNoServicesSelectedWarning] = useState<boolean>(false);

  const stepTitles = [
    tOneWayIn('services.recommend.steps.choose'),
    tOneWayIn('services.recommend.steps.writeNote'),
    tOneWayIn('services.recommend.steps.preview'),
  ];

  const onRecommend = () => {
    const services = allServices.filter((x) => selectedServiceIds.some((selected) => selected === x.id));

    const opportunityStatusUpdate = {
      oneWayInOpportunityId: oneWayInOpportunityFormQuery.data?.opportunity?.id,
      referenceClusterId: undefined,
      status: OneWayInStatus.Active,
      description: actorNote,
      createdOn: new Date(),
      closedReason: undefined,
      recommendedServices: selectedServiceIds || [],
      registeredServices: [],
      clusterContactId: undefined,
      clusterUserId: isAadUser ? undefined : user?.clusterUserId,
      handlersName: displayName || undefined,
    } as OneWayInOpportunityStatusCreateDto;

    const serviceRecommendation = {
      recipientEmail: oneWayInOpportunityFormQuery.data?.form.kontakt?.epost,
      recipientName: oneWayInOpportunityFormQuery.data?.form.kontakt?.navn,
      services: services.map((service) => ({
        serviceName: service.name,
        links: service.externalLinks.map((link) => link.url),
      })),
      comment: customerNote,
      senderName: displayName,
      senderOrganisation: cluster?.name || '',
      sendToActor: true,
      sendToCustomer: sendCustomerEmail && !!oneWayInOpportunityFormQuery.data?.form.kontakt, // Contact must exist for us to send to them
    } as ServiceRecommendationDtoV2;

    const sharedActors =
      services.filter((service) => serviceProviders?.some((x) => x.id === service.clusterId)) || [];

    const sharedStatusDto = {
      receivingClusterIds: sharedActors.map((x) => x.clusterId) || [],
      description: actorNote,
    } as CreateSharedStatusDto;

    const promise = recommendService.mutateAsync({
      opportunityStatusCreate: opportunityStatusUpdate,
      serviceRecommendation: serviceRecommendation,
      sharedStatusCreate: sharedStatusDto,
    } as OneWayInRecommendation);

    toastPromise(promise, {
      pending: tOneWayIn('recommendToast.Pending'),
      success: tOneWayIn('recommendToast.Success'),
      error: tOneWayIn('recommendToast.Error'),
    }).then(() => setDisplayResultPage(true));
  };

  const handleOnChange = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      const { checked, value } = e.currentTarget;
      const index = selectedServiceIds.indexOf(value);
      if (checked) {
        if (index === -1) {
          setSelectedServicesIds([...selectedServiceIds, value]);
        }
      } else {
        if (value) {
          setSelectedServicesIds(selectedServiceIds.filter((id) => id !== value));
        }
      }
    },
    [selectedServiceIds],
  );

  const recommendedServices = React.useMemo(() => {
    const services = allServices.filter((x) => selectedServiceIds.some((selected) => selected === x.id));

    return services.map((service) => ({
      id: service.id,
      name: service.name,
      owner: service.businessName,
      links: service.externalLinks,
    }));
  }, [allServices, selectedServiceIds]);

  if (displayResultPage) {
    return (
      <>
        <p>
          <b>{tOneWayIn('services.recommend.result.header')}</b>
        </p>
        <p>{tOneWayIn('services.recommend.result.text1')}</p>
        <p>{tOneWayIn('services.recommend.result.text2')}</p>
        <ModalActionButtonContainer>
          <Button onClick={deativateModal} theme="neutral" variant="outlined">
            {tCommon('close')}
          </Button>
        </ModalActionButtonContainer>
      </>
    );
  }

  return (
    <>
      <Stepper currentStep={stepIndex} stepCount={3} stepTitles={stepTitles} className="margin-left--0" />
      <div className="margin-bottom--1">{stepIndex < stepTitles.length ? stepTitles[stepIndex] : ''}</div>
      {stepIndex === 0 && (
        <div className="margin-bottom--1">{tOneWayIn('services.recommend.steps.chooseExplanation')}</div>
      )}
      <PStepperContent hidden={stepIndex !== 0}>
        <RecommendServiceList
          selectedChange={handleOnChange}
          selectedIds={selectedServiceIds}
          updating={updating}
        />
        {displayNoServicesSelectedWarning && (
          <Notification type="error" fullWidth>
            {tOneWayIn('services.recommend.error.chooseOneService')}
          </Notification>
        )}
      </PStepperContent>
      <PStepperContent hidden={stepIndex !== 1}>
        <RecommendServiceNoteCreation
          setActorNote={setActorNote}
          setCustomerNote={setCustomerNote}
          sendCustomerEmail={setSendCustomerEmail}
          showCustomerEmail={sendCustomerEmail}
          canSendToCustomer={!!oneWayInOpportunityFormQuery.data?.form.kontakt}
        />
      </PStepperContent>
      <PStepperContent hidden={stepIndex !== 2}>
        {sendCustomerEmail && (
          <RecommendServiceMailWrapper
            title={tOneWayIn('services.recommend.mailpage.customer.title')}
            description={tOneWayIn('services.recommend.mailpage.customer.description')}
            open={false}
          >
            <RecommendServiceMailCustomer
              customerName={oneWayInOpportunityFormQuery.data?.form.kontakt?.navn || ''}
              note={customerNote}
              recommendedServices={recommendedServices}
              userName={displayName || ''}
              actorName={cluster?.name || ''}
            />
          </RecommendServiceMailWrapper>
        )}
        <RecommendServiceMailWrapper
          title={tOneWayIn('services.recommend.mailpage.actor.title')}
          description={tOneWayIn('services.recommend.mailpage.actor.description')}
          open={false}
        >
          <RecommendServiceMailActor actorName={cluster?.name || ''} />
        </RecommendServiceMailWrapper>
      </PStepperContent>
      <div className="margin-top--6 margin-bottom--3">
        <PStepperNavigationContainer>
          {stepIndex !== 0 ? (
            <Button
              disabled={stepIndex === 0}
              variant={ButtonVariant.Link}
              onClick={() => {
                setStepIndex((prevState) => prevState - 1);
              }}
            >
              {tCommon('previous')}
            </Button>
          ) : (
            <div></div>
          )}

          {stepIndex < 2 && (
            <Button
              disabled={stepIndex === 2}
              onClick={() => {
                if (selectedServiceIds.length === 0) {
                  setDisplayNoServicesSelectedWarning(true);
                  return;
                }
                setDisplayNoServicesSelectedWarning(false);
                setStepIndex((prevState) => (prevState >= 2 ? prevState : prevState + 1));
              }}
            >
              {tCommon('next')}
            </Button>
          )}
        </PStepperNavigationContainer>
      </div>
      {(stepIndex === 2 || stepIndex === 3) && (
        <div className="display--flex">
          <Button disabled={setSelectedServicesIds.length === 0 || updating} onClick={onRecommend}>
            {tCommon('recommend')}
          </Button>
          <div className="margin-left--1">
            <Button onClick={deativateModal} theme="neutral" variant="outlined">
              {tCommon('close')}
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default OneWayInRecommendService;
