import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRoutes } from 'react-router-dom';
import DocumentTitle from 'src/components/DocumentTitle/DocumentTitle';
import { LoadingPage } from 'src/components/LoadingPage/LoadingPage';
import NoAccess from 'src/components/NoAccess/NoAccess';
import { useAuth } from 'src/features/authorization';
import useDocumentTitle from 'src/hooks/use-document-title';
import routes from '../routes';

const SivaContractsFeature: React.FC = () => {
  const element = useRoutes(routes);
  const { t: tPageTitles } = useTranslation('pageTitles');
  const { documentTitle } = useDocumentTitle(tPageTitles('ideasAndCompanies'));

  const { checkAccess } = useAuth();

  if (!checkAccess('SivaCore').read) {
    return <NoAccess />;
  }

  return (
    <>
      <DocumentTitle title={documentTitle}>
        <React.Suspense fallback={<LoadingPage />}>{element}</React.Suspense>
      </DocumentTitle>
    </>
  );
};

export default SivaContractsFeature;
