import {
  CellValue,
  CellHyperlinkValue,
  CellErrorValue,
  CellFormulaValue,
  CellRichTextValue,
  Column,
  DataValidation,
  Workbook,
  Worksheet,
  CellSharedFormulaValue,
  RichText,
} from 'exceljs';
import { saveAs } from 'file-saver';
import { getSafeDateOnly, parseDateString } from './date';

type PartialRecord<K extends keyof any, T> = {
  [P in K]?: T;
};
type OmittedColumn = Omit<Column, 'key' | 'header'>;
type ExcelColumn = Partial<OmittedColumn> & {
  header: string;
  validation?: DataValidation;
} & (
    | {
        isEmail?: boolean;
        isDate?: never;
        isNumeric?: never;
      }
    | {
        isEmail?: never;
        isDate?: boolean;
        isNumeric?: never;
      }
    | {
        isEmail?: never;
        isDate?: never;
        isNumeric?: boolean;
      }
  );

export type ColumnSettings<T extends object> = PartialRecord<keyof T, ExcelColumn>;

export class ExcelService<T extends object> {
  private readonly MAX_ROWS = 1024;

  private settings: ColumnSettings<T>;
  private workbook: Workbook;
  private worksheet: Worksheet;
  private blob: Blob;

  private keys: Array<string>;

  private mapping: Record<string, keyof T> = {};

  constructor(settings: ColumnSettings<T>, sheetName: string = 'Sheet 1') {
    this.settings = settings;
    // Get keys from settings once (better performance)
    this.keys = Object.keys(settings);

    this.keys.forEach((key) => {
      const header = this.settings[key].header as string;
      this.mapping[header] = key as keyof T;
    });

    this.workbook = new Workbook();
    this.worksheet = this.workbook.addWorksheet(sheetName);

    this.blob = new Blob([], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
    });

    this.createFile(this.settings);
  }

  private makeFirstRowFontBold() {
    this.worksheet.getRow(1).font = {
      bold: true,
    };
  }

  private convertColumnSettings(settings: ColumnSettings<T>): Array<Partial<Column>> {
    return this.keys.map((key) => ({ ...settings[key], key, width: settings[key].width || 15 }));
  }

  private isDataValidation(obj: object): obj is DataValidation {
    return 'type' in obj && 'formulae' in obj;
  }

  private isDate(obj: any): obj is Date {
    return obj instanceof Date;
  }

  private setDataValidations(settings: ColumnSettings<T>) {
    this.keys.forEach((key) => {
      const { validation } = settings[key];

      // check if has validation and is DataValidation-type
      const hasValidation = validation !== undefined && this.isDataValidation(validation);

      const column = this.worksheet.getColumn(key);
      const { letter } = column;

      for (let rowNumber = 2; rowNumber < this.MAX_ROWS; rowNumber++) {
        // check if is date, set date formatting
        if (this.settings[key]?.isDate) {
          this.worksheet.getCell(`${letter}${rowNumber}`).numFmt = 'dd/mm/yyyy';
        }

        if (this.settings[key]?.isNumeric) {
          this.worksheet.getCell(`${letter}${rowNumber}`).dataValidation = {
            type: 'decimal',
            formulae: [0],
          };
        }

        // check if has validation, set validation if so, will override defaults if set
        if (hasValidation) {
          this.worksheet.getCell(`${letter}${rowNumber}`).dataValidation = validation;
        }
      }
    });
  }

  private async createBlob(): Promise<Blob> {
    const buffer = await this.workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
    });

    return blob;
  }

  private async createFile(settings: ColumnSettings<T>): Promise<void> {
    const columns = this.convertColumnSettings(settings);
    this.worksheet.columns = columns;

    this.makeFirstRowFontBold();

    this.setDataValidations(settings);

    this.blob = await this.createBlob();
  }

  public getObjectURL(): string {
    return URL.createObjectURL(this.blob);
  }

  public getBlobSize(): number {
    return this.blob.size;
  }

  public getBlobType(): string {
    return this.blob.type;
  }

  private ensureXlsxFileExtension(fileName: string): string {
    if (fileName.endsWith('.xlsx')) {
      return fileName;
    }

    return `${fileName}.xlsx`;
  }

  public downloadBlob(fileName: string = 'mal.xlsx'): void {
    // this start a download to the client, with our blob and given fileName
    saveAs(this.blob, this.ensureXlsxFileExtension(fileName));
  }

  private isHyperlink(cellValue: CellValue): cellValue is CellHyperlinkValue {
    if (cellValue && typeof cellValue === 'object') {
      return 'hyperlink' in cellValue && 'text' in cellValue;
    }

    return false;
  }

  private isFormula(cellValue: CellValue): cellValue is CellFormulaValue {
    if (cellValue && typeof cellValue === 'object') {
      return 'formula' in cellValue && 'result' in cellValue;
    }

    return false;
  }

  private hasFormulaError(cellValue: CellValue): boolean {
    if (this.isFormula(cellValue)) {
      const { result } = cellValue;
      if (result && typeof result === 'object') {
        return 'error' in result;
      }
    }

    return false;
  }

  private isSharedFormula(cellValue: CellValue): cellValue is CellSharedFormulaValue {
    if (cellValue && typeof cellValue === 'object') {
      return 'sharedFormula' in cellValue && 'result' in cellValue;
    }

    return false;
  }

  private isError(cellValue: CellValue): cellValue is CellErrorValue {
    if (cellValue && typeof cellValue === 'object') {
      return 'error' in cellValue;
    }

    return false;
  }

  private isRichText(cellValue: CellValue): cellValue is CellRichTextValue {
    if (cellValue && typeof cellValue === 'object') {
      return 'richText' in cellValue;
    }

    return false;
  }

  private formatRichText(richText: Array<RichText>): string {
    return richText.map((rt) => rt.text.trim()).join(' ');
  }

  private shouldConvertToString(value: any): boolean {
    if (value) {
      if (value instanceof Date || value instanceof Boolean) {
        return false;
      }

      return true;
    }

    // don't convert undefined/null to string :D
    return false;
  }

  public async importFile(file: File): Promise<Array<T>> {
    // get buffer from file, create work book
    const buffer = await file.arrayBuffer();
    const workbook = new Workbook();

    // load buffer into workbook
    await workbook.xlsx.load(buffer);

    // get first sheet (should only be 1)
    const sheet = workbook.getWorksheet(1);

    // create array containing result
    const result = new Array<T>();

    // get first row (headers), and values
    const firstRow = sheet.getRow(1);
    const headers = firstRow.values;

    sheet.eachRow((row, rowNumber) => {
      // skip first row (headers)
      if (rowNumber === 1) {
        return;
      }

      // get values from row, and create an partial item
      const { values: rowValues } = row;
      const item: Partial<T> = {};

      for (let columnNumber = 1; columnNumber < (headers as any[]).length; columnNumber++) {
        // get key of first row, and current column (header)
        const header = headers[columnNumber];
        // get value from current row and current column
        const cellValue = rowValues[columnNumber];

        // get generic mapping key
        const itemKey = this.mapping[header];

        // if itemKey does not exists, we throw error
        // this means that the sheet uploaded is invalid to our template
        if (itemKey === undefined) {
          throw new Error(`Unknown header '${header}'`);
        }

        // check if it is fomula/shared formula
        if (this.isFormula(cellValue) || this.isSharedFormula(cellValue)) {
          // check if it has no forumula error
          if (!this.hasFormulaError(cellValue)) {
            const resultValue = cellValue.result as any;
            // check if any rich-text has tricked it self into result
            if (this.isRichText(resultValue)) {
              const text = this.formatRichText(resultValue.richText);

              item[itemKey] = text as any;
            } else {
              item[itemKey] = cellValue.result as any;
            }
          }
        } else if (this.isHyperlink(cellValue)) {
          const textValue = cellValue.text as any;
          // can also be rich-text inside text-value
          if (this.isRichText(textValue)) {
            const text = this.formatRichText(textValue.richText);

            item[itemKey] = text as any;
          } else {
            item[itemKey] = cellValue.text as any;
          }
        } else if (this.isError(cellValue)) {
          item[itemKey] = undefined;
        } else if (this.isRichText(cellValue)) {
          const text = this.formatRichText(cellValue.richText);

          item[itemKey] = text as any;
        } else {
          item[itemKey] = cellValue as any;
        }

        // force value to string (if not undefined)
        if (this.shouldConvertToString(item[itemKey])) {
          item[itemKey] = `${item[itemKey]}`.trim() as any;
        }

        // check if value should be numeric
        if (this.settings[itemKey]?.isNumeric) {
          const numValue = Number(item[itemKey]);
          if (isNaN(numValue)) {
            item[itemKey] = undefined;
          } else {
            item[itemKey] = numValue as any;
          }
        }

        // check if is email
        if (this.settings[itemKey]?.isEmail) {
          // check if item is string
          if (typeof item[itemKey] === 'string') {
            // lowercase all emails
            item[itemKey] = `${item[itemKey]}`.toLowerCase() as any;
          }
        }

        if (
          this.isDate(cellValue) ||
          this.settings[itemKey]?.isDate ||
          this.settings[itemKey]?.validation?.type === 'date'
        ) {
          switch (typeof cellValue) {
            case 'undefined':
              item[itemKey] = new Date() as any;

              break;
            case 'string': {
              const date = parseDateString(cellValue, new Date());
              item[itemKey] = date as any;

              break;
            }
            default: {
              const date = getSafeDateOnly(new Date(cellValue));
              item[itemKey] = date as any;

              break;
            }
          }
        }
      }

      // push value as non-partial
      result.push(item as T);
    });

    return result;
  }
}
