import { FaIcon } from '@in/component-library';
import { default as classNames, default as classnames } from 'classnames';
import React, { useState } from 'react';
import './style.scss';

export interface LabelProps extends React.HTMLAttributes<HTMLLabelElement> {
  children?: React.ReactNode | React.ReactNode[];
  className?: string;
  htmlFor: string;
  helpText?: React.ReactNode;
}

const Label: React.FC<LabelProps> = ({ children, className, helpText, ...props }) => {
  const classes = classnames('form-element__label', className);

  const [helpTextOpen, setHelpTextOpen] = useState(false);

  if (helpText) {
    return (
      <div className="display--flex flex-direction--column">
        <div className="display--flex justify-content--space-between">
          <label className={classes} {...props}>
            {children}
          </label>

          <FaIcon
            tabIndex={0}
            className={'cursor--pointer'}
            name={classNames('fa-light', {
              'fa-circle-chevron-up': helpTextOpen,
              'fa-info-circle': !helpTextOpen,
            })}
            onClick={() => setHelpTextOpen((prev) => !prev)}
            onKeyDown={(e) => {
              if (e.key === ' ' || e.key === 'Enter') {
                setHelpTextOpen((prev) => !prev);
              }
            }}
          />
        </div>
        <div
          aria-live="polite"
          aria-hidden={!helpTextOpen}
          className={classNames('margin-bottom--1 background-color--blue-50 padding--1 font-size--large', {
            'display--none': !helpTextOpen,
          })}
        >
          {helpText}
        </div>
      </div>
    );
  }

  return (
    <label className={classes} {...props}>
      {children}
    </label>
  );
};

export default Label;
