import React from 'react';
import Label from './Label';
import { FormGroupErrorContext } from './FormGroup';
import FormElementError from './FormElementError';
import generateGuid from 'src/utils/Guid';
import classnames from 'classnames';

import './Slider.scss';
import './style.scss';

export interface SliderProps extends React.InputHTMLAttributes<HTMLInputElement> {
  id?: string;
  className?: string;
  inputClassName?: string;
  label?: React.ReactNode;
  name?: string;
  description?: React.ReactNode;
  error?: React.ReactNode | boolean;
  helpText?: React.ReactNode;
}

export const Slider = React.forwardRef<HTMLInputElement, SliderProps>(
  (
    {
      id,
      className,
      inputClassName,
      error,
      label,
      description,
      name,
      min,
      max,
      value,
      step,
      helpText,
      ...props
    }: SliderProps,
    ref,
  ) => {
    const context = React.useContext(FormGroupErrorContext);
    const inputId = id || generateGuid();
    const errorId = context.errorId || generateGuid();
    const descriptionId = description ? generateGuid() : undefined;
    const resolvedError = context.error || error;
    const classes = classnames('form-element', 'slider', className);

    let minValue = min ? +min : 1;
    let maxValue = max ? +max : 10;
    let stepValue = step ? +step : 1;

    minValue = isNaN(minValue) ? 1 : minValue;
    maxValue = isNaN(maxValue) ? 10 : maxValue;
    stepValue = isNaN(stepValue) ? 1 : stepValue;

    const steps = (maxValue - minValue) / stepValue;

    const inputClasses = classnames(
      'slider__input',
      {
        'has-error': resolvedError,
      },
      `step${steps}`,
      inputClassName,
    );

    return (
      <div className={classes}>
        {label && (
          <Label htmlFor={inputId} helpText={helpText} className="margin-bottom--3">
            {label}
            {props.required && <span className="form-element__label--required">*</span>}
          </Label>
        )}

        {description && (
          <div className="form-element__description" id={descriptionId}>
            {description}
          </div>
        )}

        <input
          ref={ref}
          className={inputClasses}
          name={name}
          aria-describedby={descriptionId}
          type="range"
          {...props}
          min={minValue}
          max={maxValue}
          defaultValue={value ?? undefined}
          step={stepValue}
        />

        {!context.error && !!error && (
          <FormElementError id={errorId}>{typeof error !== 'boolean' && error}</FormElementError>
        )}
      </div>
    );
  },
);

Slider.displayName = 'Slider';
