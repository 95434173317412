import React from 'react';
import { useRoutes } from 'react-router-dom';
import routes from '../routes';
import { LoadingPage } from 'src/components/LoadingPage/LoadingPage';

const TrackingFeature: React.FC = () => {
  const element = useRoutes(routes);

  return <React.Suspense fallback={<LoadingPage />}>{element}</React.Suspense>;
};

export default TrackingFeature;
