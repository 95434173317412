import { MessageType, Tag } from '@in/component-library';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'src/components/Skeleton/Skeleton';
import useBrReg from 'src/hooks/use-brreg';

type Props = {
  orgNo: string;
};

const CompanyEnterpriseStatusTag: React.FC<Props> = ({ orgNo }) => {
  const { t: tSiva } = useTranslation('siva');

  const { data, isLoading } = useBrReg(orgNo);

  const tags = useMemo(() => {
    const tags: string[] = [];
    if (data) {
      const { konkurs, underAvvikling, underTvangsavviklingEllerTvangsopplosning } = data;

      if (konkurs) {
        tags.push(tSiva('enterpriseStatus.bankrupt'));
      }

      if (underAvvikling) {
        tags.push(tSiva('enterpriseStatus.underLiquidation'));
      }

      if (underTvangsavviklingEllerTvangsopplosning) {
        tags.push(tSiva('enterpriseStatus.underCompulsoryLiquidationOrDissolution'));
      }
    }

    return tags;
  }, [data, tSiva]);

  if (isLoading) {
    return <Skeleton width="8rem" />;
  }

  if (!data) {
    return null;
  }

  if (tags.length > 0) {
    return (
      <div className="display--flex flex-wrap--wrap gap--1">
        {tags.map((tag, index) => (
          <Tag key={index} type={MessageType.Error}>
            {tag}
          </Tag>
        ))}
      </div>
    );
  }

  return <Tag type={MessageType.Info}>{tSiva('enterpriseStatus.active')}</Tag>;
};

export default CompanyEnterpriseStatusTag;
