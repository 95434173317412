import React from 'react';
import { Table } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';

type Props<TData extends object> = {
  table: Table<TData>;
  showRowsSelectedText: boolean;
  singularTranslation?: string;
  pluralTranslation?: string;
};

const DataTableRowsSelectedText = <TData extends object>({
  table,
  showRowsSelectedText,
  singularTranslation,
  pluralTranslation,
}: Props<TData>) => {
  const { t: tCommon } = useTranslation();

  if (!showRowsSelectedText) return <></>;

  const totalRows = table.getSelectedRowModel().flatRows.length;
  const singularText = singularTranslation ? singularTranslation : tCommon('rowSelected').toLowerCase();
  const pluralText = pluralTranslation ? pluralTranslation : tCommon('rowsSelected').toLowerCase();

  return <div>{`${totalRows} ${totalRows === 1 ? singularText : pluralText}`}</div>;
};

export default DataTableRowsSelectedText;
