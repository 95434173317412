import { SelectOptionProps } from '@in/component-library';
import { Gender, TimeEntryStatus, TimeEntrySource, ClusterContactStatus, InvitationStatus } from 'src/api/v2';

export const getGenderOptions = () => {
  const genderOptions: SelectOptionProps[] = [
    {
      value: Gender.Unspecified,
      text: 'Uspesifisert',
    },
    {
      value: Gender.Female,
      text: 'Kvinne',
    },
    {
      value: Gender.Male,
      text: 'Mann',
    },
  ];

  return genderOptions;
};

export const getTimeEntryStatusOptions = () => {
  const timeEntryStatusOptions: SelectOptionProps[] = [
    {
      value: TimeEntryStatus.Counting,
      text: 'Godkjent',
    },
    {
      value: TimeEntryStatus.PendingApproval,
      text: 'Trenger godkjenning',
    },
    {
      value: TimeEntryStatus.NonCounting,
      text: 'Ikke tellende',
    },
  ];

  return timeEntryStatusOptions;
};

export const getTimeEntrySourceOptions = () => {
  const timeEntrySourceOptions: SelectOptionProps[] = [
    {
      value: TimeEntrySource.Import,
      text: 'Importert',
    },
    {
      value: TimeEntrySource.Link,
      text: 'Registreringslenke',
    },
    {
      value: TimeEntrySource.Manual,
      text: 'Manuelt',
    },
  ];

  return timeEntrySourceOptions;
};

export const getBoardInvitationStatus = () => {
  const boardInvitationOptions: SelectOptionProps[] = [
    {
      value: InvitationStatus.Created,
      text: 'Opprettet',
    },
    {
      value: InvitationStatus.Sent,
      text: 'Sendt',
    },
    {
      value: InvitationStatus.Accepted,
      text: 'Godkjent',
    },
  ];

  return boardInvitationOptions;
};

export const getClusterContactStatusOptions = () => {
  const clusterContactStatusOptions: SelectOptionProps[] = [
    {
      value: ClusterContactStatus.Active,
      text: 'Innmeldt',
    },
    {
      value: ClusterContactStatus.InActive,
      text: 'Utmeldt',
    },
    {
      value: ClusterContactStatus.PendingApproval,
      text: 'Ikke innmeldt',
    },
  ];

  return clusterContactStatusOptions;
};
