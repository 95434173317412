import React from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';

import { Button, DateInput, InputField, Stepper, Theme } from '@in/component-library';

import { StepProps } from 'src/components/Stepper';

import * as validation from 'src/utils/validation';

import { ClusterMemberCreateDto } from 'src/api/v2';

import useMembers from 'src/hooks/use-members';
import useCluster from 'src/features/cluster/hooks/use-cluster';

import { CreateMemberContext } from './CreateMemberModal';
import { defaultClusterMemberDetails } from 'src/features/members';
import { formatDateInputDate } from 'src/utils/FormatValue';
import AddressFormGroup from 'src/features/members/components/CompanyInformationForm/AddressFormGroup';

export const Step1: React.FC<StepProps> = (props) => {
  const { t: tCommon } = useTranslation();
  const { t: tError } = useTranslation('error');

  const context = React.useContext(CreateMemberContext);

  const { cluster } = useCluster();
  const { combined } = useMembers();

  const form = useForm<ClusterMemberCreateDto>({
    mode: 'onBlur',
    defaultValues: {
      ...context.member,
    },
  });

  const {
    register,
    reset,
    setValue,
    formState: { errors },
    getValues,
    handleSubmit,
  } = form;

  const values = getValues();
  const establishedDateRegister = register('establishedDate', { valueAsDate: true });

  const onSubmit = handleSubmit((data) => {
    if (!cluster) {
      return;
    }

    const record: ClusterMemberCreateDto = {
      ...data,
      clusterId: cluster.id,

      details: {
        ...defaultClusterMemberDetails,
        ...data.details,
      },
    };

    if (record.establishedDate) {
      record.establishedDate = new Date(record.establishedDate);
    }

    context.member = record;

    props.goNextStep();
  });

  return (
    <div>
      <Stepper currentStep={0} stepCount={3} />

      <form className="resource-base-form" onSubmit={onSubmit}>
        <InputField
          label={tCommon('name')}
          {...register('name', {
            required: validation.requiredI18n(tError),
          })}
          value={values.name}
          errorMsg={errors?.name?.message}
        />

        <InputField
          label={tCommon('organizationNumber')}
          {...register('organizationNumber', {
            validate: {
              validOrganizationNumber: validation.validOrganizationNumber,
              uniqueOrganizationNumber: (value: string | undefined) => {
                if (value) {
                  const exists = combined.find((x) => x.organizationNumber === value);

                  if (exists) {
                    return tError('organizationNumber.alreadyInUse');
                  } else {
                    return true;
                  }
                }

                return true;
              },
            },
          })}
          value={values.organizationNumber}
          errorMsg={errors?.organizationNumber?.message}
        />

        <fieldset className="margin-bottom--2">
          <legend>{tCommon('mailingAddress')}</legend>

          <AddressFormGroup title={tCommon('mailingAddress')} path={'mailingAddress'} form={form as any} />
        </fieldset>

        <div className="display--flex gap--2">
          <div className="flex--1">
            <DateInput
              label={tCommon('establishmentDate')}
              max={formatDateInputDate(new Date())}
              name={establishedDateRegister.name}
              onChange={(event) => {
                setValue('establishedDate', new Date(event.target.value), {
                  shouldValidate: true,
                });
              }}
              value={formatDateInputDate(values.establishedDate)}
              errorMsg={errors?.establishedDate?.message}
            />
          </div>

          <div className="flex--1">
            <InputField
              type="number"
              label={tCommon('numberOfEmployees')}
              {...register('details.numberOfEmployees', {
                valueAsNumber: true,
                min: validation.minValue(0),
              })}
              value={values?.details?.numberOfEmployees}
              errorMsg={errors?.details?.numberOfEmployees?.message}
            />
          </div>
        </div>

        <div className="display--flex gap--2 margin-top--2">
          <Button
            theme={Theme.Neutral}
            variant="outlined"
            onClick={() => {
              reset({});

              context.organizationNumber = '';
              context.member = undefined;

              props.goPreviousStep();
            }}
          >
            {tCommon('previous')}
          </Button>

          <Button theme={Theme.Neutral} type="submit">
            {tCommon('next')}
          </Button>
        </div>
      </form>
    </div>
  );
};
