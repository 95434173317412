import React from 'react';
import { Button, FaIcon, FaIcons } from '@in/component-library';
import { icon } from '@fortawesome/fontawesome-svg-core';
import BillsForm from './BillsForm';
import { useTranslation } from 'react-i18next';
import { SivaEsaReportingDto } from 'src/api/v2';
import { useParams } from 'react-router-dom';
import { PageParams } from '../types/PageParams';
import { convertIdToNumber } from '../utils/idConversion';

type Props = {
  onSubmit: (bills: SivaEsaReportingDto[]) => void;
  isLoading?: boolean;
};

const ESANoRegisteredBillsInfo: React.FC<Props> = ({ onSubmit, isLoading = false }) => {
  const { t: tSiva } = useTranslation('siva');
  const [showAddComponent, setShowAddComponent] = React.useState(false);
  const { contractId } = useParams<PageParams>();

  if (showAddComponent) {
    return (
      <BillsForm
        header={tSiva('company.details.esa.bill.add')}
        numericId={convertIdToNumber(contractId)}
        onSubmit={onSubmit}
        isLoading={isLoading}
        onCancel={() => setShowAddComponent(false)}
      />
    );
  }

  return (
    <>
      <FaIcon
        className="margin-bottom--3"
        icon={icon({ prefix: 'fal', iconName: 'money-check-dollar-pen' })}
      />
      <div className="margin-bottom--6">{tSiva('company.details.esa.bill.noRegistered')}</div>

      <Button onClick={() => setShowAddComponent(true)}>
        <FaIcon name={FaIcons.Plus} className="margin-right--1" />
        {tSiva('company.details.esa.bill.add')}
      </Button>
    </>
  );
};

export default ESANoRegisteredBillsInfo;
