import React from 'react';
import { useParams } from 'react-router-dom';
import OneWayInServices from '../../components/OneWayInServices';
import { PageParams } from '../types/PageParams';

const Services: React.FC = () => {
  const { formId } = useParams<PageParams>();

  return <OneWayInServices formId={formId} />;
};

export default Services;
