import React, { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

import OtherProjects from './pages/OtherProjects';
import DevelopmentProjects from './pages/DevelopmentProjects';
// import { InkindFeaturePage } from '../inkind';
import { Events } from '../events';
import ProjectDetailsSwitcher from './details/ProjectDetailsSwitcher';
import ProjectsSwitcher from './pages/ProjectsSwticher';

const DevelopmentProjectDetails = lazy(() => import('./details/DevelopmentProjectDetails'));
const PortalProjectDetails = lazy(() => import('./details/PortalProjectDetails'));
const InkindFeaturePage = lazy(() =>
  import('../inkind').then((module) => ({ default: module.InkindFeaturePage })),
);

const routes: RouteObject[] = [
  {
    path: '',
    index: true,
    element: <ProjectsSwitcher />,
  },
  {
    path: 'in-projects',
    element: <DevelopmentProjects />,
  },
  {
    path: 'own-projects',
    element: <OtherProjects />,
  },
  {
    path: 'inkind',
    element: <InkindFeaturePage />,
  },
  {
    path: 'meetings',
    element: <p>Not implemented yet</p>,
  },
  {
    path: 'events/*',
    element: <Events />,
  },
  {
    path: 'details/:projectId/*',
    element: <ProjectDetailsSwitcher />,
  },
  {
    path: 'own-projects/:projectId/*',
    element: <PortalProjectDetails />,
  },
  {
    path: 'in-projects/:projectId/*',
    element: <DevelopmentProjectDetails />,
  },
];

export default routes;
