import React, { useState } from 'react';
import useMembers from 'src/hooks/use-members';
import { Button, ModalActionButtonContainer, Select } from '@in/component-library';
import MemberDeductibleTable from './MemberDeductibleTable';
import { useTranslation } from 'react-i18next';

interface Props {
  onClose?: () => void;
}
const MemberEconomyFormModal: React.FC<Props> = ({ onClose }) => {
  const { members } = useMembers();
  const { t: tCommon } = useTranslation('common');
  const { t: tResourceBase } = useTranslation('resourceBase');
  const [selectedMemberId, setSelectedMemberId] = useState('');

  return (
    <>
      <Select
        label={tResourceBase('selectCompany')}
        name={'members'}
        value={selectedMemberId}
        onChange={(value) => {
          setSelectedMemberId(value);
        }}
        options={members.map((member) => {
          return { text: member?.name ? member.name : '', value: member?.id ? member.id : '' };
        })}
      />
      {selectedMemberId && <MemberDeductibleTable memberId={selectedMemberId} />}

      <p>{tResourceBase('dataSavedAutomatically')}</p>
      <ModalActionButtonContainer>
        <Button onClick={() => onClose?.()}>{tCommon('close')}</Button>
      </ModalActionButtonContainer>
    </>
  );
};

export default MemberEconomyFormModal;
