import { Notification } from '@in/component-library';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ExcelChangelogType, GetRecentExcelChangelog } from 'src/constants/excel-import';
import { formatDateToNOString } from 'src/utils/FormatValue';

type Props = {
  changelogs: ExcelChangelogType[];
};

const ExcelChangelog: React.FC<Props> = ({ changelogs }) => {
  const { t: tChangelog } = useTranslation('changelog');

  const items = useMemo(() => GetRecentExcelChangelog(changelogs), [changelogs]);

  if (items.length === 0) {
    return null;
  }

  return (
    <Notification type="generic" className="margin-bottom--2" fullWidth>
      <p>
        <strong>{tChangelog('warning.30days')}</strong>
      </p>
      <p>{tChangelog('warning.tips')}</p>

      <br />

      {items.map((item, index) => (
        <p key={index}>
          {tChangelog(item.message)} ({formatDateToNOString(item.date)})
        </p>
      ))}
    </Notification>
  );
};

export default ExcelChangelog;
