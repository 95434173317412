import { lazy } from 'react';

export { default as routes } from './routes';

export { default as ContactInformationForm } from './components/ContactInformationFormLogic';

export { default as useContact } from './hooks/use-contact';
export { default as useContacts } from './hooks/use-contacts';

// Wrapper
export const ContactDetailsFeature = lazy(() => import('./pages/ContactDetail'));
