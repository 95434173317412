import { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { MaintenanceService, ServiceHealthMessageDto } from 'src/api/v2';
import { useUser } from 'src/features/authorization';

const useMaintenance = () => {
  const { isUserLoggedIn, isUserRegistered, hasUserClusters, user, logOut } = useUser();

  const [maintenanceServie] = useState(() => new MaintenanceService());

  const maintenanceQuery = useQuery(
    ['maintenance'],
    () => maintenanceServie.getActiveServiceHealthMessage(),
    {
      refetchInterval: 1000 * 60 * 5, // Every 5 minutes
      onSuccess(serviceHealthMessage: ServiceHealthMessageDto) {
        // If we are in maintenance mode and the user is not an admin/aduser, log them out.
        if (
          user?.authDomain !== 'commonaad' &&
          serviceHealthMessage.showMaintenancePage &&
          serviceHealthMessage.isActive &&
          isUserLoggedIn
        ) {
          // If this is not an ad user, log them out during maintenance.
          logOut();
        }
      },
    },
  );

  const { isMaintenanceActive, notificationText } = useMemo(() => {
    if (!maintenanceQuery.data) {
      return {
        isMaintenanceActive: false,
        notificationText: '',
      };
    }

    const notificationText =
      isUserLoggedIn && isUserRegistered && hasUserClusters
        ? maintenanceQuery.data.loggedInMessage
        : maintenanceQuery.data.publicMessage;

    return {
      isMaintenanceActive: maintenanceQuery.data.isActive && maintenanceQuery.data.showMaintenancePage,
      notificationText: notificationText,
    };
  }, [maintenanceQuery.data, isUserLoggedIn, isUserRegistered, hasUserClusters]);

  return {
    maintenanceQuery,
    isMaintenanceActive,
    notificationText,
  };
};

export default useMaintenance;
