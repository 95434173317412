import { FaIcon, FaIcons } from '@in/component-library';
import React from 'react';

type Props = {
  archiveReferral: boolean;
  setArchiveReferral: (value: React.SetStateAction<boolean>) => void;
};

const NavigationCheckBox: React.FC<Props> = ({ archiveReferral, setArchiveReferral }) => {
  return (
    <label htmlFor="archive" className="checkbox">
      {''}
      <input
        id="archive"
        type="checkbox"
        checked={archiveReferral}
        onChange={(e) => setArchiveReferral(e.target.checked)}
      />
      <span className="checkbox-icon">
        <FaIcon name={FaIcons.Check} />
      </span>
    </label>
  );
};

export default NavigationCheckBox;
