import { Button, ButtonVariant, Notification } from '@in/component-library';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ClusterContactDto } from 'src/api/v2';
import { useBoolean } from 'usehooks-ts';

type Props = {
  contacts: ClusterContactDto[];
};

const ExistingContactsNotification: React.FC<Props> = ({ contacts }) => {
  const { t: tResourceBase } = useTranslation('resourceBase');

  const showDuplicates = useBoolean();

  if (contacts.length === 0) {
    return null;
  }

  return (
    <Notification type="info" fullWidth className="margin-bottom--3">
      <p>{tResourceBase('import.existsInResourceBase')}</p>
      <Button
        variant={ButtonVariant.Link}
        onClick={() => {
          showDuplicates.toggle();
        }}
        title={tResourceBase('showExisting')}
      >
        {tResourceBase('import.amount', { length: contacts.length })}
      </Button>
      |
      {showDuplicates.value ? (
        <ul>
          {contacts.map(({ id, firstName, lastName, emailAddress }) => (
            <li key={id}>
              {firstName} {lastName} ({emailAddress})
            </li>
          ))}
        </ul>
      ) : null}
    </Notification>
  );
};

export default ExistingContactsNotification;
