import { SivaBudgetAttachmentType, SivaBudgetDetailsDto } from 'src/api/v2';

export function getLatestSivaBudgetVersion(data: SivaBudgetDetailsDto, type: SivaBudgetAttachmentType) {
  const attachments = data.sivaBudgetAttachments.filter((x) => x.type === type);

  if (attachments.length === 0) {
    return undefined;
  }

  const sorted = [...attachments].sort((a, b) => a.version - b.version);
  const latestVersion = sorted[sorted.length - 1];

  return latestVersion;
}
