import { lazy } from 'react';

export const ResourceBaseFeature = lazy(() => import('./pages/ResourceBase'));

export * from './hooks';

export { default as CreateMemberForm } from './components/members/create-member/CreateMemberForm';
export { default as ImportClusterMemberForm } from './components/common/import-cluster-member/ImportClusterMemberForm';

export { default as CreateContactForm } from './components/contacts/create-contact/CreateContactForm';
export { default as ImportContactsForm } from './components/contacts/import-contacts/ImportContactForm';
