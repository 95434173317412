import { FaIcons, IconButton } from '@in/component-library';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EventDtoV2 } from 'src/api/v2';
import EventCard from '../EventCard/EventCard';
import css from './EventListWrapper.module.scss';

type EventPaginationTypes = {
  events: EventDtoV2[];
  isFetching: boolean;
  projectId?: string;
};

const EventPagination: React.FC<EventPaginationTypes> = ({ events, isFetching, projectId }) => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [eventsPerPage, setEventsPerPage] = useState<number>(5);
  const pageSizes: number[] = [5, 10, 15, 20, 50];

  const { t: tCommon } = useTranslation();
  const { t: tProjects } = useTranslation('projects');

  const totalCount = events.length;
  const from = (currentPage - 1) * eventsPerPage;
  const to = Math.min((currentPage - 1) * eventsPerPage + eventsPerPage, totalCount);

  React.useEffect(() => {
    if (events.length <= eventsPerPage) {
      setCurrentPage(1);
    }
  }, [events.length, eventsPerPage]);

  return (
    <>
      {events.slice(from, to).map((event) => {
        return <EventCard key={event.id} event={event} isFetching={isFetching} projectId={projectId} />;
      })}
      <div className="display--flex items-align--center justify-content--flex-end gap--2">
        <div className="display--flex items-align--center gap-1">
          <span id="eventsPerPage">{tProjects('projectsPerPage')}:</span>
          <select
            className={css['eventPagination__select']}
            onChange={(event) => {
              const { value } = event.currentTarget;
              const numValue = Number(value);
              if (numValue < totalCount) {
                setEventsPerPage(numValue);
              } else {
                setEventsPerPage(totalCount);
              }
              setCurrentPage(1);
            }}
            aria-labelledby="rowsPerPage"
          >
            {pageSizes.map((num) => {
              return <option key={num}>{num}</option>;
            })}
          </select>
        </div>

        <div className="display--flex items-align--center gap--1">
          <span>
            {from + 1} - {to}
          </span>
          <span>{tCommon('of')}</span>
          <span>{totalCount}</span>
        </div>

        <div className="display--flex items-align--center gap--2">
          <IconButton
            iconName={FaIcons.ChevronLeftLight}
            onClick={() => {
              setCurrentPage(currentPage - 1);
            }}
            disabled={currentPage === 1 || eventsPerPage === totalCount}
            aria-disabled={currentPage === 1}
            aria-label={tCommon('previousPage')}
            title={tCommon('previousPage')}
          />
          <IconButton
            iconName={FaIcons.ChevronRightLight}
            onClick={() => {
              setCurrentPage(currentPage + 1);
            }}
            disabled={to === totalCount || eventsPerPage === totalCount}
            aria-disabled={to === totalCount}
            aria-label={tCommon('nextPage')}
            title={tCommon('nextPage')}
          />
        </div>
      </div>
    </>
  );
};

export default EventPagination;
