import React, { useCallback } from 'react';

type Props = {
  isActive: boolean;
  toggle: () => void;
  deactivate: () => void;
  activate: () => void;
};

export default function useToggle(initiallyActive: boolean = false): Props {
  const [isActive, setIsActive] = React.useState(initiallyActive);

  const toggle = useCallback(() => setIsActive((isActive) => !isActive), []);
  const deactivate = useCallback((): void => setIsActive(false), []);
  const activate = useCallback(() => setIsActive(true), []);

  return { isActive, toggle, deactivate, activate };
}
