import React, { lazy, Suspense, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Tabs, PageLoader } from '@in/component-library';

import {
  DevelopmentProjectDto,
  DevelopmentProjectListItemDto,
  OperationProjectDto,
  PortalProjectDto,
  PortalProjectListItemDto,
} from 'src/api/v2';

import { RegisterInkindDefaultValues } from './RegisterInkindModal';

const ImportInkindForm = lazy(() => import('./ImportInkindForm'));
const RegisterInkindForm = lazy(() => import('./RegisterInkindForm'));

type Props = {
  onClose: () => void;

  defaultValues?: RegisterInkindDefaultValues;

  projects?: (
    | DevelopmentProjectListItemDto
    | PortalProjectListItemDto
    | DevelopmentProjectDto
    | PortalProjectDto
    | OperationProjectDto
  )[];
};

const RegisterInkindFormWrapper: React.FC<Props> = ({ onClose, defaultValues, projects }) => {
  const { t: tSelfEffort } = useTranslation('selfEffort');
  const { t: tCommon } = useTranslation('common');

  const [tab, setTab] = useState<'multiple' | 'singel'>('multiple');

  return (
    <>
      <Tabs
        tabs={[
          {
            title: tSelfEffort('registerMultiple'),
            'aria-label': tSelfEffort('registerMultiple'),
            'aria-selected': tab === 'multiple',
            onClick: () => setTab('multiple'),
          },
          {
            title: tSelfEffort('registerSingel'),
            'aria-label': tSelfEffort('registerSingel'),
            'aria-selected': tab === 'singel',
            onClick: () => setTab('singel'),
          },
        ]}
      />

      <Suspense fallback={<PageLoader aria-label={tCommon('loading')} />}>
        {tab === 'multiple' ? (
          <ImportInkindForm onClose={onClose} defaultValues={defaultValues} projects={projects} />
        ) : (
          <RegisterInkindForm onClose={onClose} defaultValues={defaultValues} projects={projects} />
        )}
      </Suspense>
    </>
  );
};

export default RegisterInkindFormWrapper;
