import { useState, useMemo } from 'react';
import { useQuery } from 'react-query';
import { OneWayInServicesService } from 'src/api/v2';
import { ONE_WAY_IN_SERVICES } from 'src/constants/hook-keys';
import { useCluster } from 'src/features/cluster';

const useOneWayInServices = () => {
  const { cluster } = useCluster();
  const [oneWayInServicesService] = useState(new OneWayInServicesService());

  const oneWayInOpportunityServicesQuery = useQuery({
    queryKey: [ONE_WAY_IN_SERVICES],
    queryFn: () => oneWayInServicesService.getOneWayInServices({ clusterId: undefined }),
    enabled: !!cluster,
    retry: false,
    staleTime: 1000 * 60 * 30, // 30 min refetching on mount
    refetchOnMount: true,
    keepPreviousData: false,
  });

  const { clusterServices, allServices } = useMemo(() => {
    if (!oneWayInOpportunityServicesQuery.data) {
      return {
        clusterServices: [],
        allServices: [],
      };
    }

    return {
      clusterServices: oneWayInOpportunityServicesQuery.data.filter((x) => x.clusterId === cluster?.id),
      allServices: oneWayInOpportunityServicesQuery.data.filter((x) => x.clusterId !== cluster?.id),
    };
  }, [oneWayInOpportunityServicesQuery.data, cluster?.id]);

  return {
    oneWayInOpportunityServicesQuery,
    clusterServices,
    allServices,
  };
};

export default useOneWayInServices;
