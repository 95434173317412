import React from 'react';
import { useTranslation } from 'react-i18next';
import { FaIcon, FaIcons, Spinner } from '@in/component-library';
import { formatAsCurrencyString } from 'src/utils/FormatValue';
import { icon } from '@fortawesome/fontawesome-svg-core';
import { IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import { ProjectStatisticsDto } from 'src/api/v2';
import Divider from 'src/components/Divider';

type Props = {
  projectStatistics?: ProjectStatisticsDto;
  isFetching?: boolean;
};

const ProjectCardStats: React.FC<Props> = ({ projectStatistics, isFetching }) => {
  const { t: tCommon } = useTranslation();
  const { t: tSelfEffort } = useTranslation('selfEffort');
  const { t: tPageTitles } = useTranslation('pageTitles');

  const spanClassName = 'padding-x--2 display--flex align-items--center gap--2';

  if (isFetching) {
    return (
      <div className="display--flex justify-content--center">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="display--flex flex-direction--column gap--1 font-size--smaller">
      <span className={spanClassName}>
        <FaIcon name={FaIcons.UserLight} size="xs" className={'color--red-100'} />
        {projectStatistics ? projectStatistics.uniqueParticipants : 0} {tCommon('participants').toLowerCase()}
      </span>

      <Divider />

      <span className={spanClassName}>
        <FaIcon
          icon={icon({ prefix: 'fal', iconName: 'calendar-clock' }) as IconDefinition}
          size="xs"
          className={'color--red-100'}
        />
        {projectStatistics ? projectStatistics.timeEntryHoursTotal.toFixed(0) : 0}{' '}
        {tSelfEffort('hoursRegistered')}
      </span>

      <Divider />

      <span className={spanClassName}>
        <FaIcon
          icon={icon({ prefix: 'fal', iconName: 'sack-dollar' }) as IconDefinition}
          size="xs"
          className={'color--red-100'}
        />
        {formatAsCurrencyString(
          projectStatistics ? projectStatistics?.timeEntryCostTotal : 0,
          ' ',
          undefined,
          0,
          undefined,
          ',-',
        )}{' '}
        {tCommon('inkind').toLowerCase()}
      </span>

      <Divider />

      <span className={spanClassName}>
        <FaIcon
          icon={icon({ prefix: 'fal', iconName: 'calendar-check' }) as IconDefinition}
          size="xs"
          className={'color--red-100'}
        />
        {projectStatistics ? projectStatistics.numberOfEvents : 0}{' '}
        {projectStatistics?.numberOfEvents === 1
          ? tCommon('event').toLowerCase()
          : tPageTitles('events').toLowerCase()}
      </span>
    </div>
  );
};

export default ProjectCardStats;
