import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'src/features/authorization';

const ContactUs: React.FC = () => {
  const userUrl = window.location.href;
  const userBrowser = navigator.userAgent;

  const { t: tCommon } = useTranslation();
  const { t: tOnboarding } = useTranslation('onboarding');
  const { checkAccess } = useAuth();

  return (
    <div className="help-and-support-contact-us">
      <h2 className="margin-top--0 margin-bottom--3">{tOnboarding('helpAndSupport.tab.ContactUs')}</h2>
      <h3 className="margin-top--0 margin-bottom--2">{tOnboarding('helpAndSupport.ContactUs.WriteToUs')}</h3>
      {checkAccess('OneWayIn').write && (
        <div className="padding--2">
          <div>
            <b>{tCommon('oneWayIn')}</b>
          </div>
          <div>
            <a
              href={`mailto:support@enveiinn.no?subject=En%20vei%20inn&body=%0D%0A%0D%0AURL%3A%20${userUrl}%0D%0ANettleser%3A%20${userBrowser}`}
            >
              support@enveiinn.no
            </a>
          </div>
        </div>
      )}
      {checkAccess('SivaCore').write && (
        <div className="padding--2">
          <div>
            <b>{tCommon('sivaReporting')}</b>
          </div>
          <div>
            <a
              href={`mailto:support@siva.no?subject=Rapportering%20til%20SIVA&body=%0D%0A%0D%0AURL%3A%20${userUrl}%0D%0ANettleser%3A%20${userBrowser}`}
            >
              support@siva.no
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

export default ContactUs;
