import { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { ClusterDto, ClustersService, SessionService } from 'src/api/v2';

import { useUser } from 'src/features/authorization';

import {
  B2C_INFO_KEY,
  CLUSTERS_KEY,
  CLUSTER_KEY,
  USER_KEY,
  USER_STATUS_HAS_ACCEPTED_TERMS,
  USER_STATUS_HAS_CLUSTERS,
  USER_STATUS_LOADING,
  USER_STATUS_REGISTERED,
  USER_STAUTS_LOGGED_IN,
} from 'src/constants/hook-keys';

export default function useClusters() {
  const { user, chosenClusterId, setHasUserClusters } = useUser();

  const [sessionService] = useState(new SessionService());
  const [clustersService] = useState(new ClustersService());

  const queryClient = useQueryClient();

  const { data, ...rest } = useQuery([CLUSTERS_KEY], () => clustersService.getMyClusters(), {
    enabled: !!user,
    onSuccess: (data) => {
      const found = data.find((x) => x.id === chosenClusterId);

      if (found) {
        setHasUserClusters(true);
        queryClient.setQueryData([CLUSTER_KEY], found);
        return;
      }

      if (data.length === 0) {
        setHasUserClusters(false);
      } else {
        setHasUserClusters(true);
      }

      if (data.length === 1 && !chosenClusterId) {
        sessionService.storeChosenCluster(data[0]!.id).finally(() => {
          queryClient.setQueryData([CLUSTER_KEY], data[0]);
        });
      }
    },
    onError: () => setHasUserClusters(false),
  });

  const storeChosenClusterMutation = useMutation(
    async (cluster: ClusterDto) => {
      return await sessionService.storeChosenCluster(cluster.id);
    },
    {
      onSuccess(_, cluster) {
        const keepQueries = [
          USER_STAUTS_LOGGED_IN,
          USER_STATUS_LOADING,
          USER_STATUS_REGISTERED,
          USER_STATUS_HAS_CLUSTERS,
          USER_STATUS_HAS_ACCEPTED_TERMS,
          B2C_INFO_KEY,
          CLUSTER_KEY,
          CLUSTERS_KEY,
        ];

        queryClient.setQueryData([CLUSTER_KEY], cluster);

        const userData = queryClient.getQueriesData([USER_KEY]);
        queryClient.setQueryData([USER_KEY], { ...userData, chosenClusterId: cluster.id });

        queryClient.resetQueries({
          predicate: (query) => {
            return !keepQueries.includes(query.queryKey[0] as string);
          },
        });
      },
    },
  );

  const deleteChosenClusterMutation = useMutation(
    () => {
      return sessionService.deleteChosenCluster();
    },
    {
      onSuccess() {
        queryClient.setQueryData(USER_STATUS_LOADING, false);
      },
    },
  );

  return {
    clusters: data || [],

    ...rest,

    storeChosenClusterMutation,
    deleteChosenClusterMutation,
  };
}
