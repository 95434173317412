import { FaIcon } from '@in/component-library';
import React from 'react';
import { icon } from '@fortawesome/fontawesome-svg-core';
import { useTranslation } from 'react-i18next';

type Props = {
  value: number;
  formattedValue?: string;
  hide?: boolean;
};

const BillSupportNumberHandler: React.FC<Props> = ({ value, formattedValue, hide = false }) => {
  const { t: tCommon } = useTranslation();

  if (hide) return <></>;

  if (Number.isNaN(value) || !isFinite(value)) {
    return (
      <div>
        <FaIcon
          className="color--red margin-right--1"
          icon={icon({ prefix: 'fal', iconName: 'triangle-exclamation' })}
        />{' '}
        {tCommon('invalidValue')}
      </div>
    );
  }

  if (value < 0) {
    return (
      <div>
        <FaIcon
          className="color--red margin-right--1"
          icon={icon({ prefix: 'fal', iconName: 'triangle-exclamation' })}
        />{' '}
        {formattedValue ? formattedValue : value}
      </div>
    );
  }

  return <span>{formattedValue ? formattedValue : value}</span>;
};

export default BillSupportNumberHandler;
