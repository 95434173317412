import React from 'react';
import { OneWayInFeedbackDto, OneWayInFeedbackUpsertDto } from 'src/api/v2';
import { useCluster } from 'src/features/cluster';
import { LoadingPage } from 'src/components/LoadingPage/LoadingPage';
import OpportunityFeedbackForm from './OpportunityFeedbackForm';

type FeedbackProps = {
  opportunityId: string;
  opportunityTitle: string;
  opportunityMunicipality: string;
  opportunityCounty: string | undefined;
  actorFeedback: OneWayInFeedbackDto | undefined;
  isFetching: boolean;
  onSave: (feedbackUpsertDto: OneWayInFeedbackUpsertDto) => void;
};

const OpportunityFeedback: React.FC<FeedbackProps> = ({
  opportunityId,
  opportunityTitle,
  opportunityMunicipality,
  opportunityCounty,
  actorFeedback,
  isFetching,
  onSave,
}) => {
  const { cluster } = useCluster();

  const handleOnSave = (correctlyReferred: boolean, comment: string) => {
    const record = {
      id: actorFeedback?.id || undefined,
      clusterId: cluster!.id,
      oneWayInOpportunityD365Id: opportunityId,
      wasCorrectlyReferred: correctlyReferred || false,
      comment: comment,
      title: actorFeedback?.title || opportunityTitle,
      municipality: actorFeedback?.municipality || opportunityMunicipality,
      county: actorFeedback?.county || opportunityCounty,
      suggestedActors: actorFeedback?.suggestedActors || [],
    } as OneWayInFeedbackUpsertDto;

    onSave(record);
  };

  return (
    <div>
      {isFetching ? (
        <LoadingPage />
      ) : (
        <OpportunityFeedbackForm
          opportunityFeedback={actorFeedback}
          isLoading={isFetching}
          onSave={handleOnSave}
        />
      )}
    </div>
  );
};

export default OpportunityFeedback;
