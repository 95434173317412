import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAsyncDebounce } from 'react-table';

import './BrRegInputRadio.scss';

import { Button, ButtonVariant, Theme, InputField, RadioButton, FaIcons } from '@in/component-library';

import generateGuid from 'src/utils/Guid';
import { formatOrganizationNumber } from 'src/utils/format';

type SearchResultItem = {
  companyName: string;
  organizationNumber: string;
};

type Props = {
  name?: string;
  debounceTime?: number;
  onSelected: (value: string) => void;
  onSelectedEmpty: () => void;
};

export const BrRegInputRadio: React.FC<Props> = ({
  name,
  debounceTime = 333,
  onSelected,
  onSelectedEmpty,
}) => {
  const { t: tCommon } = useTranslation();
  const { t: tResourceBase } = useTranslation('resourceBase');

  const resolvedName = name || generateGuid();

  const [selectedResult, setSelectedResult] = React.useState<string>('');
  const [searchResult, setSearchResult] = React.useState<SearchResultItem[]>([]);
  const [notFound, setNotFound] = React.useState<boolean>(false);
  const [totalElements, setTotalElements] = React.useState<number>(0);
  const [error, setError] = React.useState<string>('');

  // https://lawsofux.com/doherty-threshold/
  const onChange = useAsyncDebounce((event: React.ChangeEvent<HTMLInputElement>) => {
    setTotalElements(0);
    setSearchResult([]);
    setSelectedResult('');
    setNotFound(false);
    setError('');

    const { value } = event.target;
    const encodedValue = encodeURIComponent(value.trim());

    if (encodedValue === '') {
      // No need to call API with empty string
      return;
    }

    let params = `navn=${encodedValue}`;

    // Check if matching organization number (also with spaces. Example: '987 654 321')
    if (encodedValue.match(/[0-9]{9}/) || encodedValue.match(/[0-9]{3}%20[0-9]{3}%20[0-9]{3}/)) {
      params = `organisasjonsnummer=${encodedValue.replaceAll('%20', '')}`;
    }

    fetch(`https://data.brreg.no/enhetsregisteret/api/enheter?${params}&konkurs=false`)
      .then((response) => response.json())
      .then((data) => {
        if (data?._embedded) {
          const { enheter } = data._embedded;
          setTotalElements(data?.page?.totalElements);

          const mappedCompanies = enheter.map(
            (x: any) =>
              ({
                companyName: x?.navn,
                organizationNumber: x?.organisasjonsnummer,
              } as SearchResultItem),
          );

          setNotFound(false);
          setSearchResult(mappedCompanies);
        } else {
          setNotFound(true);
          setSearchResult([]);
        }
      })
      .catch(() => {
        setError(tResourceBase('brreg.somethingWentWrong'));
      });
  }, debounceTime);

  const getRadioLabel = (): string => {
    if (totalElements > 20) {
      return tResourceBase('brreg.SearchHitOver20', { total: totalElements, length: searchResult.length });
    }

    return tResourceBase('brreg.SearchHits', { total: searchResult.length });
  };

  return (
    <div>
      <p>{tResourceBase('brreg.description')}</p>

      <InputField
        label={tResourceBase('brreg.searchLabel')}
        placeholder={tResourceBase('brreg.searchPlaceholder')}
        onChange={onChange}
        errorMsg={error}
        helpText={tResourceBase('brreg.searchHelpText')}
      />

      {searchResult.length > 0 ? (
        <>
          <RadioButton
            label={getRadioLabel()}
            name={resolvedName}
            options={searchResult.map((item) => ({
              text: `${formatOrganizationNumber(item.organizationNumber)} - ${item.companyName}`,
              value: item.organizationNumber,
              selected: item.organizationNumber === selectedResult,
            }))}
            onChange={(e: React.FormEvent<HTMLInputElement>) => {
              const { value } = e.currentTarget;
              setSelectedResult(value);
            }}
          />

          <Button
            type="button"
            theme={Theme.Neutral}
            variant={ButtonVariant.Outlined}
            onClick={() => onSelected(selectedResult)}
            disabled={selectedResult === ''}
            className="brreg-select-button"
          >
            {tCommon('select')}
          </Button>
        </>
      ) : (
        <>{notFound && <p>{tResourceBase('brreg.searhcNoHits')}</p>}</>
      )}

      <h4>{tResourceBase('brreg.notFindingTheCompany.title')}</h4>
      <p>{tResourceBase('brreg.notFindingTheCompany.description')}</p>

      <Button
        type="button"
        theme={Theme.Neutral}
        variant={ButtonVariant.Outlined}
        iconName={FaIcons.PlusLight}
        onClick={onSelectedEmpty}
      >
        {tResourceBase('brreg.addWithoutBrreg')}
      </Button>
    </div>
  );
};
