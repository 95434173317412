import { ProjectListItemDto, ProjectsDto } from 'src/api/v2';

export function isOperationProject(project: ProjectsDto | ProjectListItemDto) {
  return Object.hasOwn(project, 'operationApplicationId');
}

export function isDevelopmentProject(project: ProjectsDto | ProjectListItemDto) {
  return Object.hasOwn(project, 'projectType');
}

export function isPortalProject(project: ProjectsDto | ProjectListItemDto) {
  return !isOperationProject(project) && !isDevelopmentProject(project);
}
