import { NavItemModule } from 'src/components/AppHeader/types';
import { useAuth } from 'src/features/authorization';
import { ROUTES } from 'src/navigation';

export default function useNavItemModules() {
  const { checkAccess } = useAuth();

  const isSivaBusinessGarden = checkAccess('UI_SivaBusinessGarden').read;
  // Her lager vi modulene som skal i menyen
  // Indeksen i listen gjenspeiles i menyen
  const NavItemModules: NavItemModule[] = [
    {
      module: 'OneWayIn',
      text: 'opportunities',
      path: ROUTES.ONE_WAY_IN.BASE,
    },
    {
      module: 'ResourceBase',
      text: 'resourceBase',
      path: '/resource-base',
    },
    {
      module: 'Operation',
      text: 'clusterOperation',
      path: '/cluster-operation',
    },
    {
      module: ['PortalProject', 'DevelopmentProject'],
      text: 'Projects',
      path: '/projects',
    },
    {
      module: 'Assignments',
      text: 'assignments',
      path: '/assignments',
    },
    {
      module: 'Statistics',
      text: 'statistics',
      path: '/statistics',
    },
    {
      module: 'Financing',
      text: 'financing',
      path: '/financing',
    },
    {
      module: 'SivaCore',
      text: isSivaBusinessGarden ? 'targetedCompanies' : 'ideasAndCompanies',
      path: ROUTES.SIVA.IDEAS_AND_COMPANIES.BASE,
    },
    {
      module: 'SivaCore',
      text: 'sivaReporting',
      path: ROUTES.SIVA.REPORTING.BASE,
    },
    {
      module: 'SivaAdmin',
      text: 'sivaReporting',
      path: ROUTES.SIVA.ADMIN.BASE,
    },
    {
      module: 'SivaAdmin',
      text: 'sivaDataExtract',
      path: ROUTES.SIVA.ADMIN.EXTRACT.BASE,
    },
  ];

  return { NavItemModules };
}
