import React, { lazy } from 'react';

import { RouteObject } from 'react-router-dom';

const OwiContactsPage = lazy(() => import('./pages/OwiContactsPage'));

const routes: RouteObject[] = [
  {
    path: '',
    index: true,
    element: <OwiContactsPage />,
  },
];

export default routes;
