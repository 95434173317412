import { useState } from 'react';
import { useMutation } from 'react-query';
import { SivaService } from 'src/api/v2';

export function useSivaContractAllDataExtract() {
  const [service] = useState(() => new SivaService());

  return {
    download: useMutation(async (sivaProgramAdmin: boolean) => {
      return await service.getSivaContractsAllData(sivaProgramAdmin);
    }),
  };
}
