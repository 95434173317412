import i18next from 'i18next';
import { TFunction } from 'react-i18next';
import { ClusterContactsService } from 'src/api/v2';
import { emailRegex, organizationNumberRegex, postalCodeRegex } from './regex';

/**
 * @deprecated Bruk requiredI18n med oversetting i stedet for denne.
 */
export const required = {
  value: true,
  message: 'Dette feltet er påkrevd.',
};

/**
 *
 * @param t Må være hentet fra `useTranslation('error')`
 * @returns Oversettet tekst for `required`
 */
export const requiredI18n = (t: TFunction<'error'>) => {
  return {
    value: true,
    message: t('validation.required'),
  };
};

export const minValue = (value: number) => {
  const { t } = i18next;

  return {
    value,
    message: t<string>('error:validation.minValue', { value }),
  };
};

export const maxValue = (value: number) => {
  const { t } = i18next;

  return {
    value,
    message: t<string>('error:validation.maxValue', { value }),
  };
};

export const validEmail = (value: string | undefined): boolean | string => {
  if (value) {
    if (value.match(emailRegex)) {
      return true;
    }

    const { t } = i18next;

    return t<string>('error:validation.email');
  }

  // Pass true, so a required will handle no value
  return true;
};

export const validOrganizationNumber = (value: string | undefined): boolean | string => {
  if (value) {
    const trimmedValue = value.replaceAll(' ', '');
    if (trimmedValue.length === 9 && trimmedValue.match(organizationNumberRegex)) {
      return true;
    }

    const { t } = i18next;

    return t<string>('error:validation.organizationNumber');
  }

  // Pass true, so a required will handle no value
  return true;
};

export const validPostalCode = (value: string | undefined): boolean | string => {
  if (value) {
    if (value.match(postalCodeRegex)) {
      return true;
    }

    const { t } = i18next;

    return t<string>('error:validation.postalCode');
  }

  // Pass true, so a required will handle no value
  return true;
};

export const uniqueEmailInClusterContacts =
  (existing?: string) =>
  async (value: string | undefined): Promise<boolean | string> => {
    const { t } = i18next;

    if (value) {
      if (existing && existing === value) {
        return true;
      }

      if (value.match(emailRegex)) {
        const service = new ClusterContactsService();
        const response = await service.checkEmailAvailability(value);

        if (response === true) {
          return true;
        }

        return t<string>('error:validation.uniqueEmailInClusterContacts');
      }

      return t<string>('error:validation.email');
    }

    // Pass true, so a required will handle no value
    return true;
  };
