import React from 'react';
import './Autocomplete.scss';

import classNames from 'classnames';
import { useAsyncDebounce } from 'react-table';
import Input from '../Form/Input';

import adjustScroll from 'src/utils/scroll';
import { ErrorMessage } from '@in/component-library';
import '../../styles/style.scss';

export interface AutocompleteProps<T> extends React.InputHTMLAttributes<HTMLInputElement> {
  /**
   * Label for the input field
   */
  label?: string;

  /**
   * Array of items that the search will lookup into
   */
  items: Array<T>;

  /**
   * Render the value inside the input-field
   */
  itemLabel: (item: T) => string | number | readonly string[] | undefined;

  /**
   * How the filtering will be done
   */
  filter: (item: T, input: string) => boolean;

  /**
   * Render the values inside the select list
   */
  renderItem?: (item: T) => React.ReactNode;

  /**
   * Will trigger when an item in the list is selected.
   * Returns the whole object of
   */
  onItemSelected?: (item: T) => void;

  /**
   * How long it will take from last onChange-event to suggestions will be shown, in milliseconds (Default: 250)
   */
  debounceTime?: number;

  /**
   * Reset input field after select. (Default: false)
   */
  resetAfterSelect?: boolean;

  /**
   * Sorting the filtered values
   */
  sort?: (a: T, b: T) => 1 | -1 | 0;

  /**
   * Max amount of items shown in dropdown
   */
  maxCount?: number;

  /**
   * Show error message
   */
  errorMsg?: string;

  inputClassName?: string;
}

function AutocompleteInner<T>(
  {
    items,
    itemLabel,
    filter,
    renderItem,
    onItemSelected,
    debounceTime = 250,
    onChange,
    onKeyDown,
    onFocus,
    defaultValue,
    resetAfterSelect = false,
    sort,
    maxCount = 25,
    errorMsg,
    className,
    inputClassName,
    ...rest
  }: AutocompleteProps<T>,
  ref?: React.ForwardedRef<HTMLInputElement>,
) {
  const [filtered, setFiltered] = React.useState<Array<T>>([]);
  const [currentValue, setCurrentValue] = React.useState<string | number | readonly string[] | undefined>(
    defaultValue,
  );
  const [currentIndex, setCurrentIndex] = React.useState<number>(-1);

  const listRef = React.createRef<HTMLUListElement>();
  const inputRef = React.createRef<HTMLInputElement>();
  const resolvedRef = ref || inputRef;

  const defaultRenderItem = (item: T): React.ReactNode => {
    switch (typeof item) {
      case 'undefined':
        return null;

      case 'object':
      case 'string':
      case 'number':
      default:
        return <>{item}</>;
    }
  };

  const resolvedRenderItem = renderItem || defaultRenderItem;

  const setFilteredSortedAndSliced = React.useCallback(
    (arr: Array<T>) => {
      let result = [...arr];

      if (sort) {
        result = result.sort(sort);
      }

      result = result.slice(0, maxCount);

      setFiltered(result);
    },
    [maxCount, sort],
  );

  const onClickOutside = React.useCallback(
    (event: MouseEvent) => {
      if (typeof resolvedRef !== 'function' && resolvedRef.current) {
        if (!resolvedRef.current?.contains(event.target as Node)) {
          setFilteredSortedAndSliced([]);
        }
      }
    },
    [resolvedRef, setFilteredSortedAndSliced],
  );

  React.useEffect(() => {
    if (typeof resolvedRef !== 'function' && resolvedRef.current && currentValue) {
      if (resetAfterSelect) {
        resolvedRef.current.value = '';
      } else {
        resolvedRef.current.value = `${currentValue}`;
      }
    }
  }, [currentValue, resetAfterSelect, resolvedRef]);

  React.useEffect(() => {
    if (currentIndex !== -1 && listRef.current) {
      adjustScroll(listRef.current as HTMLElement, currentIndex);
    }
  }, [currentIndex, listRef]);

  React.useEffect(() => {
    if (!defaultValue && currentValue) {
      if (typeof resolvedRef !== 'function' && resolvedRef.current && currentValue) {
        resolvedRef.current.value = '';
      }
    }
    setCurrentValue(defaultValue);
  }, [currentValue, defaultValue, resolvedRef]);

  React.useEffect(() => {
    window.addEventListener('click', onClickOutside);

    return () => {
      window.removeEventListener('click', onClickOutside);
    };
  }, [onClickOutside]);

  const handleOnChange = useAsyncDebounce((event: React.ChangeEvent<HTMLInputElement>) => {
    // Let user use 'onChange' as normal
    onChange?.(event);

    const { value } = event.target;

    const _filtered = items.filter((item) => filter(item, value));
    setCurrentValue(undefined);
    setFilteredSortedAndSliced(_filtered);
    setCurrentIndex(0);
  }, debounceTime);

  const handleOnClick = (event: React.MouseEvent<HTMLButtonElement>, index: number) => {
    event.preventDefault();

    setValueFromIndex(index);
  };

  const setValueFromIndex = (index: number) => {
    const selectedValue = filtered[index];
    onItemSelected?.(selectedValue);
    const newCurrentValue = itemLabel(selectedValue);
    setCurrentValue(newCurrentValue);
    setFilteredSortedAndSliced([]);
  };

  const handleOnKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    onKeyDown?.(event);

    let newIndex: number = -1;

    switch (event.key) {
      case 'ArrowDown':
        event.preventDefault();
        newIndex = (currentIndex + 1) % filtered.length;
        setCurrentIndex(newIndex);
        return;

      case 'ArrowUp':
        event.preventDefault();
        newIndex = (currentIndex - 1 + filtered.length) % filtered.length;
        setCurrentIndex(newIndex);
        return;

      case 'Enter':
        event.preventDefault();
        setValueFromIndex(currentIndex);
        return;

      case 'Escape':
      case 'Tab':
        setFilteredSortedAndSliced([]);
        setCurrentIndex(0);
        return;
    }
  };

  const handleOnFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    onFocus?.(event);

    setCurrentValue(undefined);
    setFilteredSortedAndSliced(items);
    setCurrentIndex(0);
  };

  return (
    <div className={classNames('autocomplete', className)}>
      <Input
        ref={resolvedRef}
        onChange={handleOnChange}
        onKeyDown={handleOnKeyDown}
        onFocus={handleOnFocus}
        defaultValue={defaultValue}
        autoComplete="off"
        aria-autocomplete="none"
        className={inputClassName}
        {...rest}
      />
      {filtered.length > 0 && (
        <div className="autocomplete__list--wrapper">
          <ul className="autocomplete__list" ref={listRef}>
            {filtered.map((item, index) => (
              <button
                className={classNames(
                  'clickable-wrapper',
                  'item-wrapper',
                  'autocomplete__list--item-wrapper',
                )}
                key={index}
                onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                  handleOnClick(event, index);
                }}
              >
                <li
                  role="option"
                  aria-selected={index === currentIndex}
                  className={classNames('autocomplete__list--item', { active: index === currentIndex })}
                >
                  {resolvedRenderItem(item)}
                </li>
              </button>
            ))}
          </ul>
        </div>
      )}
      <ErrorMessage errorMessage={errorMsg || ''} />
    </div>
  );
}

export const Autocomplete = React.forwardRef(AutocompleteInner) as <T>(
  props: AutocompleteProps<T> & { ref?: React.ForwardedRef<HTMLInputElement> },
) => ReturnType<typeof AutocompleteInner>;
