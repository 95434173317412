import React from 'react';
import { useTranslation } from 'react-i18next';
import { SivaContractPhaseDto, SivaPhase } from 'src/api/v2';
import { formatDateToNOString } from 'src/utils/FormatValue';

type Props = {
  phases: SivaContractPhaseDto[];
};

const ContractHistoric: React.FC<Props> = ({ phases }) => {
  const { t: tCommon } = useTranslation();
  const { t: tSiva } = useTranslation('siva');

  // We receive the phases from oldest to newest from SL. Thus we can create the history from the beginning, and reverse to show newest first.
  const sortedHistory = React.useMemo(() => {
    const phaseToTranslatedPhase = (phase: SivaPhase) => {
      return tSiva(`phase.${phase}` as any, {
        defaultValue: phase || '',
      });
    };

    return (
      phases
        .map((phase, index) => {
          let text = '';
          if (phase.sivaPhase === SivaPhase.Alumni) {
            const previousPhase = phases.at(index - 1);

            if (previousPhase && previousPhase.sivaPhase === SivaPhase.Idea) {
              text = tSiva('company.details.historic.ideaConcluded');
            } else {
              text = tSiva('company.details.historic.contractConcluded');
            }
          } else if (index === 0) {
            text = `${phaseToTranslatedPhase(phase.sivaPhase)} ${tSiva('company.details.historic.Registered')}`;
          } else {
            const previousPhase = phases[index - 1];

            if (previousPhase.sivaPhase === SivaPhase.Alumni) {
              const phaseTwiceRemoved = phases.at(index - 2);

              if (phaseTwiceRemoved && phaseTwiceRemoved.sivaPhase === SivaPhase.Idea) {
                text = tSiva('company.details.historic.ideaRestored');
              } else {
                text = tSiva('company.details.historic.contractRestored');
              }
            } else {
              text = tSiva('company.details.historic.ChangedFromTo', {
                from: phaseToTranslatedPhase(previousPhase.sivaPhase),
                to: phaseToTranslatedPhase(phase.sivaPhase),
              });
            }
          }

          return {
            date: new Date(phase.fromDate),
            text: text,
          };
        })
        .reverse() || []
    );
  }, [phases, tSiva]);

  if (phases.length === 0) {
    return <div className="margin-bottom--5">{tSiva('company.details.historic.None')}</div>;
  }

  return (
    <div>
      <div className="margin-bottom--2">
        <b>{tCommon('historic')}</b>
      </div>
      <div className="display--flex flex-direction--column gap--2">
        {sortedHistory.map((history) => (
          <div key={history.date.getTime()}>
            <span className="margin-right--2">
              {formatDateToNOString(new Date(history.date), false, '{date}.{month}.{year}', true)}
            </span>
            <span>{history.text}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ContractHistoric;
