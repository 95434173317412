import React, { useMemo } from 'react';
import { IndeterminateCheckbox } from 'src/components/IndeterminateCheckbox/IndeterminateCheckbox';
import css from './RecommendServiceListItem.module.scss';
import { FaIcon, FaIcons } from '@in/component-library';
import cn from 'classnames';

type RecommendServiceListItemProps = {
  id: string;
  name: string;
  counties: string[];
  tags: string[];
  prosecutor: string;
  contactPerson: string;
  contactPersonEmail: string;
  url?: string;
  onChange?: (e: React.FormEvent<HTMLInputElement>) => void;
  checked: boolean;
  servicePurpose?: string;
};

const RecommendServiceListItem: React.FC<RecommendServiceListItemProps> = ({
  id,
  name,
  counties,
  prosecutor,
  url,
  onChange,
  checked,
}) => {
  const countiesAsString = useMemo(() => {
    return counties.join(', ');
  }, [counties]);

  return (
    <div
      className={cn(css['recommend-service-list-item'], {
        [css['recommend-service-list-item--selected']]: checked,
      })}
    >
      {onChange !== undefined && (
        <div>
          <IndeterminateCheckbox onChange={onChange} value={id} checked={checked} />
        </div>
      )}
      <div className={css['recommend-service-list-item__name']}>
        <div>{name}</div>
      </div>
      <div className={css['recommend-service-list-item__prosecutor']}>{prosecutor}</div>
      <div className={css['recommend-service-list-item__name']}>{countiesAsString}</div>
      <div>
        {url && (
          <a href={url.startsWith('https://') ? url : `https://${url}`} rel="noreferrer" target="_blank">
            <FaIcon name={FaIcons.ExternalLinkLight} />
          </a>
        )}
      </div>
    </div>
  );
};

export default RecommendServiceListItem;
