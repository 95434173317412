enum UseDateShortForm {
  NONE = 0,
  SHORT_FORM_DAY = 1,
  SHORT_FORM_MONTH = 2,
  SHORT_FORM_YEAR = 4,
}
const DAYS_NO = ['søndag', 'mandag', 'tirsdag', 'onsdag', 'torsdag', 'fredag', 'lørdag'];
const DAYS_NO_SHORT = ['søn', 'man', 'tirs', 'ons', 'tors', 'fre', 'lør'];
const MONTHS_NO = [
  'januar',
  'februar',
  'mars',
  'april',
  'mai',
  'juni',
  'juli',
  'august',
  'september',
  'oktober',
  'november',
  'desember',
];
const MONTHS_NO_SHORT = ['jan', 'feb', 'mar', 'apr', 'mai', 'jun', 'jul', 'aug', 'sept', 'okt', 'nov', 'des'];

export function formatCurrencySimple(value: number, showNokSuffix: boolean = true): string {
  return formatAsCurrencyString(
    value,
    ' ',
    '',
    0,
    null,
    showNokSuffix ? ' NOK' : '',
    showNokSuffix ? '0 NOK' : '0',
  );
}
export function formatDateInputDate(date?: Date | string): string {
  return date ? formatDateToNOString(date, false, '{year}-{month}-{date}', true) : '';
}
/**
 * Method for formatting a date object to a string. Lets you control how the end results end up with a format string. The method replaces the following values in
 * your format string:
 * {day}    - The day name string
 * {date}   - The date number.
 * {month}  - The month name, or number
 * {year}   - The year number.
 * @param {Date | string | number} date
 * @param {boolean} [useMonthName=true]
 * @param {string} [formatString="{day} {date}. {month} {year}"]
 * @param {boolean} [zeroPadNumberValues=false]
 * @param {UseDateShortForm} [useShortFormOnValues = 0]
 * @returns {string}
 */
export function formatDateToNOString(
  date: Date | string | number,
  useMonthName?: boolean,
  formatString?: string,
  zeroPadNumberValues?: boolean,
  useShortFormOnValues?: UseDateShortForm,
): string {
  if (useMonthName === void 0) {
    useMonthName = true;
  }
  if (formatString === void 0) {
    formatString = '{day} {date}. {month} {year}';
  }
  if (zeroPadNumberValues === void 0) {
    zeroPadNumberValues = false;
  }
  if (useShortFormOnValues === void 0) {
    useShortFormOnValues = 0;
  }
  if (
    date == null ||
    (typeof date !== 'string' && (typeof date !== 'number' || isNaN(date)) && !(date instanceof Date))
  ) {
    throw new SyntaxError(
      'formatDateToNOString() - The date parameter is not a valid string, number or Date.',
    );
  }
  date = typeof date === 'string' || typeof date === 'number' ? new Date(date) : date;
  if (!(date instanceof Date)) {
    throw new Error(
      'formatDateToNOString() - The date parameter was not possible to parse as a valid string.',
    );
  }
  const dayMap =
      (useShortFormOnValues & UseDateShortForm.SHORT_FORM_DAY) === UseDateShortForm.SHORT_FORM_DAY
        ? DAYS_NO_SHORT
        : DAYS_NO,
    monthMap =
      (useShortFormOnValues & UseDateShortForm.SHORT_FORM_MONTH) === UseDateShortForm.SHORT_FORM_MONTH
        ? MONTHS_NO_SHORT
        : MONTHS_NO,
    fullYear = date.getFullYear().toString(),
    formatDay = dayMap[date.getDay()],
    formatDate = zeroPadNumberValues
      ? _convertToStringAndZeroPadNumber(date.getDate())
      : date.getDate().toString(),
    formatMonth = useMonthName
      ? monthMap[date.getMonth()]
      : zeroPadNumberValues
      ? _convertToStringAndZeroPadNumber(date.getMonth() + 1)
      : (date.getMonth() + 1).toString(),
    formatYear =
      (useShortFormOnValues & UseDateShortForm.SHORT_FORM_YEAR) === UseDateShortForm.SHORT_FORM_YEAR
        ? fullYear.substring(2)
        : fullYear;
  return formatString
    .replace('{day}', formatDay)
    .replace('{date}', formatDate)
    .replace('{month}', formatMonth)
    .replace('{year}', formatYear);
}

/**
 * Updated format as currency string method.
 *
 * @param {string|number}   value               - The value to format.
 * @param {string}          [thousandSeparator] - separator char between thousand
 * @param {string}          [dotReplacement]    - Replaces the '.' in the number, if it has decimals.
 * @param {number}          [decimalCount]      - Limits the number of decimal numbers
 * @param {string}          [prefix]            - Adds a string to the beginning of the value.
 * @param {string}          [suffix]            - Adds a string to the end of the value.
 * @param {string}          [defaultValueOnNull]- Default value returned if the value property is null;
 * @returns {string}
 */
export function formatAsCurrencyString(
  value: string | number,
  thousandSeparator?: string,
  dotReplacement?: string,
  decimalCount?: number,
  prefix?: string | null,
  suffix?: string | null,
  defaultValueOnNull?: string,
): string {
  if (thousandSeparator === void 0) {
    thousandSeparator = ' ';
  }
  if (dotReplacement === void 0) {
    dotReplacement = '.';
  }
  if (decimalCount === void 0) {
    decimalCount = 2;
  }
  if (defaultValueOnNull === void 0) {
    defaultValueOnNull = '0';
  }
  let valueString, valueAsArray;

  if (!value) {
    return defaultValueOnNull;
  }
  const decimals = value.toString().split('.')[1];
  if (typeof value === 'string') {
    value = parseFloat(value.replace(',', '.').replace(' ', ''));
  }
  if (decimals) {
    decimalCount = decimals ? Math.min(decimalCount, decimals.length) : decimalCount;
    valueAsArray = value.toFixed(decimalCount).split('.');
  } else {
    valueAsArray = value.toString().split('.');
  }
  for (let i = 0, length_1 = valueAsArray.length; i < length_1; ++i) {
    valueAsArray[i] = valueAsArray[i].split(/(?=(?:\d{3})+(?:\.|$))/g).join(thousandSeparator);
  }
  valueString = valueAsArray[0];
  if (valueAsArray.length > 1) {
    valueString += dotReplacement + valueAsArray[1];
  }
  valueString = typeof prefix === 'string' ? prefix + valueString : valueString;
  valueString = typeof suffix === 'string' ? valueString + suffix : valueString;
  return valueString;
}

/**
 * Helper method for padding a number with zeros, so it's always two digits.
 * @param {number}  value
 * @returns {string}
 * @private
 */
function _convertToStringAndZeroPadNumber(value: number) {
  return ('0' + value.toString()).slice(-2);
}

/**
 * Helper method to convert date to UTC date, ignoring time
 * @param {}
 */
export function convertDateToUTC(value: Date) {
  return new Date(Date.UTC(value.getFullYear(), value.getMonth(), value.getDate()));
}
