import { ClusterMemberCategoryDto } from 'src/api/v2';

interface IClusterMemberCategories {
  Member: ClusterMemberCategoryDto;
  CoreMember: ClusterMemberCategoryDto;
  AssociateMember: ClusterMemberCategoryDto;
  Lead: ClusterMemberCategoryDto;
  NotSet: ClusterMemberCategoryDto;
}

export const ClusterMemberCategories: IClusterMemberCategories = {
  CoreMember: {
    id: '701b4729-e244-47d3-b37d-ab9e47b5c961',
    name: 'Core member',
    code: 'CORE',
    description: 'Kjernemedlem',
  },
  Member: {
    id: '2928925a-cb12-4da5-814c-00615351b2a4',
    name: 'Member',
    code: 'MEMBER',
    description: 'Medlem',
  },
  AssociateMember: {
    id: 'e482de62-73f9-46b1-b6a0-b34bd2f23c7c',
    name: 'Associated member',
    code: 'ASSOCIATED_MEMBER',
    description: 'Assosiert medlem',
  },
  Lead: {
    id: '4776f757-e6c1-4462-a041-757c1c04db6d',
    name: 'Lead',
    code: 'LEAD',
    description: 'Lead',
  },
  NotSet: {
    id: '13461328-cb29-4bc3-95ff-8b6e094937d4',
    name: 'NotSet',
    code: '',
    description: 'Not set.',
  },
};
