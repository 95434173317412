import React from 'react';

import { Stepper } from 'src/components/Stepper';

import { Intro } from './Intro';
import { Step1 } from './Step1';
import { Step2 } from './Step2';
import { Step3 } from './Step3';

type Props = {
  onClose: () => void;
};

const CreateMemberForm: React.FC<Props> = ({ onClose }) => {
  return (
    <Stepper
      steps={[
        {
          title: 'intro',
          element: (props) => <Intro {...props} />,
        },
        {
          title: 'step 1',
          element: (props) => <Step1 {...props} />,
        },
        {
          title: 'step 2',
          element: (props) => <Step2 {...props} />,
        },
        {
          title: 'step 3',
          element: (props) => <Step3 {...props} onClose={onClose} />,
        },
      ]}
    />
  );
};

export default CreateMemberForm;
