import { Button } from '@in/component-library';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { SivaPhase } from 'src/api/v2';
import DialogContainer from 'src/components/DialogContainer/DialogContainer';

type Props = {
  open: boolean;
  onClose: () => void;
  onGoBack: () => void;
  onReset: () => void;
  error?: React.ReactNode;
  name?: string;
  phase: SivaPhase;
};

const NewCompanyDialog: React.FC<Props> = ({ open, onClose, onGoBack, onReset, error, name, phase }) => {
  const { t: tSiva } = useTranslation('siva');

  return (
    <DialogContainer
      title={error ? tSiva('newCompany.dialog.title.error') : tSiva('newCompany.dialog.title.confirmation')}
      show={open}
      onClose={() => {
        if (open) {
          onGoBack();
        }
      }}
    >
      <div className="margin-bottom--6">
        {error !== undefined ? (
          <>
            <p>
              <Trans
                t={tSiva}
                i18nKey={'newCompany.dialog.text.error'}
                values={{ value: name }}
                components={{
                  '1': <strong>ikke</strong>,
                }}
              >
                {name} har <strong>IKKE</strong> blitt lagt til
              </Trans>
            </p>

            <p>{error}</p>
          </>
        ) : (
          <p>
            {/* <Trans
              t={tSiva}
              i18nKey={'newCompany.dialog.text.confirmation'}
              values={{ value: name }}
              components={{
                '1': <strong>{name}</strong>,
              }}
            >
              Bedriften <strong>{name}</strong> ble lagt til.
            </Trans> */}
            {name} med fase <>{tSiva(`phase.${phase}`).toLocaleLowerCase()}</> ble lagt til.
          </p>
        )}
      </div>

      <div className="display--flex gap--1">
        {error ? (
          <>
            <Button type="button" theme="neutral" variant="solid" onClick={onClose}>
              {tSiva('newCompany.dialog.buttons.goBack.text')}
            </Button>

            <Button type="button" theme="neutral" variant="outlined" onClick={onGoBack}>
              {tSiva('newCompany.dialog.buttons.cancel.text')}
            </Button>
          </>
        ) : (
          <>
            <Button type="button" theme="neutral" variant="solid" onClick={onGoBack}>
              {tSiva('newCompany.dialog.buttons.closeAndGoBack.text')}
            </Button>
            <Button type="button" theme="neutral" variant="outlined" onClick={onReset}>
              {tSiva('newCompany.dialog.buttons.addNew.text')}
            </Button>
          </>
        )}
      </div>
    </DialogContainer>
  );
};

export default NewCompanyDialog;
