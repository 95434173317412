import { useState, useEffect } from 'react';

interface WindowHook {
  windowHeight: number | undefined;
  windowWidth: number | undefined;
  isDesktop: boolean;
}

const useWindowSize = (): WindowHook => {
  const [windowSize, setWindowSize] = useState<WindowHook>({
    windowHeight: undefined,
    windowWidth: undefined,
    isDesktop: true,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        windowHeight: window.innerHeight,
        windowWidth: window.innerWidth,
        isDesktop: window.innerWidth >= 767,
      });
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    // Cleanup
    return () => window.removeEventListener('resize', handleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return windowSize;
};

export default useWindowSize;
