import React from 'react';
import classnames from 'classnames';
import generateGuid from 'src/utils/Guid';
import FormElementError from './FormElementError';
import './style.scss';

export type FormGroupErrorContextProps = {
  error?: React.ReactNode | boolean;
  errorId?: string;
};

export const FormGroupErrorContext = React.createContext<Partial<FormGroupErrorContextProps>>({});

export interface FormGroupProps extends React.HTMLAttributes<HTMLElement> {
  children: React.ReactNode | React.ReactNode[];
  className?: string;
  tag?: React.ElementType;
  legend?: React.ReactNode;
  description?: React.ReactNode;
  error?: React.ReactNode | boolean;
  errorId?: string;
  withoutErrorPropagation?: boolean;
}

const FormGroup: React.FC<FormGroupProps> = ({
  children,
  className,
  tag = 'fieldset',
  legend,
  description,
  error,
  errorId = generateGuid(),
  withoutErrorPropagation,
  ...props
}) => {
  const classes = classnames('form-group', className);

  const descriptionId = description ? generateGuid() : undefined;

  props['aria-invalid'] = !!error;
  props['aria-errormessage'] = error ? errorId : undefined;
  props['aria-describedby'] = descriptionId;

  const Component = tag;
  return (
    <Component className={classes} {...props}>
      <>
        {legend && <legend className="form-group__legend">{legend}</legend>}
        {description && (
          <div className="form-group__description" id={descriptionId}>
            {description}
          </div>
        )}
        {withoutErrorPropagation ? (
          children
        ) : (
          <FormGroupErrorContext.Provider value={{ error, errorId }}>
            {children}
          </FormGroupErrorContext.Provider>
        )}
        <FormElementError id={errorId}>{typeof error !== 'boolean' && error}</FormElementError>
      </>
    </Component>
  );
};

export default FormGroup;
