import { FaIcons, InputField } from '@in/component-library';
import React from 'react';
import { DataTableV3GlobalFilterProps } from '../types';
import { useTranslation } from 'react-i18next';

const DataTableGlobalFilter: React.FC<DataTableV3GlobalFilterProps> = ({
  texts,
  value,
  onChange,
  disabled,
}) => {
  const { t: tCommon } = useTranslation();

  if (disabled) {
    return <div className="flex--1" />;
  }

  return (
    <InputField
      className="flex--1 margin-bottom--2"
      placeholder={texts?.globalFilterPlaceholder ?? tCommon('search') + '...'}
      value={value}
      onChange={onChange}
      icon={FaIcons.SearchLight}
      iconPlacement="right"
      aria-label={texts?.globalFilterPlaceholder ?? tCommon('search') + '...'}
      hideError
    />
  );
};

export default DataTableGlobalFilter;
