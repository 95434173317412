import React from 'react';

import classNames from 'classnames';

import './Table.scss';
import { mapAllowedChildren } from './util';
import {
  TbodyProps,
  TdProps,
  TfootProps,
  ThProps,
  TheadProps,
  TableProps,
  TrProps,
  TableSubComponents,
} from './types';

const Table: React.FC<TableProps> & TableSubComponents = ({
  children,
  className,
  ...rest
}: React.PropsWithChildren<TableProps>) => {
  const content = mapAllowedChildren(children, ['Thead', 'Tbody', 'Tfoot']);

  return (
    <table className={classNames('table', className)} {...rest}>
      {content}
    </table>
  );
};

export const Thead: React.FC<TheadProps> = ({ children, ...rest }) => {
  const content = mapAllowedChildren(children, ['Tr']);

  return <thead {...rest}>{content}</thead>;
};

export const Tbody: React.FC<TbodyProps> = ({ children, ...rest }) => {
  const content = mapAllowedChildren(children, ['Tr']);

  return <tbody {...rest}>{content}</tbody>;
};

export const Tfoot: React.FC<TfootProps> = ({ children, ...rest }) => {
  const content = mapAllowedChildren(children, ['Tr']);

  return <tfoot {...rest}>{content}</tfoot>;
};

export const Tr: React.FC<TrProps> = ({ children, ...rest }) => {
  const content = mapAllowedChildren(children, ['Th', 'Td']);

  return <tr {...rest}>{content}</tr>;
};

export const Th: React.FC<ThProps> = ({ children, ...rest }) => {
  return <th {...rest}>{children}</th>;
};

export const Td: React.FC<TdProps> = ({ children, ...rest }) => {
  return <td {...rest}>{children}</td>;
};

Table.displayName = 'Table';
Thead.displayName = 'Thead';
Tbody.displayName = 'Tbody';
Tfoot.displayName = 'Tfoot';
Th.displayName = 'Th';
Tr.displayName = 'Tr';
Td.displayName = 'Td';

Table.Head = Thead;
Table.Body = Tbody;
Table.Foot = Tfoot;
Table.Header = Th;
Table.Row = Tr;
Table.Cell = Td;

export default Table;
