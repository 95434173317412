import { useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { TimeRateCreateDto, TimeRatesService, TimeRateUpdateDto } from 'src/api/v2';

import { TIME_RATES_KEY, CONTACTS_KEY, CONTACT_KEY } from 'src/constants/hook-keys';

import useCluster from '../features/cluster/hooks/use-cluster';

export default function useTimeRates() {
  const { cluster } = useCluster();

  const [service] = useState(new TimeRatesService());

  const queryClient = useQueryClient();

  const { data, ...rest } = useQuery([TIME_RATES_KEY], () => service.getAll(cluster!.id), {
    enabled: !!cluster,
  });

  const defaultTimeRate = useMemo(() => data?.find((x) => x.isClusterDefault), [data]);
  const timeRates = useMemo(() => data?.filter((x) => !x.isClusterDefault), [data]);

  const createMutation = useMutation(
    (record: TimeRateCreateDto) => {
      if (!record.clusterId) {
        record.clusterId = cluster!.id;
      }

      return service.create(record);
    },
    {
      onSuccess() {
        queryClient.invalidateQueries([TIME_RATES_KEY]);
      },
    },
  );

  const updateMutation = useMutation(
    ({ timeRateId, record }: { timeRateId: string; record: TimeRateUpdateDto }) => {
      return service.update(timeRateId, record);
    },
    {
      onSuccess() {
        queryClient.invalidateQueries([TIME_RATES_KEY]);
      },
    },
  );

  const deleteMutation = useMutation(
    (timeRateId: string) => {
      const found = data?.find((x) => x.id === timeRateId);
      if (found && found.isClusterDefault) {
        throw new Error('Kan ikke slette standard timesats.');
      }

      return service.delete(timeRateId);
    },
    {
      onSuccess() {
        queryClient.invalidateQueries([TIME_RATES_KEY]);
        // Refetch contacts because we're changing timerate which is connected to some.
        queryClient.resetQueries([CONTACTS_KEY]);
        queryClient.resetQueries([CONTACT_KEY]);
      },
    },
  );

  return {
    timeRates: timeRates || [],
    defaultTimeRate,

    ...rest,

    createMutation,
    updateMutation,
    deleteMutation,
  };
}
