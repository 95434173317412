import React from 'react';

export function useLocalStorage<T>(key: string, initialValue?: T) {
  const [storedValue, setStoredValue] = React.useState<T>(() => {
    try {
      const item = window.localStorage.getItem(key);

      return item ? JSON.parse(item) : initialValue;
    } catch {
      // mulige feil her er non-json verdi i localstorage, eller at window ikke er definert (SSR)
      return initialValue;
    }
  });

  const setValue = (value: T | ((val: T) => T)) => {
    try {
      const valueToStore = value instanceof Function ? value(storedValue) : value;

      setStoredValue(valueToStore);

      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch {
      // Mulige feil: window === undeifned (SSR)
      // JSON.stringify kunne ikke utføres
      // (val: T) => T gir exception
    }
  };

  return [storedValue, setValue] as const;
}
