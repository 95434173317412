import { useQuery } from 'react-query';
import { MUNICIPALITY_DETAILS_KEY } from 'src/constants/hook-keys';

type MunicipalityDetailsType = {
  avgrensningsboks: {
    coordinates: number[][];
    crs: {
      properties: {
        name: string;
      };
      type: string;
    };
    type: string;
  };
  fylkesnavn: string;
  fylkesnummer: string;
  gyldigeNavn: {
    navn: SVGStringList;
    prioritet: number;
    sprak: string;
  }[];
  kommunenavn: string;
  kommunenavnNorsk: string;
  kommunenummer: string;
  punkIOmrade: {
    coordinates: number[];
    crs: {
      properties: {
        name: string;
      };
      type: string;
    };
    type: string;
  };
  samiskForvaltningsomrade: boolean;
};

export function useMunicipalityDetails(municipalityCode: string) {
  return useQuery({
    queryKey: [MUNICIPALITY_DETAILS_KEY, municipalityCode],
    queryFn: async () => {
      const resp = await fetch(`https://ws.geonorge.no/kommuneinfo/v1/kommuner/${municipalityCode}`);
      if (resp.status === 404) {
        return null;
      }

      const data = await resp.json();

      return data as MunicipalityDetailsType;
    },
    enabled: !!municipalityCode,
  });
}
