import React, { useContext } from 'react';
import { Button } from '@in/component-library';
import { useTranslation } from 'react-i18next';
import CommunicationBetweenActors from './CommunicationBetweenActors';
import Referralprocess from './ReferralProcess';
import { SideMenuSubpageContext } from '../OnboardingSideMenu';
import SideMenuNavButton from '../drawer-sidemenu/SideMenuNavButton';

const CommonQuestions: React.FC = () => {
  const { t: tCommon } = useTranslation();
  const { t: tHelpAndSupport } = useTranslation('onboarding');
  const { commonQuestionsSubpage, setCommonQuestionsSubpage } = useContext(SideMenuSubpageContext);

  if (commonQuestionsSubpage !== 'none') {
    return (
      <div className="help-and-support-common-questions__subpage">
        <h2>{tHelpAndSupport('helpAndSupport.tab.CommonQuestions')}</h2>

        {commonQuestionsSubpage === 'actorCommunication' && <CommunicationBetweenActors />}
        {commonQuestionsSubpage === 'referralprocess' && <Referralprocess />}

        <Button
          iconName="fa-light fa-arrow-left"
          iconPosition="left"
          size="medium"
          theme="neutral"
          variant="link"
          className="margin-top--3"
          onClick={() => setCommonQuestionsSubpage && setCommonQuestionsSubpage('none')}
        >
          {tCommon('previous')}
        </Button>
      </div>
    );
  }

  return (
    <div>
      <h2>{tHelpAndSupport('helpAndSupport.tab.CommonQuestions')}</h2>
      <SideMenuNavButton
        onClick={() => setCommonQuestionsSubpage && setCommonQuestionsSubpage('actorCommunication')}
      >
        {tHelpAndSupport('commonQuestions.Interplay.CommunicationBetweenActors')}
      </SideMenuNavButton>
      <SideMenuNavButton
        onClick={() => setCommonQuestionsSubpage && setCommonQuestionsSubpage('referralprocess')}
      >
        {tHelpAndSupport('commonQuestions.Interplay.Referralprocess')}
      </SideMenuNavButton>
    </div>
  );
};

export default CommonQuestions;
