import { OneWayInClosedReason, OneWayInOpportunityStatusCreateDto, OneWayInStatus } from 'src/api/v2';

const createOwiUpdateDto = (
  opprtunityId,
  status: OneWayInStatus,
  comment: string,
  userId: string | undefined,
  handlersName?: string | undefined,
  closedReason?: OneWayInClosedReason | undefined,
) =>
  ({
    oneWayInOpportunityId: opprtunityId,
    referenceClusterId: undefined,
    status: status,
    description: comment,
    createdOn: new Date(),
    closedReason: closedReason,
    registeredServices: [],
    recommendedServices: [],
    clusterContactId: undefined,
    clusterUserId: userId,
    handlersName: handlersName,
  }) as OneWayInOpportunityStatusCreateDto;

export default createOwiUpdateDto;
