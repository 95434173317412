import { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import {
  CustomProjectCategoryCreateDto,
  CustomProjectCategoryUpdateDto,
  ProjectCustomCategoriesService,
} from 'src/api/v2';

import { PROJECT_CATEGORIES_KEY, PROJECT_CATEGORY_KEY } from 'src/constants/hook-keys';

import useCluster from '../features/cluster/hooks/use-cluster';

export default function useProjectCategories() {
  const { cluster } = useCluster();

  const [service] = useState(new ProjectCustomCategoriesService());

  const queryClient = useQueryClient();

  const { data, ...rest } = useQuery(
    [PROJECT_CATEGORIES_KEY],
    () => service.getProjectCustomCategories(cluster!.id),
    {
      enabled: !!cluster,
    },
  );

  const createMutation = useMutation(
    (record: CustomProjectCategoryCreateDto) => {
      return service.createProjectCustomCategory(cluster!.id, record);
    },
    {
      onSuccess() {
        queryClient.invalidateQueries([PROJECT_CATEGORIES_KEY], { exact: true });
      },
    },
  );

  type UpdateMutationArgs = {
    projectCategoryId: string;
    record: CustomProjectCategoryUpdateDto;
  };

  const updateMutation = useMutation(
    ({ projectCategoryId, record }: UpdateMutationArgs) => {
      return service.updateProjectCustomCategory(cluster!.id, projectCategoryId, record);
    },
    {
      onSuccess(data) {
        queryClient.invalidateQueries([PROJECT_CATEGORIES_KEY], { exact: true });
        queryClient.invalidateQueries([PROJECT_CATEGORY_KEY, data.id], { exact: true });
      },
    },
  );

  const deleteMutation = useMutation(
    (projectCategoryId: string) => {
      return service.deleteProjectCustomCategory(cluster!.id, projectCategoryId);
    },
    {
      onSuccess(_, args) {
        queryClient.invalidateQueries([PROJECT_CATEGORIES_KEY], { exact: true });
        queryClient.invalidateQueries([PROJECT_CATEGORY_KEY, args], { exact: true });
      },
    },
  );

  return {
    projectCategories: data || [],

    ...rest,

    createMutation,
    updateMutation,
    deleteMutation,
  };
}
