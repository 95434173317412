import { useMemo } from 'react';
import { OneWayInStatus } from 'src/api/v2';
import useOneWayInList from './use-one-way-in-list';

const useOneWayInNewOpportunitiesNumber = () => {
  const { oneWayInAllSavedFormsQuery } = useOneWayInList();

  const alertOneWayInNewOpportunities = useMemo(() => {
    if (!oneWayInAllSavedFormsQuery.data) {
      return 0;
    }
    const newReferredOpportunities = oneWayInAllSavedFormsQuery.data.filter(
      (x) =>
        !x.status?.status ||
        x.status?.status === OneWayInStatus.Received ||
        x.status?.status === OneWayInStatus.New,
    ).length;

    return newReferredOpportunities;
  }, [oneWayInAllSavedFormsQuery.data]);

  return {
    alertOneWayInNewOpportunities,
  };
};

export default useOneWayInNewOpportunitiesNumber;
