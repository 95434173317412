function sanitizeHtml(htmlString: string): string {
  // Keep <img> tags, <a> tags and <br> tags, remove all other tags, scripts, and styling
  const sanitizedHtml = htmlString.replace(
    /<(?!\/?(a|br|p|img)|\/?script)[^>]*(>|$)|<style[^>]*>[\s\S]*?<\/style>/gi,
    '',
  );

  // Parse the sanitized HTML to handle any remaining HTML entities
  const parser = new DOMParser();
  const doc = parser.parseFromString(sanitizedHtml, 'text/html');

  // Remove all img tags
  const imgTags = doc.body.getElementsByTagName('img');

  for (let i = imgTags.length - 1; i >= 0; i--) {
    imgTags[i].parentNode?.removeChild(imgTags[i]);
  }

  // Extract the body content and return it
  return doc?.body ? doc.body.innerHTML : '';
}

export function sanitizeMinSideEmail(htmlText: string): string {
  return sanitizeHtml(htmlText);
}
