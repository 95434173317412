import React from 'react';
import { Navigate, RouteObject } from 'react-router-dom';
import { ONE_WAY_IN_BASE_ROUTE } from 'src/navigation';
import Opportunity from './pages/Opportunity';
import ActivityLog from './pages/ActivityLog';
import AddNote from './pages/AddNote';
import Archiving from './pages/Archiving';
import Reopening from './pages/Reopening';
import Referring from './pages/Referrering';
import Services from './pages/Services';

const ONE_WAY_IN_DETAILS_BASE = 'details';
const ONE_WAY_IN_DETAILS_ACTIVITY_LOG = 'activityLog';
const ONE_WAY_IN_DETAILS_NOTE = 'note';
const ONE_WAY_IN_DETAILS_REFERRER = 'referrer';
const ONE_WAY_IN_DETAILS_SERVICES = 'services';
const ONE_WAY_IN_DETAILS_ARCHIVING = 'archiving';
const ONE_WAY_IN_DETAILS_REOPENING = 'reopening';
export const ONE_WAY_IN_DETAILS = {
  BASE: (formId: string) => `${ONE_WAY_IN_BASE_ROUTE}/${ONE_WAY_IN_DETAILS_BASE}/${formId}` as const,
  ACTIVITY_LOG: (formId: string) =>
    `${ONE_WAY_IN_BASE_ROUTE}/${ONE_WAY_IN_DETAILS_BASE}/${formId}/${ONE_WAY_IN_DETAILS_ACTIVITY_LOG}` as const,
  NOTE: (formId: string) =>
    `${ONE_WAY_IN_BASE_ROUTE}/${ONE_WAY_IN_DETAILS_BASE}/${formId}/${ONE_WAY_IN_DETAILS_NOTE}` as const,
  REFERRER: (formId: string) =>
    `${ONE_WAY_IN_BASE_ROUTE}/${ONE_WAY_IN_DETAILS_BASE}/${formId}/${ONE_WAY_IN_DETAILS_REFERRER}` as const,
  SERVICES: (formId: string) =>
    `${ONE_WAY_IN_BASE_ROUTE}/${ONE_WAY_IN_DETAILS_BASE}/${formId}/${ONE_WAY_IN_DETAILS_SERVICES}` as const,
  ARCHIVING: (formId: string) =>
    `${ONE_WAY_IN_BASE_ROUTE}/${ONE_WAY_IN_DETAILS_BASE}/${formId}/${ONE_WAY_IN_DETAILS_ARCHIVING}` as const,
  REPOPENING: (formId: string) =>
    `${ONE_WAY_IN_BASE_ROUTE}/${ONE_WAY_IN_DETAILS_BASE}/${formId}/${ONE_WAY_IN_DETAILS_REOPENING}` as const,
};

const routes: RouteObject[] = [
  {
    path: ``,
    element: <Opportunity />,
  },
  {
    path: `${ONE_WAY_IN_DETAILS_ACTIVITY_LOG}`,
    element: <ActivityLog />,
  },
  {
    path: `${ONE_WAY_IN_DETAILS_NOTE}`,
    element: <AddNote />,
  },
  {
    path: `${ONE_WAY_IN_DETAILS_REFERRER}`,
    element: <Referring />,
  },
  {
    path: `${ONE_WAY_IN_DETAILS_SERVICES}`,
    element: <Services />,
  },
  {
    path: `${ONE_WAY_IN_DETAILS_ARCHIVING}`,
    element: <Archiving />,
  },
  {
    path: `${ONE_WAY_IN_DETAILS_REOPENING}`,
    element: <Reopening />,
  },
  {
    path: `*`,
    element: <Navigate to="" replace />,
  },
];

export default routes;
