import React from 'react';
import { ColumnSettings, ExcelService } from 'src/utils/excel.service';

export default function useExcel<T extends object>(settings: ColumnSettings<T>) {
  const service = React.useMemo(() => {
    return new ExcelService(settings);
  }, [settings]);

  const url = service.getObjectURL();
  const size = service.getBlobSize();
  const type = service.getBlobType();

  const downloadFile = (fileName?: string) => {
    service.downloadBlob(fileName);
  };

  const importFile = async (file: File): Promise<Array<T>> => {
    return await service.importFile(file);
  };

  return {
    url,
    size,
    type,
    downloadFile,
    importFile,
  };
}
