import React, { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { Controller, Path, UseFormReturn } from 'react-hook-form';

import { InputField } from '@in/component-library';

import { ClusterMemberUpdateDto, ExternalService } from 'src/api/v2';

export type AddressFormGroupProps = {
  title: string;
  path: Path<ClusterMemberUpdateDto>;
  form: UseFormReturn<ClusterMemberUpdateDto, any>;
  disabled?: boolean;
};

const AddressFormGroup: React.FC<AddressFormGroupProps> = ({
  title,
  path,
  form: {
    control,
    watch,
    setValue,
    setError,
    formState: { errors },
    clearErrors,
  },
  disabled,
}: AddressFormGroupProps) => {
  const { t: tCommon } = useTranslation();
  const { t: tError } = useTranslation('error');

  const [hasOnChanged, setHasOnChanged] = useState(false);

  const streetPath = useMemo(() => `${path}.street` as Path<ClusterMemberUpdateDto>, [path]);
  const zipCodePath = useMemo(() => `${path}.zipCode` as Path<ClusterMemberUpdateDto>, [path]);
  const cityPath = useMemo(() => `${path}.city` as Path<ClusterMemberUpdateDto>, [path]);
  const municipalityPath = useMemo(() => `${path}.municipality` as Path<ClusterMemberUpdateDto>, [path]);
  const municipalityNoPath = useMemo(() => `${path}.municipalityNo` as Path<ClusterMemberUpdateDto>, [path]);
  const countyPath = useMemo(() => `${path}.county` as Path<ClusterMemberUpdateDto>, [path]);

  const zipCodeWatch = watch(zipCodePath);

  const [service] = useState(new ExternalService());

  useQuery(['zip-code', path, title], () => service.getZipCode(zipCodeWatch as string), {
    enabled: !!zipCodeWatch && (zipCodeWatch as string).length === 4 && hasOnChanged,
    onSuccess(data) {
      if (data) {
        clearErrors(zipCodePath);

        setValue(cityPath, data.cityName, {
          shouldDirty: true,
          shouldValidate: true,
        });
        setValue(municipalityPath, data.municipalityName, {
          shouldDirty: true,
          shouldValidate: true,
        });
        setValue(municipalityNoPath, data.municipalityCode, {
          shouldDirty: true,
          shouldValidate: true,
        });
        setValue(countyPath, data.countyName, {
          shouldDirty: true,
          shouldValidate: true,
        });
      } else {
        setError(zipCodePath, {
          type: 'manual',
          message: tError('city.notFound'),
        });

        setValue(cityPath, undefined, { shouldDirty: true, shouldValidate: true });
      }
    },
  });

  return (
    <>
      <Controller
        control={control}
        name={streetPath}
        render={({ field }) => (
          <InputField
            className={`margin-bottom--0`}
            label={tCommon('address')}
            name={field.name}
            value={(field.value as string) || ''}
            onChange={field.onChange}
            onBlur={field.onBlur}
            errorMsg={errors?.[path as string]?.street?.message}
            disabled={disabled}
          />
        )}
      />

      <div className="display--flex gap--2">
        <div className="flex--1">
          <Controller
            control={control}
            name={zipCodePath}
            render={({ field }) => (
              <InputField
                className={`margin-bottom--0`}
                label={tCommon('zipCode')}
                name={field.name}
                value={(field.value as string) || ''}
                onChange={(e) => {
                  if (!hasOnChanged) {
                    setHasOnChanged(true);
                  }

                  field.onChange(e);
                }}
                onBlur={field.onBlur}
                errorMsg={errors?.[path as string]?.zipCode?.message}
                disabled={disabled}
              />
            )}
          />
        </div>

        <div className="flex--1">
          <Controller
            control={control}
            name={cityPath}
            render={({ field }) => (
              <InputField
                className={`margin-bottom--0`}
                label={tCommon('postalCity')}
                name={field.name}
                value={(field.value as string) || ''}
                onChange={field.onChange}
                onBlur={field.onBlur}
                errorMsg={errors?.[path as string]?.city?.message}
                disabled={disabled}
              />
            )}
          />
        </div>
      </div>

      <div className="display--flex gap--2">
        <div className="flex--1">
          <Controller
            control={control}
            name={municipalityPath}
            render={({ field }) => (
              <InputField
                className={`margin-bottom--0`}
                label={tCommon('municipality')}
                name={field.name}
                value={(field.value as string) || ''}
                onChange={field.onChange}
                onBlur={field.onBlur}
                errorMsg={errors?.[path as string]?.municipality?.message}
                disabled={disabled}
              />
            )}
          />
        </div>
        <div className="flex--1">
          <Controller
            control={control}
            name={municipalityNoPath}
            render={({ field }) => (
              <InputField
                className={`margin-bottom--0`}
                label={tCommon('municipalityNumber')}
                name={field.name}
                value={(field.value as string) || ''}
                onChange={field.onChange}
                onBlur={field.onBlur}
                errorMsg={errors?.[path as string]?.municipalityNo?.message}
                disabled={disabled}
              />
            )}
          />
        </div>
      </div>

      <div className="display--flex gap--2">
        <div className="flex--1">
          <Controller
            control={control}
            name={countyPath}
            render={({ field }) => (
              <InputField
                className={`margin-bottom--0`}
                label={tCommon('county')}
                name={field.name}
                value={(field.value as string) || ''}
                onChange={field.onChange}
                onBlur={field.onBlur}
                errorMsg={errors?.[path as string]?.state?.message}
                disabled={disabled}
              />
            )}
          />
        </div>
      </div>
    </>
  );
};

export default AddressFormGroup;
