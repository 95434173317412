import { ClusterContactCreateDto, ClusterMemberCreateDto } from 'src/api/v2';
import { ColumnSettings } from 'src/utils/excel.service';
import { ClusterMemberTypeArray } from './cluster-member-type';
import { ImportSelfEffort } from '../types/ImportSelfEffort';
import { resources } from 'src/i18n';

export type ExcelChangelogType = {
  date: Date;
  message: keyof (typeof resources)['nb']['changelog'];
};

export const GetRecentExcelChangelog = (changelogs: ExcelChangelogType[], daysOld: number = 31) => {
  const today = new Date();

  const recent = changelogs
    .filter((changelog) => {
      const diffTime = Math.abs(changelog.date.getTime() - today.getTime());
      const diffDays = diffTime / (1000 * 3600 * 24);

      return diffDays < daysOld;
    })
    .sort((a, b) => a.date.getTime() - b.date.getTime());

  return recent;
};

export const AllowedFileTypes: Array<string> = [
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];

export const ExcelImportClusterMemberSettingsChangelog: ExcelChangelogType[] = [];
export const ExcelImportClusterMemberSettings: ColumnSettings<ClusterMemberCreateDto> = {
  name: {
    header: 'Bedriftsnavn',
    width: 25,
  },
  organizationNumber: {
    header: 'Organisasjonsnummer',
    width: 25,
    validation: {
      type: 'textLength',
      operator: 'equal',
      showErrorMessage: true,
      allowBlank: true,
      error: 'Må inneholde 9 tall',
      formulae: [9],
    },
  },
  memberSince: {
    header: 'Medlem siden (av klyngen)', // Må se om dette er riktig formattering å legge i header
    width: 25,
    validation: {
      type: 'date',
      allowBlank: false,
      formulae: [],
    },
  },
  clusterMemberTypeId: {
    header: 'Medlemstype',
    width: 25,
    validation: {
      type: 'list',
      allowBlank: false,
      formulae: [`"${ClusterMemberTypeArray.map((x) => x.name).join(', ')}"`],
    },
  },
  international: {
    header: 'Internasjonal?',
    validation: {
      type: 'list',
      allowBlank: true,
      formulae: [`"Ja, Nei"`],
    },
  },
};

export const ExcelImportClusterMemberAssociateLeadSettingsChangelog: ExcelChangelogType[] = [];
export const ExcelImportClusterMemberAssociateLeadSettings: ColumnSettings<ClusterMemberCreateDto> = (() => {
  // kopier fra medlems-settings
  const settingsCopy = { ...ExcelImportClusterMemberSettings };
  // fjern clusterMemberType for samarbeidspartnere og leads
  delete settingsCopy.clusterMemberTypeId;

  return settingsCopy;
})();

export const ExcelImportClusterContactSettingsChangelog: ExcelChangelogType[] = [];
export const ExcelImportClusterContactSettings: ColumnSettings<ClusterContactCreateDto> = {
  firstName: {
    header: 'Fornavn',
    width: 20,
  },
  lastName: {
    header: 'Etternavn',
    width: 20,
  },
  emailAddress: {
    header: 'E-post',
    width: 32,
    isEmail: true,
  },
  clusterMemberId: {
    header: 'Org.nr (Bedrift)',
    validation: {
      type: 'textLength',
      operator: 'equal',
      showErrorMessage: true,
      allowBlank: true,
      error: 'Må inneholde 9 tall',
      formulae: [9],
    },
    width: 15,
  },
  mobilePhone: {
    header: 'Mobilnummer',
  },
  gender: {
    header: 'Kjønn',
    validation: {
      type: 'list',
      allowBlank: true,
      formulae: [`"Mann, Kvinne, Uspesifisert"`],
    },
    width: 10,
  },
};

export const ExcelImportSelfEffortSettingsChangelog: ExcelChangelogType[] = [
  {
    date: new Date('2023-02-23'),
    message: 'import.inkind.20230223',
  },
];
export const ExcelImportSelfEffortSettings: ColumnSettings<ImportSelfEffort> = {
  firstName: {
    header: 'Fornavn',
    width: 20,
  },
  lastName: {
    header: 'Etternavn',
    width: 20,
  },
  emailAddress: {
    header: 'E-post',
    width: 32,
    isEmail: true,
  },
  mobilePhone: {
    header: 'Mobilnummer',
  },
  gender: {
    header: 'Kjønn',
    validation: {
      type: 'list',
      allowBlank: true,
      formulae: [`"Mann, Kvinne, Uspesifisert"`],
    },
    width: 10,
  },
  clusterMemberId: {
    header: 'Bedrift (organisasjonsnummer)',
    validation: {
      type: 'textLength',
      operator: 'equal',
      showErrorMessage: true,
      allowBlank: true,
      error: 'Må inneholde 9 tall',
      formulae: [9],
    },
    width: 15,
  },
  hours: {
    header: 'Egeninnsats (timer)',
    width: 15,
    validation: {
      type: 'decimal',
      operator: 'greaterThanOrEqual',
      formulae: [0],
      promptTitle: 'Egeninnsats',
      prompt: 'Verdi må være større eller lik 0',
    },
  },
  date: {
    header: 'Dato (dd/mm/yyyy)',
    width: 15,
    validation: {
      type: 'date',
      operator: 'lessThanOrEqual',
      formulae: [new Date()],
    },
  },
  description: {
    header: 'Beskrivelse',
    width: 35,
  },
};
