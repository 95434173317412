export type County = {
  countyName: string;
  countyNumber: string;
  municipalities: string[];
};

export const Counties: County[] = [
  {
    countyName: 'Oslo',
    countyNumber: '03',
    municipalities: ['0301'],
  },
  {
    countyName: 'Rogaland',
    countyNumber: '11',
    municipalities: [
      '1101',
      '1103',
      '1106',
      '1108',
      '1111',
      '1112',
      '1114',
      '1119',
      '1120',
      '1121',
      '1122',
      '1124',
      '1127',
      '1130',
      '1133',
      '1134',
      '1135',
      '1144',
      '1145',
      '1146',
      '1149',
      '1151',
      '1160',
    ],
  },
  {
    countyName: 'Møre og Romsdal',
    countyNumber: '15',
    municipalities: [
      '1505',
      '1506',
      '1508',
      '1511',
      '1514',
      '1515',
      '1516',
      '1517',
      '1520',
      '1525',
      '1528',
      '1531',
      '1532',
      '1535',
      '1539',
      '1547',
      '1554',
      '1557',
      '1560',
      '1563',
      '1566',
      '1573',
      '1576',
      '1577',
      '1578',
      '1579',
      '1580',
    ],
  },
  {
    countyName: 'Nordland',
    countyNumber: '18',
    municipalities: [
      '1871',
      '1812',
      '1813',
      '1815',
      '1816',
      '1875',
      '1848',
      '1851',
      '1853',
      '1804',
      '1806',
      '1811',
      '1818',
      '1820',
      '1822',
      '1824',
      '1825',
      '1826',
      '1827',
      '1828',
      '1832',
      '1833',
      '1834',
      '1835',
      '1836',
      '1837',
      '1838',
      '1839',
      '1840',
      '1841',
      '1845',
      '1856',
      '1857',
      '1860',
      '1859',
      '1865',
      '1866',
      '1867',
      '1868',
      '1870',
      '1874',
    ],
  },
  {
    countyName: 'Østfold',
    countyNumber: '31',
    municipalities: [
      '3116',
      '3118',
      '3101',
      '3103',
      '3105',
      '3107',
      '3110',
      '3112',
      '3114',
      '3120',
      '3122',
      '3124',
    ],
  },
  {
    countyName: 'Akershus',
    countyNumber: '32',
    municipalities: [
      '3201',
      '3203',
      '3205',
      '3207',
      '3209',
      '3212',
      '3214',
      '3216',
      '3218',
      '3220',
      '3222',
      '3224',
      '3226',
      '3228',
      '3230',
      '3232',
      '3234',
      '3236',
      '3238',
      '3240',
      '3242',
    ],
  },
  {
    countyName: 'Buskerud',
    countyNumber: '33',
    municipalities: [
      '3301',
      '3303',
      '3305',
      '3310',
      '3312',
      '3314',
      '3316',
      '3318',
      '3320',
      '3322',
      '3324',
      '3326',
      '3328',
      '3330',
      '3332',
      '3334',
      '3336',
      '3338',
    ],
  },
  {
    countyName: 'Innlandet',
    countyNumber: '34',
    municipalities: [
      '3431',
      '3401',
      '3403',
      '3405',
      '3407',
      '3411',
      '3412',
      '3413',
      '3414',
      '3415',
      '3416',
      '3417',
      '3418',
      '3419',
      '3420',
      '3421',
      '3422',
      '3423',
      '3424',
      '3425',
      '3426',
      '3427',
      '3428',
      '3429',
      '3430',
      '3432',
      '3433',
      '3434',
      '3435',
      '3436',
      '3437',
      '3438',
      '3439',
      '3440',
      '3441',
      '3442',
      '3443',
      '3446',
      '3447',
      '3448',
      '3449',
      '3450',
      '3451',
      '3452',
      '3453',
      '3454',
    ],
  },
  {
    countyName: 'Vestfold',
    countyNumber: '39',
    municipalities: ['3901', '3903', '3905', '3907', '3909', '3911'],
  },
  {
    countyName: 'Telemark',
    countyNumber: '40',
    municipalities: [
      '4024',
      '4026',
      '4028',
      '4030',
      '4032',
      '4001',
      '4003',
      '4005',
      '4010',
      '4012',
      '4014',
      '4016',
      '4018',
      '4020',
      '4022',
      '4034',
      '4036',
    ],
  },
  {
    countyName: 'Agder',
    countyNumber: '42',
    municipalities: [
      '4201',
      '4202',
      '4203',
      '4204',
      '4205',
      '4206',
      '4207',
      '4211',
      '4212',
      '4213',
      '4214',
      '4215',
      '4216',
      '4217',
      '4218',
      '4219',
      '4220',
      '4221',
      '4222',
      '4223',
      '4224',
      '4225',
      '4226',
      '4227',
      '4228',
    ],
  },
  {
    countyName: 'Vestland',
    countyNumber: '46',
    municipalities: [
      '4601',
      '4602',
      '4611',
      '4613',
      '4616',
      '4617',
      '4618',
      '4619',
      '4620',
      '4621',
      '4612',
      '4622',
      '4623',
      '4614',
      '4624',
      '4633',
      '4615',
      '4625',
      '4626',
      '4627',
      '4628',
      '4629',
      '4630',
      '4631',
      '4632',
      '4634',
      '4635',
      '4636',
      '4637',
      '4638',
      '4639',
      '4640',
      '4641',
      '4642',
      '4643',
      '4644',
      '4645',
      '4646',
      '4647',
      '4649',
      '4650',
      '4651',
      '4648',
    ],
  },
  {
    countyName: 'Trøndelag',
    countyNumber: '50',
    municipalities: [
      '5001',
      '5006',
      '5007',
      '5014',
      '5020',
      '5021',
      '5022',
      '5025',
      '5026',
      '5027',
      '5028',
      '5029',
      '5031',
      '5032',
      '5033',
      '5034',
      '5035',
      '5036',
      '5037',
      '5038',
      '5041',
      '5042',
      '5046',
      '5047',
      '5049',
      '5055',
      '5043',
      '5044',
      '5045',
      '5052',
      '5053',
      '5054',
      '5056',
      '5057',
      '5058',
      '5059',
      '5060',
      '5061',
    ],
  },
  {
    countyName: 'Troms',
    countyNumber: '55',
    municipalities: [
      '5518',
      '5520',
      '5522',
      '5524',
      '5526',
      '5501',
      '5503',
      '5510',
      '5512',
      '5514',
      '5516',
      '5528',
      '5530',
      '5532',
      '5534',
      '5536',
      '5538',
      '5540',
      '5542',
      '5544',
      '5546',
    ],
  },
  {
    countyName: 'Finnmark',
    countyNumber: '56',
    municipalities: [
      '5618',
      '5620',
      '5601',
      '5603',
      '5605',
      '5607',
      '5610',
      '5612',
      '5614',
      '5616',
      '5622',
      '5624',
      '5626',
      '5628',
      '5630',
      '5632',
      '5634',
      '5636',
    ],
  },
];

export const CountiesBefore2024: County[] = [
  {
    countyName: 'Oslo',
    countyNumber: '03',
    municipalities: ['0301'],
  },
  {
    countyName: 'Rogaland',
    countyNumber: '11',
    municipalities: [
      '1106',
      '1134',
      '1135',
      '1160',
      '1146',
      '1101',
      '1103',
      '1108',
      '1111',
      '1112',
      '1114',
      '1119',
      '1120',
      '1121',
      '1122',
      '1124',
      '1127',
      '1130',
      '1133',
      '1144',
      '1145',
      '1149',
      '1151',
    ],
  },
  {
    countyName: 'Møre og Romsdal',
    countyNumber: '15',
    municipalities: [
      '1505',
      '1506',
      '1507',
      '1511',
      '1514',
      '1515',
      '1516',
      '1517',
      '1520',
      '1525',
      '1528',
      '1531',
      '1532',
      '1535',
      '1539',
      '1547',
      '1554',
      '1557',
      '1560',
      '1563',
      '1566',
      '1573',
      '1576',
      '1577',
      '1578',
      '1579',
    ],
  },
  {
    countyName: 'Nordland',
    countyNumber: '18',
    municipalities: [
      '1804',
      '1806',
      '1811',
      '1812',
      '1813',
      '1815',
      '1816',
      '1818',
      '1820',
      '1822',
      '1824',
      '1825',
      '1826',
      '1827',
      '1828',
      '1832',
      '1833',
      '1834',
      '1835',
      '1836',
      '1837',
      '1838',
      '1839',
      '1840',
      '1841',
      '1845',
      '1848',
      '1851',
      '1853',
      '1856',
      '1857',
      '1859',
      '1860',
      '1865',
      '1866',
      '1867',
      '1868',
      '1870',
      '1871',
      '1874',
      '1875',
    ],
  },
  {
    countyName: 'Viken',
    countyNumber: '30',
    municipalities: [
      '3002',
      '3005',
      '3006',
      '3007',
      '3013',
      '3014',
      '3015',
      '3018',
      '3019',
      '3020',
      '3021',
      '3022',
      '3023',
      '3024',
      '3025',
      '3026',
      '3027',
      '3028',
      '3029',
      '3030',
      '3031',
      '3032',
      '3033',
      '3034',
      '3035',
      '3036',
      '3037',
      '3038',
      '3039',
      '3040',
      '3041',
      '3042',
      '3043',
      '3044',
      '3045',
      '3046',
      '3047',
      '3048',
      '3049',
      '3050',
      '3051',
      '3052',
      '3053',
      '3054',
      '3016',
      '3017',
      '3001',
      '3003',
      '3004',
      '3011',
      '3012',
    ],
  },
  {
    countyName: 'Innlandet',
    countyNumber: '34',
    municipalities: [
      '3401',
      '3403',
      '3405',
      '3407',
      '3411',
      '3412',
      '3413',
      '3414',
      '3415',
      '3416',
      '3417',
      '3418',
      '3419',
      '3420',
      '3421',
      '3422',
      '3423',
      '3424',
      '3425',
      '3426',
      '3427',
      '3428',
      '3429',
      '3430',
      '3431',
      '3432',
      '3433',
      '3434',
      '3435',
      '3436',
      '3437',
      '3438',
      '3439',
      '3440',
      '3441',
      '3442',
      '3443',
      '3446',
      '3447',
      '3448',
      '3449',
      '3450',
      '3451',
      '3452',
      '3453',
      '3454',
    ],
  },
  {
    countyName: 'Vestfold og Telemark',
    countyNumber: '38',
    municipalities: [
      '3802',
      '3808',
      '3817',
      '3818',
      '3819',
      '3820',
      '3821',
      '3824',
      '3825',
      '3801',
      '3803',
      '3812',
      '3804',
      '3805',
      '3806',
      '3807',
      '3811',
      '3813',
      '3814',
      '3815',
      '3816',
      '3822',
      '3823',
    ],
  },
  {
    countyName: 'Agder',
    countyNumber: '42',
    municipalities: [
      '4222',
      '4201',
      '4202',
      '4203',
      '4204',
      '4205',
      '4206',
      '4207',
      '4211',
      '4212',
      '4213',
      '4214',
      '4215',
      '4216',
      '4217',
      '4218',
      '4219',
      '4220',
      '4221',
      '4223',
      '4224',
      '4225',
      '4226',
      '4227',
      '4228',
    ],
  },
  {
    countyName: 'Vestland',
    countyNumber: '46',
    municipalities: [
      '4601',
      '4602',
      '4611',
      '4612',
      '4613',
      '4614',
      '4615',
      '4616',
      '4617',
      '4618',
      '4619',
      '4620',
      '4621',
      '4622',
      '4623',
      '4624',
      '4625',
      '4626',
      '4627',
      '4628',
      '4629',
      '4630',
      '4631',
      '4632',
      '4633',
      '4634',
      '4635',
      '4636',
      '4637',
      '4638',
      '4639',
      '4640',
      '4641',
      '4642',
      '4643',
      '4644',
      '4645',
      '4646',
      '4647',
      '4648',
      '4649',
      '4650',
      '4651',
    ],
  },
  {
    countyName: 'Trøndelag',
    countyNumber: '50',
    municipalities: [
      '5043',
      '5044',
      '5046',
      '5052',
      '5060',
      '5001',
      '5006',
      '5007',
      '5014',
      '5020',
      '5021',
      '5022',
      '5025',
      '5026',
      '5027',
      '5028',
      '5029',
      '5031',
      '5032',
      '5033',
      '5034',
      '5035',
      '5036',
      '5037',
      '5038',
      '5041',
      '5042',
      '5045',
      '5047',
      '5049',
      '5053',
      '5054',
      '5055',
      '5056',
      '5057',
      '5058',
      '5059',
      '5061',
    ],
  },
  {
    countyName: 'Troms og Finnmark',
    countyNumber: '54',
    municipalities: [
      '5401',
      '5402',
      '5403',
      '5404',
      '5405',
      '5406',
      '5411',
      '5412',
      '5413',
      '5414',
      '5415',
      '5416',
      '5417',
      '5418',
      '5419',
      '5420',
      '5421',
      '5422',
      '5423',
      '5424',
      '5425',
      '5426',
      '5427',
      '5428',
      '5429',
      '5430',
      '5432',
      '5433',
      '5434',
      '5435',
      '5436',
      '5437',
      '5438',
      '5439',
      '5440',
      '5441',
      '5442',
      '5443',
      '5444',
    ],
  },
];
