import { useState } from 'react';
import { useQuery } from 'react-query';
import { OneWayInService } from 'src/api/v2';

import {
  ONE_WAY_IN_FORM_IN_RESPONSE_MAIL,
  ONE_WAY_IN_GROUPED_FORM_WITHOUT_SAVE,
} from 'src/constants/hook-keys';
import { useAuth } from 'src/features/authorization';

const useOneWayInReview = (formId: string | undefined = '') => {
  const [oneWayInService] = useState(new OneWayInService());

  const { checkAccess } = useAuth();
  const { read } = checkAccess('OneWayIn');

  const oneWayInReviewGroupedFormQuery = useQuery(
    [ONE_WAY_IN_GROUPED_FORM_WITHOUT_SAVE, formId],
    () => oneWayInService.getGroupedFormDetailsBare(formId),
    {
      enabled: !!formId && read,
      retry: false,
      staleTime: 1000 * 60 * 15, // 15 min refetching on mount
      refetchOnMount: true,
      keepPreviousData: false,
    },
  );

  const onewWayInOpportunityFormINResponseMailQuery = useQuery(
    [ONE_WAY_IN_FORM_IN_RESPONSE_MAIL, formId],
    () => {
      return oneWayInService.getInquiryINResponse(formId);
    },
    {
      enabled: !!formId && read,
      retry: false,
      refetchOnMount: false,
      keepPreviousData: false,
    },
  );

  return { oneWayInReviewGroupedFormQuery, onewWayInOpportunityFormINResponseMailQuery };
};

export default useOneWayInReview;
