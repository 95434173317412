import { useState } from 'react';
import { useInfiniteQuery, useQuery } from 'react-query';
import { OneWayInFeedbackService, OneWayInService } from 'src/api/v2';

import { ONE_WAY_IN_FEEDBACK_LIST, ONE_WAY_IN_FORMS_BY_MUNICIPALITY_KEY } from 'src/constants/hook-keys';
import { useAuth } from 'src/features/authorization';
import { useCluster } from 'src/features/cluster';

const useOneWayInCounty = (
  municipalities: string[],
  counties: string[],
  oldToNew?: boolean,
  fromDate?: Date,
  toDate?: Date,
  top?: number,
) => {
  const [oneWayInService] = useState(new OneWayInService());
  const [oneWayInFeedbackService] = useState(new OneWayInFeedbackService());
  const { cluster } = useCluster();

  const { checkAccess } = useAuth();
  const read = checkAccess('OneWayIn').read;

  const oneWayInFormsQuery = useInfiniteQuery(
    [ONE_WAY_IN_FORMS_BY_MUNICIPALITY_KEY, municipalities.join(','), oldToNew, fromDate, toDate],
    ({ pageParam = 1 }) =>
      oneWayInService.getAllFormsByMunicipality(
        {
          oldToNew: oldToNew,
          fromDate: fromDate,
          toDate: toDate,
          counties: counties,
          municipalities: municipalities,
          ignoreReferralParty: true,
          referralPartyNameOrId: '',
          top: top ?? 100,
        },
        pageParam,
      ),
    {
      enabled: !!cluster && read,
      retry: false,
      staleTime: 1000 * 60 * 24,
      refetchOnMount: true,
      getNextPageParam: (lastPage, pagesArray) => {
        if (lastPage.pages > pagesArray.length) return pagesArray.length + 1;

        return undefined;
      },
    },
  );

  const oneWayInFeedbackListFromActorQuery = useQuery(
    [ONE_WAY_IN_FEEDBACK_LIST],
    () => oneWayInFeedbackService.getFeedbackList(),
    {
      enabled: !!cluster && read,
      retry: false,
      staleTime: 1000 * 60 * 24,
      refetchOnMount: true,
    },
  );

  return {
    oneWayInFormsQuery,
    oneWayInFeedbackListFromActorQuery,
  };
};

export default useOneWayInCounty;
