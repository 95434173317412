import { useState, useEffect } from 'react';
import { useQuery } from 'react-query';

import { ProjectPropertyTypes, ProjectsService } from 'src/api/v2';

import { PROJECT_PROPERTY_TYPES_KEY } from 'src/constants/hook-keys';

import useCluster from '../features/cluster/hooks/use-cluster';

const initialProjectPropertyTypes: ProjectPropertyTypes = {
  phases: [],
  types: [],
  categories: [],
  socialEnvironmentalEffects: [],
  endCategories: [],
  endSubCategories: [],
  customCategories: [],
};

export default function useProjectPropertyTypes() {
  const { cluster } = useCluster();

  const [service] = useState(new ProjectsService());

  const { data, ...rest } = useQuery(
    [PROJECT_PROPERTY_TYPES_KEY],
    () => service.getProjectPropertyTypes(cluster!.id),
    {
      enabled: false,
    },
  );

  useEffect(() => {
    if (cluster?.id) rest.refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cluster?.id]);

  return {
    projectPropertyTypes: data || initialProjectPropertyTypes,

    ...rest,
  };
}
