export interface Position {
  top: number;
  bottom: number;
}
function _pointerHeight(scrollContainer: HTMLElement, index: number): number {
  const element: HTMLElement | null = scrollContainer
    ? (scrollContainer.children[index] as HTMLElement)
    : null;
  return element ? element.offsetHeight : 0;
}
function _scrollTo(position: number, scrollContainer: HTMLElement): void {
  if (scrollContainer != null) {
    scrollContainer.scrollTop = position;
  }
}

function _viewport(scrollContainer: HTMLElement): Position {
  return {
    top: scrollContainer ? scrollContainer.scrollTop : 0,
    bottom: scrollContainer ? scrollContainer.offsetHeight + scrollContainer.scrollTop : 0,
  };
}
function _pixelsToPointerTop(scrollContainer: HTMLElement, index: number): number {
  let pixelsToPointerTop = 0;

  if (scrollContainer) {
    for (let i = 0; i < index; i++) {
      const menuChild: HTMLElement = scrollContainer.children[i] as HTMLElement;
      pixelsToPointerTop += menuChild.offsetHeight;
    }
  }
  return pixelsToPointerTop;
}
function _pixelsToPointerBottom(scrollContainer: HTMLElement, index: number): number {
  return _pixelsToPointerTop(scrollContainer, index) + _pointerHeight(scrollContainer, index);
}

export default function adjustScroll(scrollContainer: HTMLElement, index: number): void {
  const pixelsToPointerTop: number = _pixelsToPointerTop(scrollContainer, index),
    pixelsToPointerBottom: number = _pixelsToPointerBottom(scrollContainer, index),
    viewPort: Position = _viewport(scrollContainer);

  if (pixelsToPointerTop <= viewPort.top) {
    _scrollTo(pixelsToPointerTop, scrollContainer);
  } else if (pixelsToPointerBottom >= viewPort.bottom) {
    let point: number = viewPort.top + _pointerHeight(scrollContainer, index);

    if (viewPort.top === 0) {
      point = pixelsToPointerBottom - scrollContainer.offsetHeight;
    }
    point = pixelsToPointerBottom - scrollContainer.offsetHeight;
    _scrollTo(point, scrollContainer);
  }
}
