import React from 'react';

import { Navigate, useLocation } from 'react-router-dom';

const RemoveTrailingSlash: React.FC = ({ ...rest }) => {
  const location = useLocation();

  // If the last character of the url is '/'
  if (location.pathname.endsWith('/')) {
    return (
      <Navigate
        replace
        {...rest}
        to={{
          pathname: location.pathname.slice(0, -1),
          search: location.search,
        }}
      />
    );
  } else return null;
};

export default RemoveTrailingSlash;
