import React, { useState } from 'react';
import DrawerSideMenu from './drawer-sidemenu/DrawerSideMenu';
import HelpAndSupportMenu from './HelpAndSupportMenu';
import Introduction from './Introduction';
import { useOnboarding } from 'src/features/onboarding/hooks.tsx/use-onboarding';
import {
  HelpAndSupportMenuPageSelection,
  HelpAndSupportCommonQuestionsSelection,
} from '../constants/HelpAndSupportMenu';
import { OnboardingPacketDto } from 'src/api/v2';

type SidemenuContextProps = {
  subpage: HelpAndSupportMenuPageSelection;
  setSubpage?: React.Dispatch<React.SetStateAction<HelpAndSupportMenuPageSelection>>;
  flow: OnboardingPacketDto | null;
  setFlow?: React.Dispatch<React.SetStateAction<OnboardingPacketDto | null>>;
  commonQuestionsSubpage: HelpAndSupportCommonQuestionsSelection;
  setCommonQuestionsSubpage?: React.Dispatch<React.SetStateAction<HelpAndSupportCommonQuestionsSelection>>;
};

export const SideMenuSubpageContext = React.createContext<SidemenuContextProps>({
  subpage: 'none',
  flow: null,
  commonQuestionsSubpage: 'none',
});

const OnboardingSideMenu: React.FC = () => {
  const { priorityPacket } = useOnboarding();
  const [flow, setFlow] = useState<OnboardingPacketDto | null>(null);
  const [subpage, setSubpage] = useState<HelpAndSupportMenuPageSelection>('none');
  const [commonQuestionsSubpage, setCommonQuestionsSubpage] =
    useState<HelpAndSupportCommonQuestionsSelection>('none');

  return (
    <SideMenuSubpageContext.Provider
      value={{ subpage, setSubpage, flow, setFlow, commonQuestionsSubpage, setCommonQuestionsSubpage }}
    >
      <DrawerSideMenu>
        {priorityPacket && <Introduction onboardingPacket={priorityPacket} />}
        <HelpAndSupportMenu />
      </DrawerSideMenu>
    </SideMenuSubpageContext.Provider>
  );
};

export default OnboardingSideMenu;
