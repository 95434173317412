import { MessageType, Tag } from '@in/component-library';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SivaPhase } from 'src/api/v2';

type Props = {
  phase: SivaPhase | '' | undefined;
};

const types: Record<SivaPhase, MessageType> = {
  [SivaPhase.Idea]: MessageType.Info, // blue
  [SivaPhase.PreIncubation]: MessageType.Info, // blue
  [SivaPhase.Incubated]: MessageType.Info, // blue
  [SivaPhase.Fi]: MessageType.Info, // blue
  [SivaPhase.ScaleUp]: MessageType.Info, // blue
  [SivaPhase.Alumni]: MessageType.Generic, // gray
  [SivaPhase.Targeted]: MessageType.Info, // blue
};

const SivaPhaseTag: React.FC<Props> = ({ phase }) => {
  const { t: tSiva } = useTranslation('siva');

  if (!phase) {
    return null;
  }

  const type = types[phase] ?? MessageType.Info;
  const text = tSiva(`phase.${phase}` as any, {
    defaultValue: phase,
  });

  return <Tag type={type}>{text}</Tag>;
};

export default SivaPhaseTag;
