import { useTranslation } from 'react-i18next';
import { Button } from '@in/component-library';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import TertiaryButton from 'src/components/TertiaryButton/TertiaryButton';

export function FormStepButtons({
  stepBackward,
  isLoading,
  stepForward,
  stepForwardDisabled,
}: {
  isLoading?: boolean | undefined;
  stepForward?: (() => void) | undefined;
  stepBackward?: (() => void) | undefined;
  stepForwardDisabled?: boolean;
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div className="display--flex align-items--center gap--1 margin-y--3">
      {stepBackward && (
        <Button type="button" theme="neutral" variant="outlined" onClick={stepBackward} isLoading={isLoading}>
          {t('previous')}
        </Button>
      )}
      {stepForward ? (
        <Button
          type="button"
          theme="neutral"
          variant="solid"
          onClick={stepForward}
          isLoading={isLoading}
          disabled={stepForwardDisabled}
        >
          {t('next')}
        </Button>
      ) : (
        <Button type="submit" theme="neutral" variant="solid" isLoading={isLoading}>
          {/* TODO: når oversettelsesgenereringen virker igjen {t('completeAdd')}*/}
          Fullfør og legg til
        </Button>
      )}
      <TertiaryButton onClick={() => navigate('..')}>{t('cancel')}</TertiaryButton>
    </div>
  );
}
