import { useState } from 'react';
import { useQuery } from 'react-query';
import { OperationProjectsService } from 'src/api/v2';
import { OPERATION_PROJECTS_KEY } from 'src/constants/hook-keys';
import { useAuth } from 'src/features/authorization';
import { useCluster } from 'src/features/cluster';

const useOperationProjects = () => {
  const [operationProjectService] = useState(new OperationProjectsService());

  const { cluster } = useCluster();

  const { checkAccess } = useAuth();
  const { read } = checkAccess('Operation');

  const operationProjectsQuery = useQuery(
    [OPERATION_PROJECTS_KEY],
    () => operationProjectService.getOperationProjects(cluster!.id),
    {
      enabled: !!cluster && read,
    },
  );

  return {
    operationProjectsQuery,
  };
};

export default useOperationProjects;
