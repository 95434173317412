import { SelectOptionProps } from '@in/component-library';
import { useMemo } from 'react';
import { roleConstants } from 'src/features/roles/constants';
import { UseUsersOptions, useUsers } from 'src/features/users';

const useSivaAdvisorOptions = (options?: UseUsersOptions) => {
  const { users } = useUsers(options);

  const advisorOptions = useMemo<SelectOptionProps[]>(() => {
    return users
      .filter((user) => user.role?.code?.startsWith(roleConstants.INTERNAL))
      .map(
        (user) =>
          ({
            value: user.id,
            text: `${user.firstName} ${user.lastName} (${user.emailAddress})`,
          }) satisfies SelectOptionProps,
      )
      .sort((a, b) => a.text.localeCompare(b.text)) as SelectOptionProps[];
  }, [users]);

  return {
    advisorOptions,
  };
};
export default useSivaAdvisorOptions;
