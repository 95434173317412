import React from 'react';
import { RouteObject } from 'react-router-dom';
import CompanyDetails from './company-details/pages/CompanyDetails';
import { COMPANY_DETAILS } from './company-details/routes';
import SivaContractsIndexPage from './pages/SicaContractsIndexPage';
import NewCompanyPage from './pages/new/NewCompanyPage';

export const IDEAS_AND_COMPANIES_IDEAS_ROUTE = 'ideas';
export const IDEAS_AND_COMPANIES_NEW_ROUTE = 'new';
export const IDEAS_AND_COMPANIES_IDEA_ROUTE = 'idea';
export const IDEAS_AND_COMPANIES_COMPANY_ROUTE = 'company';

export const IDEAS_AND_COMPANIES_ROUTES = (baseRoute: string) =>
  ({
    BASE: baseRoute,
    COMPANIES: `${baseRoute}`,
    COMPANY_DETAILS: COMPANY_DETAILS(`${baseRoute}`),
    NEW: `${baseRoute}/${IDEAS_AND_COMPANIES_NEW_ROUTE}`,
  }) as const;

const routes: RouteObject[] = [
  {
    path: '',
    element: <SivaContractsIndexPage />,
    index: true,
  },
  {
    path: `${IDEAS_AND_COMPANIES_NEW_ROUTE}/*`,
    children: [
      {
        path: ``,
        index: true,
        element: <NewCompanyPage />,
      },
    ],
  },
  {
    path: `/:contractId/*`,
    element: <CompanyDetails />,
  },
];

export default routes;
