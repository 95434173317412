import { useMemo } from 'react';
import useCompanyPhase from '../company-details/hooks/use-company-phase';
import { SivaPhase } from 'src/api/v2';

// This is placed here as readonly array to keep a stable reference
// (makes unnecessary rerenders easier to avoid)
const withoutCompanyOptions: ReadonlyArray<SivaPhase> = [SivaPhase.Idea, SivaPhase.PreIncubation];

export function useAvailablePhases(organizationNumber: string | undefined): ReadonlyArray<SivaPhase> {
  const { companyAvailablePhases } = useCompanyPhase(organizationNumber);

  return useMemo(() => {
    if (!organizationNumber) return withoutCompanyOptions;
    return companyAvailablePhases.data ?? [];
  }, [organizationNumber, companyAvailablePhases.data]);
}
