import React from 'react';
import { useTranslation } from 'react-i18next';
import { SivaEsaReportingDto } from 'src/api/v2';
import { calculateInnovationSupport } from '../utils/calculations';
import { formatCurrencySimple } from 'src/utils/FormatValue';
import BillSupportNumberHandler from './BillSupportNumberHandler';
import { Card } from '@in/component-library';

type Props = {
  bills: SivaEsaReportingDto[];
};

const ESAPhaseTotalSummary: React.FC<Props> = ({ bills }) => {
  const { t: tSiva } = useTranslation('siva');
  const marketValue = bills.reduce((accumulator, bill) => accumulator + bill.marketValue, 0);
  const deductible = bills.reduce((accumulator, bill) => accumulator + bill.invoicedDeductible, 0);

  const innovationSupport = calculateInnovationSupport(marketValue, deductible);

  if (bills.length === 0) {
    return <></>;
  }
  return (
    <div>
      <h3 className="margin-bottom--3">
        {tSiva('company.details.esa.total')} {tSiva('company.details.esa.summary').toLowerCase()}
      </h3>
      <div className="display--flex justify-content--space-between gap--2">
        <Card boxShadow>
          <div className="display--flex justify-content--center">
            <b>{tSiva('company.details.esa.marketValue')}</b>
          </div>
          <div className="display--flex justify-content--center">
            {formatCurrencySimple(marketValue, true)}
          </div>
        </Card>
        <Card boxShadow>
          <div className="display--flex justify-content--center">
            <b>{tSiva('company.details.esa.billedDeductible')}</b>
          </div>
          <div className="display--flex justify-content--center">
            {formatCurrencySimple(deductible, true)}
          </div>
        </Card>
        <Card boxShadow>
          <div className="display--flex justify-content--center">
            <b>{tSiva('company.details.esa.sumInnovationSupport')}</b>
          </div>
          <div className="display--flex justify-content--center">
            <BillSupportNumberHandler
              value={innovationSupport}
              formattedValue={`${formatCurrencySimple(innovationSupport, true)}`}
            />
          </div>
        </Card>
      </div>
    </div>
  );
};

export default ESAPhaseTotalSummary;
