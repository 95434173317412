import React from 'react';
import classNames from 'classnames';
import css from './PStepperNavigationContainer.module.scss';
type Props = React.HTMLAttributes<HTMLDivElement> & { className?: string };
const PStepperNavigationContainer = React.forwardRef<HTMLDivElement, Props>(
  ({ children, className, ...rest }: Props, ref) => {
    return (
      <div className={classNames(css['pstepper-navigation-container'], className)} {...rest} ref={ref}>
        {children}
      </div>
    );
  },
);
PStepperNavigationContainer.displayName = 'PStepperNavigationContainer';
export default PStepperNavigationContainer;
