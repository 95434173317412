import React, { useMemo } from 'react';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button, DateInput, Stepper, Toggle, Select, Theme, SelectOptionProps } from '@in/component-library';

import { StepProps } from 'src/components/Stepper';

import * as validation from 'src/utils/validation';

import { ClusterMemberCreateDto } from 'src/api/v2';

import { ClusterMemberCategories } from 'src/constants/cluster-member-category';

import useEffectOnce from 'src/hooks/use-effect-once';
import useMemberTypes from 'src/hooks/use-member-types';

import { CreateMemberContext } from './CreateMemberModal';
import { formatDateInputDate } from 'src/utils/FormatValue';

type FormData = {
  memberTypeId: string;
  memberSince: Date;
  international: boolean;
};

export const Step2: React.FC<StepProps> = (props) => {
  const { t: tCommon } = useTranslation();
  const { t: tError } = useTranslation('error');

  const context = React.useContext(CreateMemberContext);

  const { memberTypes } = useMemberTypes();
  const memberTypeOptions: Array<SelectOptionProps> = useMemo(
    () => memberTypes.map((x) => ({ value: `${x.id}`, text: `${x.name}` })) || [],
    [memberTypes],
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    reset,
    watch,
  } = useForm<FormData>({
    mode: 'onBlur',
    defaultValues: {
      memberTypeId: context.member?.clusterMemberTypeId,
      memberSince: context.member?.memberSince,
      international: context.member?.international,
    },
  });

  const values = getValues();
  const memberSinceWatch = watch('memberSince');

  const onSubmit = handleSubmit((data) => {
    if (context.member) {
      const copy: ClusterMemberCreateDto = { ...context.member };

      copy.clusterMemberTypeId = data.memberTypeId;
      copy.memberSince = data.memberSince;
      copy.international = data.international === true;
      copy.clusterMemberCategoryId = ClusterMemberCategories.Member.id;
      copy.interestAreaIds = [];

      context.member = copy;

      props.goNextStep();
    } else {
      toast.error(tError('company.infoNotFound'));
    }
  });

  const memberSinceRegister = register('memberSince', {
    required: validation.requiredI18n(tError),
  });

  useEffectOnce(() => {
    if (!context.member?.memberSince) {
      setValue('memberSince', new Date(), { shouldDirty: true });
    }
  });

  return (
    <div>
      <Stepper currentStep={1} stepCount={3} />

      <form onSubmit={onSubmit}>
        <Select
          label={tCommon('type')}
          options={memberTypeOptions}
          {...register('memberTypeId', {
            required: validation.required,
            validate: {
              mustBeValid: (value: string): boolean | string => {
                if (value) {
                  return true;
                }

                return tError('fieldCannotBeBlank');
              },
            },
          })}
          onChange={(value) => {
            setValue('memberTypeId', value, { shouldValidate: true });
          }}
          value={values.memberTypeId}
          errorMsg={errors?.memberTypeId?.message}
        />

        <DateInput
          label={tCommon('memberSince')}
          max={formatDateInputDate(new Date())}
          name={memberSinceRegister.name}
          onChange={(event) => {
            setValue('memberSince', new Date(event.target.value), {
              shouldDirty: true,
              shouldValidate: true,
            });
          }}
          value={formatDateInputDate(memberSinceWatch)}
          errorMsg={errors?.memberSince?.message}
        />
        <Toggle
          label={tCommon('international')}
          value={getValues('international')}
          onValueChange={(value: boolean) => {
            setValue('international', value, { shouldDirty: true });
          }}
        />
        <div className="display--flex gap--2 margin-top--2">
          <Button
            theme={Theme.Neutral}
            variant="outlined"
            onClick={() => {
              reset();
              props.goPreviousStep();
            }}
          >
            {tCommon('previous')}
          </Button>
          <Button type="submit" theme={Theme.Neutral}>
            {tCommon('next')}
          </Button>
        </div>
      </form>
    </div>
  );
};
