import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from '../styles/ColumnLinks.module.scss';
import { FaIcon, FaIcons } from '@in/component-library';

const ColumnLinks = () => {
  const { t } = useTranslation();

  return (
    <nav>
      <ul className={styles['column-links-list']}>
        <li>
          <p>
            <a href="https://uustatus.no/nb/erklaringer/publisert/4ca5cfd8-f091-42cf-b2f1-8c682f09b102">
              <span className="margin-right--1">{t('footer.declarityOfAvailability')}</span>
              <FaIcon name={FaIcons.ExternalLinkLight} />
            </a>
          </p>
        </li>
      </ul>
    </nav>
  );
};

export default ColumnLinks;
