import React from 'react';

import './Skeleton.scss';

export type SkeletonProps = {
  width?: React.CSSProperties['width'];
  height?: React.CSSProperties['height'];

  style?: React.CSSProperties;
};

const Skeleton: React.FC<SkeletonProps> = ({ width = '1.25rem', height = '1.25rem', style }) => {
  return <div className="skeleton" style={{ ...style, width, height }}></div>;
};

export default Skeleton;
