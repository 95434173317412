import React, { useMemo } from 'react';
import { SivaContractOverviewDto, SivaPhase } from 'src/api/v2';
import DataTableV3, { DataTableV3Actions } from 'src/components/DataTableV3';
import { DataTableV3Ids } from 'src/constants/dataTableIds';
import { useTranslation } from 'react-i18next';
import { useSivaContracts } from '../hooks/use-siva-contracts';
import { ColumnDef } from '@tanstack/react-table';
import { advisorColumnAccessor } from '../hooks/use-siva-overview-columns';
import styles from './SivaContractOverview.module.scss';
import ResetFilterButton from 'src/components/ResetFilterButton/ResetFilterButton';
import QueryParamMultiSelect from 'src/components/Filters/QueryParamMultiSelect';
import { useSearchParams } from 'react-router-dom';
import FilterDateInput from 'src/components/Filters/FilterDateInput';
import saveAs from 'file-saver';
import { useSivaContractAllDataExtract } from '../hooks/use-siva-contract-data-extract';
import { FaIcons } from '@in/component-library';
import { LoadingPage } from 'src/components/LoadingPage/LoadingPage';

type Props = {
  availablePhases: SivaPhase[];
  columns: ColumnDef<SivaContractOverviewDto>[];
};

const SivaContractOverviewDataTable: React.FC<Props> = ({ columns, availablePhases }) => {
  const { filtersElement, contracts } = useContractsWithFilters({ availablePhases });
  const { t: tSiva } = useTranslation('siva');
  const { download } = useSivaContractAllDataExtract();

  const actions: DataTableV3Actions<SivaContractOverviewDto> = {
    secondary: [
      {
        icon: FaIcons.FileDownloadLight,
        label: tSiva('export.ideasAndCompanies.exportButton'),
        onClick: async () => {
          const file = await download.mutateAsync(false);
          saveAs(file.data, file.fileName);
        },
      },
    ],
  };

  if (download.isLoading) return <LoadingPage />;
  return (
    <DataTableV3
      actions={actions}
      disableColumnEditor={true}
      id={DataTableV3Ids.Siva.IdeasAndCompanies.ContractsOverview}
      data={contracts}
      pageSizeOptions={[25, 50, 100, 250, 500]}
      pageSize={100}
      columns={columns}
      sorting={[
        {
          id: 'numericId',
          desc: true,
        },
      ]}
      customColumnFilters={filtersElement}
      hideFilterText
    />
  );
};

interface useContractsWithFiltersParams {
  availablePhases: SivaPhase[];
}

const useContractsWithFilters = ({ availablePhases }: useContractsWithFiltersParams) => {
  const { t: tCommon } = useTranslation();
  const { t: tSiva } = useTranslation('siva');

  const phaseFilterOptions = useMemo(
    () => [
      ...availablePhases.map((phase) => ({
        value: phase,
        text: tSiva(`phase.${phase}` as any, {
          defaultValue: phase,
        }),
      })),
    ],
    [availablePhases, tSiva],
  );

  const [searchParams, setSearchParams] = useSearchParams();
  const { phaseFilter, advisorFilter, fromDate, toDate } = useMemo(() => {
    return {
      phaseFilter: (searchParams.get('p')?.split(',') as SivaPhase[]) ?? ([] as SivaPhase[]),
      advisorFilter: searchParams.get('advisor')?.split(',') ?? [],
      fromDate: searchParams.get('from') ?? '',
      toDate: searchParams.get('to') ?? '',
    };
  }, [searchParams]);

  const { contracts } = useSivaContracts({
    sivaPhases: availablePhases,
  });

  const advisorOptions = useMemo(() => {
    const sortedUniqueNames = [
      ...new Set(
        contracts
          .filter((contract) => contract.advisorLastName && contract.advisorFirstName)
          .map(advisorColumnAccessor),
      ),
    ].sort();
    return [
      ...sortedUniqueNames.map((value) => ({
        value,
        text: value,
      })),
    ];
  }, [contracts]);

  const filtersElement = (
    <div className={styles.filters}>
      {availablePhases.length > 1 && (
        <div className="width--25">
          <QueryParamMultiSelect
            name="idea-filter"
            label={tCommon('phase')}
            queryStringParam="p"
            options={phaseFilterOptions}
            hideError={true}
          />
        </div>
      )}
      <div className="width--25">
        <QueryParamMultiSelect
          queryStringParam="advisor"
          name="advisor-filter"
          label={tCommon('advisor')}
          options={advisorOptions}
          hideError={true}
        />
      </div>
      {availablePhases.length === 1 && availablePhases[0] === SivaPhase.Alumni && (
        <>
          <div className="width--25">
            <FilterDateInput queryStringParam={'from'} label={tCommon('fromDate')} />
          </div>
          <div className="width--25">
            <FilterDateInput queryStringParam={'to'} label={tCommon('toDate')} />
          </div>
        </>
      )}

      <ResetFilterButton
        onClick={() => setSearchParams(undefined, { replace: true })}
        className={styles.resetButton}
      />
    </div>
  );

  const filteredContracts = useMemo(() => {
    const filterByPhase = (item: SivaContractOverviewDto): boolean => {
      if (phaseFilter.length > 0) {
        return phaseFilter.includes(item.phase as SivaPhase);
      }
      return true;
    };
    const filterByAdvisor = (item: SivaContractOverviewDto): boolean => {
      if (advisorFilter.length > 0) {
        return advisorFilter.includes(`${item.advisorFirstName} ${item.advisorLastName}`.trim());
      }
      return true;
    };
    const filterByDateRange = (item: SivaContractOverviewDto): boolean => {
      if (item?.fromDate === undefined) return false;
      const submittedDate = new Date(item?.fromDate);
      return (
        (fromDate ? submittedDate >= new Date(fromDate) : true) &&
        (toDate ? submittedDate <= new Date(toDate) : true)
      );
    };
    return contracts.filter(filterByPhase).filter(filterByAdvisor).filter(filterByDateRange);
  }, [advisorFilter, contracts, fromDate, phaseFilter, toDate]);

  return {
    filtersElement,
    contracts: filteredContracts,
  };
};

export default SivaContractOverviewDataTable;
