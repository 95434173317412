import { NavigateOptions, useSearchParams } from 'react-router-dom';
import React, { useCallback, useMemo } from 'react';
import NewCompanyProgressBar from '../components/company/NewCompanyProgressBar';

export function useFormSteps<T extends string>(steps: ReadonlyArray<T>) {
  const firstStep = steps[0];
  const [searchParams, setSearchParams] = useSearchParams({ step: firstStep });
  const step = searchParams.get('step') as T;
  const currentIndex = steps.indexOf(step);
  const nextStep = steps[currentIndex + 1];
  const previousStep = steps[currentIndex - 1];
  const totalSteps = steps.length;

  const setStep = useCallback(
    (step: T, navigateOptions?: NavigateOptions) => {
      setSearchParams({ step }, navigateOptions);
      window.scrollTo(0, 0);
    },
    [setSearchParams],
  );

  return {
    step,
    setStep,
    resetStep: useCallback(() => setSearchParams({ step: firstStep }), [firstStep, setSearchParams]),
    stepForward: useMemo(() => (nextStep ? () => setStep(nextStep) : undefined), [nextStep, setStep]),
    stepBackward: useMemo(
      () => (previousStep ? () => setStep(previousStep) : undefined),
      [previousStep, setStep],
    ),
    stepProgressBar: useMemo(
      () => <NewCompanyProgressBar currentStepIndex={currentIndex} totalSteps={totalSteps} />,
      [currentIndex, totalSteps],
    ),
  };
}
