import { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { ClusterContactCreateDto, ClusterContactsService, ClusterContactUpdateDto } from 'src/api/v2';
import { MEMBERS_KEY, TIME_ENTRIES_KEY } from 'src/constants/hook-keys';

import { CONTACTS_KEY, CONTACT_KEY } from 'src/constants/hook-keys';
import { useAuth } from 'src/features/authorization';

import { useTranslation } from 'react-i18next';
import { useCluster } from 'src/features/cluster';

export type UseContactsProps = {
  enabled: boolean;
};

export default function useContacts({ enabled }: UseContactsProps = { enabled: true }) {
  const { cluster } = useCluster();
  const { checkAccess } = useAuth();
  const { read, write } = checkAccess('ResourceBase');
  const { t: tCommon } = useTranslation('common');

  const [service] = useState(new ClusterContactsService());

  const queryClient = useQueryClient();

  const { data: contacts, ...rest } = useQuery([CONTACTS_KEY], () => service.getClusterContacts(), {
    enabled: !!cluster && enabled && read,
  });

  const createMutation = useMutation(
    async (record: ClusterContactCreateDto) => {
      if (!write) {
        throw new Error(tCommon('cannotWrite'));
      }

      if (!record.clusterId && cluster) {
        record.clusterId = cluster.id;
      }

      return await service.create([record]);
    },
    {
      onSuccess(result) {
        queryClient.setQueryData([CONTACTS_KEY], []);

        queryClient.invalidateQueries([CONTACTS_KEY]);

        result.forEach((item) => {
          if (item.data) {
            queryClient.invalidateQueries([CONTACT_KEY, item.data.id]);
          }
        });
      },
    },
  );

  const createListMutation = useMutation(
    async (records: ClusterContactCreateDto[]) => {
      if (!write) {
        throw new Error(tCommon('cannotWrite'));
      }

      return await service.create(records);
    },
    {
      onSuccess(result) {
        queryClient.resetQueries([CONTACTS_KEY]);

        result.forEach((item) => {
          if (item.data) {
            queryClient.resetQueries([CONTACT_KEY, item.data.id]);
          }
        });
      },
    },
  );

  const updateMutation = useMutation(
    async ({ contactId, record }: { contactId: string; record: ClusterContactUpdateDto }) => {
      if (!write) {
        throw new Error(tCommon('cannotWrite'));
      }

      return await service.updateContact(contactId, record);
    },
    {
      onSuccess(data) {
        queryClient.resetQueries([CONTACTS_KEY]);
        queryClient.resetQueries([TIME_ENTRIES_KEY]);
        queryClient.resetQueries([CONTACT_KEY, data.id]);

        if (data.clusterMemberId) {
          queryClient.resetQueries([MEMBERS_KEY]);
        }
      },
    },
  );

  const deleteListMutation = useMutation(
    async (contactIds: string[]) => {
      if (!write) {
        throw new Error(tCommon('cannotWrite'));
      }

      return await service.delete(contactIds);
    },
    {
      onSuccess(data) {
        queryClient.resetQueries([CONTACTS_KEY]);

        if (data) {
          data.forEach((item) => {
            if (item.data) {
              queryClient.resetQueries([CONTACT_KEY, item.data]);
            }
          });
        }
      },
    },
  );

  const approveLeadsMutation = useMutation(
    async (contactIds: string[]) => {
      if (!write) {
        throw new Error(tCommon('cannotWrite'));
      }

      return await service.approveLeadsToMembers(contactIds);
    },
    {
      onSuccess() {
        queryClient.resetQueries([CONTACTS_KEY]);
      },
    },
  );

  return {
    contacts: contacts || [],
    ...rest,

    createMutation,
    createListMutation,
    updateMutation,
    deleteListMutation,
    approveLeadsMutation,
  };
}
