import { SivaContractDetailDto, SivaPhase } from 'src/api/v2';

export function isSivaContractActive(contract: SivaContractDetailDto | undefined) {
  if (!contract) return false;

  const lastPhase = contract.phases.at(-1);

  // If we don't have a phase, it is not active
  if (!lastPhase) return false;

  // If the last phase is alumni, it is not active

  return lastPhase.sivaPhase !== SivaPhase.Alumni;
}
