import { SivaContractPhaseDto } from 'src/api/v2';

/**
 * Get the newest phase active on current date or earlier if any.
 *
 * Phases are sorted oldest first from SL.
 */
export function getContractCurrentPhase(phases: SivaContractPhaseDto[]) {
  return phases.filter((phase) => new Date(phase.fromDate) <= new Date()).at(-1);
}
