import { Notification } from '@in/component-library';
import React from 'react';
import { useTranslation } from 'react-i18next';
import LinkButtonDetails from 'src/components/LinkButtonDetails/LinkButtonDetails';

type Props = {
  items: { email: string; rowNumber: number }[];
};

const InvalidEmailsNotification: React.FC<Props> = ({ items }) => {
  const { t: tCommon } = useTranslation();
  const { t: tResourceBase } = useTranslation('resourceBase');

  if (items.length === 0) {
    return null;
  }

  return (
    <Notification type="error" fullWidth className="margin-bottom--2">
      <p>{tResourceBase('import.invalidEmailsFound')}</p>
      <LinkButtonDetails text={tResourceBase('showInvalidEmails')}>
        <ul>
          {items.map(({ email, rowNumber }, index) => (
            <li key={index}>
              {email} ({tCommon('row')}: {rowNumber})
            </li>
          ))}
        </ul>
      </LinkButtonDetails>
    </Notification>
  );
};

export default InvalidEmailsNotification;
