import { Notification } from '@in/component-library';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ClusterContactCreateDto, ClusterMemberCreateDto } from 'src/api/v2';

type Props = {
  contacts: ClusterContactCreateDto[];
  leads: ClusterMemberCreateDto[];
};

const ReadyForImportNotification: React.FC<Props> = ({ contacts, leads }) => {
  const { t: tResourceBase } = useTranslation('resourceBase');

  if (contacts.length === 0) {
    return null;
  }

  return (
    <Notification type="success" fullWidth className="margin-bottom--3">
      <p>
        {tResourceBase('import.contact.readyForImport', { contacts: contacts.length, leads: leads.length })}
      </p>
    </Notification>
  );
};

export default ReadyForImportNotification;
