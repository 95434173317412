import { useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { SessionService, UsersService } from 'src/api/v2';
import { COMMON_AAD } from 'src/components/AppHeader/constants';

import {
  B2C_INFO_KEY,
  USER_KEY,
  USER_SESSION_HAS_EXIRED,
  USER_STATUS_HAS_ACCEPTED_TERMS,
  USER_STATUS_HAS_CLUSTERS,
  USER_STATUS_HAS_LOGIN_ERROR_4XX,
  USER_STATUS_HAS_LOGIN_ERROR_5XX,
  USER_STATUS_LOADING,
  USER_STATUS_REGISTERED,
  USER_STAUTS_LOGGED_IN,
} from 'src/constants/hook-keys';

const useUser = () => {
  const [usersService] = useState(new UsersService());
  const [sessionService] = useState(new SessionService());

  const queryClient = useQueryClient();

  const { data: loadingUser } = useQuery([USER_STATUS_LOADING], () => true, {
    enabled: false,
    initialData: true,
  });

  const { data: isUserLoggedIn } = useQuery([USER_STAUTS_LOGGED_IN], () => false, {
    enabled: false,
    initialData: false,
  });

  const { data: isUserRegistered } = useQuery([USER_STATUS_REGISTERED], () => false, {
    enabled: false,
    initialData: false,
  });

  const { data: hasUserClusters } = useQuery([USER_STATUS_HAS_CLUSTERS], () => true, {
    enabled: false,
    // Satt til true default, pga renders, hvis denne er false 1-2 første render ved refresh
    // useClusters() får da i oppgave om å motvevise at denne da er `false` hvis ingen klynger
    initialData: true,
  });

  const { data: hasUserSessionExipred } = useQuery([USER_SESSION_HAS_EXIRED], () => false, {
    enabled: false,
    initialData: false,
  });

  const { data: hasUserLoginError4XX } = useQuery([USER_STATUS_HAS_LOGIN_ERROR_4XX], () => false, {
    enabled: false,
    initialData: false,
  });

  const { data: hasUserLoginError5XX } = useQuery([USER_STATUS_HAS_LOGIN_ERROR_5XX], () => false, {
    enabled: false,
    initialData: false,
  });

  const setHasUserClusters = (value: boolean) => queryClient.setQueryData([USER_STATUS_HAS_CLUSTERS], value);

  const { data: hasUserAcceptedTerms } = useQuery([USER_STATUS_HAS_ACCEPTED_TERMS], () => false, {
    enabled: false,
    initialData: false,
  });

  const { data: user, ...rest } = useQuery([USER_KEY], () => sessionService.getCurrent(), {
    refetchInterval: 1000 * 60,
    retry: false,

    onSuccess: (data) => {
      if (data) {
        queryClient.setQueryData([USER_STAUTS_LOGGED_IN], true);
        queryClient.setQueryData([USER_STATUS_REGISTERED], true);
        queryClient.setQueryData([USER_STATUS_LOADING], false);
        queryClient.setQueryData([USER_STATUS_HAS_LOGIN_ERROR_4XX], false);
        queryClient.setQueryData([USER_STATUS_HAS_LOGIN_ERROR_5XX], false);
        queryClient.setQueryData([USER_SESSION_HAS_EXIRED], false);
      }
    },
    onError: (error) => {
      const status = (error as any).Status || (error as any).status;

      switch (status) {
        case 404:
          queryClient.setQueryData([USER_STAUTS_LOGGED_IN], true);
          queryClient.setQueryData([USER_STATUS_REGISTERED], false);
          break;

        case 400:
        case 403:
          queryClient.setQueryData([USER_STATUS_HAS_LOGIN_ERROR_4XX], true);
          break;
        case 500:
          queryClient.setQueryData([USER_STATUS_HAS_LOGIN_ERROR_5XX], true);
          break;
        default:
          if (isUserLoggedIn) {
            // Bruker var logget inn, dvs at sesjon har gått ut.
            queryClient.setQueryData([USER_SESSION_HAS_EXIRED], true);
          }

          queryClient.setQueryData([USER_STAUTS_LOGGED_IN], false);
          queryClient.setQueryData([USER_STATUS_REGISTERED], false);
          queryClient.setQueryData([USER_STATUS_HAS_CLUSTERS], false);
          queryClient.setQueryData([USER_STATUS_HAS_ACCEPTED_TERMS], false);
          break;
      }

      queryClient.setQueryData([USER_STATUS_LOADING], false);
      queryClient.setQueryData([USER_KEY], undefined);
    },
  });

  const b2cInfo = useQuery([B2C_INFO_KEY], () => usersService.getUserByB2CId(), {
    enabled: !!user,

    onSuccess(data) {
      if (new Date(data.portalTermsConsentDate) < new Date('2000-01-01')) {
        queryClient.setQueryData([USER_STATUS_HAS_ACCEPTED_TERMS], false);
      } else {
        queryClient.setQueryData([USER_STATUS_HAS_ACCEPTED_TERMS], true);
      }
    },
    retry: false,
  });

  const acceptTermsMutation = useMutation(
    () => {
      if (!b2cInfo.data) {
        return Promise.reject('b2cInfo is undefined');
      }

      return usersService.consentPortalTerms();
    },
    {
      onSuccess() {
        queryClient.invalidateQueries([B2C_INFO_KEY]);
      },
    },
  );

  const logOut = () => {
    window.location.href = '/api/v2/session/sign-out';
  };

  const chosenClusterId = useMemo<string | undefined>(() => {
    if (!user) return undefined;

    return user.chosenClusterId;
  }, [user]);

  const displayName = useMemo<string | undefined>(() => {
    if (!user) return undefined;

    return user.displayName;
  }, [user]);

  const isAadUser = useMemo(() => user?.authDomain === COMMON_AAD, [user]);

  if (isAadUser) {
    // Stop siteimprove tracking
    if (typeof window._sz === 'object') {
      window._sz.push(['notrack']);
    }
  }

  return {
    loadingUser,
    isUserLoggedIn,
    isUserRegistered,
    hasUserClusters,
    setHasUserClusters,
    hasUserAcceptedTerms,
    hasUserLoginError4XX,
    hasUserLoginError5XX,
    chosenClusterId,
    displayName,
    dateOfBirth: user?.dateOfBirth,
    hasUserSessionExipred,

    user,
    b2cInfo,
    isAadUser,
    logOut,

    ...rest,
    acceptTermsMutation,
  };
};

export default useUser;
