import { useMemo, useState } from 'react';
import { getContractCurrentPhase } from '../utils/helpers';
import { SivaContractUpdateDto, SivaPhase, SivaService } from 'src/api/v2';
import { useCluster } from 'src/features/cluster';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { SIVA_CONTRACTS_DETAIL_KEY, SIVA_CONTRACTS_KEY } from 'src/constants/hook-keys';

const useCompanyContract = (contractId: number | undefined) => {
  const { cluster } = useCluster();

  const [service] = useState(() => new SivaService());

  const queryClient = useQueryClient();

  const { data, isLoading, isError } = useQuery({
    queryKey: [SIVA_CONTRACTS_DETAIL_KEY, contractId],
    queryFn: () => service.getSivaContractDetails(contractId!),
    enabled: !!cluster && !!contractId,
  });

  const updateSivaContractMutation = useMutation(
    (updateDto: SivaContractUpdateDto) => service.updateSivaContract(updateDto),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([SIVA_CONTRACTS_DETAIL_KEY, contractId]);
        queryClient.resetQueries([SIVA_CONTRACTS_DETAIL_KEY, contractId]);
        queryClient.refetchQueries([SIVA_CONTRACTS_KEY]);
        queryClient.invalidateQueries([SIVA_CONTRACTS_KEY]);
      },
    },
  );

  const companyContracts = useMemo(() => {
    return data?.company?.contracts || [];
  }, [data]);

  const contract = data?.requestedContract;

  const currentPhase = useMemo(
    () => (contract ? getContractCurrentPhase(contract?.phases) : undefined),
    [contract],
  );

  const contractAdmissionDate = contract?.admissionDate;

  const contractEndDate =
    currentPhase?.sivaPhase === SivaPhase.Alumni ? currentPhase.fromDate : currentPhase?.toDate;

  return {
    contract,
    contractAdmissionDate,
    contractEndDate,
    currentPhase,
    company: data?.company,
    admissionFormDate: contract?.startupFormDate,
    enrouteFormDate: contract?.inProgressFormDate,
    exitFormDate: contract?.exitFormDate,
    companyContracts,
    companyContractsCount: companyContracts.length,
    updateSivaContractMutation: updateSivaContractMutation,

    isLoading,
    isError,
  };
};

export default useCompanyContract;
