import React from 'react';
import { useTranslation } from 'react-i18next';

import '../styles/ErrorSupportMessage.scss';

const ErrorSupportMessage: React.FC = () => {
  const { t: tSiva } = useTranslation('siva');
  return (
    <div>
      <div>{tSiva('error.support.message')}</div>
      <div>
        <a className="siva-error-support__message" href="mailto:support@siva.no" title="support@siva.no">
          support@siva.no
        </a>
      </div>
    </div>
  );
};

export default ErrorSupportMessage;
