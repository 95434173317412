export const convertHoursAndMinutesToHours = (hours: number, minutes: number): number => {
  // Gotta account for those strings who think they're numbers.
  const h = Number(hours) ?? 0;
  const m = Number(minutes) ?? 0;

  if (h + m === 0) return 0;

  const minutesToHours = m / 60;

  return h + minutesToHours;
};

export const convertHourstoHoursAndMinutes = (hours: number): { hours: number; minutes: number } => {
  /**
   * Nullish-operator doesn't evaluate NaN, which is returned from Number("abc").
   * Use boolean casting.
   */
  // eslint-disable-next-line no-extra-boolean-cast
  const h = !!Number(hours) ? hours : 0;

  if (h === 0) {
    return {
      hours: 0,
      minutes: 0,
    };
  }

  // If hours is positive, round down. If hours is negative, round up.
  const wholeHours = h > 0 ? Math.floor(h) : Math.ceil(h);

  const fraction = h - wholeHours;
  const fractionMinutes = fraction * 60;

  const wholeMinutes = fractionMinutes > 0 ? Math.floor(fractionMinutes) : Math.ceil(fractionMinutes);

  return {
    hours: wholeHours,
    minutes: wholeMinutes,
  };
};
