import { lazy } from 'react';
export { ONE_WAY_IN } from './routes';
export { default as routes } from './routes';

export { default as useOneWayIn } from './hooks/use-one-way-in';
export { default as useOneWayInList } from './hooks/use-one-way-in-list';
export { default as useOneWayInServicesByCluster } from './hooks/use-one-way-in-services-by-cluster';
export { default as useOneWayInNewOpportunitiesNumber } from './hooks/use-one-way-in-new-opportunities-number';
export { default as OneWayInOpportunities } from './pages/OneWayInOpportunities';
export { default as OneWayInCountyOpportunities } from './pages/OneWayInCountyOpportunities';
export { default as OneWayInActorOpportunities } from './pages/OneWayInActorOpportunities';

export const OneWayInFeature = lazy(() => import('./pages/OneWayInFeature'));
