import React, { useCallback, useEffect } from 'react';
import { NumberInput, IconButton, FaIcons, Button, ButtonVariant } from '@in/component-library';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { formatCurrencySimple } from 'src/utils/FormatValue';
import useMemberEconomy from './hooks/use-member-economy';
import useMemberEconomySumCalculation from './hooks/use-member-economy-sum-calculation';
import { EconomyData } from './MemberEconomyForm';
import css from './MemberEconomyForm.module.scss';
import cn from 'classnames';
import { ClusterMemberEconomyDto } from 'src/api/v2';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'src/features/authorization';

interface Props {
  finances?: Array<ClusterMemberEconomyDto>;
  memberId: string;
  setTotalMarkedValue?: React.Dispatch<React.SetStateAction<number>>;
  setTotalInvoicedDeductible?: React.Dispatch<React.SetStateAction<number>>;
  setTotalInnovationSupport?: React.Dispatch<React.SetStateAction<number>>;
  setTotalSupportIntensity?: React.Dispatch<React.SetStateAction<number>>;
  setTotalSupportLimit?: React.Dispatch<React.SetStateAction<number>>;
}

const MemberDeductibleTable: React.FC<Props> = ({
  memberId,
  finances,
  setTotalMarkedValue,
  setTotalInvoicedDeductible,
  setTotalInnovationSupport,
  setTotalSupportIntensity,
  setTotalSupportLimit,
}) => {
  const { createMutation, updateMutation, deleteMutation } = useMemberEconomy(memberId);
  const { t: tCommon } = useTranslation('common');
  const { t: tResourceBase } = useTranslation('resourceBase');

  const { checkAccess } = useAuth();
  const access: boolean = checkAccess('MemberEconomy').write;

  const { control, getValues } = useForm<EconomyData>({
    shouldUnregister: false,
    defaultValues: {
      finances: finances,
    },
  });
  const {
    fields: financingSourceFields,
    append: appendFinance,
    remove: removeFinance,
  } = useFieldArray<EconomyData, 'finances', 'internalId'>({
    control,
    name: 'finances',
    keyName: 'internalId',
  });

  const {
    totalSupportIntensity,
    totalInnovationSupport,
    totalSupportLimit,
    totalMarkedValue,
    totalInvoicedDeductible,
    calculateSupportIntensity,
    calculateInnovationSupport,
    calculateLimitValue,
  } = useMemberEconomySumCalculation(control);

  useEffect(() => {
    setTotalMarkedValue?.(totalMarkedValue);
    setTotalInvoicedDeductible?.(totalInvoicedDeductible);
    setTotalInnovationSupport?.(totalInnovationSupport);
    setTotalSupportIntensity?.(totalSupportIntensity);
    setTotalSupportLimit?.(totalSupportLimit);
  }, [
    setTotalInnovationSupport,
    setTotalInvoicedDeductible,
    setTotalMarkedValue,
    setTotalSupportIntensity,
    setTotalSupportLimit,
    totalInnovationSupport,
    totalInvoicedDeductible,
    totalMarkedValue,
    totalSupportIntensity,
    totalSupportLimit,
  ]);

  const inputBlurHandler = useCallback(
    (id, index) => {
      const clusterMemberEconomy = getValues('finances')[index];
      updateMutation.mutateAsync({
        clusterMemberEconomyId: id,
        clusterMemberEconomy: clusterMemberEconomy,
      });
    },
    [updateMutation, getValues],
  );
  return (
    <div className={'display--flex flex-direction--column'}>
      <div className={css['input-grid']} role="table" aria-label="">
        <div className={css['header__row']} role="rowgroup">
          <div role={'columnheader'}>{tResourceBase('invoiceNumber')}</div>
          <div role={'columnheader'}>{tResourceBase('marketValue')}</div>
          <div role={'columnheader'}>{tResourceBase('invoicedDeductible')}</div>
          <div role={'columnheader'}>{tResourceBase('innovationSupport')}</div>
          <div role={'columnheader'}>{tResourceBase('supportIntensity')}</div>
          <div role={'columnheader'}>{tResourceBase('tooMuchSupport')}</div>
          <div role={'columnheader'} aria-label="slett"></div>
        </div>

        {financingSourceFields.map((financingSource, index) => (
          <div className={css['body__row']} key={financingSource.id} role="rowgroup">
            <div role="cell">
              <Controller
                render={({ field }) => (
                  <NumberInput
                    {...field}
                    onBlur={() => {
                      field.onBlur();
                      inputBlurHandler(financingSource.id, index);
                    }}
                    autoComplete="off"
                    hideError={true}
                    label={tResourceBase('invoiceNumber')}
                    hideLabel={true}
                    disabled={!access}
                  />
                )}
                name={`finances.${index}.invoiceNumber` as const}
                control={control}
              />
            </div>

            <div role="cell">
              <Controller
                render={({ field }) => (
                  <NumberInput
                    {...field}
                    role="cell"
                    numberLocale="nb-NO"
                    onBlur={() => {
                      field.onBlur();
                      inputBlurHandler(financingSource.id, index);
                    }}
                    onChange={(event) => {
                      field.onChange(+event.target.value);
                    }}
                    autoComplete="off"
                    hideError={true}
                    label={tResourceBase('marketValue')}
                    hideLabel={true}
                    disabled={!access}
                  />
                )}
                name={`finances.${index}.marketValue` as const}
                control={control}
              />
            </div>

            <div role="cell">
              <Controller
                render={({ field }) => (
                  <NumberInput
                    {...field}
                    role="cell"
                    numberLocale="nb-NO"
                    onChange={(event) => {
                      field.onChange(+event.target.value);
                    }}
                    onBlur={() => {
                      field.onBlur();
                      inputBlurHandler(financingSource.id, index);
                    }}
                    autoComplete="off"
                    hideError={true}
                    label={tResourceBase('invoicedDeductible')}
                    hideLabel={true}
                    disabled={!access}
                  />
                )}
                name={`finances.${index}.invoicedDeductible` as const}
                control={control}
              />
            </div>

            <div role="cell">{formatCurrencySimple(calculateInnovationSupport(index), false)}</div>

            <div role="cell">{calculateSupportIntensity(index)}%</div>

            <div className={cn({ [css['error']]: calculateLimitValue(index) > 0.75 })} role="cell">
              {formatCurrencySimple(calculateLimitValue(index), false)}
            </div>

            <div role="cell">
              {access && (
                <IconButton
                  isLoading={deleteMutation.isLoading}
                  iconName={FaIcons.TrashAltLight}
                  onClick={() => {
                    deleteMutation.mutateAsync(financingSource.id).then(() => {
                      removeFinance(index);
                    });
                  }}
                />
              )}
            </div>
          </div>
        ))}
      </div>
      <div></div>
      {access && (
        <Button
          isLoading={deleteMutation.isLoading}
          variant={ButtonVariant.Link}
          iconName={FaIcons.PlusLight}
          onClick={() => {
            createMutation
              .mutateAsync({
                invoicedDeductible: 0,
                marketValue: 0,
                invoiceNumber: '',
                clusterMemberId: memberId,
              })
              .then((data) => {
                appendFinance(data);
              });
          }}
        >
          {tCommon('add')}
        </Button>
      )}
    </div>
  );
};

export default MemberDeductibleTable;
