import { Tag } from '@in/component-library';
import React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  active: boolean;
};

const CompanyActiveTag: React.FC<Props> = ({ active }) => {
  const { t: tCommon } = useTranslation();
  const type = active ? 'success' : 'generic';

  return <Tag type={type}>{active ? tCommon('active') : tCommon('inactive')}</Tag>;
};

export default CompanyActiveTag;
