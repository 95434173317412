import i18next from 'i18next';
import { OneWayInStatus, OneWayInOpportunitySimpleV2Dto } from 'src/api/v2';

export type StatusTag = {
  status: {
    status: (typeof OneWayInStatus)['Received'];
  };
};

export const translateStatusTag = (currentStatusTag: string | undefined) => {
  const { t } = i18next;

  let statusTagText = t<string>('oneWayIn:status.wip');

  if (
    !currentStatusTag ||
    currentStatusTag === OneWayInStatus.Received ||
    currentStatusTag === OneWayInStatus.New
  ) {
    statusTagText = t<string>('oneWayIn:status.New');
  }

  if (currentStatusTag === OneWayInStatus.Closed || currentStatusTag === OneWayInStatus.Forwarded) {
    statusTagText = t<string>('oneWayIn:status.Archived');
  }

  if (currentStatusTag === OneWayInStatus.FollowUpLater) {
    statusTagText = t<string>('oneWayIn:status.FollowUpLater');
  }

  return statusTagText;
};

export function filterSelectedStatusTag(
  item: OneWayInOpportunitySimpleV2Dto,
  filterValue: (OneWayInStatus | null)[],
) {
  if (filterValue.length === 0) {
    return true;
  }

  if (!item.status) {
    return filterValue.includes(null);
  }

  return filterValue.includes(item.status.status);
}
