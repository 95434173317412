import { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { ClusterContactsService, ClusterMembersService, ClusterMemberUpdateDto } from 'src/api/v2';

import { MEMBER_KEY, MEMBER_CONTACTS_KEY, MEMBERS_KEY } from 'src/constants/hook-keys';

import useCluster from '../features/cluster/hooks/use-cluster';

export default function useMember(memberId: string | undefined = '') {
  const { cluster } = useCluster();

  const [memberService] = useState(new ClusterMembersService());
  const [contactService] = useState(new ClusterContactsService());

  const queryClient = useQueryClient();

  const { data: member, ...rest } = useQuery(
    [MEMBER_KEY, memberId],
    () => memberService.getSingle(memberId),
    {
      enabled: !!cluster && !!memberId,
    },
  );

  const contacts = useQuery(
    [MEMBER_CONTACTS_KEY, memberId],
    () => contactService.getClusterMemberContacts(memberId),
    {
      enabled: !!member,
    },
  );

  const updateMutation = useMutation(
    (record: ClusterMemberUpdateDto) => {
      if (!memberId) {
        throw new Error('noMemberId');
      }

      return memberService.update(memberId, record);
    },
    {
      onSuccess() {
        queryClient.resetQueries([MEMBERS_KEY]);
        queryClient.resetQueries([MEMBER_KEY, memberId], { exact: true });
      },
    },
  );

  const deleteMutation = useMutation(
    () => {
      return memberService.delete(memberId);
    },
    {
      onSuccess() {
        queryClient.resetQueries([MEMBERS_KEY]);
        queryClient.removeQueries([MEMBER_KEY, memberId]);
      },
    },
  );

  return {
    member,
    contacts,

    ...rest,

    updateMutation,
    deleteMutation,
  };
}
