import React, { FC, useMemo, useState } from 'react';
import { SelectOptionProps } from '@in/component-library';
import {
  DevelopmentProjectDto,
  DevelopmentProjectListItemDto,
  EventCategory,
  EventCreateDtoV2,
  EventDtoV2,
  EventUpdateDtoV2,
  OperationProjectDto,
  PortalProjectDto,
  PortalProjectListItemDto,
  ProjectsDto,
} from 'src/api/v2';
import { toastPromise } from 'src/utils/toast';
import { convertHoursAndMinutesToHours, convertHourstoHoursAndMinutes } from 'src/utils/time';

import './EventForm.scss';
import { useTranslation } from 'react-i18next';
import useProjectDetails from 'src/hooks/use-project-details';
import EventForm, { ExtraFormDto } from './EventForm';
import { getSafeDateOnly } from 'src/utils/date';
import useEvent from 'src/hooks/use-event';
import useProjects, { type UseProjectsType } from '../../hooks/use-projects';
import { isDevelopmentProject, isOperationProject } from '../../utils/project-utils';

interface EventFormWrapperProps {
  project?:
    | DevelopmentProjectDto
    | PortalProjectDto
    | OperationProjectDto
    | ProjectsDto
    | DevelopmentProjectListItemDto
    | PortalProjectListItemDto;
  event?: EventDtoV2;
  onClose?: () => void;

  projectOptionFilter?: (project: UseProjectsType) => boolean;
}

type ExtendedCreateEventDto = EventCreateDtoV2 & { formHours: number; formMinutes: number };
type ExtendedUpdateEventDto = EventUpdateDtoV2 & { formHours: number; formMinutes: number };

const EventFormWrapper: FC<EventFormWrapperProps> = ({ project, event, onClose, projectOptionFilter }) => {
  const { t: tEvents } = useTranslation('events');
  const [isLoading, setIsLoading] = useState(false);
  const [selectedProjectId, setSelectedProjectId] = useState<string | undefined>(project?.id);
  const { projects, project: selectedProject } = useProjects(selectedProjectId);

  const { projectExpenses } = useProjectDetails(selectedProjectId);

  const { createMutation, updateMutation } = useEvent(event ? event.id : '');

  const projectExpenseOptions = useMemo(
    () =>
      projectExpenses.map((expenseType) => {
        if (expenseType.expenseType?.name) {
          return { text: expenseType.expenseType.name, value: expenseType.expenseType.id };
        } else if (expenseType.title) {
          return { text: expenseType.title, value: expenseType.id };
        } else {
          return { text: '', value: '' };
        }
      }),
    [projectExpenses],
  );

  const projectOptions: SelectOptionProps[] = projects
    .filter((project) => (projectOptionFilter ? projectOptionFilter(project) : true))
    .map((project) => {
      return { value: project.id, text: project.name };
    });

  const handleCreateEvent = (data: ExtendedCreateEventDto) => {
    setIsLoading(true);
    const record: EventCreateDtoV2 = {
      name: data.name,
      description: data.description,
      projectId: selectedProjectId ? selectedProjectId : data.projectId,
      startDate: getSafeDateOnly(new Date(data.startDate)),
      endDate: data.endDate ? getSafeDateOnly(new Date(data.endDate)) : undefined,
      duration: convertHoursAndMinutesToHours(data.formHours, data.formMinutes),
      location: data.location,
      isPublic: !data.isPublic, //Toggle value
      expenseTypeIds: data.expenseTypeIds,
      category: EventCategory.Event,
    };

    const promise = createMutation.mutateAsync(record);

    toastPromise(promise, {
      error: tEvents('couldNotCreateEvent'),
    })
      .then(() => {
        setIsLoading(false);
        onClose?.();
      })
      .catch(() => setIsLoading(false));
  };

  const handleUpdateEvent = (data: ExtendedUpdateEventDto) => {
    setIsLoading(true);
    // Assert confidence in event existance
    const _event = event as EventDtoV2;

    const record: EventUpdateDtoV2 = {
      name: data.name,
      projectId: data.projectId,
      description: data.description,
      startDate: getSafeDateOnly(new Date(data.startDate)),
      endDate: data.endDate ? getSafeDateOnly(new Date(data.endDate)) : undefined,
      duration: convertHoursAndMinutesToHours(data.formHours, data.formMinutes),
      location: data.location,
      isPublic: !data.isPublic, //Toggle value
      expenseTypeIds: data.expenseTypeIds,
      category: undefined,
    };

    const promise = updateMutation.mutateAsync({ eventId: _event.id, record });

    toastPromise(promise, {
      error: tEvents('couldNotEditEvent'),
    })
      .then(() => {
        setIsLoading(false);
        onClose?.();
      })
      .catch(() => setIsLoading(false));
  };

  const onSubmit = event ? handleUpdateEvent : handleCreateEvent;

  // Project is either a DevelopmentProject or OperationProject based on unique non-null properties
  const showExpenseAssociation =
    selectedProject !== undefined &&
    (isOperationProject(selectedProject) || isDevelopmentProject(selectedProject));

  const hasProject = !!project;
  const hasEvent = !!event;

  const { hours, minutes } = convertHourstoHoursAndMinutes(event?.duration || 0);

  const defaultValues: Partial<EventDtoV2 & ExtraFormDto> = {
    projectId: project ? project.id : '',
    expenseTypeIds: event?.expenseTypes?.[0] ? [event.expenseTypes[0].id] : [],
    formHours: hours,
    formMinutes: minutes,
    ...event,
  };

  const shouldUseDefaults = hasProject && hasEvent;

  return (
    <EventForm
      onSubmit={onSubmit}
      onClose={onClose}
      setSelectedProjectId={setSelectedProjectId}
      defaultValues={defaultValues}
      showProjectSelector={!hasProject}
      showExpenseAssociation={showExpenseAssociation}
      showDeleteFormButton={hasEvent}
      shouldUseDefaults={shouldUseDefaults}
      projectOptions={projectOptions}
      projectExpenseOptions={projectExpenseOptions}
      isLoading={isLoading}
      project={project ? project : selectedProject}
    />
  );
};

export default EventFormWrapper;
