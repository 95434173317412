import React from 'react';
import { Navigate, RouteObject } from 'react-router-dom';

const MemberContacts = React.lazy(() => import('./pages/MemberContacts'));
const MemberInformation = React.lazy(() => import('./pages/MemberInformation'));
const MemberFiles = React.lazy(() => import('./pages/MemberFiles'));
const MemberFeesAndEffort = React.lazy(() => import('./pages/MemberFeesAndEffort'));
const MemberInterestAreas = React.lazy(() => import('./pages/MemberInterestAreas'));
const MemberCompanyInformation = React.lazy(() => import('./pages/MemberCompanyInformation'));
const Grants = React.lazy(() => import('./pages/Grants'));

const routes: RouteObject[] = [
  {
    index: true,
    element: <Navigate to="company-information" replace />,
  },
  {
    path: 'company-information',
    element: <MemberCompanyInformation />,
  },
  {
    path: 'info',
    element: <MemberInformation />,
  },
  {
    path: 'files',
    element: <MemberFiles />,
  },
  {
    path: 'interest-areas',
    element: <MemberInterestAreas />,
  },
  {
    path: 'contacts',
    element: <MemberContacts />,
  },
  {
    path: 'member-fees-and-effort',
    element: <MemberFeesAndEffort />,
  },
  {
    path: 'grants',
    element: <Grants />,
  },
  {
    path: '*',
    element: <Navigate to="company-information" replace />,
  },
];

export default routes;
