import { Button, FaIcon, FaIcons } from '@in/component-library';
import React from 'react';

import '../../styles/OneWayInStyle.scss';
import { useTranslation } from 'react-i18next';

type Props = {
  title: string;
  description?: string;
  children: React.ReactNode;
  open?: boolean;
};

const RecommendServiceMailWrapper: React.FC<Props> = ({ title, description, children, open = false }) => {
  const { t: tCommon } = useTranslation();
  const [showEmail, setShowEmail] = React.useState<boolean>(open);
  return (
    <div className="display--flex">
      <div className="margin-left--2 margin-right--2">
        <FaIcon name={FaIcons.EnvelopeLight} />
      </div>
      <div className="flex-grow--2">
        <div>
          <b>{title}</b>
        </div>
        {description && <div>{description}</div>}
        <div>
          <Button variant={'link'} onClick={() => setShowEmail((prevValue) => !prevValue)}>
            {showEmail ? tCommon('showEmailToggle.hide') : tCommon('showEmailToggle.show')}
          </Button>
        </div>
        {showEmail && children}
      </div>
    </div>
  );
};

export default RecommendServiceMailWrapper;
