import { RoleDto, RoleCategory } from 'src/api/v2';

interface IRoles {
  Default: RoleDto;
  ClusterManager: RoleDto;
  ClusterAdvisor: RoleDto;
  ClusterPrimaryContact: RoleDto;
  ClusterAdmin: RoleDto;
  ClusterBoardLeader: RoleDto;
  ClusterBoardMember: RoleDto;
  ClusterBoardVaraMember: RoleDto;
  ClusterAdministrative: RoleDto;
  ClusterProjectManager: RoleDto;
}

export const Roles: IRoles = {
  Default: {
    id: '8c189b58-9cad-462b-b67f-4211c66062a2',
    name: 'Standard',
    code: 'DEFAULT',
    description: 'Default',
    roleCategory: RoleCategory.Regular,
    moduleAccesses: [],
  },
  ClusterAdvisor: {
    id: 'ce6d90c6-db01-44dd-b2ce-ab864d5d8d32',
    name: 'Klyngerådgiver',
    code: 'CLUSTER_ADVISOR',
    description: 'Cluster advisor',
    roleCategory: RoleCategory.Regular,
    moduleAccesses: [],
  },
  ClusterPrimaryContact: {
    id: '5ca341e4-39cf-446f-a5da-f79f018db3ee',
    name: 'Hovedkontakt',
    code: 'CLUSTER_PRIMARY_CONTACT',
    description: 'Cluster Primary Contact',
    roleCategory: RoleCategory.Regular,
    moduleAccesses: [],
  },
  ClusterAdmin: {
    id: '1e70bc7a-64dc-407b-9be8-d049763a208d',
    name: 'Klyngeadministrator',
    code: 'CLUSTER_ADMIN',
    description: 'Cluster Admin',
    roleCategory: RoleCategory.Management,
    moduleAccesses: [],
  },
  ClusterBoardLeader: {
    id: 'cff7f465-a488-4841-ac13-b23e3e45dfd0',
    name: 'Styreleder',
    code: 'CLUSTER_BOARD_LEADER',
    description: 'En del av styreledelsen/styret',
    roleCategory: RoleCategory.Board,
    moduleAccesses: [],
  },
  ClusterBoardMember: {
    id: '512541db-f108-44ab-8543-6694ad7f73e5',
    name: 'Styremedlem',
    code: 'CLUSTER_BOARD_MEMBER',
    description: 'En del av styreledelsen/styret',
    roleCategory: RoleCategory.Board,
    moduleAccesses: [],
  },
  ClusterBoardVaraMember: {
    id: '7e18b0a2-db72-4ac8-aaf0-2c06f8652a7a',
    name: 'Styrevaramedlem',
    code: 'CLUSTER_BOARD_VARA_MEMBER',
    description: 'En del av styreledelsen/styret',
    roleCategory: RoleCategory.Board,
    moduleAccesses: [],
  },
  ClusterManager: {
    id: '73e1d724-a5f1-47a3-ad43-5b9fdcad808d',
    name: 'Klyngeleder',
    code: 'CLUSTER_MANAGER',
    description: 'En del av klyngeledelsen',
    roleCategory: RoleCategory.Management,
    moduleAccesses: [],
  },
  ClusterAdministrative: {
    id: '56bc4e76-cf36-43b9-b609-876d3d77a0d3',
    name: 'Administrativ',
    code: 'CLUSTER_ADMINISTRATIVE',
    description: 'En del av klyngeledelsen',
    roleCategory: RoleCategory.Management,
    moduleAccesses: [],
  },
  ClusterProjectManager: {
    id: '16bdd921-3585-4148-8e12-22385aa487e0',
    name: 'Prosjektleder',
    code: 'CLUSTER_PROJECT_MANAGER',
    description: 'En del av klyngeledelsen',
    roleCategory: RoleCategory.Management,
    moduleAccesses: [],
  },
};

export const AllRoles: Array<RoleDto> = [
  Roles.ClusterAdmin,
  Roles.ClusterAdministrative,
  Roles.ClusterAdvisor,
  Roles.ClusterBoardLeader,
  Roles.ClusterBoardMember,
  Roles.ClusterBoardVaraMember,
  Roles.ClusterManager,
  Roles.ClusterPrimaryContact,
  Roles.ClusterProjectManager,
];

export const ClusterManagementRoles: Array<RoleDto> = [
  Roles.ClusterManager,
  Roles.ClusterAdministrative,
  Roles.ClusterProjectManager,
];

export const ClusterBoardRoles: Array<RoleDto> = [
  Roles.ClusterBoardLeader,
  Roles.ClusterBoardMember,
  Roles.ClusterBoardVaraMember,
];

export const ClusterAdvisorRoles: Array<RoleDto> = [Roles.ClusterAdvisor];
