import React from 'react';
import { Button, ButtonVariant, FaIcon } from '@in/component-library';

import { useBoolean } from 'usehooks-ts';
import { useTranslation } from 'react-i18next';
import { icon } from '@fortawesome/fontawesome-svg-core';
import { IconDefinition } from '@fortawesome/pro-regular-svg-icons';

import {
  DevelopmentProjectListItemDto,
  OperationProjectListItemDto,
  PortalProjectListItemDto,
} from 'src/api/v2';

type Props = {
  project: OperationProjectListItemDto | DevelopmentProjectListItemDto | PortalProjectListItemDto;

  createEventModal: ReturnType<typeof useBoolean>;
  registerInkindModal: ReturnType<typeof useBoolean>;
};

const ProjectCardButtons: React.FC<Props> = ({ project, createEventModal, registerInkindModal }) => {
  const { t: tEvents } = useTranslation('events');
  const { t: tSelfEffort } = useTranslation('selfEffort');

  if (project.projectEndReason) {
    return null;
  }

  return (
    <div className="display--flex flex-direction--column gap--2">
      <Button variant={ButtonVariant.Outlined} onClick={createEventModal.setTrue}>
        <FaIcon
          icon={icon({ prefix: 'fal', iconName: 'clipboard-list' }) as IconDefinition}
          size="sm"
          className={'margin-right--2'}
        />
        {tEvents('newEvent')}
      </Button>

      <Button variant={ButtonVariant.Outlined} onClick={registerInkindModal.setTrue}>
        <>
          <FaIcon
            icon={icon({ prefix: 'fal', iconName: 'sack-dollar' }) as IconDefinition}
            size="sm"
            className={'margin-right--2'}
          />
          {tSelfEffort('registerSelfEffort')}
        </>
      </Button>
    </div>
  );
};

export default ProjectCardButtons;
