import { useMemo, useState } from 'react';
import { UseQueryOptions, useMutation, useQuery, useQueryClient } from 'react-query';
import { ClusterUserCreateDto, ClusterUserDto, ClusterUserUpdateDto, ClusterUsersService } from 'src/api/v2';
import { CLUSTERS_KEY, CLUSTER_USERS_KEY, USER_KEY } from 'src/constants/hook-keys';
import { useAuth } from 'src/features/authorization';
import { useCluster } from 'src/features/cluster';
import useEnvironment from 'src/hooks/use-environment';

export type UseUsersOptions = Omit<
  UseQueryOptions<ClusterUserDto[], any, ClusterUserDto[], any>,
  'queryKey' | 'queryFn'
>;

export function useUsers(options?: UseUsersOptions) {
  const [service] = useState(new ClusterUsersService());

  const { cluster } = useCluster();
  const { checkAccess } = useAuth();

  const { read } = checkAccess('AccessControl');

  const queryClient = useQueryClient();

  const { data, ...rest } = useQuery({
    queryKey: [CLUSTER_USERS_KEY],
    queryFn: () => service.getClusterUsers(),
    ...options,
    enabled: !!cluster && read && options?.enabled !== false,
  });

  const createMutation = useMutation((record: ClusterUserCreateDto) => service.create(record), {
    onSuccess() {
      queryClient.invalidateQueries([CLUSTER_USERS_KEY]);
    },
  });

  const updateMutation = useMutation(
    ({ clusterUserId, record }: { clusterUserId: string; record: ClusterUserUpdateDto }) =>
      service.update(clusterUserId, record),
    {
      onSuccess() {
        queryClient.invalidateQueries([CLUSTER_USERS_KEY]);
      },
    },
  );

  const deleteMutation = useMutation((clusterUserId: string) => service.delete(clusterUserId), {
    onSuccess() {
      queryClient.invalidateQueries([CLUSTER_USERS_KEY]);
    },
  });

  const { isDev, isTest } = useEnvironment();

  const activationBaseUrl = useMemo(() => {
    if (isDev) {
      return 'https://dev.flerbedriftsportal.no/user-activation/';
    }

    if (isTest) {
      return 'https://test.flerbedriftsportal.no/user-activation/';
    }

    return 'https://flerbedriftsportal.no/user-activation/';
  }, [isDev, isTest]);

  const inviteMutation = useMutation(
    (clusterUserId: string) =>
      service.invite({
        activationBaseUrl,
        clusterUserIds: [clusterUserId],
      }),
    {
      onSuccess() {
        queryClient.invalidateQueries([CLUSTER_USERS_KEY]);
      },
    },
  );

  const acceptMutation = useMutation((invitationCode: string) => service.accept(invitationCode), {
    onSuccess() {
      queryClient.invalidateQueries([CLUSTER_USERS_KEY]);
      queryClient.invalidateQueries([CLUSTERS_KEY]);
      queryClient.invalidateQueries([USER_KEY]);
    },
  });

  return {
    users: data || [],

    ...rest,

    mutations: {
      create: createMutation,
      update: updateMutation,
      delete: deleteMutation,
      invite: inviteMutation,
      accept: acceptMutation,
    },
  };
}
