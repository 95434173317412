import React from 'react';
import { RouteObject } from 'react-router-dom';
import {
  FinalReportDraft,
  FinalReports,
  HalfYearReportDraft,
  HalfYearReports,
  AnnualReports,
  AnnualReportDraft,
  ReportArchive,
  ReportStatistics,
} from './cluster';
import { convertURLAndParamsToURL } from 'src/utils/route';

const Reports = React.lazy(() => import('./pages/ReportsPage'));

export const REPORTS_ROUTE_BASE = '/assignments/reports';

const REPORTS_ROUTE_RELATIVE_HALF_YEAR = 'half-year';
const REPORTS_ROUTE_RELATIVE_FINAL = 'final-report';
const REPORTS_ROUTE_RELATIVE_ANNUAL = 'annual';
const REPORTS_ROUTE_RELATIVE_ARCHIVE = 'archive';
const REPORTS_ROUTE_RELATIVE_STATISTICS = 'statistics';
export const REPORTS_ROUTE_RELATIVE_DRAFT = 'draft';

export function getReportDraftNavigationRoute(params: {
  reportId: string;
  draftType?: string;
  projectId?: string;
}) {
  return convertURLAndParamsToURL(REPORTS_ROUTE_RELATIVE_DRAFT, params);
}

export function getAnnualReportStatisticsNavigationRoute(params: { reportId: string }) {
  return convertURLAndParamsToURL(REPORTS_ROUTE_BASE_ANNUAL_STATISTICS, params);
}

export const REPORTS_ROUTE_BASE_HALF_YEAR = `${REPORTS_ROUTE_BASE}/${REPORTS_ROUTE_RELATIVE_HALF_YEAR}`;
export const REPORTS_ROUTE_BASE_FINAL = `${REPORTS_ROUTE_BASE}/${REPORTS_ROUTE_RELATIVE_FINAL}`;
export const REPORTS_ROUTE_BASE_ANNUAL = `${REPORTS_ROUTE_BASE}/${REPORTS_ROUTE_RELATIVE_ANNUAL}`;
export const REPORTS_ROUTE_BASE_ANNUAL_STATISTICS = `${REPORTS_ROUTE_BASE}/${REPORTS_ROUTE_RELATIVE_ANNUAL}/${REPORTS_ROUTE_RELATIVE_STATISTICS}`;
export const REPORTS_ROUTE_BASE_ARCHIVE = `${REPORTS_ROUTE_BASE}/${REPORTS_ROUTE_RELATIVE_ARCHIVE}`;

const routes: RouteObject[] = [
  {
    path: '',
    element: <Reports />,
    index: true,
  },
  {
    path: `${REPORTS_ROUTE_RELATIVE_HALF_YEAR}/*`,
    children: [
      {
        path: '',
        element: <HalfYearReports />,
        index: true,
      },
      {
        path: REPORTS_ROUTE_RELATIVE_DRAFT,
        element: <HalfYearReportDraft />,
      },
    ],
  },
  {
    path: `${REPORTS_ROUTE_RELATIVE_FINAL}/*`,
    children: [
      {
        path: '',
        element: <FinalReports />,
        index: true,
      },
      {
        path: REPORTS_ROUTE_RELATIVE_DRAFT,
        element: <FinalReportDraft />,
      },
    ],
  },
  {
    path: `${REPORTS_ROUTE_RELATIVE_ANNUAL}/*`,
    children: [
      {
        path: '',
        element: <AnnualReports />,
        index: true,
      },
      {
        path: REPORTS_ROUTE_RELATIVE_DRAFT,
        element: <AnnualReportDraft />,
      },
      {
        path: REPORTS_ROUTE_RELATIVE_STATISTICS,
        element: <ReportStatistics />,
      },
    ],
  },
  {
    path: REPORTS_ROUTE_RELATIVE_ARCHIVE,
    element: <ReportArchive />,
  },
];

export default routes;
