import React, { Suspense } from 'react';

import { FaIcons, PageLoader } from '@in/component-library';

import SideActionMenuFormChooser from './SideActionMenuFormChooser';

import { RegisterInkindFormWrapper } from 'src/features/inkind';
import { MemberEconomyFormModal } from 'src/features/members';

import {
  CreateContactForm,
  CreateMemberForm,
  ImportContactsForm,
  ImportClusterMemberForm,
} from 'src/features/resource-base';
import { EventFormWrapper } from 'src/features/projects';
import { ExcelImportClusterMemberSettings } from 'src/constants/excel-import';
import { ClusterMemberCategories } from 'src/constants/cluster-member-category';
import MeetingForm from 'src/features/events/components/Meetings/MeetingForm';
import { useTranslation } from 'react-i18next';

type Props = {
  cases: 'selfeffort' | 'contact' | 'member' | 'event' | 'deductible' | 'meeting' | undefined;
  case1: string;
  case2: string;
  case3: string;
  case4: string;
  case5: string;
  case6: string;
  toggle: () => void;
};

const FormDisplay: React.FC<Props> = ({ cases, case1, case2, case3, case4, case5, case6, toggle }) => {
  const { t: tCommon } = useTranslation();
  switch (cases) {
    case case1:
      return <RegisterInkindFormWrapper onClose={toggle} />;

    case case2:
      return (
        <SideActionMenuFormChooser
          forms={[
            {
              header: 'Legg til kontakt',
              buttonName: 'Legg til kontakt',
              form: <CreateContactForm onClose={toggle} />,
            },
            {
              header: 'Importer kontakter',
              buttonName: 'Importer kontakter',
              form: <ImportContactsForm onImport={toggle} onCancel={toggle} />,
              closeProps: ['onImport', 'onCancel'],
              icon: FaIcons.FileExcelLight,
            },
          ]}
        />
      );

    case case3:
      return (
        <SideActionMenuFormChooser
          forms={[
            {
              header: 'Legg til medlem',
              buttonName: 'Legg til medlem',
              form: <CreateMemberForm onClose={toggle} />,
            },
            {
              header: 'Importer medlemmer',
              buttonName: 'Importer medlemmer',
              form: (
                <ImportClusterMemberForm
                  onImport={toggle}
                  onCancel={toggle}
                  clusterMemberCategoryId={ClusterMemberCategories.Member.id}
                  excelTemplate={ExcelImportClusterMemberSettings}
                  translations={{
                    fileName: 'import.template.members',
                    createError: 'generic.create',
                    noneToImport: 'import.noneToImport',
                    submitButton: 'importMembers',
                  }}
                />
              ),
              closeProps: ['onImport', 'onCancel'],
              icon: FaIcons.FileExcelLight,
            },
          ]}
        />
      );
      break;
    case case4:
      return (
        <Suspense fallback={<PageLoader aria-label={tCommon('loading')} />}>
          <EventFormWrapper onClose={toggle} />
        </Suspense>
      );
    case case5:
      return <MemberEconomyFormModal onClose={toggle} />;
    case case6:
      return <MeetingForm onClose={toggle} />;
    default:
      return <></>;
  }
};

export default FormDisplay;
