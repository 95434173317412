import React, { FC, PropsWithChildren } from 'react';

import { Body } from '@in/component-library';
import PageBody from './PageBody';

export interface PageProps extends PropsWithChildren {
  noPadding?: boolean;
}

const Page: FC<PageProps> = ({ children, noPadding = false }: PageProps) => {
  return (
    <Body>
      <PageBody noPadding={noPadding}>{children}</PageBody>
    </Body>
  );
};

export default Page;
