import { useState } from 'react';
import { useQuery } from 'react-query';
import { DevelopmentProjectsService, OperationProjectsService, PortalProjectsService } from 'src/api/v2';
import { useCluster } from 'src/features/cluster';

import { PROJECT_STATISTICS_KEY } from 'src/constants/hook-keys';

export default function useProjectStatistics(
  projectId: string,
  projectType: 'development' | 'operation' | 'other' | undefined,
) {
  const { cluster } = useCluster();

  const [developmentProjectService] = useState(new DevelopmentProjectsService());
  const [operationProjectsService] = useState(new OperationProjectsService());
  const [portalProjectService] = useState(new PortalProjectsService());

  const { data, ...rest } = useQuery(
    [PROJECT_STATISTICS_KEY, projectId],
    () => {
      switch (projectType) {
        case 'development':
          return developmentProjectService.getProjectStatistics(projectId);
        case 'operation':
          return operationProjectsService.getProjectStatistics(projectId);
        case 'other':
          return portalProjectService.getProjectStatistics(projectId);
      }
    },
    {
      enabled: !!cluster && !!projectId,
    },
  );

  return {
    projectStatistics: data,
    ...rest,
  };
}
