export function convertURLAndParamsToURL(
  url: string,
  params: { [id: string]: string | number | undefined | null },
) {
  if (!url) return '';
  if (!params) return url;

  const keys = Object.keys(params);

  if (keys.length === 0) {
    return url;
  }

  const paramString = keys.map((key) => `${key}=${params[key]?.toString()}`);

  return `${url}?${paramString.join('&')}`;
}
