import { useMemo } from 'react';

import { OperationApplicationStatus } from 'src/api/v2';

import { ProjectPhaseTypes } from 'src/constants/project';
import useOperationApplications from './use-operation-applications';
import { SelectOption, SelectOptionGroup } from 'src/components/Form/Select';
import { useTranslation } from 'react-i18next';
import {
  useDevelopmentProjects,
  useOperationProjects,
  usePortalProjects,
  useProjects,
} from 'src/features/projects';

export default function useProjectSelectOptions() {
  const { operationApplications } = useOperationApplications();
  const { isLoading } = useProjects();
  const { developmentProjectsQuery } = useDevelopmentProjects();
  const { portalProjectsQuery } = usePortalProjects();
  const { operationProjectsQuery } = useOperationProjects();

  const developmentProjects = useMemo(
    () => developmentProjectsQuery.data || [],
    [developmentProjectsQuery.data],
  );
  const portalProjects = useMemo(() => portalProjectsQuery.data || [], [portalProjectsQuery.data]);

  const { t: tCommon } = useTranslation();
  const { t: tProjects } = useTranslation('projects');
  const { t: tPageTitles } = useTranslation('pageTitles');

  const projectSelectOptionsGrouped = useMemo(() => {
    const options: Array<SelectOption | SelectOptionGroup> = [];

    if (isLoading) {
      options.push({ value: '', text: `${tCommon('loadingData')}` });
      return options;
    } else {
      options.push({ value: '', text: '---' });
    }

    if (operationApplications.length > 0) {
      const years = new Set(
        operationApplications
          .filter((x) => x.status !== OperationApplicationStatus.Completed)
          .map((x) => x.operationalYear)
          .sort((a, b) => b - a),
      );
      years.forEach((year) => {
        const application = operationApplications.find((x) => x.operationalYear === year);

        const applicationProjects = operationProjectsQuery.data?.filter(
          (x) => x.operationApplicationId === application?.id,
        );

        options.push({
          label: `${tCommon('clusterOperation')} ${year}`,
          options:
            applicationProjects
              ?.filter(
                (x) =>
                  x.projectPhase?.code !== ProjectPhaseTypes.terminated.code &&
                  x.projectPhase?.code !== ProjectPhaseTypes.completed.code,
              )
              .map((x) => ({ text: x.name, value: x.id })) ?? [],
        });
      });
    }

    options.push({
      label: tProjects('developmentProjects'),
      options: developmentProjects.map((x) => ({ text: x.name, value: x.id })),
    });

    options.push({
      label: tPageTitles('ownProjects'),
      options: portalProjects.map((x) => ({ text: x.name, value: x.id })),
    });

    return options;
  }, [
    isLoading,
    operationApplications,
    tProjects,
    developmentProjects,
    tPageTitles,
    portalProjects,
    tCommon,
    operationProjectsQuery.data,
  ]);

  return {
    projectSelectOptionsGrouped,
  };
}
