import { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { TimeEntryService, TimeEntryUpsertDto } from 'src/api/v2';

import {
  TIME_ENTRIES_KEY,
  OPERATION_TIME_ENTRIES_KEY,
  EVENT_DETAILS_KEY,
  OPERATION_TIME_ENTRIES_BY_ID_KEY,
} from 'src/constants/hook-keys';
import { useAuth } from 'src/features/authorization';

import useCluster from '../features/cluster/hooks/use-cluster';
import { useTranslation } from 'react-i18next';
import useActiveOperationApplication from './use-active-operation-application';

export type UseTimeEntriesProps = {
  enabled: boolean;
  operationId?: string | null | undefined;

  clusterMemberCategoryId?: string | null;
};

export default function useTimeEntries(
  { enabled, operationId, clusterMemberCategoryId }: UseTimeEntriesProps = {
    enabled: true,
    clusterMemberCategoryId: null,
  },
) {
  const { cluster } = useCluster();

  const { operationApplication } = useActiveOperationApplication();

  const [service] = useState(new TimeEntryService());

  const queryClient = useQueryClient();

  const { checkAccess } = useAuth();
  const { read, write } = checkAccess('Inkind');
  const { t: tCommon } = useTranslation('common');

  const { data, ...rest } = useQuery(
    [TIME_ENTRIES_KEY],
    () =>
      service.getTimeEntries(
        cluster!.id,
        null,
        null,
        null,
        null,
        null,
        null,
        clusterMemberCategoryId || null,
      ),
    {
      enabled: !!cluster && enabled && read,
    },
  );

  const operationTimeEntries = useQuery(
    [OPERATION_TIME_ENTRIES_KEY],
    () => service.getTimeEntries(cluster!.id, null, null, null, null, null, operationApplication!.id),
    { enabled: !!cluster && !!operationApplication && enabled && read },
  );

  const operationTimeEntriesById = useQuery(
    [OPERATION_TIME_ENTRIES_BY_ID_KEY, operationId],
    () => service.getTimeEntries(cluster!.id, null, null, null, null, null, operationId!),
    { enabled: !!cluster && !!operationId && enabled && read },
  );

  const createTimeEntryMutation = useMutation(
    async (record: TimeEntryUpsertDto) => {
      if (!write) {
        throw new Error(tCommon('cannotWrite'));
      }

      return await service.createTimeEntry(record);
    },
    {
      onSuccess() {
        queryClient.invalidateQueries([TIME_ENTRIES_KEY]);
        queryClient.invalidateQueries([EVENT_DETAILS_KEY]);
      },
    },
  );

  const createTimeEntryListMutation = useMutation(
    async (records: TimeEntryUpsertDto[]) => {
      if (!write) {
        throw new Error(tCommon('cannotWrite'));
      }

      return await service.createTimeEntries(records);
    },
    {
      onSuccess() {
        queryClient.invalidateQueries([TIME_ENTRIES_KEY]);
        queryClient.invalidateQueries([EVENT_DETAILS_KEY]);
      },
    },
  );

  const deleteTimeEntryMutation = useMutation(
    async (timeEntryIds: string[]) => {
      if (!write) {
        throw new Error(tCommon('cannotWrite'));
      }

      return await service.deleteTimeEntry(timeEntryIds);
    },
    {
      onSuccess() {
        queryClient.invalidateQueries([TIME_ENTRIES_KEY]);
        queryClient.invalidateQueries([EVENT_DETAILS_KEY]);
      },
    },
  );

  return {
    timeEntries: data || [],
    operationTimeEntries: operationTimeEntries.data || [],
    operationTimeEntriesById: operationTimeEntriesById.data || [],

    ...rest,

    createTimeEntryMutation,
    createTimeEntryListMutation,
    deleteTimeEntryMutation,
  };
}
