import { useMemo } from 'react';

import { OneWayInOpportunitySimpleV2Dto } from '../../../../api/v2';
import { OneWayInListDto } from '../../types/oneWayInList';

type OpportunityList = {
  formList: OneWayInOpportunitySimpleV2Dto[];
};

const useOpportunityList = ({ formList }: OpportunityList) => {
  const { opportunityList } = useMemo(() => {
    if (!formList) {
      return {
        opportunityList: [] as OneWayInListDto[],
      };
    }

    const list = formList.map((opportunity) => ({
      ...opportunity,
    }));

    return {
      opportunityList: list,
    };
  }, [formList]);

  return { opportunityList };
};

export default useOpportunityList;
