import { useMemo } from 'react';
import { OneWayInServiceProviderDto } from 'src/api/v2';

const useCounties = (actors: OneWayInServiceProviderDto[]) => {
  const counties = useMemo(() => {
    const countyArray: Array<string> = [];
    return actors
      .reduce((accumulator, provider) => {
        if (!provider.county) return accumulator;
        if (!accumulator.includes(provider.county)) {
          accumulator.push(provider.county);
        }
        return accumulator;
      }, countyArray)
      .map((county) => {
        return {
          text: county,
          value: county,
        };
      });
  }, [actors]);

  return { counties };
};

export default useCounties;
