import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { icon } from '@fortawesome/fontawesome-svg-core';
import { IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import './SideNavigationMenu.scss';
import { FaIcon, FaIcons } from '@in/component-library';
import { useTranslation } from 'react-i18next';

type SideNavigationMenuProps = {
  backButtonPath: string;
  routerLinks: RouterLinks[];
  className?: string;
};

export type RouterLinks = {
  id: number;
  isVisible: boolean;
  to: string;
  icon: { prefix: IconDefinition['prefix']; iconName: string };
  textContent: string;
  exact?: boolean;
};

const SideNavigationMenu: React.FC<SideNavigationMenuProps> = ({
  backButtonPath,
  routerLinks,
  className,
}) => {
  const { t: tCommon } = useTranslation();

  return (
    <aside className={className}>
      <div className="side-navigation-menu">
        <nav className="side-navigation-menu__buttons">
          <Link to={backButtonPath} className="margin-top--2 margin-bottom--12">
            <div>
              <FaIcon name={FaIcons.ArrowLeft} className="margin-right--1" /> <u>{tCommon('back')}</u>
            </div>
          </Link>

          {routerLinks.map((routerLink) => {
            if (routerLink.isVisible) {
              return (
                <NavLink
                  key={routerLink.id}
                  to={routerLink.to}
                  end={routerLink.exact}
                  className="margin-top--1"
                >
                  <FaIcon
                    className="display--block"
                    size="1x"
                    icon={icon(routerLink.icon as IconDefinition)}
                  />
                  <span className="margin-top--1">{routerLink.textContent}</span>
                </NavLink>
              );
            }
          })}
        </nav>
      </div>
    </aside>
  );
};

export default SideNavigationMenu;
