import React from 'react';
import DocumentTitle from '../DocumentTitle/DocumentTitle';
import { Button } from '@in/component-library';
import useDocumentTitle from 'src/hooks/use-document-title';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const NotFoundPage: React.FC = () => {
  const { t: tCommon } = useTranslation();
  const { t: tError } = useTranslation('error');

  const title = tError('notFound.title');
  const subtitle1 = tError('notFound.subtitle1');
  const subtitle2 = tError('notFound.subtitle2');

  const { documentTitle } = useDocumentTitle(title);

  const navigate = useNavigate();

  return (
    <DocumentTitle title={documentTitle}>
      <div className="display--flex flex-direction--column gap--4 align-items--center justify-content--flex-start text-align--center max-width--1128 margin-x--auto">
        <h1 className="margin-bottom--8 margin-top--8">{title}</h1>

        <div>
          <h2>{subtitle1}</h2>
          <h3>{subtitle2}</h3>
        </div>

        <div className="display--flex align-items--center justify-content--center gap--4">
          <Button
            type="button"
            variant="solid"
            onClick={() => {
              navigate('/');
            }}
          >
            {tCommon('goToMainPage')}
          </Button>
          <Button
            type="button"
            variant="outlined"
            onClick={() => {
              navigate('/choose-cluster');
            }}
          >
            {tCommon('chooseActor')}
          </Button>
        </div>
      </div>
    </DocumentTitle>
  );
};

export default NotFoundPage;
