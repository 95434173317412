import { useState } from 'react';
import { useQuery } from 'react-query';
import { EventsVService } from 'src/api/v2';
import { useCluster } from 'src/features/cluster';

import { EVENT_DETAILS_KEY } from 'src/constants/hook-keys';

export default function useEventDetails(eventId: string) {
  const { cluster } = useCluster();

  const [service] = useState(new EventsVService());

  const { data, ...rest } = useQuery([EVENT_DETAILS_KEY, eventId], () => service.getEventDetails(eventId), {
    enabled: !!cluster && !!eventId,
  });

  return {
    eventDetails: data,
    ...rest,
  };
}
