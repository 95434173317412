import React, { useId } from 'react';

type Props = {
  label: React.ReactNode;
  value: React.ReactNode;

  id?: string;
};

const ValueField: React.FC<Props> = ({ label, value, id }) => {
  const internalId = useId();
  const resolvedId = id ?? internalId;

  return (
    <div className="display--flex flex-direction--column margin-bottom--2 font-family--in">
      <label className="font-size--x-large font-weight--semi-bold margin-bottom--1" htmlFor={resolvedId}>
        {label}
      </label>
      <span className="font-size--20px" id={resolvedId}>
        {value}
      </span>
    </div>
  );
};

export default ValueField;
