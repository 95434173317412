import { lazy } from 'react';

export { default as useOperationProjects } from './hooks/use-operation-projects';
export { default as useDevelopmentProjects } from './hooks/use-development-projects';
export { default as usePortalProjects } from './hooks/use-portal-projects';

export { default as useProjects } from './hooks/use-projects';

export const ProjectsFeature = lazy(() => import('./pages/ProjectsFeature'));
export const ProjectDetailAbout = lazy(() => import('./details/pages/ProjectDetailAbout'));
export const ProjectDescription = lazy(() => import('./details/pages/ProjectDescription'));
export const ProjectDetailEvents = lazy(() => import('./details/pages/ProjectDetailEvents'));

export const ProjectSelfEffortV2 = lazy(() => import('./details/pages/selfeffort/ProjectSelfEffortV2'));

export const ProjectDetailEconomy = lazy(() => import('./details/pages/economy/ProjectDetailEconomy'));

export const ProjectDetailLeads = lazy(() => import('./details/pages/participants/ProjectDetailLeads'));
export const ProjectDetailMembers = lazy(() => import('./details/pages/participants/ProjectDetailMembers'));

export const EventFormWrapper = lazy(() => import('./details/components/EventFormWrapper'));

export const ProjectListWrapper = lazy(() => import('./components/ProjectListWrapper/ProjectListWrapper'));

export { default as routes } from './routes';
