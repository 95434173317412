import { ClusterMemberDetailsDto } from 'src/api/v2';

export const defaultClusterMemberDetails: ClusterMemberDetailsDto = {
  numberOfEmployees: undefined,
  numberOfEmployeesLastYear: undefined,
  isFetchedFromBrReg: undefined,
  nace: undefined,
  companyTypeCode: undefined,
  companyTypeDescription: undefined,
  industry: undefined,
  bankrupt: undefined,
  liquidation: undefined,
  forcedLiquidation: undefined,
  registryEnhetsRegisteretDate: undefined,
  registryMvaRegisteret: undefined,
  registryForetaksRegisteret: undefined,
  registryStiftelsesRegisteret: undefined,
  registryFrivillighetsRegisteret: undefined,
  femaleFounder: undefined,
  femaleCEO: undefined,
  femaleChairman: undefined,
  grossTurnover: undefined, // omsetning
  staffCosts: undefined, // lønnskostnader
  resultBeforeAllocations: undefined, // driftsresultat
  assetsTotal: undefined, // balanse
};
