import React, { useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button, ButtonVariant, Theme, DateInput, InputField } from '@in/component-library';

import { toastPromise } from 'src/utils/toast';
import { requiredI18n } from 'src/utils/validation';
import { formatDateInputDate } from 'src/utils/FormatValue';

import { Select } from 'src/components/Form/Select';

import { PortalProjectCreateDto } from 'src/api/v2';

import { ProjectCategoryTypes } from 'src/constants/project';

import useProjectCategories from 'src/hooks/use-project-categories';
import useProjectPropertyTypes from 'src/hooks/use-project-property-types';
import usePortalProjects from '../hooks/use-portal-projects';

type Props = {
  onClose: () => void;
};

export const AddProjectForm: React.FC<Props> = ({ onClose }) => {
  const { t: tCommon } = useTranslation();
  const { t: tError } = useTranslation('error');
  const { t: tProjects } = useTranslation('projects');

  const { createProjectMutation } = usePortalProjects();
  const { createMutation: createProjectCategoryMutation } = useProjectCategories();
  const { projectPropertyTypes } = useProjectPropertyTypes();

  const categories = useMemo(() => {
    if (projectPropertyTypes?.categories) {
      return projectPropertyTypes.categories.map((x) => ({ value: x.id, text: x.name }));
    }

    return [];
  }, [projectPropertyTypes]);

  const customCategories = useMemo(() => {
    if (projectPropertyTypes?.customCategories) {
      return projectPropertyTypes.customCategories.map((x) => ({ value: x.id, text: x.name }));
    }

    return [];
  }, [projectPropertyTypes]);

  const [showCustomTypeField, setShowCustomTypeField] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
  } = useForm<PortalProjectCreateDto & { customProjectCategoryName?: string }>({
    defaultValues: {
      description: '',
      goalDescription: '',
      focusAreaIds: [],
      startDate: undefined,
      endDate: undefined,
      socialEnvironmentEffectIds: [],
    },
  });

  const values = getValues();

  const onSubmit = handleSubmit((data) => {
    let customCategory = false;
    const customProjectCategoryName = data.customProjectCategoryName;

    if (data.projectCategoryId === ProjectCategoryTypes.custom) {
      customCategory = true;
      if (!customProjectCategoryName) {
        return;
      }
    } else if (customCategories.some((x) => x.value === data.projectCategoryId)) {
      customCategory = true;
    } else {
      customCategory = false;
    }

    if (!data.startDate || !data.endDate) {
      return;
    }

    if (customCategory && customProjectCategoryName) {
      createProjectCategoryMutation
        .mutateAsync({ name: customProjectCategoryName })
        .then((result) => {
          postNewProject(data, result.name);
        })
        .catch((error) => {
          if (error.status === 409) {
            // Error is that custom project category with that name already exists.
            toast.error(tProjects('projectCategoryAlreadyExists'));
          } else {
            toast.error(tProjects('couldNotCreateCategory'));
          }
        });
    } else {
      postNewProject(data);
    }
  });

  const postNewProject = (data: PortalProjectCreateDto, customProjectCategoryId: string = '') => {
    const promise = createProjectMutation.mutateAsync({
      ...data,
      socialEnvironmentEffectIds: [],
      projectCategoryId:
        customProjectCategoryId.length > 0 ? customProjectCategoryId : data.projectCategoryId,
    });

    toastPromise(promise, {
      error: tProjects('couldNotCreateProject'),
    }).then(() => {
      onClose();
    });
  };

  const cancel = () => {
    onClose();
  };

  return (
    <form onSubmit={onSubmit}>
      <InputField
        label={tCommon('name')}
        className="margin-bottom--0"
        {...register('name', {
          required: requiredI18n(tError),
        })}
        errorMsg={errors?.name?.message}
      />

      <div className="display--flex justify-content--center gap--4 date-input-container">
        <DateInput
          name={
            register('startDate', {
              required: requiredI18n(tError),
            }).name
          }
          label={tCommon('startDate')}
          onChange={(event) => {
            const { value } = event.target;
            const StartDate = value ? new Date(value) : new Date(0);
            setValue('startDate', StartDate, { shouldValidate: true, shouldDirty: true });
          }}
          value={values.startDate ? formatDateInputDate(values.startDate) : undefined}
          errorMsg={errors.startDate?.message}
        />
        <DateInput
          name={
            register('endDate', {
              required: requiredI18n(tError),
            }).name
          }
          label={`${tCommon('endDate')}`}
          onChange={(event) => {
            const { value } = event.target;
            const EndDate = value ? new Date(value) : undefined;
            if (EndDate) {
              setValue('endDate', EndDate, { shouldValidate: true, shouldDirty: true });
            }
          }}
          value={values.endDate ? formatDateInputDate(values.endDate) : undefined}
          errorMsg={errors.endDate?.message}
        />
      </div>

      <Select
        label={tCommon('category')}
        {...register('projectCategoryId', {
          required: requiredI18n(tError),
          validate: {
            assertNotEmpty: (value) => (value !== null && value !== '' ? true : requiredI18n(tError).message),
          },
          onChange: (event) => {
            setValue('projectCategoryId', event.target.value);
            if (event.target.value === ProjectCategoryTypes.custom) {
              setShowCustomTypeField(true);
            } else if (showCustomTypeField) {
              setShowCustomTypeField(false);
            }
          },
        })}
        error={errors?.projectCategoryId?.message}
        options={[
          {
            value: '',
            text: `${tProjects('selectProjectCategory')}`,
          },
          {
            label: `${tProjects('predefinedCategories')}`,
            options: categories,
          },
          {
            label: `${tProjects('customCategories')}`,
            options: customCategories,
          },
        ]}
      />

      {showCustomTypeField && (
        <InputField
          label={tProjects('customProjectCategoryName')}
          {...register('customProjectCategoryName', {})}
          errorMsg={errors?.customProjectCategoryName?.message}
        />
      )}

      <div className="display--flex gap--2 justify-content--flex-start padding-top--3">
        <Button
          theme={Theme.Neutral}
          type="submit"
          isLoading={createProjectMutation.isLoading || createProjectCategoryMutation.isLoading}
        >
          {tCommon('create')}
        </Button>
        <Button
          theme={Theme.Neutral}
          variant={ButtonVariant.Outlined}
          type="button"
          onClick={cancel}
          disabled={createProjectMutation.isLoading || createProjectCategoryMutation.isLoading}
        >
          {tCommon('cancel')}
        </Button>
      </div>
    </form>
  );
};
