import { Button, FaIcon, FaIcons, PageLoader } from '@in/component-library';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Page from 'src/components/Page/Page';
import SivaContractOverviewDataTable from '../components/SivaContractOverviewDataTable';
import { useSivaContracts } from '../hooks/use-siva-contracts';
import { ROUTES } from 'src/navigation';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'src/features/authorization';
import { useSivaOverviewTabs } from '../hooks/use-siva-overview-tabs';

const SivaContractsIndexPage: React.FC = () => {
  const { t: tCommon } = useTranslation();
  const { t: tSiva } = useTranslation('siva');
  const { t: tPageTitles } = useTranslation('pageTitles');

  const navigate = useNavigate();

  const { checkAccess } = useAuth();
  const { read: isBusinessGarden } = checkAccess('UI_SivaBusinessGarden');
  const title = isBusinessGarden ? tPageTitles('targetedCompanies') : tSiva('ideasAndCompanies.title');

  const { contracts, isLoading } = useSivaContracts();

  const { availablePhasesForTab, tabElements, columnsForTab } = useSivaOverviewTabs(isBusinessGarden);

  if (isLoading) {
    return (
      <Page>
        <h1 className="margin-bottom--6">{title}</h1>

        <PageLoader aria-label="Laster inn data" />
      </Page>
    );
  }

  return (
    <Page>
      <h1 className="margin-bottom--6">{title}</h1>

      {contracts.length === 0 && (
        <div className="display--flex flex-direction--column align-items--flex-start gap--3 margin-bottom--6">
          <div className="display--flex align-items--center gap--2">
            <FaIcon className="font-size--40px" name={FaIcons.SparklesLight} />
            <FaIcon className="font-size--40px" name={FaIcons.BuildingLight} />
          </div>
          <h2 className="margin-y--0">{tSiva('ideasAndCompanies.notFound')}</h2>
        </div>
      )}

      {checkAccess('SivaCore').write && (
        <Button
          type="button"
          iconName={FaIcons.PlusLight}
          onClick={() => {
            navigate(ROUTES.SIVA.IDEAS_AND_COMPANIES.NEW);
          }}
        >
          {tCommon('addNew')}
        </Button>
      )}

      {contracts.length > 0 && (
        <>
          {tabElements}
          <SivaContractOverviewDataTable availablePhases={availablePhasesForTab} columns={columnsForTab} />
        </>
      )}
    </Page>
  );
};

export default SivaContractsIndexPage;
