import { useState } from 'react';
import { useQuery } from 'react-query';
import { ExternalService } from 'src/api/v2';
import { EXTERNAL_ACCOUNTING_DATA_KEY } from 'src/constants/hook-keys';

export function useExternalAccountingData(organizationNumber: string) {
  const [service] = useState(() => new ExternalService());

  return useQuery({
    queryKey: [EXTERNAL_ACCOUNTING_DATA_KEY, organizationNumber],
    queryFn: async () => await service.getAccountingData(organizationNumber),
    enabled: !!organizationNumber && organizationNumber.length === 9,
  });
}
