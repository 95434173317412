import React from 'react';
import { Helmet } from 'react-helmet-async';

export type DocumentTitleProps = {
  title?: string;
  children?: React.ReactNode;
};

const DocumentTitle: React.FC<DocumentTitleProps> = ({ title, children }) => {
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {children}
    </>
  );
};

export default DocumentTitle;
