import { createColumnHelper } from '@tanstack/react-table';
import React from 'react';
import { Link } from 'react-router-dom';
import { SivaGoalsDetailsDto } from 'src/api/v2';
import { ROUTES } from 'src/navigation';
import SivaGoalTag from '../components/SivaGoalTag';

const columnHelper = createColumnHelper<SivaGoalsDetailsDto>();

export const sivaGoalsDetailsColumns = [
  columnHelper.accessor((row) => row.clusterStatusName, {
    header: 'Type',
    cell: (info) => {
      return info.getValue();
    },
    size: 100,
  }),
  columnHelper.accessor((row) => row.clusterName, {
    header: 'Operatørnavn',
    cell: (info) => {
      return info.getValue();
    },
  }),
  columnHelper.accessor((row) => row.goalsStatus, {
    header: 'Status',
    cell: (info) => {
      return <SivaGoalTag status={info.getValue()} />;
    },
    size: 100,
  }),
  columnHelper.accessor((row) => row.updatedAt, {
    header: 'Sist oppdatert',
    cell: (info) => {
      const date = new Date(info.getValue());

      return date.toLocaleDateString();
    },
  }),
  columnHelper.display({
    header: 'Se Måltall',
    cell: ({ row: { original } }) => {
      if (original.goalAccomplishments.length === 0) {
        return null;
      }

      return (
        <Link to={ROUTES.SIVA.ADMIN.GOALS.YEAR(original.year).DETAILS(original.clusterId).BASE}>
          Se måltall
        </Link>
      );
    },
  }),
];
