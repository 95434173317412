import React from 'react';
import { Navigate, RouteObject } from 'react-router-dom';

const ClusterOperationOverview = React.lazy(
  () => import('src/features/cluster-operation/pages/ClusterOperationOverview'),
);
const ClusterOperationProjects = React.lazy(
  () => import('src/features/cluster-operation/pages/ClusterOperationProjects'),
);
const ClusterOperationProjectsEconomy = React.lazy(
  () => import('src/features/cluster-operation/pages/ClusterOperationProjectsEconomy'),
);
const ClusterOperationSelfEffort = React.lazy(
  () => import('src/features/cluster-operation/pages/ClusterOperationSelfEffort'),
);
const ClusterOperationProject = React.lazy(
  () => import('src/features/cluster-operation/pages/Project/ClusterOperationProject'),
);
const ClusterOperationProjectAbout = React.lazy(
  () => import('src/features/cluster-operation/pages/Project/ClusterOperationProjectAbout'),
);
const ClusterOperationProjectEconomy = React.lazy(
  () => import('src/features/cluster-operation/pages/Project/ClusterOperationProjectEconomy'),
);
const ClusterOperationProjectEvents = React.lazy(
  () => import('src/features/cluster-operation/pages/Project/ClusterOperationProjectEvents'),
);
const ClusterOperationProjectLeads = React.lazy(
  () => import('src/features/cluster-operation/pages/Project/ClusterOperationProjectLeads'),
);
const ClusterOperationProjectMembers = React.lazy(
  () => import('src/features/cluster-operation/pages/Project/ClusterOperationProjectMembers'),
);
const ClusterOperationProjectOverview = React.lazy(
  () => import('src/features/cluster-operation/pages/Project/ClusterOperationProjectOverview'),
);
const ClusterOperationProjectSelfEffort = React.lazy(
  () => import('src/features/cluster-operation/pages/Project/ClusterOperationProjectSelfEffort'),
);

const projectChildren: RouteObject[] = [
  {
    index: true,
    element: <Navigate to={`overview`} replace />,
    path: '',
  },
  {
    element: <ClusterOperationProjectOverview />,
    path: 'overview',
  },
  {
    element: <ClusterOperationProjectEvents />,
    path: 'events',
  },
  {
    element: <ClusterOperationProjectMembers />,
    path: 'members',
  },
  {
    element: <ClusterOperationProjectLeads />,
    path: 'leads',
  },
  {
    element: <ClusterOperationProjectEconomy />,
    path: 'economy',
  },
  {
    element: <Navigate to={'../inkind'} replace />,
    path: 'self-effort',
  },
  {
    element: <ClusterOperationProjectSelfEffort />,
    path: 'inkind',
  },
  {
    element: <ClusterOperationProjectAbout />,
    path: 'about',
  },
];

const routes: RouteObject[] = [
  {
    path: '',
    index: true,
    element: <Navigate to="projects" replace />,
  },
  {
    path: 'projects',
    children: [
      {
        path: '',
        index: true,
        element: <ClusterOperationProjects />,
      },
      {
        element: <ClusterOperationProject />,
        path: ':projectId/*',
        children: projectChildren,
      },
    ],
  },
  {
    element: <ClusterOperationProjectsEconomy />,
    path: 'economy',
  },
  {
    element: <Navigate to="/cluster-operation/inkind" replace />,
    path: 'selfeffort',
  },
  {
    element: <ClusterOperationSelfEffort />,
    path: 'inkind',
  },
  {
    element: <ClusterOperationOverview />,
    path: 'statistics',
  },
];

export default routes;
