import { Notification } from '@in/component-library';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ClusterMemberCreateDto } from 'src/api/v2';

type Props = {
  items: ClusterMemberCreateDto[];
};

const ImportMembersSuccessMessage: React.FC<Props> = ({ items }) => {
  const { t: tResourceBase } = useTranslation('resourceBase');

  if (items.length === 0) {
    return null;
  }

  return (
    <Notification type="success" fullWidth className="margin-bottom--3">
      <p>
        {items.length} {items.length > 1 ? tResourceBase('members') : tResourceBase('member')}{' '}
        {tResourceBase('readyForImport')}.
      </p>
    </Notification>
  );
};

export default ImportMembersSuccessMessage;
