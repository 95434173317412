import React, { ReactNode } from 'react';

type Props = {
  icon: ReactNode;
  iconName: string;
  description: string;
  showOptionalIndication?: boolean;
};

const ActionPageDescription: React.FC<Props> = ({ icon, iconName, description, showOptionalIndication }) => {
  return (
    <div className="display--flex flex-direction--column align-items--center">
      <div className="icon-bubble">{icon}</div>
      <h4 className="font-weight--bold margin-top--2 margin-bottom--1">{iconName}</h4>
      <p className="text-align--left margin-top--1 margin-bottom--0">{description}</p>
      {showOptionalIndication && <p className="margin-bottom--0 margin-top--1">*Valgfritt</p>}
    </div>
  );
};

export default ActionPageDescription;
