import React from 'react';
import { useCluster } from 'src/features/cluster';
import useStatusPrograms from 'src/hooks/use-status-programs';
import { ProgramRendererType } from 'src/types/ProgramRendererType';

type ProgramRendererProgam = {
  program: ProgramRendererType | ProgramRendererType[];
  render?: never;
};

type ProgramRendererRender = {
  program?: never;
  render: React.PropsWithChildren<{
    program: ProgramRendererType;
  }>[];
};

export type ProgramRendererProps = React.PropsWithChildren<
  {
    loader?: React.ReactNode;
  } & (ProgramRendererProgam | ProgramRendererRender)
>;

const ProgramRenderer: React.FC<ProgramRendererProps> = ({ children, program, render, loader }) => {
  const { cluster, isLoading } = useCluster();
  const { clusterStatusRulesMap } = useStatusPrograms();

  if (render && Array.isArray(render)) {
    const currentProgram = render.find((p) =>
      clusterStatusRulesMap[p.program].includes(cluster?.clusterStatusId || ''),
    );

    if (currentProgram) {
      return <>{currentProgram.children}</>;
    }

    const wildCard = render.find((p) => p.program === '*');
    if (wildCard) {
      return <>{wildCard.children}</>;
    }

    return <></>;
  }

  if (!program) {
    return <>{children}</>;
  }

  const clusterStatusIds = Array.isArray(program)
    ? program.map((p) => clusterStatusRulesMap[p]).flat()
    : clusterStatusRulesMap[program];

  if (isLoading) {
    if (loader) {
      return <>{loader}</>;
    }

    return <></>;
  }

  if (cluster?.clusterStatusId && clusterStatusIds.includes(cluster?.clusterStatusId)) {
    return <>{children}</>;
  }

  if (Array.isArray(program) ? program.some((p) => p === '*') : program === '*') {
    return <>{children}</>;
  }

  return <></>;
};

export default ProgramRenderer;
