import accountProfile from 'src/i18n/locales/en/accountProfile.json';
import buttons from 'src/i18n/locales/en/buttons.json';
import cluster from 'src/i18n/locales/en/cluster.json';
import clusterOperation from 'src/i18n/locales/en/clusterOperation.json';
import common from 'src/i18n/locales/en/common.json';
import error from 'src/i18n/locales/en/error.json';
import events from 'src/i18n/locales/en/events.json';
import oneWayIn from 'src/i18n/locales/en/oneWayIn.json';
import pageTitles from 'src/i18n/locales/en/pageTitles.json';
import projects from 'src/i18n/locales/en/projects.json';
import reports from 'src/i18n/locales/en/reports.json';
import resourceBase from 'src/i18n/locales/en/resourceBase.json';
import selfEffort from 'src/i18n/locales/en/selfEffort.json';
import statistics from 'src/i18n/locales/en/statistics.json';
import accountSettings from 'src/i18n/locales/en/accountSettings.json';
import tableTitles from 'src/i18n/locales/en/tableTitles.json';
import sidenav from 'src/i18n/locales/en/sidenav.json';
import projectCategories from 'src/i18n/locales/en/projectCategories.json';
import projectTypes from 'src/i18n/locales/en/projectTypes.json';
import financing from 'src/i18n/locales/en/financing.json';
import changelog from 'src/i18n/locales/en/changelog.json';
import siva from 'src/i18n/locales/en/siva.json';
import clusterStatus from 'src/i18n/locales/en/clusterStatus.json';
import onboarding from 'src/i18n/locales/en/onboarding.json';

const en = {
  accountProfile,
  buttons,
  cluster,
  clusterOperation,
  common,
  error,
  events,
  oneWayIn,
  pageTitles,
  projectCategories,
  projects,
  projectTypes,
  reports,
  resourceBase,
  selfEffort,
  statistics,
  accountSettings,
  tableTitles,
  sidenav,
  financing,
  changelog,
  siva,
  clusterStatus,
  onboarding,
};

export default en;
