import { useState } from 'react';
import { useQuery } from 'react-query';
import { EventsVService } from 'src/api/v2';
import useCluster from '../features/cluster/hooks/use-cluster';

import { EVENTS_KEY } from 'src/constants/hook-keys';

export default function useEvents(
  projectId?: string,
  isPublic?: boolean | null,
  isContinous?: boolean | null,
) {
  const { cluster } = useCluster();

  const [service] = useState(new EventsVService());

  const { data, ...rest } = useQuery(
    [EVENTS_KEY, projectId],
    () => service.getEvents(cluster!.id, isPublic, isContinous, projectId),
    {
      enabled: !!cluster,
    },
  );

  return {
    events: data || [],
    ...rest,
  };
}
