import React, { ReactNode } from 'react';
import { FaIcon, FaIcons } from '@in/component-library';
import css from './SideMenuNavButton.module.scss';

type Props = {
  onClick: () => void;
  children: ReactNode;
};

const SideMenuNavButton = ({ onClick, children }: Props) => {
  return (
    <button onClick={onClick} className={css['sidemenu-nav__button']}>
      <span className={css['sidemenu-nav__content']}>{children}</span>
      <FaIcon name={FaIcons.ArrowRight} className={css['sidemenu-nav__icon']} />
    </button>
  );
};

export default SideMenuNavButton;
