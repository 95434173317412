import React from 'react';
import { Tag } from '@in/component-library';
import { OneWayInStatus } from 'src/api/v2';

import { translateStatusTag } from '../helpers/statusTags';

type Props = {
  status: OneWayInStatus | undefined;
  className?: string;
};
const OneWayInOpportunityStatusTag: React.FC<Props> = ({ status, className }) => {
  const determineTagType = () => {
    let tagType = 'success';

    if (!status || status === OneWayInStatus.Received || status === OneWayInStatus.New) {
      tagType = 'info';
    }

    if (status === OneWayInStatus.Closed || status === OneWayInStatus.Forwarded) {
      tagType = 'generic';
    }

    if (status === OneWayInStatus.FollowUpLater) {
      tagType = 'none'; // Notice has name "none" for some reason
    }

    return tagType;
  };

  return (
    <Tag className={className} type={determineTagType()}>
      {translateStatusTag(status)}
    </Tag>
  );
};

export default OneWayInOpportunityStatusTag;
