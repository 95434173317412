import { useQuery } from 'react-query';
import { fetchRawFromBrReg } from 'src/api/brreg';
import { BRREG_FETCH_KEY } from 'src/constants/hook-keys';

const useBrReg = (organizationNumber: string | undefined) => {
  return useQuery({
    queryKey: [BRREG_FETCH_KEY, organizationNumber],
    queryFn: () => fetchRawFromBrReg(organizationNumber),
    enabled: !!organizationNumber,
    retry: false,
  });
};

export default useBrReg;
