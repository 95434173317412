import React from 'react';
import { Card, FaIcon, FaIcons } from '@in/component-library';
import useToggle from 'src/hooks/use-toggle';
import classNames from 'classnames';
import './CardAccordion.scss';
import '../../styles/style.scss';

type Props = {
  open?: boolean;
  defaultOpen?: boolean;
  title?: React.ReactNode;
  error?: boolean | React.ReactNode;
  children?: React.ReactNode | React.ReactNode[];
  borderBottom?: boolean;
};

export const CardAccordion: React.FC<Props> = ({ children, open, title, error, borderBottom }) => {
  const { isActive, toggle } = useToggle(open || false);
  const contentRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (isActive !== open) {
      toggle();
    }

    // isActive & toggle left out cause we only want to watch for changes on open
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  React.useEffect(() => {
    if (contentRef.current) {
      if (isActive) {
        const extraMargin = 32;
        contentRef.current.style.maxHeight = contentRef.current.scrollHeight + extraMargin + 'px';
      } else {
        contentRef.current.style.maxHeight = 0 + 'px';
      }
    }
  }, [contentRef, isActive]);

  const handleToggleAccordion = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    toggle();
  };

  return (
    <Card className={classNames('card-accordion', { 'is-active': isActive, 'border--bottom': borderBottom })}>
      <button
        className={classNames('card-accordion__header', 'clickable-wrapper')}
        onClick={handleToggleAccordion}
      >
        <span className={classNames('card-accordion__header--title', { 'has-error': error })}>{title}</span>
        <span className="card-accordion__header--icon">
          {isActive ? <FaIcon name={FaIcons.ChevronUp} /> : <FaIcon name={FaIcons.ChevronDown} />}
        </span>
      </button>
      <div
        ref={contentRef}
        className={classNames('card-accordion__content', { 'is-active': isActive })}
        aria-hidden={!isActive}
      >
        {children}
      </div>
    </Card>
  );
};
