import { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { ClustersService, ClusterUpdateDto } from 'src/api/v2';

import { CLUSTER_KEY } from 'src/constants/hook-keys';

export default function useCluster() {
  const [clustersService] = useState(new ClustersService());

  const queryClient = useQueryClient();

  const { data: cluster, ...rest } = useQuery([CLUSTER_KEY], () => clustersService.getCurrent(), {});

  const updateMutation = useMutation(
    ({ clusterId, record }: { clusterId: string; record: ClusterUpdateDto }) => {
      // Ensure we do not lose our one way in municipalities on update.
      record.oneWayInMunicipalities = cluster?.oneWayInMunicipalities;
      //Same with counties.
      record.oneWayInCounties = cluster?.oneWayInCounties;
      //And VMAnames - but we are also ensuring these in BFF
      record.oneWayInVmaNames = cluster?.oneWayInVmaNames;

      return clustersService.update(clusterId, record);
    },
    {
      onSuccess(data) {
        queryClient.setQueryData([CLUSTER_KEY], data);
      },
    },
  );

  return {
    cluster,

    ...rest,

    updateMutation,
  };
}
