import React from 'react';
import { SivaCompanyDetailDto, SivaContractDetailDto, SivaContractPhaseDto, SivaPhase } from 'src/api/v2';
import IdeaChangePhaseForm from '../../components/idea/IdeaChangePhaseForm';
import PreIncWithoutCompanyForm from '../../components/company/PreIncWithoutCompanyForm';
import CompanyPhaseChangeForm from '../../components/company/CompanyPhaseChangeForm';

type Props = {
  contract: SivaContractDetailDto;
  currentPhase: SivaContractPhaseDto;
  selectedPhase: SivaPhase;
  company?: SivaCompanyDetailDto;
  onCancel: () => void;
};

const EditPhaseFormDisplay: React.FC<Props> = ({
  contract,
  currentPhase,
  selectedPhase,
  company,
  onCancel,
}) => {
  if (!selectedPhase) return <></>;
  if (contract && currentPhase.sivaPhase === SivaPhase.Idea) {
    return (
      <IdeaChangePhaseForm
        contract={contract}
        selectedPhase={selectedPhase}
        company={company ? company : undefined}
        onCancel={onCancel}
      />
    );
  }
  if (contract && currentPhase.sivaPhase === SivaPhase.PreIncubation && !company) {
    return <PreIncWithoutCompanyForm contract={contract} selectedPhase={selectedPhase} onCancel={onCancel} />;
  }
  if (contract && company && selectedPhase) {
    return (
      <CompanyPhaseChangeForm
        contract={contract}
        company={company}
        selectedPhase={selectedPhase}
        onCancel={onCancel}
      />
    );
  }
  return <></>;
};

export default EditPhaseFormDisplay;
