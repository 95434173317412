import useConfiguration from './use-configuration';

export default function useHotjar() {
  const { environmentInformation } = useConfiguration();
  function log(message: string) {
    if (environmentInformation?.name != 'prod') {
      console.debug(`HotjarEvent ${message} called`);
      return;
    }
    if (window && typeof window.hj === 'function') {
      return window.hj('event', message);
    } else {
      console.error('Hotjar was not defined, failed to push an event');
    }
  }

  return {
    log,
  };
}
