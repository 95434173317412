import React from 'react';

import { useTranslation } from 'react-i18next';

import InnovasjonNorgeLogo from '../assets/logo/innovasjon-norge.svg';
import SivaLogo from '../assets/logo/siva-logo.svg';

import styles from '../styles/ColumnFigCaption.module.scss';

const ColumnFigCaptions = () => {
  const { t } = useTranslation();
  return (
    <figure role="group" className={styles['footer-figure']}>
      <div className={styles['footer-figure__container']}>
        <figure>
          <img src={SivaLogo} alt={t('footer.siva.logo.altText')} width="90" height="40" />
        </figure>

        <figure>
          <img src={InnovasjonNorgeLogo} alt={t('footer.innovasjonNorge.logo.altText')} />
        </figure>
      </div>
      <figcaption>{t('footer.siva.figcaption')}</figcaption>
    </figure>
  );
};

export default ColumnFigCaptions;
