import { SelectOptionProps } from '@in/component-library';
import { SivaPhase } from 'src/api/v2';

export const bransjer: string[] = [
  'Batteri',
  'CCU/CCS (karbonfangst og -bruk & karbonfangst og -lagring)',
  'Havvind',
  'Hydrogen',
  'Manufacturing (industriell produksjon)',
  'Maritim industri',
  'Prosessindustri',
  'Skog/tre/bio-næring',
  'Sol',
  'Agrifoodtech',
  'Bygg og anlegg',
  'Helse',
  'IKT',
  'Kultur (som ikke inngår i opplevelsesbasert reiseliv)',
  'Marin industri (fiske og fangst, havbruk og havbeite, fiskeindustri, mel- og forproduksjon, utstyr- og tjenesteproduksjon)',
  'Mat-/drikkeproduksjon (som ikke inngår i opplevelsesbasert reiseliv)',
  'Olje og gass',
  'Opplevelsesbasert reiseliv (reiseliv, mat/drikke, natur og kultur)',
  'Tjenesteyting',
  'Annen vareproduserende industri',
  'Annen fornybar energi',
  'Annet',
];

export const newCompanyPhases = [SivaPhase.PreIncubation, SivaPhase.Incubated, SivaPhase.Fi];

export const phaseOptions = [
  {
    value: SivaPhase.PreIncubation,
    text: 'Preinkubator',
  },
  {
    value: SivaPhase.Incubated,
    text: 'Inkubatorbedrift',
  },
  {
    value: SivaPhase.Fi,
    text: 'Forretningsmessig innovasjon',
  },
];

export const bransjeOptions = bransjer.map((item) => ({ value: item, text: item }));

export const importedIdeaSource: SelectOptionProps = {
  value: 'Import',
  text: 'Import',
};

export const ideaSourceOptions: SelectOptionProps[] = [
  {
    value: 'industri',
    text: 'Industri',
  },
  {
    value: 'øvrig-næringsliv',
    text: 'Øvrig næringsliv',
  },
  {
    value: 'fou',
    text: 'FoU (universitet, høgskoler, forskningsinstitutt)',
  },
  {
    value: 'selvstendig-grunder',
    text: 'Selvstendig gründer(e)',
  },
  {
    value: 'annet',
    text: 'Annet',
  },
];
