import { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { ClusterMembersService } from 'src/api/v2';
import { MEMBER_TYPES_KEY } from 'src/constants/hook-keys';
import useCluster from '../features/cluster/hooks/use-cluster';

export default function useMemberTypes() {
  const { cluster } = useCluster();

  const [service] = useState(new ClusterMembersService());

  const { data, ...rest } = useQuery([MEMBER_TYPES_KEY], () => service.getTypes(), {
    enabled: !!cluster,
  });

  const selectOptions = useMemo(
    () => data?.map((x) => ({ value: `${x.id}`, text: `${x.name}` })) || [],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, rest.dataUpdatedAt],
  );

  return {
    memberTypes: data || [],

    ...rest,

    memberTypeOptions: selectOptions,
  };
}
