import { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { SivaContractCreateDto, SivaPhase, SivaService } from 'src/api/v2';
import { MEMBERS_KEY, SIVA_CONTRACTS_KEY } from 'src/constants/hook-keys';
import { useAuth } from 'src/features/authorization';
import { useCluster } from 'src/features/cluster';

export interface ISivaContractsFilterDto {
  year?: number;
  sivaPhases?: SivaPhase[];
  searchString?: string;
}

export function useSivaContracts(queryParams?: ISivaContractsFilterDto) {
  const { cluster } = useCluster();
  const { checkAccess } = useAuth();
  const { write, read } = checkAccess('SivaCore');

  const [service] = useState(() => new SivaService());

  const queryClient = useQueryClient();

  const { data, ...rest } = useQuery({
    queryKey: [SIVA_CONTRACTS_KEY, queryParams],
    queryFn: () => service.getSivaContracts(queryParams?.sivaPhases),
    enabled: !!cluster && read === true,
  });

  const createMutation = useMutation({
    mutationFn: (record: SivaContractCreateDto) => {
      if (!write) {
        throw new Error('access denied');
      }

      return service.createSivaContract(record);
    },
    onSuccess: () => {
      queryClient.invalidateQueries([SIVA_CONTRACTS_KEY]);
      queryClient.refetchQueries([SIVA_CONTRACTS_KEY]);
      queryClient.invalidateQueries([MEMBERS_KEY]);
      queryClient.refetchQueries([MEMBERS_KEY]);
    },
  });

  return {
    contracts: data || [],
    ...rest,

    mutations: {
      create: createMutation,
    },
  };
}
