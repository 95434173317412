import { useState } from 'react';
import { useQuery } from 'react-query';
import { DevelopmentProjectsService } from 'src/api/v2';
import { DEV_PROJECTS_KEY } from 'src/constants/hook-keys';
import { useAuth } from 'src/features/authorization';
import { useCluster } from 'src/features/cluster';

type Props = {
  projectPhaseId?: string;
  mainGoalId?: string;
  focusAreaId?: string;
};

const useDevelopmentProjects = (props: Props = {}) => {
  const { cluster } = useCluster();

  const [developmentProjectService] = useState(new DevelopmentProjectsService());

  const { checkAccess } = useAuth();
  const { read } = checkAccess('DevelopmentProject');

  const developmentProjectsQuery = useQuery(
    [DEV_PROJECTS_KEY, props.projectPhaseId, props.mainGoalId, props.focusAreaId],
    () =>
      developmentProjectService.getDevelopmentProjects(
        cluster!.id,
        props.projectPhaseId,
        props.mainGoalId,
        props.focusAreaId,
      ),
    {
      enabled: !!cluster && read,
    },
  );

  return {
    developmentProjectsQuery,
  };
};

export default useDevelopmentProjects;
