import { ClusterMemberCreateDto, ExternalService, IsFemale } from './v2';

export interface BrRegEnhet {
  organisasjonsnummer: string;
  navn: string;

  organisasjonsform: {
    kode: string;
    beskrivelse: string;
    utgaatt?: string;
  };

  hjemmeside?: string;
  postadresse?: {
    kommunenummer: string;
    kommune: string;
    postnummer: string;
    poststed: string;
    landkode: string;
    adresse: string[];
  };

  registreringsdatoEnhetsregisteret: string;
  registrertIMvaregisteret: boolean;

  naeringskode1: {
    kode: string;
    beskrivelse: string;
  };
  naeringskode2?: {
    kode: string;
    beskrivelse: string;
  };
  naeringskode3?: {
    kode: string;
    beskrivelse: string;
  };

  antallAnsatte: number;
  overordnetEnhet: string;

  forretningsadresse?: {
    postnummer?: string;
    kommune?: string;
    poststed?: string;
    landkode?: string;
    adresse?: Array<string>;
    kommunenummer?: string;
  };

  stiftelsesdato: string;

  institusjonellSektorkode?: {
    kode: string;
    beskrivelse: string;
  };

  registrertIForetaksregisteret: boolean;
  registrertIStiftelsesregisteret: boolean;
  registrertIFrivillighetsregisteret: boolean;

  sisteInnsendteAarsregnskap?: string;

  konkurs: boolean;
  underAvvikling: boolean;
  underTvangsavviklingEllerTvangsopplosning: boolean;
  maalform: string;
}

export const fetchRawFromBrReg = (organizationNumber: string | undefined): Promise<BrRegEnhet> => {
  return fetch(`https://data.brreg.no/enhetsregisteret/api/enheter/${organizationNumber}`).then((res) =>
    res.json(),
  );
};

export const fetchFromBrReg = async (
  organizationNumber: string | undefined,
): Promise<ClusterMemberCreateDto> => {
  if (organizationNumber === undefined) {
    throw new Error('organizationNumber.undefined');
  }

  const trimmedOrgNo = organizationNumber.replaceAll(' ', '');

  const response = await fetch(`https://data.brreg.no/enhetsregisteret/api/enheter/${trimmedOrgNo}`);

  if (!response.ok) {
    throw new Error(response.statusText);
  }

  const data = (await response.json()) as BrRegEnhet;

  const establishedDate = data.stiftelsesdato || data.registreringsdatoEnhetsregisteret;

  const record: ClusterMemberCreateDto = {
    name: data.navn,
    organizationNumber: data.organisasjonsnummer,
    establishedDate: new Date(establishedDate),
    international: data.forretningsadresse?.landkode !== 'NO' || false,
    clusterId: '',
    memberSince: undefined,
    clusterMemberTypeId: undefined,
    clusterMemberCategoryId: undefined,
    clusterMemberFeeId: undefined,
    interestAreaIds: [],
    mailingAddress: {
      country: data.forretningsadresse?.landkode,
      street: data.forretningsadresse?.adresse?.join(' '),
      city: data.forretningsadresse?.poststed,
      county: undefined,
      zipCode: data.forretningsadresse?.postnummer,
      municipality: data.forretningsadresse?.kommune,
      municipalityNo: data.forretningsadresse?.kommunenummer,
    },
    billingAddress: {
      country: data.postadresse?.landkode,
      street: data.postadresse?.adresse.join(' '),
      city: data.postadresse?.poststed,
      county: undefined,
      zipCode: data.postadresse?.postnummer,
      municipality: data.postadresse?.kommune,
      municipalityNo: data.postadresse?.kommunenummer,
    },
    description: undefined,
    orderNumber: undefined,
    details: {
      nace: data.naeringskode1?.kode,
      industry: data.naeringskode1.beskrivelse,
      companyTypeCode: data.organisasjonsform.kode,
      companyTypeDescription: data.organisasjonsform.beskrivelse,
      bankrupt: data.konkurs,
      liquidation: data.underAvvikling,
      forcedLiquidation: data.underTvangsavviklingEllerTvangsopplosning,
      registryEnhetsRegisteretDate: new Date(data.registreringsdatoEnhetsregisteret),
      registryMvaRegisteret: data.registrertIMvaregisteret,
      registryForetaksRegisteret: data.registrertIForetaksregisteret,
      registryStiftelsesRegisteret: data.registrertIStiftelsesregisteret,
      registryFrivillighetsRegisteret: data.registrertIFrivillighetsregisteret,

      // nye felter
      isFetchedFromBrReg: true,
      assetsTotal: 0,
      grossTurnover: 0,
      numberOfEmployees: data?.antallAnsatte || 0,
      numberOfEmployeesLastYear: 0,
      resultBeforeAllocations: 0,
      staffCosts: 0,
      femaleCEO: IsFemale.Other,
      femaleChairman: IsFemale.Other,
      femaleFounder: IsFemale.Other,
    },
  };

  // TODO: Legg til henting av finsans data her?!

  const service = new ExternalService();
  if (record.billingAddress?.zipCode) {
    const zipCode = await service.getZipCode(record.billingAddress?.zipCode);

    record.billingAddress!.county = zipCode.countyName;
    record.billingAddress!.city = zipCode.cityName;
    record.billingAddress!.municipality = zipCode.municipalityName;
    record.billingAddress!.municipalityNo = zipCode.municipalityCode;
  }

  if (record.mailingAddress?.zipCode) {
    if (record.billingAddress?.zipCode && record.mailingAddress.zipCode === record.billingAddress?.zipCode) {
      record.mailingAddress!.county = record.billingAddress.county;
      record.mailingAddress!.city = record.billingAddress.county;
      record.mailingAddress!.municipality = record.billingAddress.municipality;
      record.mailingAddress!.municipalityNo = record.billingAddress.municipalityNo;
    } else {
      const zipCode = await service.getZipCode(record.mailingAddress?.zipCode);

      record.mailingAddress!.county = zipCode.countyName;
      record.mailingAddress!.city = zipCode.cityName;
      record.mailingAddress!.municipality = zipCode.municipalityName;
      record.mailingAddress!.municipalityNo = zipCode.municipalityCode;
    }
  }

  return record;
};

export const searchBrReg = (searchTerm: string): Promise<BrRegEnhet[]> => {
  return new Promise((resolve, reject) => {
    if (searchTerm.length < 3) {
      resolve([]);
    }

    const encodedValue = encodeURIComponent(searchTerm.trim());

    if (encodedValue === '') {
      // No need to call API with empty string
      return;
    }

    let params = `navn=${encodedValue}`;

    // Check if matching organization number (also with spaces. Example: '987 654 321')
    if (encodedValue.match(/[0-9]{9}/) || encodedValue.match(/[0-9]{3}%20[0-9]{3}%20[0-9]{3}/)) {
      params = `organisasjonsnummer=${encodedValue.replaceAll('%20', '')}`;
    }

    fetch(`https://data.brreg.no/enhetsregisteret/api/enheter?${params}&konkurs=false`)
      .then((response) => {
        if (!response.ok) {
          reject(response.statusText);
        }

        return response.json();
      })
      .then((data) => {
        if (data?._embedded) {
          return data?._embedded.enheter;
        } else {
          return [];
        }
      })
      .then((data: BrRegEnhet[]) => {
        resolve(data);
      })
      .catch(reject);
  });
};
