import { FaIcon, FaIcons } from '@in/component-library';
import { createColumnHelper } from '@tanstack/react-table';
import React from 'react';
import { OneWayInFeedbackDto } from 'src/api/v2';

const columnHelper = createColumnHelper<OneWayInFeedbackDto>();

export const otherReviewTableColumns = [
  columnHelper.accessor((row) => row.wasCorrectlyReferred, {
    id: 'referredCorrectly',
    size: 100,
    maxSize: 100,
    enableSorting: false,
    cell: (info) => (
      <FaIcon
        size="lg"
        color={info.getValue() ? '#2fcc72' : '#ed0000'}
        name={info.getValue() ? FaIcons.CheckCircle : FaIcons.Times}
        className="margin-right--1"
      />
    ),
  }),
  columnHelper.accessor((row) => row.clusterName, {
    id: 'actorName',
    cell: (info) => <span>{info.getValue()}</span>,
  }),
  columnHelper.accessor((row) => row.comment, {
    id: 'comment',
    cell: (info) => <span>{info.getValue()}</span>,
  }),
];
