import React from 'react';
import ForwardOpportunityUserReroute from './pages/ForwardOpportunityUserReroute';

import { RouteObject } from 'react-router-dom';

export const TRACKING_ROUTES = {
  FORWARD_OPPORTUNITY: `/opportunity-forward`,
};

const routes: RouteObject[] = [
  {
    path: TRACKING_ROUTES.FORWARD_OPPORTUNITY,
    element: <ForwardOpportunityUserReroute />,
  },
];

export default routes;
