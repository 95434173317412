import { BrregSearch, Button, listItem } from '@in/component-library';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useBrregSearch } from 'src/hooks/use-brreg-search';
import { useDebounce } from 'usehooks-ts';

type Props = {
  onClick: (organizationNumber: string, name: string) => void;
  initialOrganizationNumber: string;
  onSkip: undefined | (() => void);
};

const NewCompanyBrregSearch: React.FC<Props> = ({ onClick, initialOrganizationNumber, onSkip }) => {
  const { t: tSiva } = useTranslation('siva');

  const [searchValue, setSearchValue] = useState<string>(initialOrganizationNumber);
  const [error, setError] = useState('');

  const searchValueDebounced = useDebounce(searchValue, 100);

  const { data } = useBrregSearch(searchValueDebounced);

  const options: listItem[] = useMemo(
    () =>
      data?._embedded?.enheter.map((item) => ({
        organizationNumber: item.organisasjonsnummer,
        name: item.navn,
        address: item.forretningsadresse
          ? `${item.forretningsadresse?.postnummer ?? ''} ${item.forretningsadresse?.kommune ?? ''}`
          : '',
      })) ?? [],
    [data?._embedded?.enheter],
  );

  return (
    <>
      <h2>{tSiva('newCompany.brregSearch.title')}</h2>

      <p>{tSiva('newCompany.brregSearch.description')}</p>

      <BrregSearch
        id={'brregSearch'}
        label={tSiva('newCompany.brregSearch.search.label')}
        name={'brregSearch'}
        className="margin-bottom--4"
        value={searchValue}
        onChange={(e) => setSearchValue(e.currentTarget.value ?? '')}
        list={options}
        selected={[]}
        options={options}
        listItemOnClick={(item) => {
          setError('');
          const organizationNumber = item.currentTarget.value;
          const found = options.find((option) => option.organizationNumber === organizationNumber);
          onClick(organizationNumber, found?.name ?? '');
        }}
        hideError={!error}
        errorMsg={error}
        focus={true}
        open={true}
      />

      {onSkip && (
        <Button theme="neutral" variant="solid" onClick={onSkip}>
          Gå videre uten å legge til bedrift
        </Button>
      )}
    </>
  );
};

export default NewCompanyBrregSearch;
