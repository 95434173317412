import { Card, Tag } from '@in/component-library';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { DevelopmentProjectDto, DevelopmentProjectListItemDto, PortalProjectListItemDto } from 'src/api/v2';
import DialogContainer from 'src/components/DialogContainer/DialogContainer';
import { LoadingPage } from 'src/components/LoadingPage/LoadingPage';
import { RegisterInkindModal } from 'src/features/inkind';
import EventFormWrapper from 'src/features/projects/details/components/EventFormWrapper';
import useProjectStatistics from 'src/hooks/use-project-statistics';
import { formatDateToNOString } from 'src/utils/FormatValue';
import { useBoolean } from 'usehooks-ts';
import { isDevelopmentProject, isOperationProject } from '../../utils/project-utils';
import ProjectCardButtons from './ProjectCardButtons';
import ProjectCardStats from './ProjectCardStats';
import cn from 'classnames';
import css from './ProjectCard.module.scss';

type ProjectCardProps = {
  project: DevelopmentProjectListItemDto | PortalProjectListItemDto;
  isFetching: boolean;
  projectType: 'development' | 'operation' | 'other' | undefined;
};

const ProjectCard: React.FC<ProjectCardProps> = ({ project, isFetching, projectType }) => {
  const { projectStatistics, isFetching: isFetchingStats } = useProjectStatistics(project.id, projectType);

  const registerInkindModal = useBoolean();
  const createEventModal = useBoolean();

  const { t: tCommon } = useTranslation();
  const { t: tEvents } = useTranslation('events');
  const { t: tProjects } = useTranslation('projects');

  const link = useMemo(() => {
    if (isOperationProject(project)) return `/cluster-operation/projects/${project.id}`;

    if (isDevelopmentProject(project)) return `/projects/in-projects/${project.id}`;

    return `/projects/details/${project.id}`;
  }, [project]);

  const tagType = useMemo(() => {
    switch (project.projectEndReason?.projectEndCategory.code) {
      case 'PEC01':
        return 'error';
      case 'PEC02':
        return 'success';
      case 'PEC03':
        return 'none';
      default:
        return 'info';
    }
  }, [project.projectEndReason?.projectEndCategory.code]);

  return (
    <>
      {isFetching ? (
        <LoadingPage />
      ) : (
        <Card
          boxShadow="elevated"
          className={cn(
            css['project-card'],
            'display--grid grid-cols--12 width--100 justify-content--space-between margin-bottom--4',
          )}
        >
          <div className="col-span--6">
            <div className="display--flex gap--1">
              <Tag type={tagType}>
                {project.projectEndReason === null
                  ? tCommon('active')
                  : tProjects(
                      `${
                        project.projectEndReason == null
                          ? tCommon('active')
                          : project.projectEndReason?.projectEndCategory.code
                      }` as unknown as TemplateStringsArray,
                    )}
              </Tag>

              <span className={'font-size--smaller'}>
                {isDevelopmentProject(project) && !isOperationProject(project)
                  ? `${(project as DevelopmentProjectDto).projectType?.name} - ${project.projectPhase?.name}`
                  : project.projectPhase?.name}
              </span>
            </div>

            <Link to={link}>
              <h2>{project.name}</h2>
            </Link>

            <span>
              {tCommon('startDate')}:{' '}
              {formatDateToNOString(project.startDate, false, '{date}.{month}.{year}', true)} |{' '}
              {tCommon('endDate')}:{' '}
              {formatDateToNOString(project.endDate, false, '{date}.{month}.{year}', true)}
            </span>
          </div>

          <div className="col-span--3 display--flex justify-content--center">
            <ProjectCardStats projectStatistics={projectStatistics} isFetching={isFetchingStats} />
          </div>

          <div className="col-span--3 display--flex justify-content--flex-end align-items--center">
            <ProjectCardButtons
              project={project}
              createEventModal={createEventModal}
              registerInkindModal={registerInkindModal}
            />
          </div>
        </Card>
      )}

      <DialogContainer
        title={tEvents('newEvent')}
        show={createEventModal.value}
        onClose={createEventModal.setFalse}
      >
        <EventFormWrapper project={project} onClose={createEventModal.setFalse} />
      </DialogContainer>

      <RegisterInkindModal
        show={registerInkindModal.value}
        onClose={registerInkindModal.setFalse}
        defaultValues={{ projectId: project.id }}
        isApplicationProject={isOperationProject(project)}
      />
    </>
  );
};

export default ProjectCard;
