import { Button, ButtonVariant, Theme } from '@in/component-library';
import React from 'react';
import { DataTableV3Actions } from '../types';
import { Table } from '@tanstack/react-table';

type PropType<TData extends object> = {
  actions: DataTableV3Actions<TData> | undefined;
  table: Table<TData> | undefined;
};

const PrimaryActions = <TData extends object>({ actions, table }: PropType<TData>) => {
  if (!table || !actions) {
    return <></>;
  }

  return (
    <div className="display--flex align-items--center gap--2">
      {actions?.primary?.map((action, index) => (
        <Button
          key={index}
          type="button"
          iconName={action.icon}
          theme={action.theme ?? Theme.Neutral}
          variant={action.variant ?? ButtonVariant.Solid}
          onClick={() => {
            const rows = table.getSelectedRowModel().flatRows.map((row) => row.original);
            const resetSelected = action.onClick?.(rows);
            if (resetSelected === true) {
              table.toggleAllRowsSelected(false);
            }
          }}
        >
          {action.label}
        </Button>
      ))}
    </div>
  );
};

export default PrimaryActions;
