import { SivaService } from 'src/api/v2';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { KTU_DATE_KEY } from '../constants/hook-keys';

/**
 * Calculate which KTU dates to show based on a give contract start date
 * and a given minimum date for KTU dates
 * @param date the start date of the contract
 */
export const useCalculateSivaKTUDates = (
  date: Date | string | undefined | null,
): [undefined, undefined] | [Date, Date] => {
  const [sivaService] = useState(new SivaService());

  const ktuDateQuery = useQuery(
    [KTU_DATE_KEY, date],
    async () => {
      if (!date) return undefined;
      const safeDate = typeof date === 'string' ? new Date(date) : date;
      const response = await sivaService.getEarliestKtuStartupFormDate(safeDate);

      // Nswag is returning string instead of the Date it claims to return
      return new Date(response);
    },
    {
      // Should always return the same if given the same date,
      // so we try to avoid refetching
      staleTime: Infinity,
      cacheTime: Infinity,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
  );

  const start = ktuDateQuery.data;

  if (start === undefined) return [undefined, undefined];

  const mid = new Date(start.getFullYear() + 1, start.getMonth(), 1);

  return [start, mid];
};
