import React from 'react';
import './SupportButton.scss';
import { FaIcon } from '@in/component-library';
import { icon } from '@fortawesome/fontawesome-svg-core';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import useToggle from 'src/hooks/use-toggle';
import SupportButtonPopup from './SupportButtonPopup';
import { useTranslation } from 'react-i18next';
import ProgramRenderer from '../ProgramRenderer';
import { useUser } from 'src/features/authorization';

const SupportButton: React.FC = () => {
  const { isActive: showPopup, deactivate: deactivatePopup, activate: activatePopup } = useToggle();
  const { t: tCommon } = useTranslation();

  const { isUserLoggedIn, user } = useUser();

  if (isUserLoggedIn && user?.chosenClusterId) {
    return (
      <ProgramRenderer
        render={[
          {
            program: '*',
            children: (
              <>
                <div className="support">
                  <button
                    className="support__button"
                    aria-label={tCommon('contactSupport')}
                    onClick={() => activatePopup()}
                  >
                    <FaIcon
                      icon={icon({ prefix: 'far', iconName: 'message-question' }) as IconDefinition}
                      size="2x"
                      className={'support__button-icon'}
                    />
                  </button>
                </div>
                {showPopup && <SupportButtonPopup show={showPopup} deactivate={() => deactivatePopup()} />}
              </>
            ),
          },
          {
            program: 'one-way-in',
            children: null,
          },
        ]}
      />
    );
  } else {
    return <></>;
  }
};

export default SupportButton;
