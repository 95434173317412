import React, { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

import EventOverview from 'src/features/events/pages/EventOverview';

const EventDetails = lazy(() => import('src/features/events/pages/EventDetails/EventDetails'));

const routes: RouteObject[] = [
  {
    path: '',
    element: <EventOverview />,
  },
  {
    path: '/:eventId',
    element: <EventDetails />,
  },
];

export default routes;
