import React from 'react';
import { useTranslation } from 'react-i18next';

import { Spinner } from '@in/component-library';

import Page from 'src/components/Page/Page';

type Props = {
  text?: string;
};

export const LoadingPage: React.FC<Props> = ({ text }) => {
  const { t: tCommon } = useTranslation();

  const resolvedText = text || tCommon('loading');

  return (
    <Page>
      <div className="padding-y--5 text-align--center">
        <Spinner text={resolvedText} />
      </div>
    </Page>
  );
};
