import React, { useMemo } from 'react';

import { MultiSelectListBox, MultiSelectListBoxProps } from '@in/component-library';
import useUpdateQueryStringParam from 'src/features/one-way-in/opportunity-table/hooks/use-update-query-string-param';

type FilterProps = {
  queryStringParam: string;
} & Omit<MultiSelectListBoxProps, 'value'> &
  React.RefAttributes<HTMLDivElement>;

const QueryParamMultiSelect = (props: FilterProps) => {
  const { searchParams } = useUpdateQueryStringParam();
  const { queryStringParam } = props;
  const { queryParams } = useMemo(() => {
    const queryParams = searchParams.get(queryStringParam)?.split(',') ?? [];

    return {
      queryParams,
    };
  }, [queryStringParam, searchParams]);

  const { updateQueryStringParam } = useUpdateQueryStringParam();

  return (
    <MultiSelectListBox
      {...props}
      value={props.options.filter((option) => queryParams.includes(option.value))}
      onAdd={(id) => updateQueryStringParam(queryStringParam, id)}
      onRemove={(id) => updateQueryStringParam(queryStringParam, id)}
    />
  );
};

export default QueryParamMultiSelect;
