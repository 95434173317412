import { useMemo } from 'react';
import { OneWayInServiceProviderDto } from 'src/api/v2';

const useMunicipalities = (actors: OneWayInServiceProviderDto[]) => {
  const municipalities = useMemo(() => {
    return [
      ...new Set(
        actors
          .map((actor) => actor.municipalities)
          .flat()
          .sort(),
      ),
    ].map((municipality) => ({
      text: municipality,
      value: municipality,
    }));
  }, [actors]);

  return { municipalities };
};

export default useMunicipalities;
