import React from 'react';
import './AppLoading.scss';
import logo from 'src/assets/img/nic_klynger_logo_positiv_nic-main_compact.svg';
import { useTranslation } from 'react-i18next';

const AppLoading: React.FC = () => {
  const { t: tPageTitles } = useTranslation('pageTitles');

  return (
    <div className="app-loading">
      <div className="app-loading__wrapper">
        <div className="logo-spinner">
          <img className="logo-spinner__logo" src={logo} alt={`${tPageTitles('portal')} logo spinner`} />
        </div>
      </div>
    </div>
  );
};

export default AppLoading;
