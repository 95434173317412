import { FaIcon, FaIcons } from '@in/component-library';
import classNames from 'classnames';
import React from 'react';
import { useSivaBudgetDetailsList } from '../../hooks/use-siva-budget-details-list';
import TertiaryButton from 'src/components/TertiaryButton/TertiaryButton';

type Props = {
  budgetId: string;
};

const BudgetDownloadButton: React.FC<Props> = ({ budgetId }) => {
  const { mutations } = useSivaBudgetDetailsList(undefined);

  return (
    <TertiaryButton
      aria-label="Last ned budsjett"
      onClick={async () => await mutations.download.mutateAsync(budgetId)}
      disabled={mutations.download.isLoading}
      className={classNames({
        'animation--spin': mutations.download.isLoading,
      })}
      minimalPadding
    >
      <FaIcon name={mutations.download.isLoading ? FaIcons.SpinnerLight : FaIcons.ArrowToBottomLight} />
    </TertiaryButton>
  );
};

export default BudgetDownloadButton;
