import React from 'react';
import { Navigate, RouteObject } from 'react-router-dom';

const AccessControl = React.lazy(() => import('./pages/AccessControl/AccessControl'));
const ContactInformation = React.lazy(() => import('./pages/ContactInformation/ContactInformation'));
const VisionMission = React.lazy(() => import('./pages/VisionMission/VisionMission'));
const MainGoal = React.lazy(() => import('./pages/MainGoal/MainGoal'));
const FocusAreas = React.lazy(() => import('./pages/FocusAreas/FocusAreas'));
const CustomProjectCategory = React.lazy(() => import('./pages/ProjectCustomCategory/CustomProjectCategory'));
const Grunndata = React.lazy(() => import('./pages/Grunndata/Grunndata'));
const MembershipFee = React.lazy(() => import('./pages/Grunndata/pages/MembershipFee/MembershipFee'));
const TimeRate = React.lazy(() => import('./pages/Grunndata/pages/TimeRate/TimeRate'));
const InterestAreas = React.lazy(() => import('./pages/Grunndata/pages/InterestAreas/InterestAreas'));
const AboutCluster = React.lazy(() => import('./pages/AboutCluster/AboutCluster'));
const BusinessDescription = React.lazy(() => import('./pages/BusinessDescription/BusinessDescription'));

const routes: RouteObject[] = [
  {
    element: <Navigate to={`about-cluster`} replace />,
    path: '',
  },
  {
    element: <AboutCluster />,
    path: 'about-cluster/*',
    children: [
      {
        index: true,
        element: <Navigate replace to={`contact-information`} />,
        path: '',
      },
      {
        element: <ContactInformation />,
        path: 'contact-information',
      },
      {
        element: <AccessControl />,
        path: 'access-control',
      },
      {
        path: '*',
        element: <Navigate to="/account-settings/about-cluster/contact-information" replace />,
      },
    ],
  },
  {
    element: <BusinessDescription />,
    path: 'business-description/*',
    children: [
      {
        element: <Navigate to={`vision-mission`} replace />,
        path: '',
      },
      {
        element: <VisionMission />,
        path: 'vision-mission',
      },
      { element: <MainGoal />, path: 'main-goals' },
      { element: <FocusAreas />, path: 'focus-areas' },
      {
        element: <CustomProjectCategory />,
        path: 'project-categories',
      },
      {
        path: '*',
        element: <Navigate to="/account-settings/business-description/vision-mission" replace />,
      },
    ],
  },
  {
    element: <Grunndata />,
    path: 'core-data/*',
    children: [
      {
        element: <Navigate to={`membership-fees`} replace />,
        path: '',
      },
      { element: <MembershipFee />, path: 'membership-fees' },
      { element: <TimeRate />, path: 'time-rates' },
      { element: <InterestAreas />, path: 'interest-areas' },
      {
        path: '*',
        element: <Navigate to="membership-fees" replace />,
      },
    ],
  },
  {
    path: '*',
    element: <Navigate to="/account-settings/about-cluster/contact-information" replace />,
  },
];

export default routes;
